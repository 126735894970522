import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {toast} from 'react-toastify';
import {useQuery} from 'react-query';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  appEmpty,
  chevronRight,
  configurationIc,
  edit,
  eyeDark,
  trash,
} from '../../assets/images/images';
import {
  DashboardLayout,
  PrimaryButton,
  TableLayout,
  Loader,
  EmptyState,
  PageLimit,
  Pagination,
} from '../../components';
import CreateTokenModal from './CreateTokenModal';
import DeleteTokenModal from './DeleteTokenModal';
import EditTokenModal from './EditTokenModal';
import RevealKeyModal from './RevealKeyModal';
import {fetchApiTokens} from '../../requests/queries/tokens';
import {CopyIcon} from '../../components/images';
import * as Actions from '../../store/actions';
import {PageContext} from '../../helpers/context';

function ApiTokens({getAllApps, apps: {allApps}, auth: {userDetails}}) {
  const navigate = useNavigate();
  const [openTokenModal, setOpenTokenModal] = useState(false);
  const [openEditToken, setOpenEditToken] = useState(false);
  const [openDeleteToken, setOpenDeleteToken] = useState(false);
  const [openRevealKey, setOpenRevealKey] = useState(false);
  const [currentToken, setCurrentToken] = useState({});
  const [view, setView] = useState({});
  const [currentPage, setCurrentPage] = useState('1');
  const [limit, setLimit] = useState(10);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('apitokens');
  }, [setPageName]);

  useEffect(() => {
    getAllApps({limit: 10000});
  }, [getAllApps]);

  const userApps =
    allApps &&
    allApps?.apps?.map(app => ({
      value: app._id,
      label: app.name,
      name: app.name,
    }));

  const {data: apiTokens, isLoading} = useQuery(
    ['api-tokens', {page: currentPage, limit}],
    () =>
      fetchApiTokens({
        page: currentPage,
        limit,
      }),
    {
      onSuccess: data => {
        toast.success(data.message);
      },
      onError: error => {
        toast.error(error.response.data.error);
      },
    },
  );

  const manageTokens =
    userDetails?.permissions?.developerPermissions?.manageTokens;

  const data = apiTokens?.apiTokens;
  const noToken = apiTokens?.totalRecords === 0;

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const handleCopyToken = token => {
    if (view[token._id]) {
      navigator.clipboard
        .writeText(token?.token)
        .then(() => {
          toast.success('Copied to clipboard');
        })
        .catch(error => {
          console.error('Copy failed: ', error);
        });
    } else {
      setCurrentToken(prevToken => ({...prevToken, selectedKey: token._id}));
      setOpenRevealKey(true);
    }
  };

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={configurationIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Developers</p>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">API Tokens</p>
        </div>
      }
    >
      <CreateTokenModal
        open={openTokenModal}
        setOpen={setOpenTokenModal}
        userApps={userApps}
      />
      <EditTokenModal
        selectedToken={currentToken}
        open={openEditToken}
        setOpen={setOpenEditToken}
        userApps={userApps}
      />
      <DeleteTokenModal
        selectedToken={currentToken}
        open={openDeleteToken}
        setOpen={setOpenDeleteToken}
      />
      <RevealKeyModal
        setRevealKey={() =>
          setView(prev => ({...prev, [currentToken.selectedKey]: true}))
        }
        open={openRevealKey}
        setOpen={setOpenRevealKey}
      />

      <section className="flex flex-col">
        <div className="flex items-center justify-between w-full mb-8">
          <h2 className="text-base font-medium text-grey sm:text-lg">
            API Tokens
          </h2>
          {manageTokens && (
            <PrimaryButton
              buttonText="Create Token"
              onClick={() => setOpenTokenModal(prev => !prev)}
            />
          )}
        </div>
        <TableLayout negativeMargins negativeRightMargin="-mr-4 xl:-mr-[76px]">
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-4 text-xs font-medium text-left sm:pl-6 xl:pl-12 text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>token name</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>token id</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>date/time</span>
                </div>
              </th>
              <th className="w-[25.9%]"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {isLoading ? (
                  <Loader height={35} />
                ) : !isLoading && noToken ? (
                  <EmptyState
                    body="You have not created any token yet."
                    height={50}
                    src={appEmpty}
                    buttonText="Create Token"
                    onClick={() => setOpenTokenModal(prev => !prev)}
                    maxWidth="max-w-[276px] p-6"
                  />
                ) : null}
              </td>
            </tr>
          </tbody>

          <tbody className="text-sm bg-white">
            {data?.map((token, i) => (
              <tr className="border-b border-grey60" key={i}>
                <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                  {token?.name || '-'}
                </td>
                <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                  <div className="flex items-center gap-3">
                    <span className="-mb-1">
                      {view[token._id]
                        ? token?.token
                        : '***********************'}
                    </span>
                    <button onClick={() => handleCopyToken(token)}>
                      {view[token._id] ? (
                        <CopyIcon />
                      ) : (
                        <img src={eyeDark} alt="" width={14} height={14} />
                      )}
                    </button>
                  </div>
                </td>
                <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                  {moment(token?.createdAt).format('Do MMM, YYYY h:mm a') ||
                    '-'}
                </td>
                <td className="p-4 pl-0 whitespace-nowrap">
                  {manageTokens && (
                    <div className="flex items-center gap-4">
                      <button
                        title="Edit token"
                        className="flex items-end gap-1 w-max"
                        onClick={() => {
                          setCurrentToken(token);
                          setOpenEditToken(prev => !prev);
                        }}
                      >
                        <img src={edit} alt="" width={16} height={16} />
                      </button>
                      <button
                        title="Delete token"
                        onClick={() => {
                          setCurrentToken(token);
                          setOpenDeleteToken(prev => !prev);
                        }}
                        className="h-4 aspect-square"
                      >
                        <img src={trash} alt="" width={16} height={16} />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </TableLayout>

        <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
          <div className="flex items-center text-body text-tiny">
            <PageLimit
              onLimitChange={setLimit}
              total={apiTokens && apiTokens?.totalRecords}
              length={data?.length}
              limit={limit}
            />
          </div>

          <div className="mb-8 sm:mb-0">
            <Pagination
              total={data && Math.ceil(apiTokens?.totalRecords / limit)}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
                const searchParams = new URLSearchParams(
                  document.location.search,
                );
                searchParams.set('page', activePage);
                const newSearchParams = searchParams.toString();
                navigate(`?${newSearchParams}`);
              }}
            />
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(ApiTokens);
