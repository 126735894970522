import React, {useCallback, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {
  DashboardLayout,
  EmptyState,
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  SearchInput,
  SecondaryButton,
  TableLayout,
} from '../../../components';
import {
  baasMobile,
  blueInfo,
  corruptedFile,
  eye,
  failed,
  greenCheck,
  greenCheckBg,
  pending,
  pendingIcon,
  successful,
} from '../../../assets/images/images';
import {
  fetchBAASTransactionPayoutsSummary,
  fetchBAASTransactions,
  fetchBAASTransactionsSummary,
} from '../../../requests/queries/baas';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {getStatusTextAndStyles} from '../../../helpers/getStatusTextAndStyles';
import BaasTransactionModal from './BaasTransactionModal';
import {filterOutEmptyValues, generateQueryParams} from '../../../helpers';
import {useBaasExport} from './hooks/useBAASExport';
import BaasWithdrawModal from './BaasWithdrawModal';
import {useBaasSettlement} from './hooks/useBaasSettlement';
import {typeAndStatusFilterOptions} from './constants';
import {usePermissions} from '../../../hooks/useSidbarPermissions';
import {OverviewCards} from '../../../components/OverviewCards';
import {buildTableCardData} from '../../../helpers/buildTableCardData';
import AppliedFilters from 'components/AppliedFilters';

const initialFilters = {
  start: '',
  end: '',
};

function BaasTransactions() {
  const navigate = useNavigate();
  const [isWithdrawModal, setIsWithdrawModal] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [openFilter, setOpenFilter] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [transactionDetails, setTransactionDetails] = useState(false);
  const [searchText, setSearchText] = useState('');
  const {userDetails} = useSelector(state => state?.auth);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterProps, setFilterProps] = useState(null);

  const [query, setQuery] = useState({
    page: 1,
    length: 10,
  });
  const {user_account} = useBaasSettlement();
  const toggleTransactionsModal = useCallback(transaction => {
    setTransactionDetails(prevState => !prevState);
    setTransaction(transaction);
  }, []);

  const {
    baasPagePermissions: {canTransfer, canExportTransactions},
  } = usePermissions();

  function shortenText(text, maxLength = 0) {
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength - 5)}......${text.slice(-3)}`;
  }

  const params = useMemo(
    () => ({
      company_id: userDetails && userDetails?.company?.id,
      ...query,
      search: searchText,
      start_date: filters?.start,
      end_date: filters?.end,
      status: filters?.transaction_status?.toString()?.toUpperCase(),
      transaction_type: filters?.transaction_type?.toString()?.toLowerCase(),
    }),
    [
      filters?.end,
      filters?.start,
      query,
      searchText,
      userDetails,
      filters?.transaction_status,
      filters?.transaction_type,
    ],
  );

  const {handleExport, exportData} = useBaasExport({
    params,
    apiFunc: fetchBAASTransactions,
  });
  const {data: baas_transactions, isLoading} = useQuery(
    ['baas-transactions', {params}],
    () => fetchBAASTransactions({params}),
    {
      retry: false,
      meta: {preventGlobalError: true},
      enabled: !exportData,
    },
  );

  const {data: baas_transactions_summary, isLoading: summaryLoading} = useQuery(
    ['baas-transaction-summary', {params}],
    () => fetchBAASTransactionsSummary({params}),
    {enabled: !exportData},
  );

  const transactions = useMemo(
    () => !isLoading && baas_transactions && baas_transactions?.entity,
    [baas_transactions, isLoading],
  );

  const toggleWithdrawModal = () => setIsWithdrawModal(prevState => !prevState);

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };
  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);
    const {status} = filteredOptions;
    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }
    let baseUrl = '';
    if (queryParams) {
      baseUrl += `?${queryParams}`;
    }

    if (shouldDownload) {
      filteredOptions.download = true;
      setFilters({
        ...filters,
        ...filteredOptions,
      });
    } else {
      navigate(baseUrl);
    }

    setFilters(filteredOptions);
    setOpenFilter(false);
    setFiltersApplied(true);
  };
  const handleResetFilter = () => {
    navigate('');
    setFilters(initialFilters);
    setOpenFilter(true);
    setSearchText('');
    setFiltersApplied(false);
  };

  const isNotFiltered = useMemo(
    () => !isLoading && transactions?.transactions?.length === 0,
    [transactions?.transactions?.length, isLoading],
  );

  const isFiltered = useMemo(
    () =>
      isNotFiltered &&
      (Object.values(filters).some(s => s) || searchText?.length > 0),
    [isNotFiltered, filters, searchText],
  );

  const summaryData = useMemo(
    () =>
      (!summaryLoading &&
        baas_transactions_summary &&
        baas_transactions_summary?.entity) ||
      {},
    [baas_transactions_summary, summaryLoading],
  );

  const transactionsSummary = [
    {
      icon: pendingIcon,
      alt: 'pending',
      title: 'Pending',
      value: shortenLargeNumbers(summaryData?.pending || 0, null, {
        startAt: 'M',
        decimals: 0,
      }),
    },
    {
      icon: successful,
      alt: 'successful',
      title: 'Successful',
      value: shortenLargeNumbers(summaryData?.successful || 0, null, {
        startAt: 'M',
        decimals: 0,
      }),
    },
    {
      icon: failed,
      alt: 'failed',
      title: 'Failed',
      value: shortenLargeNumbers(summaryData?.failed || 0, null, {
        startAt: 'M',
        decimals: 0,
      }),
    },
  ];

  const tableCardData = buildTableCardData(
    {
      count: (summaryData?.credit ?? 0) + (summaryData?.debit ?? 0),
    },
    transactionsSummary,
  );

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={baasMobile} alt="" />
          <p className="ml-2 -mb-1">Transactions</p>
        </div>
      }
    >
      <div className="flex items-center gap-4 flex-wrap">
        <p className="text-grey font-medium text-[18px]">Transactions</p>
        {filtersApplied && filterProps && (
          <AppliedFilters {...filterProps} isMainPage={true} />
        )}
      </div>
      <div className="flex mt-8 items-start lg:items-center lg:flex-row flex-col gap-5 justify-between p-5 lg:p-0">
        <div className="flex items-center gap-2">
          <SearchInput
            onChange={value => setSearchText(value)}
            className="sm:w-[277px] rounded-lg outline-[#eceff3]"
          />
          <FilterButton
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            className="h-[48px] justify-center w-[130px]"
          />
        </div>
        <div className="flex items-center gap-2">
          <SecondaryButton
            buttonText="Export"
            className="w-[121px]"
            loading={exportData}
            onClick={handleExport}
            disabled={!canExportTransactions}
          />
          <PrimaryButton
            buttonText="Transfer"
            className="w-[121px]"
            onClick={toggleWithdrawModal}
            disabled={!canTransfer}
          />
        </div>
      </div>

      <BaasWithdrawModal
        show={isWithdrawModal}
        onClose={toggleWithdrawModal}
        userBalance={user_account?.amount}
        type="transfer"
      />

      <BaasTransactionModal
        show={transactionDetails}
        onClose={toggleTransactionsModal}
        transaction={transaction}
      />

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={typeAndStatusFilterOptions}
        onFilterStateChange={handleFilterStateChange}
      />
      <div className="mt-8">
        <OverviewCards
          cardData={tableCardData}
          overviewLoading={isLoading}
          className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-${tableCardData.length} gap-2 lg:gap-4 flex-wrap my-5`}
        />

        <TableLayout negativeMargins className="mt-6 w-full">
          <thead className="text-small font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                name
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                date created
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                amount
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                channel
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                type
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                ref id
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                status
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {isLoading ? (
                  <Loader height={45} />
                ) : !isFiltered && isNotFiltered ? (
                  <EmptyState
                    height={45}
                    noBtn
                    className="w-full"
                    src={corruptedFile}
                    heading="No Transaction History Found"
                    body="Sorry, it appears there is no transaction history available for this company."
                    maxWidth="max-w-[500px]"
                  />
                ) : isFiltered ? (
                  <EmptyState
                    height={45}
                    noBtn
                    customBtn={
                      <button
                        onClick={handleResetFilter}
                        className="text-brandBlue p-4 text-sm font-medium"
                      >
                        Update preferences
                      </button>
                    }
                    heading="No Transaction History Found"
                    body="Oops, it looks like there's no transaction history that matches the filters/search term you've selected. Please try adjusting your filters."
                  />
                ) : null}
              </td>
            </tr>
          </tbody>

          {!isLoading &&
            transactions?.transactions?.map((transaction, idx) => (
              <tbody className="text-sm text-body bg-white" key={idx}>
                <tr
                  className="text-left border-b  bg-white cursor-pointer border-grey60"
                  onClick={() => toggleTransactionsModal(transaction)}
                >
                  <td className="p-4 pl-12 whitespace-nowrap">
                    {transaction?.transaction_type === 'transfer'
                      ? transaction?.recipient_account_name
                      : transaction?.sender_account_name || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {(transaction?.date_created &&
                      moment(transaction?.date_created)?.format(
                        'DD MMM YYYY h:m',
                      )) ||
                      '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {shortenLargeNumbers(
                      transaction?.transaction_amount || 0,
                      'NGN',
                      {startAt: 'B'},
                    )}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span className="uppercase font-inter w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center text-dojahBlue bg-[#EEF3FC] text-xs">
                      {transaction?.source || '-'}
                    </span>
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span
                      className={`${
                        getStatusTextAndStyles(
                          transaction?.transaction_type === 'transfer' &&
                            transaction?.transaction_event_type?.includes(
                              'transaction_charge',
                            )
                            ? 'charge'
                            : transaction?.transaction_type?.toLowerCase(),
                        )?.solid
                      } uppercase font-inter w-fit rounded-full px-2 py-1 h-5 flex items-center justify-center  text-xs`}
                    >
                      {transaction?.transaction_type === 'transfer' &&
                      !transaction?.transaction_event_type?.includes(
                        'transaction_charge',
                      )
                        ? 'Debit'
                        : transaction?.transaction_event_type?.includes(
                            'transaction_charge',
                          )
                        ? 'Charge'
                        : 'Credit' || '-'}
                    </span>
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {shortenText(transaction?.transaction_id || '--', 21) ||
                      '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <span
                      className={` ${
                        getStatusTextAndStyles(
                          transaction?.transaction_status?.toLowerCase(),
                        )?.styles
                      } uppercase w-fit rounded-full px-2 py-1 h-5 font-inter flex items-center justify-center  text-xs`}
                    >
                      {
                        getStatusTextAndStyles(
                          transaction?.transaction_status?.toLowerCase(),
                        )?.text
                      }
                    </span>
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <button>
                      <img src={eye} alt="" />
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
        </TableLayout>
      </div>

      {!isLoading && transactions?.transactions?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={length => {
              setQuery(prevState => ({
                ...prevState,
                length,
                page: 1,
              }));
            }}
            limit={query?.length}
            total={transactions?.current_page}
            length={transactions?.transactions?.length}
          />

          <Pagination
            total={transactions?.total_pages}
            current={Number(transactions?.current_page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
              const newSearchParams = searchParams.toString();
              navigate(`?${newSearchParams}`);
            }}
          />
        </div>
      )}
    </DashboardLayout>
  );
}

export default BaasTransactions;
