import React, {memo} from 'react';
import {NavLink} from 'react-router-dom';
import * as Assets from '../../assets/images/images';

import {Constants} from '../../helpers';
import {baasNavLinks} from './constants';
import {NavigationItem, SubNavigationDropdown} from './components';
import {usePermissions} from 'hooks/useSidbarPermissions';
import {
  apiLogsMobileNav,
  apiLogsNav,
} from '../../assets/images/images';

const TrypilaSideNavigations = memo(
  ({media, splitLocation, pathname, openDropdown, toggleSubRoute}) => {
    const {USER_PERMISSIONS} = usePermissions();
    return (
      <>
        <div className="flex flex-col">
          <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
            <div className="flex flex-col">
              {baasNavLinks?.map((link, idx) => (
                <NavigationItem
                  key={link?.label + idx}
                  media={media}
                  title={link?.label}
                  active={splitLocation[1] === ''}
                  to={link?.url}
                  webIcon={link?.imgSrc}
                  mobileIcon={link?.imgSrc}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
          <SubNavigationDropdown
            media={media}
            toggleSubRoute={() => toggleSubRoute('developers')}
            src={media ? Assets.developersNav : Assets.developersMobileNav}
            open={openDropdown === 'developers'}
            label="Developers"
          />

          {openDropdown === 'developers' && (
            <div className="flex flex-col">
              <NavigationItem
                media={media}
                title="API Logs"
                active={splitLocation[1] === ''}
                to={Constants.PAGES_URL.DEVELOPERS_API_LOGS}
                webIcon={apiLogsNav}
                mobileIcon={apiLogsMobileNav}
                inner
              />
              <NavigationItem
                media={media}
                title="Configuration"
                active={splitLocation[1] === ''}
                to={Constants.PAGES_URL.DEVELOPERS_CONFIGURATION}
                webIcon={Assets.configurationNav}
                mobileIcon={Assets.configurationMobileNav}
                inner
              />

              <NavigationItem
                media={media}
                title="Webhooks"
                active={splitLocation[1] === ''}
                to={Constants.PAGES_URL.DEVELOPERS_WEBHOOK}
                webIcon={Assets.webhookNav}
                mobileIcon={Assets.webhookMobileNav}
                inner
              />
            </div>
          )}
        </div>

        <div className="mt-2">
          <NavigationItem
            media={media}
            title={
              <div className="flex items-center font-inter gap-2 mb-1">
                Logs
                <span className="inline-flex items-center rounded-full px-2 py-0.5 text-[#396C31] bg-[#DDECDB] text-xs font-medium">
                  NEW
                </span>
              </div>
            }
            active={
              pathname === Constants.PAGES_URL.AUDIT_LOGS ||
              pathname === Constants.PAGES_URL.EXPORT_LOGS
            }
            to={Constants.PAGES_URL.AUDIT_LOGS}
            webIcon={Assets.logWeb}
            mobileIcon={Assets.logMobile}
          />
        </div>

        <div className="flex flex-col mt-2">
          <SubNavigationDropdown
            media={media}
            toggleSubRoute={() => toggleSubRoute('settings')}
            src={media ? Assets.settingsNav : Assets.settingsMobileNav}
            open={openDropdown === 'settings'}
            label="Settings"
          />

          {openDropdown === 'settings' && (
            <>
              <NavLink
                to={Constants.PAGES_URL.SETTINGS_PROFILE}
                className={`nav-item rounded flex lg:hidden items-center gap-2 pl-8 py-2 relative ${splitLocation[1]} === "" ? "active" : ""`}
                aria-current="page"
              >
                <img
                  src={Assets.settingsMobileNav}
                  alt=""
                  width={18}
                  height={18}
                />
                <span className="-mb-1 text-sm text-body">All settings</span>
              </NavLink>
              <div className="flex-col hidden lg:flex">
                <NavigationItem
                  media={media}
                  title="Profile"
                  active={splitLocation[1] === ''}
                  to={Constants.PAGES_URL.SETTINGS_PROFILE}
                  webIcon={Assets.profileNav}
                  mobileIcon={Assets.settingsMobileNav}
                  inner
                />

                {USER_PERMISSIONS?.role === 'Owner' && (
                  <NavigationItem
                    media={media}
                    title="Company Verification"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.SETTINGS_COMPANY_VERIFICATION}
                    webIcon={Assets.auditLogsNav}
                    mobileIcon={Assets.verifyIc}
                    inner
                  />
                )}

                {/*<NavigationItem*/}
                {/*  media={media}*/}
                {/*  title="Team"*/}
                {/*  active={splitLocation[1] === ''}*/}
                {/*  to={Constants.PAGES_URL.SETTINGS_TEAM}*/}
                {/*  webIcon={teamNav}*/}
                {/*  mobileIcon={teamNav}*/}
                {/*  inner*/}
                {/*/>*/}
              </div>
            </>
          )}
        </div>
      </>
    );
  },
);

export {TrypilaSideNavigations};
