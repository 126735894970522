import React from 'react';
import SettingsLayout from './SettingsLayout';
import {companyVc1, companyVc2, verifyIc} from '../../assets/images/images';
import {PrimaryButton} from '../../components';
import {useSelector} from 'react-redux';
import {getStatusTextAndStyles} from '../../helpers/getStatusTextAndStyles';

function CompanyVerification() {
  const {userDetails} = useSelector(state => state?.auth);

  const slaDocument = userDetails?.company?.sla_document;
  const slaDocumentStatus = userDetails?.company?.sla_document_status;
  const verificationLink = userDetails?.company?.verification_document;
  const verificationStatus = userDetails?.company?.verification_document_status;

  return (
    <SettingsLayout pageTitle="Company Verification" pageIcon={verifyIc}>
      <section className="py-[30px] px-6 bg-white">
        <p className="font-atakk text-sm text-[#090909]">
          Provide your identity information for a successful business activation
          and ensure that you get your SLA signed to start using the live
          environment.
        </p>
        <div className="mt-6 lg:pr-9 grid md:grid-cols-2 gap-6">
          <div className="bg-white80 rounded-lg pt-5 px-[22px] pb-6">
            <div className="flex items-center justify-end">
              <div
                className={`py-1 px-2 rounded-3xl w-fit ${
                  getStatusTextAndStyles(verificationStatus).solid
                }`}
              >
                <p className=" uppercase text-xs font-medium font-inter">
                  {verificationStatus || 'not started'}
                </p>
              </div>
            </div>

            <div className="mt-6">
              <div className="w-full bg-white rounded-[4px] p-4">
                <img src={companyVc1} alt="" />
                <h1 className="font-medium text-base text-body mt-3">
                  Identity/Business Verification
                </h1>
                <p className="text-body text-sm mt-1">
                  Provide Government data, selfie & CAC details
                </p>
              </div>

              {verificationStatus !== 'completed' && (
                <PrimaryButton
                  buttonText="Start Verification"
                  className={`mt-8 min-w-[134px] ${
                    (!verificationLink || verificationLink?.length === 0) &&
                    'border'
                  }`}
                  background={
                    (!verificationLink || verificationLink?.length === 0) &&
                    '#7676762b'
                  }
                  onClick={() =>
                    window.open(verificationLink, '_blank').focus()
                  }
                  disabled={!verificationLink || verificationLink?.length === 0}
                />
              )}
            </div>
          </div>
          <div className="bg-white80 rounded-lg pt-5 px-[22px] pb-6">
            <div className="flex items-center justify-end">
              <div
                className={`py-1 px-2 rounded-3xl w-fit ${
                  getStatusTextAndStyles(slaDocumentStatus).solid
                }`}
              >
                <p className=" uppercase text-xs font-medium font-inter">
                  {slaDocumentStatus || 'not signed'}
                </p>
              </div>
            </div>
            <div className="mt-6">
              <div className="w-full bg-white rounded-[4px] p-4">
                <img src={companyVc2} alt="" />
                <h1 className="font-medium text-base text-body mt-3">
                  Get your SLA Signed
                </h1>
                <p className="text-body text-sm mt-1">
                  Sign SLA to get your business verified
                </p>
              </div>

              {slaDocumentStatus !== 'completed' && (
                <PrimaryButton
                  buttonText="Sign"
                  className={`mt-8 min-w-[134px] ${
                    (!slaDocumentStatus || slaDocumentStatus?.length === 0) &&
                    'border'
                  }`}
                  background={
                    (!slaDocumentStatus || slaDocumentStatus?.length === 0) &&
                    '#7676762b'
                  }
                  onClick={() => window.open(slaDocument, '_blank').focus()}
                  disabled={
                    !slaDocumentStatus || slaDocumentStatus?.length === 0
                  }
                />
              )}
            </div>
          </div>
        </div>

        <div className="border border-[#3F7CDB] bg-[#EEF3FC] rounded-[8px] py-3 px-4 mt-[31px]">
          <p className="text-black text-small font-atakk font-bold">NOTE</p>
          <div className="mt-2 flex items-center gap-2 ml-2">
            <div className="bg-body rounded-full min-w-[4px] min-h-[4px] " />
            <p className="text-body text-sm font-normal">
              Please note, Identity information should be submitted by company
              authorized signatory only.
            </p>
          </div>
          <div className="mt-2 flex items-center gap-2 ml-2">
            <div className="bg-body rounded-full min-w-[4px] min-h-[4px] " />
            <p className="text-body text-sm ">
              Ensure that the same AUTHORIZED PERSONNEL signs the SLA.
            </p>
          </div>
        </div>
      </section>
    </SettingsLayout>
  );
}

export default CompanyVerification;
