export const isNestedObjectTruthy = obj => {
  const {__v, ...rest} = obj;
  if (Object.keys(rest).length === 0) {
    return false;
  }
  for (const [_, value] of Object.entries(rest)) {
    if (value && typeof value === 'object') {
      if (Object.keys(value).length === 0) {
        return false;
      }
      if (!isNestedObjectTruthy(value)) {
        return false;
      }
    } else if (!value && value !== 0) {
      return false;
    }
  }
  return true;
};
