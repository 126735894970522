import React, {memo} from 'react';
import {NavLink} from 'react-router-dom';
import {PAGES_URL} from '../../helpers/constants';
import {DashboardLayout} from '../../components';
import {logMobile} from '../../assets/images/images';

const ROUTES = [
  {
    name: 'Audit Logs',
    path: PAGES_URL.AUDIT_LOGS,
    key: 'audit',
  },
  {
    name: 'Export Logs',
    path: PAGES_URL.EXPORT_LOGS,
    key: 'export',
  },
];

function Layout({children, download}) {
  
  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={logMobile} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1">Logs</p>
        </div>
      }
    >
      <div className="mt-3 flex items-center justify-between mb-4 min-h-[48px]">
        <h2 className="text-base font-medium text-grey xl:text-lg">Logs</h2>

        <div>{download}</div>
      </div>

      <div className="mb-6 rounded-xl bg-white p-1 border border-[#E1E4EA] flex items-center gap-2 overflow-x-auto mr-[76px] w-fit">
        {ROUTES.map((route, index) => (
          <NavLink
            key={index}
            end
            to={route.path}
            className={({isActive}) =>
              `pt-[13px] pb-[7px] px-4 text-sm  flex items-center justify-center rounded-lg shadow-[0px_4px_32px_-4px,rgba(199,196,196,0.18)] ${
                isActive
                  ? 'text-dojahBlue bg-blue-100'
                  : 'text-grey-500 bg-transparent'
              }`
            }
          >
            {route.name}
          </NavLink>
        ))}
      </div>
      {children}
    </DashboardLayout>
  );
}

export default memo(Layout);
