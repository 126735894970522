import React, {useState, useEffect, useCallback} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import moment from 'moment';
import {
  apiCover,
  apiStatusMobile,
  noService,
  onlineServices,
} from 'assets/images/images';
import {Accordion, DashboardLayout, Loader} from 'components';
import {fetchAllStatus, fetchService} from 'requests/apiStatus';

function Index() {
  const [offlineServicesCount, setOfflineServicesCount] = useState(0);
  const queryClient = useQueryClient();

  const {data: statusData, isLoading: isLoadingAll} = useQuery(
    'api-status',
    fetchAllStatus,
  );

  const getStatusColorForTimeline = successRate => {
    if (successRate >= 70) return 'bg-brandBlue60';
    if (successRate >= 25) return 'bg-warning20';
    return 'bg-error';
  };

  const ServiceStatus = ({serviceKey, value}) => {
    const {
      data: serviceData,
      isLoading,
      error,
    } = useQuery(
      `service-status-${serviceKey}`,
      () => fetchService(serviceKey),
      {
        retry: false,
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: () => {
          updateOfflineServiceCount();
        },
      },
    );

    let successRate = 0;
    if (serviceData && serviceData[serviceKey]) {
      successRate =
        (serviceData[serviceKey].successful_requests /
          serviceData[serviceKey].total_requests) *
        100;
    }

    let status = 'no data';
    if (serviceData && serviceData[serviceKey]) {
      if (successRate < 25) {
        status = 'offline';
      } else if (successRate >= 25 && successRate <= 70) {
        status = 'service interruption';
      } else {
        status = 'online';
      }
    }

    const getStatusColor = () => {
      switch (status) {
        case 'online':
          return 'bg-[#55A249]';
        case 'offline':
          return 'bg-error';
        case 'service interruption':
          return 'bg-warning20';
        default:
          return 'bg-grey90';
      }
    };

    const getStatusText = () => status;

    return (
      <div className="bg-[#EEF3FC] rounded-2xl px-3 pt-4 pb-5">
        {isLoading ? (
          <Loader height={10} />
        ) : error ? (
          <p className="text-red-500">Error loading status</p>
        ) : (
          <>
            <div className="flex items-start gap-[6px]">
              <div className="rounded-full flex items-center justify-center w-[35px] h-[35px] bg-white">
                <img src={apiCover} alt="cover" />
              </div>
              <div>
                <div className="flex flex-col gap-[6px]">
                  <p className="text-deepBlue font-semibold font-inter text-tiny break-words">
                    {value}
                  </p>
                  <div
                    className={`px-2 py-1 flex items-center justify-center gap-1 rounded-3xl w-fit ${getStatusColor()}`}
                  >
                    <span className="min-h-[5px] min-w-[5px] rounded-full bg-white"></span>
                    <p className="text-white uppercase font-inter text-xs font-medium whitespace-nowrap">
                      {getStatusText()}
                    </p>
                  </div>
                </div>
                {serviceData?.[serviceKey]?.last_request && (
                  <p className="text-grey90 font-medium font-inter text-xs mt-1">
                    {moment(serviceData[serviceKey].last_request).format(
                      'ddd MMM D, YYYY : hh:mm A',
                    )}
                  </p>
                )}
              </div>
            </div>
            {!serviceData?.[serviceKey] && (
              <p className="text-grey90 text-center mx-auto font-medium font-inter text-xs mt-4">
                No data available
              </p>
            )}
            {serviceData?.[serviceKey]?.timeline && (
              <div className="mt-6 flex items-end gap-1 justify-end">
                {serviceData[serviceKey].timeline
                  .slice(-24)
                  .map((item, index) => (
                    <div
                      key={index}
                      className={`w-[5px] h-[22px] rounded-full ${getStatusColorForTimeline(
                        item.success_rate,
                      )}`}
                      title={`${moment(item.timestamp).format(
                        'ddd MMM D, YYYY : hh:mm A',
                      )}: ${item.success_rate}% success rate`}
                    />
                  ))}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const updateOfflineServiceCount = useCallback(() => {
    if (statusData) {
      let count = 0;
      Object.entries(statusData).forEach(([country, apis]) => {
        Object.keys(apis).forEach(serviceKey => {
          const queryData = queryClient.getQueryData(
            `service-status-${serviceKey}`,
          );

          if (queryData && queryData[serviceKey]) {
            let successRate = 0;
            if (
              queryData[serviceKey].successful_requests &&
              queryData[serviceKey].total_requests
            ) {
              successRate =
                (queryData[serviceKey].successful_requests /
                  queryData[serviceKey].total_requests) *
                100;
            }

            if (successRate < 25) {
              count++;
            }
          }
        });
      });
      setOfflineServicesCount(count);
    }
  }, [statusData, queryClient]);

  useEffect(() => {
    updateOfflineServiceCount();
  }, [statusData, queryClient, updateOfflineServiceCount]);

  const findLatestDateForCategory = useCallback(
    apis => {
      let latestDate = null;

      Object.entries(apis).forEach(([key]) => {
        const queryData = queryClient.getQueryData(`service-status-${key}`);
        const serviceData = queryData?.[key];

        if (serviceData?.last_request) {
          const timestamp = new Date(serviceData.last_request);
          if (!latestDate || timestamp > latestDate) {
            latestDate = timestamp;
          }
        }
      });

      if (!latestDate) {
        Object.entries(apis).forEach(([key]) => {
          const query = queryClient.getQueryCache();
          const serviceDataQuery = query.find(`service-status-${key}`);
          const serviceData = serviceDataQuery?.state?.data?.[key];

          if (serviceData?.last_request) {
            const timestamp = new Date(serviceData.last_request);
            if (!latestDate || timestamp > latestDate) {
              latestDate = timestamp;
            }
          }
        });
      }

      return latestDate;
    },
    [queryClient],
  );

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={apiStatusMobile} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1">Status</p>
        </div>
      }
    >
      <h2 className="text-base font-medium text-grey xl:text-lg">API Status</h2>

      <div className="mt-6 bg-white border border-[#e4e3ec] rounded-xl p-4 flex items-center gap-8">
        <img
          src={offlineServicesCount > 0 ? noService : onlineServices}
          alt="service status"
        />
        <div className="flex flex-col">
          <h1 className="text-[#080917] font-medium text-base md:text-lg">
            {offlineServicesCount > 0
              ? `${offlineServicesCount} service(s) offline in the past hour`
              : 'All systems are online and working fine'}
          </h1>
          <p className="text-[#858DAA] font-inter text-small">
            {offlineServicesCount > 0
              ? `${offlineServicesCount} of our services have been offline in the last 1 hr`
              : 'All our services are working well and none has been offline in the last 1 hr'}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-6 mt-10">
        {isLoadingAll ? (
          <Loader height={40} />
        ) : statusData ? (
          Object.entries(statusData).map(([country, apis]) => {
            const latestDate = findLatestDateForCategory(apis);

            const lastCheckedDate = latestDate
              ? moment(latestDate).format('ddd MMM D, YYYY : hh:mm A')
              : 'N/A';

            return (
              <Accordion
                key={country}
                title={country}
                customComponent={
                  <p className="font-inter font-medium text-small lg:text-tiny text-[#858daa]">
                    {lastCheckedDate !== 'N/A' ? (
                      <>
                        <span className="text-deepBlue">Last checked:</span>{' '}
                        {lastCheckedDate}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </p>
                }
                customTitleSize="text-lg"
                customTitleColor="text-deepBlue font-semibold"
                customClasses="border border-[#D9E5F8] rounded-[12px]"
                customPadding="px-6 py-[22px] flex-col md:flex-row !items-start lg:items-center"
              >
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-x-4 gap-y-6">
                  {Object.entries(apis).map(([key, value]) => (
                    <ServiceStatus key={key} serviceKey={key} value={value} />
                  ))}
                </div>
              </Accordion>
            );
          })
        ) : (
          <p>No API status data available.</p>
        )}
      </div>
    </DashboardLayout>
  );
}

export default Index;
