/* eslint-disable react-hooks/exhaustive-deps */

import React, {useMemo} from 'react';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import * as flowQuery from '../../../../../requests/queries/flows';
import {toast} from 'react-toastify';
import {defaultFlowProcess} from '../defaultProcess';
import {v4} from 'uuid';
import {CreditCheckContext} from '../../../../../helpers/context/CreditCheckContext';
import {fetchAvailableFields} from '../../../../../requests/queries/flows';
import {useSelector} from 'react-redux';
import {mergeRules} from '../utils';

export const useFlowData = ({slug, flowProcess, createFlowProcess}) => {
  const {user} = useSelector(state => state?.auth);
  const queryClient = useQueryClient();
  const {
    set: {setTempFlow},
    processArrayWithTypes,
  } = React.useContext(CreditCheckContext);

  const fetchTasks = async () => {
    const [stagedResponse, publishedResponse] = await Promise.all([
      flowQuery.fetchTasks(slug),
      flowQuery.fetchPublishedTasks(slug),
    ]);

    return {
      data: {
        changes: stagedResponse?.data?.changes || [],
      },
      rules: publishedResponse?.rules || [],
    };
  };

  const {
    data: allTasks,
    isLoading: gettingTasks,
    isFetching,
  } = useQuery(['all-tasks', slug], fetchTasks, {
    placeholderData: flowProcess?.process,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {data: fieldsType, isLoading} = useQuery(
    'fetchAvailableFields',
    fetchAvailableFields,
  );

  const fieldsAndOperators = useMemo(
    () => !isLoading && fieldsType,
    [fieldsType, isLoading],
  );

  const {mutateAsync: addNewTask, isLoading: addingTask} = useMutation(
    task => flowQuery.addTask(slug, task),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all-tasks');
        toast.success('Task added successfully');
      },
      onError: error => toast.error(error?.response?.data?.error),
    },
  );

  const {mutateAsync: updateExistingTask, isLoading: updatingTask} =
    useMutation(task => flowQuery.updateTask(slug, task), {
      onSuccess: () => {
        queryClient.invalidateQueries('all-tasks');
        toast.success('Task updated successfully');
      },
      onError: error => toast.error(error?.response?.data?.error),
    });

  const {mutateAsync: saveFlowProcess, isLoading: savingProcess} = useMutation(
    process => flowQuery.saveProcess(slug, process),
    {
      onSuccess: () => toast.success('Process saved successfully'),
      onError: error => toast.error(error.response.data.error),
    },
  );
  const {mutateAsync: clearRule, isLoading: clearingRule} = useMutation(
    () => flowQuery.clearProjectRule(slug),
    {
      onSuccess: data => {
        toast.success(data.message || 'Rule cleared successfully');
        queryClient.invalidateQueries('all-tasks');
      },
      onError: error => toast.error(error.response.data.error),
    },
  );
  const {mutateAsync: publishRules, isLoading: publishingRules} = useMutation(
    () => flowQuery.publishTasks(slug, user.id),
    {
      onSuccess: data => {
        toast.success(data.message || 'Rule cleared successfully');
        queryClient.invalidateQueries('all-tasks');
        localStorage.removeItem('isModified');
      },
      onError: error => toast.error(error.response.data.error),
    },
  );

  React.useEffect(() => {
    if (!gettingTasks) {
      const mergedRules = mergeRules(allTasks, {rules: allTasks?.rules || []});
      localStorage.setItem(
        'isModified',
        JSON.stringify(allTasks?.data?.changes?.length > 0),
      );

      const flowArray =
        mergedRules.length > 0 ? mergedRules : allTasks?.rules || [];

      const start = defaultFlowProcess?.process[0];
      const end = defaultFlowProcess?.process[2];

      const newTempFlow = flowArray.map(({...rule}) => {
        const currentConditions =
          rule?.conditions || rule?.rule?.conditions || {};
        const currentEvent = rule?.event || rule?.rule?.event || {};
        const conditionKey = Object.keys(currentConditions)[0];

        return {
          key: v4(),
          ...(rule.rule || rule),
          id: rule?.rule?.id || rule?.id || '',
          risk_score: currentEvent?.params?.risk_score,
          currentRuleCondition: conditionKey,
          configurations: conditionKey ? currentConditions[conditionKey] : [],
        };
      });

      const FLOW_PROCESS = [start, newTempFlow, end];
      setTempFlow(FLOW_PROCESS);
      createFlowProcess({process: FLOW_PROCESS});
    }
  }, [
    allTasks?.data?.changes,
    allTasks?.rules,
    createFlowProcess,
    gettingTasks,
    processArrayWithTypes,
    setTempFlow,
  ]);

  // React.useEffect(() => {
  //   if (!gettingTasks) {
  //     const latestChanges = getLatestStagedChanges(allTasks);
  //     const flowArray =
  //       latestChanges.length > 0 ? latestChanges : allTasks?.rules || [];
  //
  //     const start = defaultFlowProcess?.process[0];
  //     const end = defaultFlowProcess?.process[2];
  //
  //     const newTempFlow = flowArray.map(({...rule}) => {
  //       const currentConditions =
  //         rule?.conditions || rule?.rule?.conditions || {};
  //       const currentEvent = rule?.event || rule?.rule?.event || {};
  //       const conditionKey = Object.keys(currentConditions)[0];
  //
  //       return {
  //         key: v4(),
  //         ...(rule.rule || rule),
  //         id: rule?.rule?.id || rule?.id || '',
  //         risk_score: currentEvent?.params?.risk_score,
  //         currentRuleCondition: conditionKey,
  //         configurations: conditionKey ? currentConditions[conditionKey] : [],
  //       };
  //     });
  //
  //     const FLOW_PROCESS = [start, newTempFlow, end];
  //     setTempFlow(FLOW_PROCESS);
  //     createFlowProcess({process: FLOW_PROCESS});
  //   }
  // }, [
  //   allTasks?.data?.changes,
  //   allTasks?.rules,
  //   createFlowProcess,
  //   gettingTasks,
  //   processArrayWithTypes,
  //   setTempFlow,
  // ]);

  return {
    allTasks,
    clearRule,
    publishRules,
    gettingTasks,
    isFetching,
    addNewTask,
    updateExistingTask,
    saveFlowProcess,
    addingTask,
    updatingTask,
    publishingRules,
    clearingRule,
    savingProcess,
    fieldsAndOperators,
  };
};
