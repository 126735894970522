import {calculatePricing} from '../../helpers/calculatePricing';

const getActivePages = defaultPages => {
  const PAGES_DATA = JSON.parse(localStorage.getItem('pages')) || defaultPages;

  const activePages =
    PAGES_DATA &&
    PAGES_DATA?.filter(page => page[`${page?.page}-${page?.pageId}`]);

  const index = activePages.findIndex(obj => obj.page === 'user-data');

  if (index !== -1) {
    const obj = activePages.splice(index, 1);
    activePages.unshift(obj[0]);
  }
  return activePages;
};

const getNewPagesConfig = defaultPages => {
  const activePages = getActivePages(defaultPages);
  return (
    activePages &&
    activePages?.map(data => {
      if (data.config) {
        delete data.config.liveness;

        return {
          page: data.page,
          config: data.config,
        };
      } else {
        return {
          page: data.page,
        };
      }
    })
  );
};

const getFraudRules = fraudRules =>
  fraudRules
    ?.filter(rule => rule.isActive)
    ?.reduce((acc, curr) => {
      acc[curr.page] = curr[curr.page];
      return acc;
    }, {});

const setPricingForOtherPages = (
  widgetPages,
  widgetPricing,
  setWidgetCurrentPricing,
) => {
  const pages =
    widgetPages !== null &&
    widgetPages
      ?.filter(
        p => !Object.hasOwn(p || {}, 'settings') && p.page !== 'user-data',
      )
      ?.map(p => p.page);
  pages?.map(page => {
    const pageData = (widgetPricing && widgetPricing[page]?.services) || {};
    const price = Object.fromEntries(
      Object.entries(pageData || {}).map(([key, value]) => [
        key,
        value > 0 ? value / 100 : value,
      ]),
    );
    const services = {
      services: {...price},
    };
    setWidgetCurrentPricing({
      [page]: services,
    });
  });
};

const getPricing = (pricing = {}, page) =>
  page && pricing !== null ? calculatePricing(pricing[page], page)[page] : {};
const getMinMaxPricing = (
  widgetScreen,
  widgetPricing,
  widgetCurrentPricing,
) => {
  const prices = {min: 0, max: 0};
  if (
    widgetScreen?.page !== 'indemnity' &&
    Object.hasOwn(widgetScreen || {}, 'settings')
  ) {
    const price = getPricing(widgetCurrentPricing, widgetScreen?.page);

    prices.min = price?.min;
    prices.max = price?.max;
  } else {
    const price = getPricing(widgetPricing, widgetScreen?.page);
    prices.min = price?.min / 100;
    prices.max = price?.max / 100;
  }

  return prices;
};

const signaturePageDefaultInfo =
  'By using our services, you agree that we may collect and use your information to ' +
  'enhance your experience. Rest assured, your data is handled with the utmost care and ' +
  'in compliance with privacy regulations. For more details, check out our Privacy policy ' +
  'Your trust means everything to us!';

const disclaimerPageDefaultInfo = `You must be in a well-lit environment before getting started.
  Avoid wearing anything that hides or hinders your face from being seen.
  All parts of your identification must be shown properly and must be clear.`;

const toggleStringConversion = (string, reverse) => {
  const informationLines =
    typeof string === 'string' &&
    string.split('\n').filter(line => line.trim() !== '');

  if (reverse && Array.isArray(string)) {
    return string?.join('\n');
  }
  return informationLines;
};

export {
  getActivePages,
  getNewPagesConfig,
  getFraudRules,
  setPricingForOtherPages,
  getPricing,
  getMinMaxPricing,
  signaturePageDefaultInfo,
  disclaimerPageDefaultInfo,
  toggleStringConversion,
};
