/* eslint-disable-next-line no-case-declarations */

import {
  WIDGETS,
  W_LOADING,
  W_UPDATED,
  U_LOADING,
  SINGLE_APP,
  UPDATE_WIDGET,
  CREATE_WIDGETS,
  SET_SCREEN_TYPE,
  SET_FRAUD_RULES,
  SET_WIDGET_STATE,
  SET_WIDGET_PAGES,
  SET_WIDGET_SCREEN,
  GET_SINGLE_WIDGET,
  SET_WIDGET_DEFAULT,
  MUTATE_WIDGET_PAGES,
  PREVIEW_WIDGET_CODE,
  SET_FRAUD_DEFAULT_PAGES,
  SET_WIDGET_CURRENT_PRICING,
  PRICING,
  SET_WIDGET_TOTAL_CURRENT_PRICING,
} from '../types';
import {calculatePricing} from '../../helpers/calculatePricing';

const initialState = {
  widgetState: 1,
  widgetPages: [],
  fraudRules: [],
  allWidgets: null,
  widgetCode: null,
  screenType: null,
  w_loading: false,
  w_updated: false,
  u_loading: false,
  defaultPages: null,
  singleWidget: null,
  widgetScreen: null,
  widgetPricing: null,
  fraudDefaultPages: null,
  widgetCurrentPricing: null,
  widgetCurrentTotalPricing: null,
};

export default function widgets(state = initialState, action) {
  let filteredTotalPrice = {};
  let filteredPages = [];
  let activePages = [];

  switch (action.type) {
    case W_LOADING:
      return {...state, w_loading: action.payload};
    case U_LOADING:
      return {...state, u_loading: action.payload};
    case W_UPDATED:
      return {...state, w_updated: !state.w_updated};
    case SET_WIDGET_STATE:
      return {...state, widgetState: action.payload};
    case PRICING:
      return {...state, widgetPricing: action.payload};
    case SET_WIDGET_CURRENT_PRICING:
      return {
        ...state,
        widgetCurrentPricing: {
          ...state.widgetCurrentPricing,
          ...action.payload,
        },
      };
    case SET_WIDGET_TOTAL_CURRENT_PRICING:
      // eslint-disable-next-line no-case-declarations
      // let filteredTotalPrice = {};
      // // eslint-disable-next-line no-case-declarations
      // const activePages =
      //   state.widgetPages !== null && state.widgetPages?.map(p => p.page);
      // activePages.map(page => {
      //   const priceData =
      //     state.widgetCurrentPricing !== null &&
      //     calculatePricing(state.widgetCurrentPricing[page] || {}, page);
      //   const data = {
      //     ...state.widgetCurrentTotalPricing,
      //     ...priceData,
      //   };
      //   filteredTotalPrice = Object.fromEntries(
      //     Object.entries(data).filter(([key]) => activePages.includes(key)),
      //   );
      // });
      //
      return {...state, widgetCurrentTotalPricing: action.payload};
    case SINGLE_APP:
      return {...state, singleApp: action.payload};
    case WIDGETS:
      return {...state, allWidgets: action.payload};
    case SET_WIDGET_SCREEN:
      return {...state, widgetScreen: action.payload};
    case SET_SCREEN_TYPE:
      return {...state, screenType: action.payload};
    case PREVIEW_WIDGET_CODE:
      return {...state, widgetCode: action.payload};
    case SET_WIDGET_DEFAULT:
      return {...state, defaultPages: action.payload};
    case SET_FRAUD_DEFAULT_PAGES:
      return {...state, fraudDefaultPages: action.payload};

    case MUTATE_WIDGET_PAGES:
      if (action.payload.type === 'add') {
        return {
          ...state,
          defaultPages:
            action.payload.page?.page === 'user-data'
              ? [action.payload.page, ...state.defaultPages]
              : [...state.defaultPages, action.payload.page],
        };
      }
      if (action.payload.type === 'delete') {
        return {
          ...state,
          defaultPages: state.defaultPages.filter(
            page => page.pageId !== action.payload.page.pageId,
          ),
        };
      }
      break;

    case SET_WIDGET_PAGES:
      filteredPages =
        action.payload && Array.isArray(action.payload)
          ? action.payload?.filter(
              page => page[`${page?.page}-${page?.pageId}`],
            )
          : [
              ...(state.widgetPages || []).filter(
                page => page?.pageId !== action.payload?.pageId,
              ),
              action.payload,
            ]?.filter(page => page[`${page?.page}-${page?.pageId}`] === true);
      activePages = filteredPages?.map(p => p?.page);
      activePages?.forEach(page => {
        const priceData = calculatePricing(
          state.widgetCurrentPricing?.[page] || {},
          page,
        );
        filteredTotalPrice = {
          ...filteredTotalPrice,
          ...priceData,
        };
      });

      filteredTotalPrice = Object.fromEntries(
        Object.entries(filteredTotalPrice).filter(([key]) =>
          activePages.includes(key),
        ),
      );

      return {
        ...state,
        widgetPages: [...filteredPages],
        widgetCurrentTotalPricing: filteredTotalPrice,
      };

    case SET_FRAUD_RULES:
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          fraudRules: action.payload.filter(rule => rule?.isActive),
        };
      } else {
        return {
          ...state,
          fraudRules: [
            ...(state.fraudRules && state.fraudRules).filter(
              rule => rule.page !== action.payload.page,
            ),
            action.payload,
          ].filter(rule => rule?.isActive),
        };
      }

    case CREATE_WIDGETS:
    case UPDATE_WIDGET:
    case GET_SINGLE_WIDGET:
      return {...state, singleWidget: action.payload};

    default:
      return state;
  }
}
