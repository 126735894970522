import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import {
  DashboardLayout,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components';
import * as Actions from '../../../../store/actions';
import {
  biodataIc,
  chevronRight,
  copyBlue,
  documentText,
  // downloadIc,
  easyOnboardIc,
  userTick,
  arrowLeft,
} from '../../../../assets/images/images';
import {Constants, getInitials} from '../../../../helpers';
import ReusableLink from '../../../Verifications/ReusableLink';
import ReviewProcessModal from './ReviewProcessModal';
import moment from 'moment';
import GeneratePDF from './GeneratePDF';
import {collapseNestedObject} from '../../../../helpers/collapseNestedObject';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {fetchCase, updateCase} from '../../../../requests/queries/flows';
import {getStatusTextAndStyles} from '../../../../helpers/getStatusTextAndStyles';
import {useCasesDetails} from '../hooks/useCasesDetails';

const commentLimit = 2;

function Layout({
  flows: {
    cases,
    loading,
    nextAndPrevious,
    cases_comments,
    saveCommentLoading,
    deleteCommentLoading,
    commentsLoading,
  },
  auth: {user},
  getPreviousCaseId,
  getNextCaseId,
  getCasesComments,
  makeCommentOnACase,
  children,
}) {
  const {slug, id} = useParams();
  const navigate = useNavigate();
  const [openReviewProcessModal, setOpenRPModal] = useState(false);
  const [comment, setComment] = useState('');
  const [data, setData] = useState({});
  const [next, setNext] = useState(commentLimit);
  const queryClient = useQueryClient();
  const {data: caseData, isLoading} = useQuery(['a_case_details', slug], () =>
    fetchCase(slug),
  );

  const reviewCaseMutation = useMutation(updateCase);

  const caseDetails = useMemo(
    () => !isLoading && caseData && caseData?.entity,
    [caseData, isLoading],
  );

  const currentPage = +localStorage.getItem('casesPageNumber');
  const vStatus = caseDetails?.status?.toLowerCase() || 'pending';
  const riskScore =
    caseDetails?.transaction?.evaluation_result?.risk_score || 0;
  const outcome = caseDetails?.transaction?.evaluation_result?.outcome || '';

  useEffect(() => {
    getCasesComments(slug);
  }, [getCasesComments, slug]);

  const COMMENTS = (cases_comments && cases_comments?.comments) || [];
  const handleCommentSend = useCallback(() => {
    if (!comment) {
      return toast.error('Please enter a comment');
    }
    makeCommentOnACase({
      id: slug,
      comment: {
        comment,
        author: (user && `${user?.first_name} ${user?.last_name}`) || 'Author',
      },
    });

    setComment('');
  }, [comment, makeCommentOnACase, slug, user]);

  const performUpdate = useCallback(async () => {
    try {
      const updatedData = {...data};
      if (data?.action === 'allow') delete updatedData?.reason;
      if (Object.values(updatedData)?.length === 0) return;
      await reviewCaseMutation?.mutate(
        {case_id: slug, data: updatedData},
        {
          onSuccess: () => {
            setOpenRPModal(false);
            queryClient?.invalidateQueries();
          },
          onError: error => {
            toast.error(error?.response?.data?.error);
          },
        },
      );
    } catch (e) {
      console.log(e);
    }
  }, [data, queryClient, reviewCaseMutation, slug]);
  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success(`ID copied`);
  };

  const getNext = useCallback(() => {
    if (nextAndPrevious?.nextCaseId === '') {
      getNextCaseId({currentPage, navigate, id});
    } else if (nextAndPrevious?.nextCaseId !== '') {
      id === undefined
        ? navigate(`/pending-cases/details/${nextAndPrevious?.nextCaseId}`)
        : navigate(`/flows/cases/${id}/details/${nextAndPrevious?.nextCaseId}`);
    }
  }, [nextAndPrevious?.nextCaseId, getNextCaseId, currentPage, navigate, id]);
  const getPrev = useCallback(() => {
    if (nextAndPrevious.prevCaseId === '' && currentPage > 1) {
      getPreviousCaseId({currentPage, navigate, id});
    } else if (nextAndPrevious?.prevCaseId !== '') {
      id === undefined
        ? navigate(`/pending-cases/details/${nextAndPrevious?.prevCaseId}`)
        : navigate(`/flows/cases/${id}/details/${nextAndPrevious?.prevCaseId}`);
    }
  }, [
    nextAndPrevious?.prevCaseId,
    currentPage,
    getPreviousCaseId,
    navigate,
    id,
  ]);
  const {USER, TRANSACTION, ADDRESS, PAYMENT, SENDER, RECEIVER, META} =
    useCasesDetails({caseDetails});

  const projectId = useMemo(
    () => id || TRANSACTION?.project_id,
    [TRANSACTION?.project_id, id],
  );

  const LINKS = [
    {
      path: Constants.PAGES_URL.FLOWS_CASES_DETAILS.replace(
        ':id',
        projectId,
      ).replace(':slug', slug),
      src: biodataIc,
      text: 'Case Information',
    },
    {
      path: Constants.PAGES_URL.FLOWS_CASES_BEHAVIOURAL_ANALYSIS.replace(
        ':id',
        projectId,
      ).replace(':slug', slug),
      src: userTick,
      text: 'Behavioral Analysis',
    },
    {
      path: Constants.PAGES_URL.FLOWS_CASES_SUPPORTING_DOC.replace(
        ':id',
        projectId,
      ).replace(':slug', slug),
      src: documentText,
      text: 'Supporting Doc',
    },
  ];
  const handleLoadMore = () => {
    setNext(next + commentLimit);
  };

  const pdfData = [
    {
      title: 'User Information',
      data: USER,
    },
    {
      title: 'Transaction',
      data: {
        transaction_gateway: TRANSACTION?.channel,
        transaction_time: moment(
          !isNaN(TRANSACTION?.event?.transaction?.time)
            ? +TRANSACTION?.event?.transaction?.time
            : TRANSACTION?.event?.transaction?.time,
        ).format('HH:mm a'),
        amount: TRANSACTION?.event?.transaction?.amount,
        currency: TRANSACTION?.currency,
      },
    },
    {
      title: 'Address',
      data: ADDRESS,
    },
    {
      title: 'Payment',
      data: PAYMENT,
    },
    {
      title: 'Sender',
      data: SENDER,
    },
    {
      title: 'Receiver',
      data: RECEIVER,
    },
    {
      title: 'Meta data',
      data: collapseNestedObject(Array.isArray(META) ? META[0] : META),
    },
  ];

  return (
    <DashboardLayout
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyOnboardIc} alt="" />
          <p className="ml-2 -mb-1">Fraud detection</p>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1">Flows</p>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1">Cases</p>
        </div>
      }
    >
      <ReviewProcessModal
        open={openReviewProcessModal}
        setOpen={setOpenRPModal}
        data={data}
        setData={setData}
        onClick={performUpdate}
        loading={reviewCaseMutation?.isLoading}
      />
      <button
        className="flex items-center"
        onClick={() => navigate(id ? `/flows/cases/${id}` : '/pending-cases')}
      >
        <img src={arrowLeft} alt="" width={16} height={16} />
        <p className="-mb-1 text-sm text-grey20 font-medium">Go Back</p>
      </button>

      <div className="flex justify-between items-start relative w-full mt-4">
        <h1 className="text-grey text-lg font-medium">Case Details</h1>
        {cases?.data?.current_page > 1 || nextAndPrevious?.prevCaseId ? (
          <div className="flex justify-between items-center gap-4 text-sm font-medium">
            <button
              className="flex items-center text-secondaryBlue80"
              title="Previous case"
              onClick={getPrev}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.02329 9.99999L13.1483 14.125L11.97 15.3033L6.66663 9.99999L11.97 4.69666L13.1483 5.87499L9.02329 9.99999Z"
                  fill="#444771"
                />
              </svg>
              <p className="-mb-1">Previous case</p>
            </button>
            {nextAndPrevious?.nextCaseId ? (
              <div className="w-[1px] h-4 bg-body" />
            ) : null}
            {nextAndPrevious?.nextCaseId ? (
              <button
                className="flex items-center text-brandBlue"
                title="Next case"
                onClick={getNext}
              >
                <p className="-mb-1">Next case</p>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9766 10L6.85159 5.87501L8.02992 4.69668L13.3333 10L8.02992 15.3033L6.85159 14.125L10.9766 10Z"
                    fill="#3F7CDB"
                  />
                </svg>
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="mt-4 flex-wrap items-start min-h-full xl:flex xl:flex-nowrap basis-full">
        <div className="basis-[19.14%] flex flex-col gap-6">
          <div className="flex gap-2 xl:p-2 xl:flex-col">
            {LINKS &&
              LINKS?.map(({path, src, text}) => (
                <ReusableLink key={path} path={path} src={src} text={text} />
              ))}
          </div>

          <GeneratePDF
            documentData={pdfData}
            subTitle={USER && `${USER?.name}, ${USER?.email}`}
          />
        </div>
        {children}
        <div className="pt-10 xl:basis-[34.83%] px-4 sticky  bg-white xl:bg-white80">
          <div className="flex items-center justify-between">
            <div
              className={`flex uppercase pt-[5px] pb-[5px] px-2 text-xs font-medium ${
                getStatusTextAndStyles(vStatus)?.solid
              } rounded-full w-fit items-start mb-3 gap-[2px] capitalize`}
            >
              {isLoading ? '...' : vStatus}
            </div>
            {!loading && vStatus === 'pending' && (
              <PrimaryButton
                buttonText="Review process"
                fontSize="text-xs"
                xPadding="px-2"
                yPadding="pt-[9px] pb-[7px]"
                className="hidden sm:flex mb-4"
                onClick={() => setOpenRPModal(true)}
              />
            )}
            {!isLoading && vStatus === 'pending' && (
              <div
                className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80"
                style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
              >
                <PrimaryButton
                  buttonText="Review user"
                  xPadding="p-[35px]"
                  className="w-full bottom-2"
                  onClick={() => setOpenRPModal(true)}
                />
              </div>
            )}
          </div>
          <div className="flex items-center justify-between p-4 mt-0 text-xs uppercase rounded bg-white80 xl:bg-white">
            <div className="flex flex-col gap-1 text-grey">
              <p className="font-medium uppercase">case id</p>
              <p>
                {loading
                  ? '...'
                  : `${caseDetails?.transaction?.event?.transaction?.id?.toUpperCase()}` ||
                    '-'}
              </p>
            </div>
            <button
              onClick={() =>
                copyText(caseDetails?.transaction?.event?.transaction?.id)
              }
              className="flex items-center gap-2 px-2 pt-[9px] pb-[7px] border rounded border-brandBlue w-fit"
            >
              <img src={copyBlue} alt="" width={14} height={14} />
              <span className="text-xs font-medium text-brandBlue">Copy</span>
            </button>
          </div>
          <div className="flex flex-wrap items-stretch gap-4 mt-4 lg:flex-nowrap">
            <div className="w-full p-4 rounded bg-white80 xl:bg-white">
              <div className="flex flex-col gap-1">
                <p className="uppercase text-grey text-xs">risk score</p>
                <p className="text-success text-xl">{riskScore}</p>
              </div>
            </div>
            <div className="w-full p-4 rounded bg-white80 xl:bg-white">
              <p className="text-xs font-medium uppercase text-grey">OUTCOME</p>
              <div
                className={`${
                  getStatusTextAndStyles(outcome).solid
                } mt-2 flex uppercase pt-[5px] pb-[3px] px-2 text-xs font-medium  rounded-full w-fit items-start gap-[2px]`}
              >
                <span>{outcome || '-'}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-stretch gap-4 mt-4 lg:flex-nowrap">
            <div className="w-full p-4 rounded bg-white80 xl:bg-white">
              <p className="text-xs font-medium uppercase text-grey">
                DATE & TIME CREATED
              </p>
              <p className="mt-2 text-sm text-body font-medium">
                {moment(caseDetails?.createdAt).format('DD MMM YYYY h:m') ||
                  '-'}
              </p>
            </div>
            <div className="w-full p-4 rounded bg-white80 xl:bg-white">
              <p className="text-xs font-medium uppercase text-grey">
                DECISION TYPE
              </p>
              <div className="mt-2 flex uppercase pt-[5px] pb-[3px] px-2 text-xs font-medium bg-secondaryBlue text-white rounded-full w-fit items-start gap-[2px]">
                <span>manual</span>
              </div>
            </div>
          </div>
          <div className="p-4 mt-4 rounded bg-white80 xl:bg-white grow xl:grow-0">
            <div className="flex items-center gap-1">
              <p className="text-xs font-medium uppercase text-grey">
                Comments
              </p>
              <div className="flex items-center justify-center w-4 h-4 pt-[2px] text-xs text-white rounded-full bg-brandBlue">
                <span className="relative bottom-[1px]">
                  {COMMENTS?.length || 0}
                </span>
              </div>
            </div>
            <div className="relative mt-4">
              <textarea
                value={comment}
                name="comment"
                id=""
                data-smallertextarea=""
                onChange={e => setComment(e.target.value)}
              />
              <PrimaryButton
                buttonText={loading ? 'loading...' : 'Send'}
                fontSize="text-xs"
                xPadding="px-2"
                yPadding="pt-[9px] pb-[7px]"
                className="absolute right-2 bottom-4"
                onClick={handleCommentSend}
                disabled={!comment || saveCommentLoading}
              />
            </div>
            <div
              style={{
                opacity:
                  deleteCommentLoading || saveCommentLoading || commentsLoading
                    ? 0.4
                    : 1,
              }}
            >
              {(!saveCommentLoading || !commentsLoading) &&
                COMMENTS?.slice(0, next)?.map((comment, index, data) => (
                  <div
                    key={comment?._id}
                    className={`flex flex-col gap-1 mt-3 pb-2 ${
                      index === data.length - 1
                        ? ''
                        : 'border-b border-brandBlue4'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      <div className="w-6 h-6 bg-brandBlue40 text-brandBlue flex items-center justify-center rounded-full text-xs font-medium">
                        {getInitials(comment?.author) || '-'}
                      </div>
                      <p className="font-medium text-sm text-grey">
                        {comment?.author || '-'}
                      </p>
                    </div>
                    <p className="text-sm  text-body">
                      {comment?.comment || '-'}
                    </p>
                    <div className="relative">
                      <p className="text-[9px] text-grey40 font-medium uppercase">
                        {moment(comment.createdAt).fromNow() || ''}
                      </p>
                      {/*<PrimaryButton*/}
                      {/*  buttonText="delete"*/}
                      {/*  fontSize="text-[9px]"*/}
                      {/*  xPadding="px-2"*/}
                      {/*  yPadding="pt-[4px] pb-[5px]"*/}
                      {/*  className="absolute right-2 bottom-2 bg-danger"*/}
                      {/*  onClick={() =>*/}
                      {/*    deleteCasesComment({*/}
                      {/*      id: slug,*/}
                      {/*      commentId: comment?._id,*/}
                      {/*    })*/}
                      {/*  }*/}
                      {/*  disabled={deleteCommentLoading}*/}
                      {/*/>*/}
                    </div>
                  </div>
                ))}
            </div>

            {!commentsLoading && next < COMMENTS?.length && (
              <SecondaryButton
                className="flex items-start gap-2 mt-4"
                fontSize="text-xs"
                xPadding="px-2"
                yPadding="pt-[9px] pb-[7px]"
                btnHeight="h-7"
                buttonText="Load more"
                onClick={handleLoadMore}
                loading={commentsLoading}
              />
            )}
          </div>
          {/* <ActivityLog /> */}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(Layout);
