import moment from 'moment';
import {dateRanges} from './getDateRange';
import {toast} from 'react-toastify';

const generateSelectedOptions = options => {
  const selectedOptions = {};

  options.forEach(option => {
    if (option.values.length > 0) {
      selectedOptions[option.name] = option.values.join(', ');
    }
  });

  return selectedOptions;
};

export const createSelectedOptions = (optionsArray, dateFilter) => {
  const selectedOptions = generateSelectedOptions(optionsArray);

  if (dateFilter.start && dateFilter.end) {
    selectedOptions.start = dateFilter.start;
    selectedOptions.end = dateFilter.end;
  }

  return selectedOptions;
};

export const toggleArrayValue = (array, value) => {
  if (array.includes(value)) {
    return array.filter(item => item !== value);
  } else {
    return [...array, value];
  }
};

export const customDateFilters = ({value, appFilters, onCheck}) => {
  const lowercaseValue = value?.toLowerCase();
  const dateRange = dateRanges[lowercaseValue];
  if (dateRange) {
    const {start, end} = dateRange();
    return onCheck({...appFilters, start, end});
  }
  return appFilters;
};

export const formatCustomDateRange = (start, end) => {
  if (start && end) {
    const startMoment = moment(start);
    const endMoment = moment(end);
    const diffInMonths = endMoment.diff(startMoment, 'months', true);

    if (diffInMonths > 6) {
      toast.error(
        'Please contact the support team for information beyond 6 months',
      );
      return {start: '', end: ''};
    }
  }

  const formattedStartDate = start
    ? moment(start).format('YYYY-MM-DD 00:00:00')
    : '';
  const formattedEndDate = end ? moment(end).format('YYYY-MM-DD 23:59:59') : '';
  return {start: formattedStartDate, end: formattedEndDate};
};

export const applyDateFilter = ({value, appFilters, setDateFilter}) => {
  switch (value) {
    case 'today':
    case 'yesterday':
    case 'seven_days':
    case 'last_month':
    case 'last_three_month':
    case 'last_six_month': {
      const result = customDateFilters({
        value,
        appFilters,
        onCheck: newDateFilter => setDateFilter(newDateFilter),
      });

      if (result && result.start && result.end) {
        const {start, end} = result;
        setDateFilter({start, end});
      }
      break;
    }
    default:
      break;
  }
};
