/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable-next-line no-prototype-builtins */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import * as EmailValidator from 'email-validator';

import {
  chevronRight,
  closeWidget,
  easyOnboardIc,
  edit,
  exclamationMark,
  logo,
  widgetBack,
} from '../../assets/images/images';
import {DashboardLayout, PrimaryButton, SkeletonLoader} from '../../components';

import * as Actions from '../../store/actions';
import {toast} from 'react-toastify';
import {PreviewScreens} from './PreviewScreens';
import {Constants, enforceFraudCheckConfigurations} from '../../helpers';
import ButtonLoader from '../../components/ButtonLoader';
import {EasyOnboardTour2} from '../../components/Tour';
import {TourContext} from '../../helpers/context';
import ConfigPanel from './ConfigPanel';
import {getPages} from '../../helpers/getPages';
import inverseColor from '../../helpers/colorInversion';
import {DEFAULT_PAGES} from './defaultPages';
import {EasyOnboardContext} from '../../helpers/context/EasyOnboardContext';
import {PlayIc} from '../../components/images';
import {
  getFraudRules,
  getMinMaxPricing,
  getNewPagesConfig,
  setPricingForOtherPages,
} from './widgetHelpers';
import ConfirmUnSaveFlowModal from './UnsavedPrompt';
import {useBlockNavigation} from '../../hooks/useBlockNavigation';
import countryList from 'react-select-country-list';
import {shortenLargeNumbers} from '../../helpers/shortenLargeNumbers';

function CustomFlow({
  auth: {userDetails},
  widgets,
  createWidget,
  setSingleWidget,
  updateWidget,
  setWidgetPages,
  setWidgetDefaultPages,
  setWidgetCurrentPricing,
}) {
  const options = useMemo(() => countryList().getData(), []);

  const [selectedApp, setSelectedApp] = useState('');
  const {
    widgetData,
    totalCost,
    setWidgetData,
    trackChanges,
    setTrackChanges,
    setQuestions,
  } = useContext(EasyOnboardContext);

  const [{saveType, nameError, currentPage, typeCall, pages}, setState] =
    useReducer((state, action) => ({...state, ...action}), {
      saveType: '',
      nameError: false,
      currentPage: '',
      setType: '',
      pages: '',
    });

  const hasWidgetUrl = !!localStorage.subdomain && !!userDetails?.widgetUrl;
  const customWidgetUrl = hasWidgetUrl
    ? userDetails?.widgetUrl
    : userDetails?.company?.widget_url;
  const location = useLocation();
  const navigate = useNavigate();

  const {alertModal, setAlertModal, nextLocation, setNextLocation} =
    useBlockNavigation();

  const minimumMultipleOptions = 3;

  const textAreaRef = useRef(null);
  const initialRender = useRef(true);

  useEffect(() => {
    setSingleWidget(null);
    setWidgetData({
      name: '',
      index_instructions: [],
      country: options?.map(c => c.value),
    });
    setQuestions([]);
  }, []);

  useEffect(() => {
    if (selectedApp !== '') {
      setSelectedApp(selectedApp);
    }
  }, [selectedApp]);

  const pageCurrentMaxPrice = getMinMaxPricing(
    widgets?.widgetScreen,
    widgets?.widgetPricing,
    widgets?.widgetCurrentPricing,
  ).max;

  const pageCurrentMinPrice = getMinMaxPricing(
    widgets?.widgetScreen,
    widgets?.widgetPricing,
    widgets?.widgetCurrentPricing,
  ).min;

  const displayPrice = (condition, price) =>
    condition &&
    shortenLargeNumbers(
      price || 0,
      userDetails?.company?.currency || 'NGN',
      {},
    );

  const data = useMemo(
    () => widgets?.defaultPages?.find(widget => widget?.pageId === currentPage),
    [currentPage, widgets?.defaultPages],
  );
  const fraudData = useMemo(
    () =>
      widgets?.fraudDefaultPages?.find(widget => widget?.page === currentPage),
    [currentPage, widgets?.fraudDefaultPages],
  );

  useEffect(() => {
    if (initialRender.current) {
      const indemnityPage = {
        page: 'indemnity',
        config: {},
        'indemnity-0': true,
      };

      setSingleWidget(null);
      setWidgetPages(indemnityPage);
      setWidgetDefaultPages(DEFAULT_PAGES);
      initialRender.current = false;
    }
  });

  useEffect(() => {
    setPricingForOtherPages(
      widgets?.widgetPages,
      widgets?.widgetPricing,
      setWidgetCurrentPricing,
    );

    return () => setWidgetCurrentPricing({});
  }, [
    widgets?.widgetPages?.length,
    widgets?.widgetScreen?.page,
    setWidgetCurrentPricing,
  ]);

  const handleFocus = () => {
    textAreaRef.current.focus();
  };

  const newPages = getNewPagesConfig(widgets?.defaultPages).filter(
    p => p.page !== 'indemnity',
  );
  const FRAUD_RULES = getFraudRules(widgets?.fraudRules);
  const hasGovtData = !!newPages.find(page => page?.page === 'government-data');
  const hasQuestions = newPages.find(page => page.page === 'custom-questions');
  const checkOptions = hasQuestions?.config?.questions?.find(q =>
    ['multiple', 'single'].includes(q?.type),
  );

  const publishWidget = useCallback(
    (type, preview) => {
      preview ? setState({typeCall: 'preview'}) : setState({typeCall: type});

      const newType = type === 'draft' ? '' : type;

      if (checkOptions?.options?.length < minimumMultipleOptions) {
        return toast.error('Options should NOT be less than three (3).');
      }
      if (hasQuestions?.config?.title === '') {
        return toast.error('Question(s) title is required');
      }
      if (!selectedApp) return toast.error('Please select an app');
      if (!newPages.length) {
        return toast.error('Please add verification page(s)');
      }
      if (!widgetData?.name || widgetData?.name.includes('Untitled')) {
        setState({nameError: true});
        return toast.error('Please name your flow');
      }
      const isConfigured = enforceFraudCheckConfigurations(FRAUD_RULES);
      if (!isConfigured) {
        return toast.error('Please configure all Fraud check page(s)');
      }

      if (
        FRAUD_RULES?.age_limit?.limit < 16 ||
        FRAUD_RULES?.age_limit?.limit > 90
      ) {
        return toast.error('Age limit must be between 16 and 90');
      }

      if (
        widgetData?.support_email &&
        !EmailValidator.validate(widgetData?.support_email)
      ) {
        return toast.error('Support email is not valid');
      }

      if (hasGovtData)
        toast.success(
          <div className="flex items-center justify-center">
            <span className=" mr-3 w-9 h-6 bg-white rounded-full flex items-center justify-center">
              <img src={exclamationMark} alt="Info" width={18} />
            </span>
            <p className="text-[12px]">
              Please note, government data is only available for specific
              countries.
            </p>
          </div>,
        );

      const WIDGET_DATA = {
        ...widgetData,
        published: !!newType,
        pages: [...newPages],
        rules: FRAUD_RULES,
      };

      const message =
        type === 'publish'
          ? 'Widget published successfully'
          : 'Widget saved successfully';
      createWidget({
        app: selectedApp,
        update: {...WIDGET_DATA},
        message,
        type: newType,
        preview,
        navigate,
        to: nextLocation,
      });
      if (trackChanges) {
        setTrackChanges(false);
      }
    },
    [
      selectedApp,
      widgets?.singleWidget,
      createWidget,
      widgetData,
      widgets?.widgetPages,
      updateWidget,
      navigate,
      nextLocation,
      newPages,
      FRAUD_RULES,
      trackChanges,
    ],
  );

  const handleChangeName = useCallback(
    e => {
      const {value} = e.target;
      setState({nameError: false});
      setWidgetData({...widgetData, name: value});
      setTrackChanges(true);
    },
    [widgetData, setWidgetData],
  );

  const widgetId = location?.search?.split('=')[1];

  const handlePreview = () => {
    if (widgetId && widgetId?.length >= 24) {
      setState({typeCall: 'preview'});
      widgetId && widgets?.previewWidgetCode(widgetId, customWidgetUrl);
    } else {
      setState({typeCall: 'preview'});
      publishWidget('draft', 'preview');
    }
  };

  const {tourState, updateTourState} = useContext(TourContext);

  return (
    <DashboardLayout
      fullScreen
      topPadding="pt-0"
      xlLeftMargin="xl:ml-12"
      xlRightPadding="sm:pr-0"
      className="h-screen"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyOnboardIc} alt="" width={18} height={18} />
          <Link to={Constants.PAGES_URL.EASY_ONBOARD} className="ml-2 -mb-1">
            Easy onboard
          </Link>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">Create a flow</p>
        </div>
      }
    >
      {tourState?.easy_onboard_tour_2 ? (
        <EasyOnboardTour2
          updateTourState={updateTourState}
          DATA={Object.keys(widgetData)}
          loading={widgets?.w_loading}
        />
      ) : null}

      <ConfirmUnSaveFlowModal
        handleSave={async () => {
          await publishWidget(saveType);
          setAlertModal(false);
        }}
        alertModal={alertModal}
        setAlertModal={() => {
          setAlertModal(!alertModal);
          setNextLocation('');
        }}
        handleExit={() => {
          setAlertModal(false);
          setTrackChanges(false);
          navigate(nextLocation);
        }}
        isLoading={widgets?.w_loading}
      />

      {typeCall !== '' && widgets?.w_loading && (
        <div className="fixed  left-0 top-0 z-50  w-full h-full flex items-center justify-center">
          <ButtonLoader color="#3F7CDB" />
          <span className="text-sm mt-16 text-body font-medium italic">
            ...
            {typeCall === 'changes'
              ? 'update'
              : typeCall === 'draft'
              ? 'sav'
              : 'load'}
            ing
          </span>
        </div>
      )}
      <div
        className="flex gap-[3.72%] bg-[#f9f9f9] basis-full h-full"
        style={{opacity: typeCall !== '' && widgets?.w_loading && 0.5}}
      >
        <div className="w-full basis-[30.709%] flex flex-col h-screen">
          <div className="flex items-center gap-1 mt-6">
            <input
              ref={textAreaRef}
              className={`text-lg font-medium ${
                !widgetData?.name ? 'text-grey40 italic' : 'text-grey'
              } outline-none focus:outline focus:outline-offset-2 focus:outline-1 ${
                nameError
                  ? 'outline outline-danger outline-offset-2 outline-2'
                  : ''
              }  `}
              type="text"
              value={widgetData?.name}
              placeholder="Widget flow name here..."
              onChange={handleChangeName}
              style={{
                // width: widgetData?.name?.length * 20,
                maxWidth: 350,
                maxHeight: 50,
                border: 'none',
              }}
            />
            <img
              src={edit}
              onClick={handleFocus}
              alt=""
              className="cursor-pointer ml-2 mt-2"
            />
          </div>
          <div className="mt-6 overflow-y-auto custom-flow">
            <ConfigPanel
              setSelectedApp={setSelectedApp}
              setCurrentPage={setState}
              setPages={setState}
              pages={pages}
              widgetData={widgetData}
            />
          </div>
        </div>

        <div className="bg-black25 basis-[65.571%] custom-flow overflow-y-auto">
          <div className="bg-white80 fixed flex w-[53%] mr-6 right-0 items-center gap-4 py-2 px-12 justify-end">
            <div className="-mb-1 mr-auto flex items-center justify-center p-2 font-medium text-sm">
              Total Price:{' '}
              {widgets?.w_loading ? (
                <span className=" ml-2">
                  <SkeletonLoader
                    style={{
                      width: '100%',
                      height: 20,
                      maxWidth: 150,
                      margin: 0,
                    }}
                  />
                </span>
              ) : totalCost.min === totalCost.max ? (
                `${displayPrice(
                  widgets?.widgetPages?.length > 0 || totalCost?.max / 100,
                  totalCost.max / 100 || 0,
                )}`
              ) : (
                `${displayPrice(
                  widgets?.widgetPages?.length > 0 || totalCost?.min / 100,
                  totalCost.min / 100 || 0,
                )} - ${displayPrice(
                  widgets?.widgetPages?.length > 0 || totalCost?.max / 100,
                  totalCost.max / 100 || 0,
                )}`
              )}
            </div>
            <button
              onClick={() => {
                publishWidget('draft');
                setState({typeCall: 'draft'});
              }}
              className="p-2 text-xs font-medium rounded text-grey outline outline-1 outline-grey80"
            >
              {typeCall === 'draft' && widgets?.w_loading
                ? 'Saving...'
                : 'Save as draft'}
            </button>
            <PrimaryButton
              buttonText="Publish"
              xPadding="px-2"
              yPadding="pt-[9px] pb-[7px]"
              fontSize="text-xs"
              onClick={() => {
                publishWidget('publish');
                setState({typeCall: 'publish'});
              }}
              loading={typeCall === 'publish' && widgets?.w_loading}
              disabled={typeCall === 'publish' && widgets?.w_loading}
              className="h-7"
            />
            <button
              onClick={handlePreview}
              disabled={typeCall === 'preview' && widgets?.w_loading}
              style={{
                opacity: typeCall === 'preview' && widgets?.w_loading ? 0.5 : 1,
                cursor:
                  typeCall === 'preview' && widgets?.w_loading
                    ? 'not-allowed'
                    : 'pointer',
              }}
              className="px-2 pt-[9px] pb-[7px] flex items-end gap-1 text-xs font-medium rounded text-brandBlue"
            >
              <PlayIc />
              {typeCall === 'preview' && widgets?.w_loading
                ? 'Processing...'
                : ' Preview widget'}
            </button>
          </div>

          {widgets?.screenType === 'verifications' ? (
            <div
              className="min-h-screen pt-14"
              style={{
                opacity:
                  widgets?.widgetScreen?.page === 'indemnity'
                    ? 1
                    : data &&
                      data[
                        `${widgets?.widgetScreen?.page}-${widgets?.widgetScreen?.pageId}`
                      ]
                    ? 1
                    : 0.3,
                transition: 'opacity 0.3s ease-in-out',
              }}
            >
              <div
                className={`flex flex-col items-center justify-center max-w-lg mx-auto mt-[50px] ${
                  widgets?.widgetScreen?.page !== 'custom-questions'
                    ? 'py-4'
                    : 'py-14'
                }`}
              >
                {!['user-data', 'indemnity', 'custom-questions'].includes(
                  widgets?.widgetScreen?.page,
                ) && (
                  <div className="bg-white mb-6 p-2 text-sm rounded">
                    <p>
                      {getPages(widgets?.widgetScreen?.page)}:{' '}
                      <span className="text-brandBlue ml-2">
                        {['government-data'].includes(
                          widgets?.widgetScreen?.page,
                        ) &&
                          `${displayPrice(
                            data &&
                              data[
                                `${widgets?.widgetScreen?.page}-${widgets?.widgetScreen?.pageId}`
                              ],
                            pageCurrentMinPrice || 0,
                          )} - `}
                        {displayPrice(
                          data &&
                            data[
                              `${widgets?.widgetScreen?.page}-${widgets?.widgetScreen?.pageId}`
                            ],
                          pageCurrentMaxPrice || 0,
                        )}
                      </span>
                    </p>
                  </div>
                )}
                {widgets?.widgetScreen?.page === 'custom-questions' &&
                  PreviewScreens[widgets?.widgetScreen?.page]}
                {widgets?.widgetScreen?.page !== 'custom-questions' && (
                  <div className="flex flex-col items-center w-full pb-10 bg-white rounded">
                    <div className="flex justify-between w-full">
                      <button disabled>
                        <img src={widgetBack} alt="" className="pt-4 pl-4" />
                      </button>
                      <button disabled>
                        <img src={closeWidget} alt="" className="pt-4 pr-4" />
                      </button>
                    </div>
                    {widgets?.widgetScreen?.page !== 'additional-document' && (
                      <header className="flex flex-col items-center mt-2">
                        <img
                          src={widgets?.singleApp?.logo || ''}
                          alt=""
                          className="max-h-10"
                        />
                      </header>
                    )}
                    {PreviewScreens[widgets?.widgetScreen?.page || 'indemnity']}
                    {![
                      'id',
                      'additional-document',
                      'business-id',
                      'signature',
                      'selfie',
                    ].includes(widgets?.widgetScreen?.page) && (
                      <button
                        disabled
                        className="w-full h-12 max-w-sm mt-6 font-medium  rounded text-tiny bg-brandBlue"
                        style={{
                          backgroundColor:
                            widgets?.singleApp?.color ||
                            widgets?.singleApp?.color_code ||
                            '',
                          color: inverseColor(
                            widgets?.singleApp?.color ||
                              widgets?.singleApp?.color_code ||
                              '#ffffff',
                          ),
                        }}
                      >
                        Continue
                      </button>
                    )}
                    {!userDetails?.company?.is_partner_company && (
                      <div className="flex items-center justify-center gap-2 my-4">
                        <span className="text-sm text-grey">Powered by</span>
                        <img src={logo} alt="" width={30.55} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {widgets?.screenType === 'fraud' ? (
            <div
              className="min-h-screen pt-14"
              style={{
                opacity: fraudData && fraudData.isActive ? 1 : 0.3,
                transition: 'opacity 0.3s ease-in-out',
              }}
            >
              {PreviewScreens[widgets?.widgetScreen?.page || 'fraud-check']}
            </div>
          ) : null}

          {widgets?.screenType === 'integration' ? (
            <div className="min-h-screen pt-14">
              {PreviewScreens[widgets?.widgetScreen?.page || 'web_sdk']}
            </div>
          ) : null}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(CustomFlow);
