import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
// import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {
  blacklistBlack,
  dropdownBlue,
  dropdownGrey,
  edit,
  eye,
  lookupEmpty,
} from '../../../assets/images/images';
import {
  DashboardLayout,
  EmptyState,
  // FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  // SearchInput,
  TableLayout,
} from '../../../components';
import * as Actions from '../../../store/actions';
// import {isObjectValueEmpty} from '../../../helpers/isObjectEmpty';
import {Constants} from '../../../helpers';
import CreateListModal from './CreateListModal';
import EditListModal from './EditListModal';
// import CustomListsFilter from './CustomListsFilter';
import moment from 'moment';
import {OverviewCards} from '../../../components/OverviewCards';
import {buildTableCardData} from '../../../helpers/buildTableCardData';

function FraudDetectionFlows({
  auth: {userDetails},
  // getFlows,
  getLists,
  getAllApps,
  updateList,
  caseLists: {listLoading, lists, mutateLoading},
}) {
  const navigate = useNavigate();
  // const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState('1');
  const [limit, setLimit] = useState(10);
  // const [filters, setSearchFilters] = useState({});
  const [list, setList] = useState({});
  const [createListModal, setOpenCreateListModal] = useState(false);
  const [editListModal, setOpenEditListModal] = useState(false);
  const [listItems, setListItems] = useState([]);
  const handleTableRowClick = id => {
    navigate(`${Constants.PAGES_URL.CUSTOM_LISTS}/${id}`);
  };
  useEffect(() => {
    getAllApps({});
    getLists({page: currentPage, limit});
  }, [getLists, getAllApps, currentPage, limit]);
  useEffect(() => {
    lists && setListItems([...((lists && lists?.lists) || [])]);
  }, [lists]);

  const canAccessCustomLists =
    userDetails?.permissions?.customListPermissions?.viewCustomList &&
    userDetails?.permissions?.customListPermissions?.manageCustomList;

  const pageClick = selected => {
    setCurrentPage(selected);
  };
  // const filterStructure = (key, alias) =>
  //   filters[key]
  //     ?.split(',')
  //     ?.map(value => `&${alias || key}=${value}`)
  //     ?.join('');
  // const handleFilter = () => {
  //   getFlows({
  //     page: currentPage,
  //     limit,
  //     start: filters?.start,
  //     end: filters?.end,
  //     apps: filterStructure('apps', 'app_id'),
  //     environment: filterStructure('environment'),
  //     service: filterStructure('service type', 'service') || '',
  //   });
  //
  //   localStorage.setItem('webhookFilters', JSON.stringify(filters));
  // };
  //
  // const handleResetFilter = useCallback(() => {
  //   if (isObjectValueEmpty(filters)) {
  //     setSearchFilters({});
  //     localStorage.setItem('flowFilters', JSON.stringify({}));
  //     getFlows({page: currentPage});
  //   } else {
  //     toast.error('No filters to reset');
  //   }
  // }, [currentPage, filters, getFlows]);

  const tableCardData = buildTableCardData({
    count: lists?.totalRecords,
  });
  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={blacklistBlack} alt="" />
          <p className="ml-2 -mb-1">Custom Lists</p>
        </div>
      }
    >
      <CreateListModal
        open={createListModal}
        setOpen={setOpenCreateListModal}
        setListItems={setListItems}
        listItems={listItems}
      />
      <EditListModal
        list={list}
        open={editListModal}
        setOpen={setOpenEditListModal}
        updateList={updateList}
        loading={mutateLoading}
        listItems={listItems}
        setListItems={setListItems}
      />
      {/*<CustomListsFilter*/}
      {/*  openFilter={openFilter}*/}
      {/*  setOpenFilter={setOpenFilter}*/}
      {/*  onCheck={data => setSearchFilters({...filters, ...data})}*/}
      {/*  handleFilter={handleFilter}*/}
      {/*  resetFilters={handleResetFilter}*/}
      {/*  reset={filters}*/}
      {/*  appFilters={filters}*/}
      {/*/>*/}
      <div className="flex items-center justify-between w-full mb-6">
        <h2 className="text-grey text-base sm:text-lg font-medium">
          Custom Lists
        </h2>
        <div className="flex items-center gap-4">
          {canAccessCustomLists && (
            <PrimaryButton
              buttonText="Create a new list"
              onClick={() => setOpenCreateListModal(true)}
            />
          )}
          {/*<SearchInput />*/}
          {/*<FilterButton openFilter={openFilter} setOpenFilter={setOpenFilter} />*/}
        </div>
      </div>

      <section className="flex flex-col">

        <OverviewCards
          cardData={tableCardData}
          overviewLoading={listLoading}
          className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-1 gap-2 lg:gap-4 flex-wrap my-5`}
        />

        <TableLayout negativeMargins negativeRightMargin="-mr-4 xl:-mr-[76px]">
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>List name</span>
                  <img src={dropdownBlue} alt="" width={16} height={16} />
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>number of items</span>
                  <img src={dropdownGrey} alt="" width={16} height={16} />
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>created by</span>
                  <img src={dropdownGrey} alt="" width={16} height={16} />
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>date added</span>
                  <img src={dropdownGrey} alt="" width={16} height={16} />
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>last updated</span>
                  <img src={dropdownGrey} alt="" width={16} height={16} />
                </div>
              </th>
              <th className="min-w-[175px]"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {listLoading ? (
                  <Loader height={45} />
                ) : !listLoading && listItems && listItems?.length === 0 ? (
                  <EmptyState
                    heading="No record found!"
                    height={45}
                    buttonText="Create a new list"
                    onClick={() => setOpenCreateListModal(true)}
                    body="No list has been created yet, to get started click on the button below"
                    maxWidth="max-w-[226px]"
                    src={lookupEmpty}
                  />
                ) : null}
              </td>
            </tr>
          </tbody>

          <tbody className="text-sm bg-white">
            {!listLoading &&
              listItems &&
              listItems?.map((item, i) => (
                <tr
                  key={i}
                  className="border-b border-grey60 cursor-pointer"
                  onClick={() =>
                    canAccessCustomLists && handleTableRowClick(item?._id)
                  }
                >
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {item?.name}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {item?.count || 0}
                  </td>
                  <td className="capitalize p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {item?.author || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(item?.createdAt).format('Do MMMM YYYY hh:mm')}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(item?.updatedAt).format('Do MMMM YYYY hh:mm')}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap relative">
                    <div className="flex items-center gap-3 z-2 p-5 absolute top-0">
                      {canAccessCustomLists && (
                        <button
                          title="View"
                          onClick={() => handleTableRowClick(item?._id)}
                        >
                          <img src={eye} alt="" width={16} height={16} />
                        </button>
                      )}
                      {canAccessCustomLists && (
                        <button
                          title="Edit"
                          onClick={e => {
                            e.stopPropagation();
                            setOpenEditListModal(true);
                            setList(item);
                          }}
                        >
                          <img src={edit} alt="" width={16} height={16} />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
        {!listLoading && listItems && listItems?.length !== 0 && (
          <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={setLimit}
                flows
                length={listItems && listItems?.length}
                limit={limit}
              />
            </div>
            <div className="mb-8 sm:mb-0">
              <Pagination
                total={lists && Math.ceil(lists?.totalRecords / limit)}
                current={+currentPage}
                onPageChange={activePage => {
                  pageClick(activePage);
                }}
              />
            </div>
          </div>
        )}
      </section>
    </DashboardLayout>
  );
}
export default connect(state => state, Actions)(FraudDetectionFlows);
