import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  corruptedFile,
  onboardEmpty,
  ongoingIc,
  pendingIcon,
} from '../../assets/images/images';
import {
  Loader,
  PageLimit,
  Pagination,
  SearchInput,
  TableLayout,
} from '../../components';
import {Constants} from '../../helpers';
import * as Actions from '../../store/actions';
import moment from 'moment';
import {PageContext} from '../../helpers/context';
import EmptyState from '../../components/EmptyState';
import VerificationWrapper from './index';
import {getStatusTextAndStyles} from '../../helpers/getStatusTextAndStyles';
import {useWidgetFlows} from '../../hooks/useWidgetFlows';
import {useAllApps} from '../../hooks/useApps';
import {getParams, handleSetIds, NoSearchResults} from './utils';
import {OverviewAnalytics} from './OverviewAnalytics';
import {shortenLargeNumbers} from '../../helpers/shortenLargeNumbers';

function PendingVerifications() {
  const navigate = useNavigate();
  const {widgetFlows, isLoading} = useWidgetFlows();
  const dispatch = useDispatch();
  const {
    verifications: {verifications, loading},
  } = useSelector(state => ({
    verifications: state?.verifications,
    auth: state?.auth,
  }));
  const getFlowName = useCallback(
    flowId => widgetFlows?.find(flow => flow.value === flowId)?.name,
    [widgetFlows],
  );

  // search query implementation
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const search = query.get('search');
  const [searchText, setSearchText] = useState(search);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const {userApps, appIds, isLoading: appsLoading} = useAllApps();

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('verifications');
  }, [setPageName]);
  const pageClick = selected => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const apiParams = getParams({
      limit,
      appIds,
      currentPage,
      setCurrentPage,
      setSearchText,
      setFiltersApplied,
    });

    !appsLoading &&
      userApps &&
      appIds &&
      dispatch(Actions?.getVerifications({...apiParams, pending: true}));
  }, [appIds, appsLoading, currentPage, dispatch, limit, userApps]);

  const verificationsData = useMemo(
    () => (verifications ? verifications?.data : []),
    [verifications],
  );
  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);

    dispatch(
      Actions?.getVerifications({
        page: 1,
        limit,
        appIds: appIds,
        pending: true,
      }),
    );
    setSearchText('');
  };

  const getVerificationsWithSearch = (text, appIds) => {
    const apiParams = {
      page: 1,
      limit,
      term: text,
      appIds,
      pending: true,
    };

    setCurrentPage(1);
    dispatch(Actions?.getVerifications(apiParams));
  };
  const clearSearch = () => {
    setSearchText('');
    setCurrentPage(1);
    navigate('');
    getVerificationsWithSearch('', appIds);
  };

  const cardData = [
    {
      icon: pendingIcon,
      alt: 'pending',
      title: 'Pending',
      value: shortenLargeNumbers(verifications?.meta?.total_count || 0, null, {
        startAt: 'M',
        decimals: 0,
      }),
    },
  ];

  return (
    <VerificationWrapper isChild={true}>
      <div className="flex flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
        <h2 className="text-base font-medium text-grey sm:text-lg">
          My Pending Verifications
        </h2>
        <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
          <SearchInput
            onChange={text => {
              setSearchText(text);
              if (text) {
                getVerificationsWithSearch(text, appIds);
                navigate(`?search=${text}`);
              } else {
                clearSearch();
              }
            }}
            defaultValue={searchText}
            placeholder="Search by name, or, reference ID"
          />
        </div>
      </div>
      <OverviewAnalytics cardData={cardData} isLoading={loading || isLoading} />

      <TableLayout negativeMargins>
        <thead className="font-semibold uppercase text-small">
          <tr className="bg-white80">
            <th className="pt-[22px] pb-[18px] pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-grey whitespace-nowrap">
              <div className="flex items-center gap-1">
                <span>name</span>
              </div>
            </th>
            <th className="pt-[22px] pb-[18px] pl-0 font-medium text-left text-grey whitespace-nowrap">
              <div className="flex items-center gap-1">
                <span>Flows</span>
              </div>
            </th>
            <th className="pt-[22px] pb-[18px] pl-0 font-medium text-left text-grey whitespace-nowrap">
              <div className="flex items-center gap-1">
                <span>Reference ID</span>
              </div>
            </th>
            <th className="pt-[22px] pb-[18px] pl-0 font-medium text-left text-grey whitespace-nowrap">
              <div className="flex items-center gap-1">
                <span>status</span>
              </div>
            </th>

            <th className="pt-[22px] pb-[18px] pl-0 font-medium text-left text-grey whitespace-nowrap">
              <div className="flex items-center gap-1">
                <span>Date/Time created</span>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={8}>
              {loading || isLoading ? (
                <Loader height={45} />
              ) : !loading && !verificationsData?.length && search !== null ? (
                !filtersApplied ? (
                  <EmptyState
                    height={45}
                    body="No data found"
                    src={corruptedFile}
                    noBtn
                  />
                ) : (
                  <EmptyState
                    height={45}
                    body="No results found"
                    noBtn
                    customBtn={
                      <button
                        onClick={handleResetFilter}
                        className="text-brandBlue p-4 text-sm font-medium"
                      >
                        Update preferences
                      </button>
                    }
                  />
                )
              ) : !loading &&
                !verificationsData.length &&
                search === null &&
                (!verifications || !appIds) ? (
                // eslint-disable-next-line react/jsx-indent
                <EmptyState
                  body="Identify your users by viewing their verification details"
                  src={onboardEmpty}
                  buttonText="Go to Verification FLow"
                  onClick={() => navigate('/easy-onboard')}
                  height={45}
                />
              ) : !verificationsData.length && search !== null ? (
                <NoSearchResults />
              ) : null}
            </td>
          </tr>
        </tbody>

        <tbody className="text-sm bg-white">
          {!(loading || isLoading) &&
            verificationsData &&
            verificationsData.map((verification, i) => (
              <tr
                className="text-sm border-b cursor-pointer border-grey60"
                key={i}
                onClick={() => {
                  navigate(
                    Constants.PAGES_URL.VERIFICATIONS_BIO_DATA.replace(
                      ':slug',
                      verification?.verification_id,
                    ),
                    {
                      state: {
                        sessionId: verification?.verification_id,
                      },
                    },
                  );
                  handleSetIds(verification);
                }}
              >
                <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-body whitespace-nowrap">
                  {verification.first_name
                    ? `${verification.first_name} ${verification.last_name} ${verification.middle_name}`
                    : verification.business_name
                    ? verification.business_name
                    : '-'}
                </td>
                <td className="p-4 pl-0 text-body whitespace-nowrap">
                  {getFlowName(verification?.flow_id) ||
                    verification?.flow_id ||
                    '-'}
                </td>
                <td className="p-4 pl-0 text-body whitespace-nowrap">
                  {verification?.reference_id}
                </td>
                <td className="p-4 pl-0 whitespace-nowrap">
                  <div
                    className={`uppercase pt-[5px] pb-[3px] px-2 text-xs font-medium   rounded-full w-fit flex items-start gap-[2px] ${
                      getStatusTextAndStyles(
                        verification?.status?.toLowerCase(),
                      ).styles
                    }`}
                  >
                    {
                      getStatusTextAndStyles(
                        verification?.status?.toLowerCase(),
                      ).text
                    }
                    {verification?.status?.toLowerCase() === 'ongoing' && (
                      <img src={ongoingIc} alt="" />
                    )}
                    {/* <img src={expiredIc} alt="" /> */}
                  </div>
                </td>{' '}
                <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                  {moment(verification?.datetime).format('Do MMM, YYYY h:mm A')}
                </td>
              </tr>
            ))}
        </tbody>
      </TableLayout>

      {!loading && verificationsData?.length !== 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
          <div className="flex items-center text-body text-tiny">
            <PageLimit
              onLimitChange={setLimit}
              // className="mt-3 mt-sm-0"
              total={verifications && verifications?.meta?.total_count}
              limit={verificationsData && verificationsData?.length}
            />
          </div>
          <div className="mb-8 sm:mb-0">
            <Pagination
              total={
                verifications &&
                Math.ceil(verifications?.meta?.total_count / limit)
              }
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
                const searchParams = new URLSearchParams(
                  document.location.search,
                );
                searchParams.set('page', activePage);
                const newSearchParams = searchParams.toString();
                navigate(`?${newSearchParams}`);
              }}
            />
          </div>
        </div>
      )}
    </VerificationWrapper>
  );
}

export default PendingVerifications;
