import React, {useState} from 'react';
import LogsLayout from './LogsLayout';
import {
  EmptyState,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  TableLayout,
  Filter,
} from 'components';
import {roundDownload} from 'assets/images/images';
import {useQuery} from 'react-query';
import {getExportLogs} from 'requests/queries/audit-logs';
import moment from 'moment/moment';
import {filterOutEmptyValues} from 'helpers';
import AppliedFilters from 'components/AppliedFilters';

const filterOptions = [
  {
    title: 'Status',
    name: 'status',
    values: [
      {label: 'Completed', value: 'completed', name: 'completed'},
      {label: 'Failed', value: 'failed', name: 'failed'},
      {label: 'Initiated', value: 'initialised', name: 'initiated'},
    ],
  },
];

const initialFilters = {
  status: '',
  start: '',
  end: '',
};

function ExportLogs() {
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterProps, setFilterProps] = useState(null);

  const filterEmptyParams = params =>
    Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v != null && v !== ''),
    );

  const {
    data: response,
    isLoading,
    isError,
    error,
  } = useQuery(['export-logs', currentPage, limit, filters], () =>
    getExportLogs(filterEmptyParams({page: currentPage, limit, ...filters})),
  );

  const export_logs = response?.exports || [];
  const totalPages = response?.totalPages || 0;
  const totalRecords = response?.totalRecords || 0;

  const handleFilter = selectedOptions => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    setFilters(filteredOptions);
    setCurrentPage(1);
    setOpenFilter(false);
    setFiltersApplied(true);
  };

  const handleResetFilter = () => {
    setFilters(initialFilters);
    setCurrentPage(1);
    setOpenFilter(true);
    setFiltersApplied(false);
  };

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <LogsLayout>
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
        onFilterStateChange={handleFilterStateChange}
      />
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <EmptyState
          body={`There was an error fetching the logs: ${error?.message}`}
          noBtn
          height={55}
        />
      ) : !export_logs.length && filtersApplied ? (
        <EmptyState
          body="This filter did not return any data, try using different values."
          noBtn
          height={55}
          customBtn={
            <button
              onClick={handleResetFilter}
              className="text-brandBlue p-4 text-sm font-medium"
            >
              Update preferences
            </button>
          }
        />
      ) : export_logs.length === 0 ? (
        <EmptyState noBtn height={55} body="No logs found" />
      ) : (
        <>
          <div className="mt-4 flex items-center justify-between mb-4">
            <div className="flex items-center gap-4 flex-wrap">
              <h2 className="text-base font-medium text-grey xl:text-lg">
                Export Logs
              </h2>
              {filtersApplied && filterProps && (
                <AppliedFilters {...filterProps} isMainPage={true} />
              )}
            </div>

            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
          </div>

          <TableLayout negativeMargins>
            <thead className="text-xs font-semibold uppercase">
              <tr className="bg-white80">
                <th className="p-5 pl-4 sm:pl-6 xl:pl-12 w-[37%] font-medium text-left text-xs text-grey whitespace-nowrap">
                  Source
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  Status
                </th>
                <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                  Date
                </th>
                <th className="w-[10.69%]"></th>
              </tr>
            </thead>
            <tbody className="text-sm bg-white">
              {export_logs.map(log => (
                <tr
                  key={log._id}
                  className="border-b cursor-pointer border-grey60 hover:bg-gray-100 "
                >
                  <td className="p-4 pl-4 sm:pl-6 log-[37%] xl:pl-12 text-tiny text-body">
                    {log.type} - {log.env}
                  </td>
                  <td className="p-4 pl-0 text-body">
                    <div
                      className={`py-1 px-2 font-inter text-xs font-medium uppercase rounded-full w-fit ${
                        log.status === 'failed'
                          ? 'text-[#B42318] bg-[#F0D3D1]'
                          : log.status === 'initialised'
                          ? 'text-white bg-batchBlue'
                          : 'text-[#396C31] bg-[#DDECDB]'
                      }`}
                    >
                      {log.status === 'initialised' ? 'Initiated' : log.status}
                    </div>
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body">
                    {moment(log?.createdAt).format('Do MMM, YYYY, hh:mmA') ||
                      '-'}
                  </td>
                  <td className="p-4 pl-0">
                    <a
                      href={log.link}
                      download
                      className="flex items-center gap-2 text-[#0D192C] rounded-lg py-2 px-3 border border-[#12376914] text-xs font-medium font-inter"
                    >
                      Download
                      <img src={roundDownload} alt="download" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableLayout>

          {export_logs.length > 0 && (
            <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
              <div className="flex items-center text-body text-tiny">
                <PageLimit
                  onLimitChange={newLimit => {
                    setLimit(newLimit);
                    setCurrentPage(1);
                  }}
                  total={totalRecords}
                  length={export_logs.length}
                  limit={limit}
                />
              </div>

              <div className="mb-8 sm:mb-0">
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  onPageChange={setCurrentPage}
                />
              </div>
            </div>
          )}
        </>
      )}
    </LogsLayout>
  );
}

export default ExportLogs;
