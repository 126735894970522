import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import Layout from './Layout';
import {Accordion, EmptyState, Loader, RowData} from '../../components';
import {enlargeIc, downloadGrey, placeholder} from '../../assets/images/images';
import * as Actions from '../../store/actions';
import {isObjectEmpty} from '../../helpers/isObjectEmpty';
import {PageContext} from '../../helpers/context';
import {isNestedObjectTruthy} from '../../helpers/isNestedObjectTruthy';
import ComplianceQuestions from './ComplianceQuestions';
import GenerateAndDownloadSignaturePDF from './SignaturePDF/SignaturePdf';
import {useAllApps} from 'hooks/useApps';

const RoundedImage = ({imageUrl}) => (
  <div
    style={{width: '19vh', height: '19vh', borderRadius: '50rem'}}
    className="flex items-center justify-center overflow-clip shadow-lg shadow-brandBlue4"
  >
    <img
      src={imageUrl}
      alt="Liveness Image"
      className="h-full w-auto object-cover object-center"
    />
  </div>
);

const ExpandImageToggle = ({resizeImage, imgUrl, id}) => (
  <div className="absolute flex items-center gap-2 right-2 bottom-2">
    <button
      onBlur={() => resizeImage(`${id}`)}
      onClick={() => resizeImage(`${id}`, true)}
      className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white80 w-fit"
    >
      <span className="-mb-[2px]">Enlarge image</span>
      <img src={enlargeIc} alt="" className="-mt-[2px]" />
    </button>
    <a
      href={`${imgUrl}`}
      download="file"
      rel="noreferrer"
      target="_blank"
      className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white80 w-fit"
    >
      <span>Download</span>
      <img src={downloadGrey} alt="" className="-mt-[2px]" />
    </a>
  </div>
);

function BioData() {
  const {state} = useLocation();
  const {slug} = useParams();
  const dispatch = useDispatch();
  const {userApps, isLoading} = useAllApps();

  const {session_summary, KYC_Customer, KYC_Business, loading} = useSelector(
    state => state?.verifications,
  );
  useEffect(() => {
    dispatch(Actions.getSessionSummary(slug || state?.sessionId));
  }, [state?.sessionId, slug, dispatch]);

  const LIVENESS = session_summary?.data?.Liveness;
  const VERIFICATION = session_summary?.data?.Verification;
  const GOV_DATAS = session_summary?.data?.government_datas;
  const GOV_ID_DATA = session_summary?.data?.government_id_data;
  const USER_DATA = session_summary?.data?.user_data;
  const BUSINESS_DATA = session_summary?.data?.business_data;
  const BUSINESS_ID = session_summary?.data?.business_id;
  const UPLOAD_FILES = session_summary?.data?.files;
  const SIGNATURE = session_summary?.data?.signature;
  const QUESTIONS = session_summary?.data?.questions;
  let APP_INFO;

  if (!isLoading && userApps) {
    APP_INFO = userApps?.find(app => app.value === VERIFICATION?.app_id);
  }

  const BUSINESS_DATA2 = KYC_Business?.entity;
  const EMAIL = session_summary?.data?.email;
  const PHONE = session_summary?.data?.phone;
  const CUSTOMER = KYC_Customer?.entity;

  const isEmpty = useMemo(
    () =>
      !loading &&
      isNestedObjectTruthy({
        LIVENESS,
        GOV_DATAS,
        GOV_ID_DATA,
        PHONE,
        EMAIL,
        SIGNATURE,
        USER_DATA,
      }),
    [
      EMAIL,
      GOV_DATAS,
      GOV_ID_DATA,
      LIVENESS,
      PHONE,
      SIGNATURE,
      USER_DATA,
      loading,
    ],
  );

  const showDate = useCallback(date => {
    const invalidDates = [null, undefined, '', '-'];
    return (
      (!invalidDates.includes(date) && moment(date).format('Do MMMM YYYY')) ||
      'N/A'
    );
  }, []);

  const vType = VERIFICATION?.verification_type?.toLowerCase();

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('verifications');
  }, [setPageName]);

  const reversedDateStr = useCallback(
    dateStr => dateStr.split('-').reverse().join('-'),
    [],
  );

  const fullName = useMemo(
    () =>
      USER_DATA?.first_name
        ? `${USER_DATA?.first_name || '-'} ${USER_DATA?.last_name} ${
            USER_DATA?.middle_name
          }`
        : CUSTOMER?.first_name
        ? `${CUSTOMER?.first_name || '-'} ${CUSTOMER?.last_name} ${
            CUSTOMER?.middle_name
          }`
        : GOV_ID_DATA?.first_name
        ? `${GOV_ID_DATA?.first_name} ${GOV_ID_DATA?.last_name} ${GOV_ID_DATA?.middle_name}`
        : '-',
    [USER_DATA, CUSTOMER, GOV_ID_DATA],
  );

  const USER_DATA_1 = useMemo(
    () => [
      {
        leftText: 'Full name',
        rightText: fullName,
      },
      {
        leftText: 'date of birth',
        rightText:
          USER_DATA?.dob &&
          showDate(USER_DATA?.dob || reversedDateStr(USER_DATA?.dob)),
      },
      {
        leftText: 'Nationality',
        rightText: USER_DATA?.nationality || '-',
      },
      {
        leftText: 'Residence country',
        rightText: USER_DATA?.residence_country || '-',
      },
      {
        leftText: 'Mobile',
        rightText: USER_DATA?.mobile || '-',
      },
    ],
    [USER_DATA, fullName, reversedDateStr, showDate],
  );

  const MULTIPLE_GOVT_DATA =
    GOV_DATAS &&
    Object.entries(GOV_DATAS).reduce((acc, [k, v]) => {
      if (k !== 'customer_id' && v && typeof v === 'object') {
        const idData = Object.entries(v).reduce((dataAcc, [key, value]) => {
          if (typeof value !== 'object' && value && key) {
            dataAcc.push({
              leftText: key.replace(/_/g, ' '),
              rightText: value,
            });
          }
          return dataAcc;
        }, []);

        if (idData.length) {
          acc.push({idType: k, idData});
        }
      }
      return acc;
    }, []);

  const CUSTOMER_BUSINESS_DATA = useMemo(
    () => [
      {
        leftText: 'Company name',
        rightText: `${
          BUSINESS_DATA2?.approved_name || BUSINESS_DATA?.extracted_names || '-'
        } `,
      },
      {
        leftText: BUSINESS_DATA?.gd_type,
        rightText: `${
          BUSINESS_DATA2?.rc_number || BUSINESS_DATA?.extracted_rc_no || '-'
        } `,
      },
      {
        leftText: 'Country',
        rightText: BUSINESS_DATA?.country || '-',
      },
      {
        leftText: 'Address',
        rightText: BUSINESS_DATA2?.address || '-',
      },
      {
        leftText: 'registration date',
        rightText: showDate(
          BUSINESS_DATA2?.registration_date || BUSINESS_DATA?.extracted_date,
        ),
      },
    ],
    [BUSINESS_DATA, BUSINESS_DATA2, showDate],
  );

  const CUSTOMER_BUSINESS_ID = useMemo(
    () => [
      {
        leftText: 'Company name',
        rightText: `${BUSINESS_ID?.extracted_names || '-'} `,
      },
      {
        leftText: BUSINESS_ID?.extracted_type || 'RC',
        rightText: `${BUSINESS_ID?.extracted_rc_no || '-'}`,
      },
      {
        leftText: 'Country',
        rightText: BUSINESS_ID?.country || '-',
      },
      {
        leftText: 'date',
        rightText: showDate(BUSINESS_ID?.datetime),
      },
    ],
    [BUSINESS_ID, showDate],
  );

  const ID_DATA = useMemo(
    () => [
      {
        leftText: 'Full name',
        rightText:
          `${GOV_ID_DATA?.first_name} ${GOV_ID_DATA?.last_name} ${GOV_ID_DATA?.middle_name}` ||
          '-',
      },
      {
        leftText: 'expiry date',
        rightText: showDate(GOV_ID_DATA?.expiry_date),
      },

      {
        leftText: 'date of birth',
        rightText: showDate(GOV_ID_DATA?.date_of_birth),
      },
      {leftText: 'Country', rightText: GOV_ID_DATA?.nationality || '-'},
      {
        leftText: 'date issued',
        rightText: showDate(GOV_ID_DATA?.date_issued),
      },
      {
        leftText: 'ID number',
        rightText: GOV_ID_DATA?.document_number || '-',
      },
      {
        leftText: 'Type',
        rightText: GOV_ID_DATA?.document_type?.replace(/_/g, ' ') || '-',
      },
    ],
    [GOV_ID_DATA, showDate],
  );

  const resizeImage = (id, enlarge) => {
    const img = document.getElementById(id);

    if (enlarge) {
      img.classList.remove('max-h-[192px]');
    } else {
      img.classList.add('max-h-[192px]');
    }
  };

  return (
    <Layout verificationId={slug}>
      {loading && !session_summary ? (
        <Loader height={45} />
      ) : !loading &&
        ((session_summary && !session_summary?.data) || isEmpty) ? (
        // eslint-disable-next-line
        <EmptyState
          body="No Bio data found for this verification"
          noBtn
          height={50}
        />
      ) : (
        <div className="bg-white min-h-[660px]">
          {!isObjectEmpty(USER_DATA) && (
            <>
              <p className="font-medium text-grey">User Data</p>

              <div className="flex flex-col mt-6">
                {USER_DATA_1.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
            </>
          )}
          {!isObjectEmpty(BUSINESS_DATA) && (
            <>
              <p className="font-medium text-grey mt-14">Business Data</p>

              <div className="flex flex-col mt-6">
                {CUSTOMER_BUSINESS_DATA.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
              {/* <div className="flex items-center gap-2 mt-6 justify-end">
                <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px] self-end">
                  {Math.floor(Number(BUSINESS_DATA?.confidence)) || 0}%
                  confidence level
                </div>
              </div> */}
            </>
          )}
          {!isObjectEmpty(BUSINESS_ID) && (
            <>
              <p className="font-medium text-grey mt-14">Business ID</p>
              <div className="flex flex-col mt-6">
                {CUSTOMER_BUSINESS_ID.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
              <div className="flex items-center gap-2 mt-6 justify-end">
                <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px] self-end">
                  {Math.floor(Number(BUSINESS_ID?.confidence || 0))}% confidence
                  level
                </div>
              </div>
              <div className="relative mt-10">
                {typeof BUSINESS_ID?.image_url === 'string' &&
                BUSINESS_ID?.image_url?.endsWith('.pdf') ? (
                  <object
                    width="100%"
                    height="600px"
                    data={BUSINESS_ID?.image_url}
                  ></object>
                ) : (
                  <img
                    src={BUSINESS_ID?.image_url || placeholder}
                    alt=""
                    className="w-full mt-6 object-contain max-h-[192px]"
                    id="id-url"
                  />
                )}
                <ExpandImageToggle
                  resizeImage={resizeImage}
                  imgUrl={BUSINESS_ID?.image_url || placeholder}
                  id="id-url"
                />
              </div>
            </>
          )}
          {!isObjectEmpty(GOV_DATAS) &&
            MULTIPLE_GOVT_DATA?.length > 0 &&
            MULTIPLE_GOVT_DATA[0]?.idData?.length > 0 && (
              <>
                {MULTIPLE_GOVT_DATA.map(({idType, idData}) => (
                  <div key={idType} className="mt-5">
                    <Accordion
                      isOpen={MULTIPLE_GOVT_DATA.length !== 1}
                      customTitle={
                        <div className="flex items-center gap-1 w-full">
                          <p className="font-medium text-sm text-grey">
                            Government Data -
                          </p>{' '}
                          <div className="flex items-center gap-2">
                            <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]">
                              {idType?.replace(/_/g, ' ').toUpperCase() || '-'}
                            </div>
                          </div>
                        </div>
                      }
                    >
                      {idData?.map(({leftText, rightText}, i) => {
                        const isImageField = [
                          'image',
                          'photo',
                          'image url',
                        ].includes(leftText.toLowerCase());

                        return isImageField ? (
                          <div
                            key={i}
                            className="relative mb-10 flex justify-center"
                          >
                            <img
                              src={
                                `data:image/png;base64,${rightText}` ||
                                rightText
                              }
                              alt=""
                              className="mt-6 max-h-[192px] rounded"
                            />
                          </div>
                        ) : (
                          <div
                            key={i}
                            className="flex items-center justify-between border-t px-4 py-4 gap-10 border-grey60 last:border-b"
                          >
                            <span className="text-xs font-medium uppercase text-grey">
                              {leftText}
                            </span>
                            <p className="text-sm text-body whitespace-pre-line text-end">
                              {rightText}
                            </p>
                          </div>
                        );
                      })}
                    </Accordion>
                  </div>
                ))}
              </>
            )}
          {!isObjectEmpty(EMAIL) && (
            <div className="mt-10">
              <div className="flex items-center justify-between">
                <p className="font-medium text-grey">Email</p>
              </div>

              <div className="flex flex-col mt-4">
                <div className="flex items-center justify-between px-4 py-4 border-t border-grey60 last:border-y md:px-0">
                  <span className="text-xs font-medium uppercase text-grey">
                    Email
                  </span>
                  <span className="text-sm text-body">{EMAIL.email}</span>
                </div>
              </div>
            </div>
          )}
          {!isObjectEmpty(PHONE) && (
            <div className="mt-10">
              <div className="flex items-center justify-between">
                <p className="font-medium text-grey">Phone Number</p>
              </div>

              <div className="flex flex-col mt-4">
                <div className="flex items-center justify-between px-4 py-4 border-t border-grey60 last:border-y md:px-0">
                  <span className="text-xs font-medium uppercase text-grey">
                    Phone number
                  </span>
                  <span className="text-sm text-body">{PHONE.phone}</span>
                </div>
              </div>
            </div>
          )}
          {!isObjectEmpty(GOV_ID_DATA) && (
            <div className="mt-10">
              <div className="flex items-center justify-between">
                <p className="font-medium text-grey">Government ID</p>
                <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]">
                  {vType?.replaceAll('_', ' ')?.toUpperCase() || '-'}
                </div>
              </div>
              <div className="flex flex-col mt-4">
                {ID_DATA.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
              <div className="relative mt-5">
                <div className="relative">
                  <p className="text-xs font-bold uppercase text-black">
                    Front
                  </p>

                  <img
                    src={VERIFICATION?.id_url || placeholder}
                    alt=""
                    className="w-full mt-2 object-cover max-h-[192px]"
                    id="id-url"
                  />
                  <ExpandImageToggle
                    resizeImage={resizeImage}
                    imgUrl={VERIFICATION?.id_url || placeholder}
                    id="id-url"
                  />
                </div>
                {VERIFICATION?.back_url && (
                  <div className="relative mt-4">
                    <p className="text-xs font-bold uppercase text-black">
                      Back
                    </p>
                    <img
                      src={VERIFICATION?.back_url || placeholder}
                      alt=""
                      className="w-full mt-2 object-cover max-h-[192px]"
                      id="back-url"
                    />
                    <ExpandImageToggle
                      resizeImage={resizeImage}
                      imgUrl={VERIFICATION?.back_url || placeholder}
                      id="back-url"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {!isObjectEmpty(LIVENESS) &&
            (LIVENESS?.close_mouth_url || LIVENESS?.face_url) && (
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <p className="font-medium text-grey">
                    {vType?.includes('video')
                      ? 'Video'
                      : vType?.includes('selfie')
                      ? 'Selfie'
                      : 'Liveness'}
                  </p>
                  <div className="flex items-center gap-2">
                    {LIVENESS?.status === 'Completed' && (
                      <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-success20 font-medium bg-success rounded-full w-fit flex items-start gap-[2px]">
                        Completed
                      </div>
                    )}
                    {/*<div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]">*/}
                    {/*  {Math.floor(Number(LIVENESS?.confidence)) || 0}%*/}
                    {/*  confidence level*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className="flex justify-center flex-wrap md:flex-nowrap items-center gap-2 mt-6 w-full  md:max-w-full">
                  {LIVENESS?.face_url && (
                    <div className="relative w-fit">
                      {!LIVENESS?.face_url.endsWith('.webm') ? (
                        <RoundedImage imageUrl={LIVENESS?.face_url} />
                      ) : (
                        <video width="auto" height="auto" controls>
                          <source src={LIVENESS?.face_url} type="video/webm" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  )}
                  {LIVENESS?.open_mouth_url && (
                    <div className="relative w-fit">
                      <RoundedImage imageUrl={LIVENESS?.open_mouth_url} />
                    </div>
                  )}
                  {LIVENESS?.close_mouth_url && (
                    <div className="relative w-fit">
                      <RoundedImage imageUrl={LIVENESS?.close_mouth_url} />
                    </div>
                  )}
                </div>
              </div>
            )}
          {!isObjectEmpty(SIGNATURE) && (
            <>
              <div className="mt-10">
                <p className="font-medium text-grey">Signature</p>

                <div className="flex flex-col mt-4">
                  <RowData
                    leftText="Signature"
                    rightText={SIGNATURE?.signature}
                  />
                </div>
              </div>
              <div className="mt-2">
                {!isLoading && userApps ? (
                  <GenerateAndDownloadSignaturePDF
                    logo={APP_INFO?.logo}
                    text={SIGNATURE?.text}
                    name={SIGNATURE?.signature}
                    signature={SIGNATURE?.url}
                  />
                ) : (
                  ''
                )}
              </div>
            </>
          )}
          {Array.isArray(UPLOAD_FILES) && UPLOAD_FILES.length > 0 && (
            <div className="mt-10">
              <p className="font-medium text-grey">Other IDs/Documents</p>
              <div className="relative mt-2">
                {UPLOAD_FILES.map((file, i) => (
                  <div
                    className="mb-7 relative border bg-white80 border-dashed rounded-md border-brandBlue4 p-3 w-full"
                    key={i}
                  >
                    <div className=" flex w-full">
                      <span className="uppercase pt-[2px] pb-[3px] px-2 text-xs text-brandBlue50 font-medium bg-brandBlue4 rounded-full">
                        {file?.title || '-'}
                      </span>
                    </div>

                    {file?.file_url &&
                    ['.jpg', '.png', '.jpeg', '.j'].includes(
                      file?.file_url.match(/\.[^.]*$/)[0],
                    ) ? (
                      <div className="relative">
                        <img
                          src={file?.file_url || placeholder}
                          alt=""
                          className="w-full mt-3 rounded-md object-cover max-h-[192px]"
                          id={`id-url-${i}`}
                        />
                        <ExpandImageToggle
                          resizeImage={resizeImage}
                          imgUrl={file?.file_url || placeholder}
                          id={`id-url-${i}`}
                        />
                      </div>
                    ) : (
                      <object
                        width="100%"
                        height="600px"
                        data={file?.file_url}
                      ></object>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          <ComplianceQuestions QUESTIONS={QUESTIONS} />
        </div>
      )}
    </Layout>
  );
}

export default BioData;
