import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {
  EmptyState,
  FilterButton,
  PageLimit,
  TableLayout,
} from '../../../../components';
import Layout from '../Layout';
import CasesFilter from './CasesFilter';
import * as Actions from '../../../../store/actions';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/Loader';
import moment from 'moment';
import {PageContext} from '../../../../helpers/context';
import {useQuery} from 'react-query';
import {
  fetchCases,
  // generateSampleData,
} from '../../../../requests/queries/flows';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';
import {getStatusTextAndStyles} from '../../../../helpers/getStatusTextAndStyles';

function Tabs({activeTab, setActiveTab}) {
  return (
    <div className="flex items-center gap-1 relative pr-12">
      <div className="absolute w-full h-[1px] bg-[#E0E0E0] bottom-0" />
      <button
        className={`px-[10px] pt-[12px] pb-[10px] rounded-t border border-b-0 ${
          activeTab === 'pending'
            ? 'bg-brandBlue50 text-sm text-white font-semibold border-brandBlue relative'
            : 'bg-[#F2F2F2] text-sm text-grey border-grey60'
        }`}
        onClick={() => setActiveTab('pending')}
      >
        Pending cases
      </button>
      <button
        className={`px-[10px] pt-[12px] pb-[10px] rounded-t border border-b-0 ${
          activeTab === 'Completed'
            ? 'bg-brandBlue50 text-sm text-white font-semibold border-brandBlue relative'
            : 'bg-[#F2F2F2] text-sm text-grey border-grey60'
        }`}
        onClick={() => setActiveTab('Completed')}
      >
        Resolved cases
      </button>
    </div>
  );
}

function FlowsCases() {
  const page = localStorage.getItem('casesPageNumber');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('pending');
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(page || '1');
  const [limit, setLimit] = useState(10);
  const {slug} = useParams();
  const capitalizedWord = word => word.charAt(0).toUpperCase() + word.slice(1);

  const params = useMemo(
    () => ({
      project_id: slug,
      limit,
      page: currentPage,
      status: capitalizedWord(activeTab),
    }),
    [activeTab, currentPage, limit, slug],
  );

  const {data: casesData, isLoading} = useQuery(['flow-cases', {params}], () =>
    fetchCases({params}),
  );
  // useQuery(['sample', {params}], () => generateSampleData('y74iGDG'));

  const cases = useMemo(
    () => (!isLoading && casesData && casesData?.rows) || [],
    [casesData, isLoading],
  );

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  return (
    <Layout
      action={
        <CasesFilter
          status={activeTab}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          onCheck={setFilters}
          handleFilter={() => {}}
          resetFilters={() => {}}
          appFilters={filters}
        />
      }
    >
      <div className="py-7 bg-white -ml-12 pl-12 -mr-4 xl:mr-[-76px] pr-7">
        <div className="mt-7">
          <p className="text-sm text-grey font-medium">Cases</p>
        </div>
        <div className="mt-6 flex items-center justify-between">
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className="flex items-center gap-4">
            {/*<SearchInput />*/}
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
          </div>
        </div>
      </div>

      <TableLayout negativeMargins negativeRightMargin="-mr-4 xl:-mr-[76px]">
        <thead className="text-xs font-semibold uppercase">
          <tr className="bg-white80">
            <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
              <div className="flex items-end gap-1">
                <span>case id</span>
              </div>
            </th>
            <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
              <div className="flex items-end gap-1">
                <span>Transaction Amount</span>
              </div>
            </th>
            <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
              <div className="flex items-end gap-1">
                <span>status</span>
              </div>
            </th>
            <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
              <div className="flex items-end gap-1">
                <span>date created</span>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={8}>
              {isLoading ? (
                <Loader height={65} />
              ) : !isLoading && cases?.length === 0 ? (
                <EmptyState
                  body={`No ${activeTab} case(s) found`}
                  noBtn
                  height={65}
                />
              ) : null}
            </td>
          </tr>
        </tbody>

        <tbody className="text-sm bg-white">
          {cases?.length !== 0 &&
            cases?.map((_case, i) => (
              <tr
                className="border-b border-grey60 cursor-pointer"
                key={i}
                onClick={() =>
                  navigate(`/flows/cases/${slug}/details/${_case.id}`, {
                    state: {
                      status: _case.status,
                      createdAt: moment(_case?.createdAt).format(
                        'Do MMM YYYY h:m a',
                      ),
                    },
                  })
                }
              >
                <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                  {`${_case.id.toUpperCase()}` || '-'}
                </td>
                <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                  {shortenLargeNumbers(_case.transaction?.amount, true, {
                    startAt: 'B',
                  }) || '-'}
                </td>
                <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                  <div
                    className={`uppercase py-1 px-2 text-xs font-medium ${
                      getStatusTextAndStyles(_case?.status?.toLowerCase())
                        ?.solid
                    } rounded-full flex items-center w-fit`}
                  >
                    <span className="-mb-[2px]">{_case.status || '-'}</span>
                  </div>
                </td>
                <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                  {moment(_case?.createdAt).format('Do MMM YYYY h:m a')}
                </td>
              </tr>
            ))}
        </tbody>
      </TableLayout>

      {!isLoading && cases?.length !== 0 && (
        <div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">
          <div className="flex items-center text-body text-tiny">
            <PageLimit
              onLimitChange={setLimit}
              total={casesData?.count}
              length={cases?.length}
              limit={limit}
            />
          </div>

          <div className="mb-8 sm:mb-0">
            <Pagination
              total={casesData && Math.ceil(casesData?.count / limit)}
              current={+currentPage}
              onPageChange={activePage => {
                localStorage.setItem('casesPageNumber', String(activePage));
                pageClick(activePage);
              }}
            />
          </div>
        </div>
      )}
    </Layout>
  );
}

export default connect(state => state, Actions)(FlowsCases);
