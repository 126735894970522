import React, {useMemo} from 'react';
import {useAllApps} from '../../hooks/useApps';
import {getCookieValue} from '../../helpers/getCookie';
import {useQuery} from 'react-query';
import {fetchVerificationsAnalysis} from '../../requests/queries/verifications';
import {getFlows} from './utils';
import {OverviewCards} from '../../components/OverviewCards';

export const OverviewAnalytics = ({cardData = [], isLoading}) => {
  const {appIds, isLoading: appsLoading} = useAllApps();

  const env = getCookieValue('env');
  const envData = env === 'Live' ? 'production' : env;

  const params = useMemo(
    () => ({
      app_ids: appIds?.toString(),
      environment: envData?.toLowerCase(),
    }),
    [appIds, envData],
  );
  const {data, isLoading: statsLoading} = useQuery(
    ['verification-analysis', {...params}],
    () => fetchVerificationsAnalysis({params}),
    {enabled: Boolean(!appsLoading && appIds && cardData?.length === 0)},
  );

  const ANALYSIS_DATA = useMemo(
    () => (!statsLoading && data?.data ? data?.data : []),
    [data?.data, statsLoading],
  );

  const CARDS = getFlows(ANALYSIS_DATA?.cards);
  const columnsLength = cardData?.length || CARDS?.length;
  const allCardData = cardData?.length > 0 ? cardData : CARDS;

  return (
    <>
      <OverviewCards
        cardData={allCardData}
        overviewLoading={statsLoading || isLoading}
        className={`grid  md:grid-cols-${
          columnsLength > 3 ? columnsLength - 1 : 3
        } xl:grid-cols-${columnsLength} gap-2 lg:gap-4 flex-wrap my-5`}
      />
    </>
  );
};
