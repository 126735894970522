import React, {useMemo, useState} from 'react';
import {
  DashboardLayout,
  EmptyState,
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  SearchInput,
  TableLayout,
} from '../../../components';
import {
  baasBalance,
  baasMobile,
  corruptedFile,
  eye,
} from '../../../assets/images/images';
import {useNavigate} from 'react-router-dom';
import {
  Constants,
  filterOutEmptyValues,
  generateQueryParams,
} from '../../../helpers';
import {useQuery} from 'react-query';
import {fetchBAASCompanyWallets} from '../../../requests/queries/baas';
import moment from 'moment';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {useSelector} from 'react-redux';
import {useBaasExport} from './hooks/useBAASExport';
import {CopyItem} from '../../../components/CopyItem';
import {usePermissions} from '../../../hooks/useSidbarPermissions';
import {OverviewCards} from '../../../components/OverviewCards';
import {buildTableCardData} from '../../../helpers/buildTableCardData';
import AppliedFilters from 'components/AppliedFilters';

const initialFilters = {
  start: '',
  end: '',
  flows: '',
};

function BaasWallets() {
  const [openFilter, setOpenFilter] = useState(false);
  const navigate = useNavigate();
  const [filters, setFilters] = useState(initialFilters);
  const [searchText, setSearchText] = useState('');
  const {userDetails} = useSelector(state => state?.auth);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterProps, setFilterProps] = useState(null);

  const [query, setQuery] = useState({
    page: 1,
    length: 10,
  });

  const {
    baasPagePermissions: {canExportWallets},
  } = usePermissions();
  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  const params = useMemo(
    () => ({
      ...query,
      company_id: userDetails && userDetails?.company?.id,
      search: searchText,
      start_date: filters?.start,
      end_date: filters?.end,
    }),
    [filters?.end, filters?.start, query, searchText, userDetails],
  );

  const {handleExport, exportData} = useBaasExport({
    params,
    apiFunc: fetchBAASCompanyWallets,
  });

  const {data: company_wallets, isLoading} = useQuery(
    ['baas-wallet', {params}],
    () => fetchBAASCompanyWallets({params}),
    {
      meta: {preventGlobalError: true},
      enabled: !exportData,
    },
  );

  const wallets = useMemo(
    () => !isLoading && company_wallets && company_wallets?.entity,
    [company_wallets, isLoading],
  );

  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);
    const {status} = filteredOptions;
    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }
    let baseUrl = '';
    if (queryParams) {
      baseUrl += `?${queryParams}`;
    }

    if (shouldDownload) {
      filteredOptions.download = true;
      setFilters({
        ...filters,
        ...filteredOptions,
      });
    } else {
      navigate(baseUrl);
    }

    setFilters(filteredOptions);
    setOpenFilter(false);
    setFiltersApplied(true);
  };

  const handleResetFilter = () => {
    navigate('');
    setFilters(initialFilters);
    setOpenFilter(true);
    setFiltersApplied(false);
  };

  const tableCardData = buildTableCardData({
    count: wallets?.total,
    title: 'Total Wallet Created',
    icon: baasBalance,
  });

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-8"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={baasMobile} alt="" />
          <p className="ml-2 -mb-1">Wallets</p>
        </div>
      }
    >
      <div className="flex items-start lg:items-center lg:flex-row flex-col gap-5 justify-between p-5 lg:p-0">
        <div className="flex items-center gap-4 flex-wrap">
          <p className="text-grey font-medium text-[18px]">Wallets</p>
          {filtersApplied && filterProps && (
            <AppliedFilters {...filterProps} isMainPage={true} />
          )}
        </div>

        <div className="flex items-center gap-2">
          <SearchInput
            onChange={value => setSearchText(value)}
            className="sm:w-[277px] rounded-lg outline-[#eceff3]"
          />
          <FilterButton
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            className="h-[48px] justify-center w-[130px]"
          />
          <PrimaryButton
            buttonText="Export"
            className="w-[121px]"
            onClick={handleExport}
            loading={exportData}
            disabled={!canExportWallets}
          />
        </div>
      </div>

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        onFilterStateChange={handleFilterStateChange}
        filterOptions={[]}
      />

      <div className="mt-8">
        <OverviewCards
          cardData={tableCardData}
          overviewLoading={isLoading}
          className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-1 gap-2 lg:gap-4 flex-wrap my-5`}
        />
        <TableLayout negativeMargins className="mt-6 w-full">
          <thead className="text-small font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                wallet name
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                bank name
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                account number
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                date created
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                current balance
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap"></th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {isLoading ? (
                  <Loader height={45} />
                ) : !isLoading && wallets?.wallets?.length === 0 ? (
                  <EmptyState
                    height={45}
                    heading="No record found"
                    noBtn
                    className="w-full"
                    src={corruptedFile}
                    body="Opps No wallet history found this company"
                    maxWidth="max-w-[500px]"
                  />
                ) : null}
              </td>
            </tr>
          </tbody>

          {!isLoading &&
            wallets &&
            wallets?.wallets?.map(wallet => (
              <tbody
                className="text-sm text-body bg-white"
                key={wallet?.wallet_id}
              >
                <tr className="text-left border-b  bg-white border-grey60">
                  <td className="p-4 pl-12 whitespace-nowrap">
                    {`${wallet?.first_name || ''} ${
                      wallet?.last_name || '-'
                    }` || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {wallet?.bank_name || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap flex items-center">
                    {wallet?.account_number || '-'}
                    {wallet?.account_number?.length > 0 && (
                      <CopyItem value={wallet?.account_number} />
                    )}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {(wallet?.date_created &&
                      moment(wallet?.date_created).format('DD MMM YYYY h:m')) ||
                      '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    {shortenLargeNumbers(wallet?.wallet_amount || 0, 'NGN', {
                      startAt: 'M',
                    })}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <button
                      onClick={() =>
                        navigate(
                          Constants.PAGES_URL.BAAS_ACCOUNT_DETAIL?.replace(
                            ':slug',
                            wallet?.wallet_id,
                          ),
                        )
                      }
                    >
                      <img src={eye} alt="" />
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
        </TableLayout>
      </div>

      {!isLoading && wallets?.wallets?.length > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-12 sm:gap-0 pl-12 pr-6">
          <PageLimit
            onLimitChange={length => {
              setQuery(prevState => ({
                ...prevState,
                length,
                page: 1,
              }));
            }}
            limit={query?.length}
            total={wallets?.current_page}
            length={wallets?.wallets?.length}
          />

          <Pagination
            total={wallets?.total_pages}
            current={Number(wallets?.current_page)}
            onPageChange={activePage => {
              pageClick(activePage);
              const searchParams = new URLSearchParams(
                document.location.search,
              );
              searchParams.set('page', activePage);
              const newSearchParams = searchParams.toString();
              navigate(`?${newSearchParams}`);
            }}
          />
        </div>
      )}
    </DashboardLayout>
  );
}

export default BaasWallets;
