import {api} from '../../helpers';

function fetchIndividualLookup(params) {
  return api
    .get('/users/widget/lookup/individual', {params})
    .then(res => res.data);
}
function fetchLookupHistoryAnalysis(params) {
  return api
    .get('/utils/api/lookup_history/analysis', {...params})
    .then(res => res.data);
}

function fetchGlobalSearch(params) {
  return api
    .get('/users/widget/lookup/business/detail', {params})
    .then(res => res.data);
}
function fetchBanksCodes() {
  return api.get('/users/widget/lookup/bank-codes').then(res => res.data);
}

export {
  fetchLookupHistoryAnalysis,
  fetchIndividualLookup,
  fetchGlobalSearch,
  fetchBanksCodes,
};
