import React from 'react';
import {SkeletonLoader} from './index';
import {toolTip} from '../assets/images/images';

export const OverviewCards = ({
  cardData = [],
  overviewLoading,
  className,
  innerClassName,
  loaderWidth,
}) => (
  <div
    className={
      className ||
      'flex items-center justify-center xl:justify-between xl:gap-0 gap-4 mt-4 flex-wrap mb-12'
    }
  >
    {cardData &&
      cardData?.map(({icon, alt, title, value, count, isNegative}) => (
        <div
          key={title}
          className={`${innerClassName} bg-white relative py-6 pl-6 flex-wrap pr-2 rounded-lg flex items-center gap-2 lg:flex-col lg:items-start xl:flex-row xl:items-center`}
        >
          <img src={icon} alt={alt} />
          <div className="flex flex-col flex-wrap gap-1">
            <p className="text-sm text-body">{title}</p>

            {overviewLoading ? (
              <SkeletonLoader
                style={{
                  width: loaderWidth || 150,
                  height: 25,
                  marginBottom: 10,
                }}
              />
            ) : (
              <h1
                className={`text-[20px] font-semibold ${
                  isNegative ? 'text-status-404' : 'text-grey'
                }`}
              >
                {value}
              </h1>
            )}
            {title === 'In Progress' && (
              <div className="absolute bottom-[14px] right-[24px] group">
                <img src={toolTip} alt="tooltip" className="cursor-pointer" />
                <div className="hidden group-hover:block w-[229px] absolute bottom-full text-center top-[25px] right-0 bg-[#F9F9F9] text-[#5C688E] text-[12px] rounded p-2">
                  This is a combination of Pending and Ongoing Verifications
                </div>
              </div>
            )}
            {count && (
              <p className="font-medium text-xs absolute right-[16px] bottom-[8px]">
                Count: <span>{count}</span>
              </p>
            )}
          </div>
        </div>
      ))}
  </div>
);
