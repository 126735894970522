import {
  logo as defaultLogo,
  dojahFavicon,
  reconciloLogo,
  trypilaLogo,
} from '../assets/images/images';
import {setLocalStorageData} from './index';
import {MODULE} from '../store/types';

const DEFAULT_THEME = {
  color: '#3F7CDB',
  tagline: 'Generic company tagline',
  dojahTagline: 'Verify Customer Identity Easily | KYC\nVerification | Dojah',
};

/**
 * Determines the correct company logo based on the subdomain.
 */
const getCompanyLogo = (subdomain, fetchedLogo) => {
  if (subdomain.includes('reconcilo')) return reconciloLogo;
  if (subdomain.includes('trypila')) return trypilaLogo;
  return fetchedLogo || defaultLogo;
};

/**
 * Updates the theme settings for the application.
 */
const updateTheme = ({colorCode, companyLogo, favicon, tagline, name}) => {
  document.title = name
    ? tagline || DEFAULT_THEME.tagline
    : DEFAULT_THEME.dojahTagline;
  document.body.style.setProperty('--color-primary', colorCode);

  setLocalStorageData('companyColor', colorCode);
  setLocalStorageData('companyLogo', companyLogo);
  setLocalStorageData(
    'companyFavicon',
    name ? favicon || 'null' : dojahFavicon,
  );
};

/**
 * Fetches and sets the theme for the given subdomain.
 */
export const getSubDomainTheme = async (subdomain, setLoading, dispatch) => {
  setLoading(true);

  const companyLogo = getCompanyLogo(subdomain);
  setLocalStorageData('subdomain', subdomain);
  setLocalStorageData('companyLogo', companyLogo);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/company/context?subdomain=${subdomain}`,
    );
    const data = await response.json();

    if (!data?.company?.name) throw new Error('Company data missing');

    const {
      color_code,
      logo,
      tagline,
      favicon,
      name,
      modularisation,
      is_partner_company,
    } = data?.company || {};

    dispatch({
      type: MODULE,
      payload: {...modularisation, is_partner_company},
    });

    updateTheme({
      colorCode: color_code || DEFAULT_THEME.color,
      companyLogo: getCompanyLogo(subdomain, logo),
      tagline,
      favicon,
      name,
    });
  } catch (error) {
    console.error('Error fetching company theme:', error.message);
    updateTheme({
      colorCode: DEFAULT_THEME.color,
      companyLogo,
    });
  } finally {
    setLoading(false);
  }
};
