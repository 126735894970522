/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable-next-line no-unused-vars */
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {
  Checkbox,
  CustomRadio,
  Modal,
  VerificationToggle,
} from '../../../components';
import * as Actions from '../../../store/actions';
import {Constants} from '../../../helpers';
import CountryInput from '../../../components/CountryInput';
import {infoRedSm} from '../../../assets/images/images';
import {EasyOnboardContext} from '../../../helpers/context/EasyOnboardContext';
import OTPVerificationMode from '../../../components/OTPVerificationMode';
import {shortenLargeNumbers} from 'helpers/shortenLargeNumbers';
import {
  countryDefaults,
  CountryType,
  deleteTypeIfLivenessFalse,
  getMatchedCountries,
  ToggleCountriesData,
  updateConfig,
} from './utils';

function GovernmentData({
  widgets: {widgetScreen, w_loading, widgetPages, widgetPricing},
  auth: {userDetails, userSettings},
  setWidgetCurrentPricing,
  setWidgetScreen,
  setWidgetPages,
  ...otherProps
}) {
  const initialRender = useRef(true);

  const {open, setOpen} = otherProps;
  const [selectedCountry, setSelectedCountry] = useState({});
  const [update, setUpdate] = useState(true);
  const [config, setConfig] = useState({});
  const [pricing, setPricing] = useState({
    services: {},
    verification: {},
  });
  const {setDetectWithLiveness} = useContext(EasyOnboardContext);
  const displayPrice = price =>
    shortenLargeNumbers(price || 0, userDetails?.company?.currency, {});
  const foundWidget =
    useMemo(
      () =>
        widgetPages &&
        widgetPages?.find(
          data =>
            data?.pageId === widgetScreen?.pageId &&
            data?.page === 'government-data',
        ),
      [widgetPages, widgetScreen],
    ) || null;

  useEffect(() => {
    if (foundWidget && initialRender.current && !w_loading) {
      const matchedCountries = getMatchedCountries(foundWidget);
      if (matchedCountries.length > 0) {
        setSelectedCountry(matchedCountries[0]);
      } else {
        setSelectedCountry(Constants.lookups.countries2[0]);
      }

      const selectedDefaults = countryDefaults[selectedCountry?.value] || {};
      const updatedConfig = Object.keys(selectedDefaults).reduce((acc, key) => {
        acc[key] =
          foundWidget?.config[key] !== undefined
            ? foundWidget?.config[key]
            : selectedDefaults[key];
        return acc;
      }, {});

      setConfig(updatedConfig);

      initialRender.current = false;
    }
  }, [w_loading, foundWidget, selectedCountry.value]);

  useEffect(() => {
    const governmentDataPage = widgetPages?.find(
      data =>
        data?.page === 'government-data' && data[`${data.page}-${data.pageId}`],
    );

    if (!governmentDataPage) {
      setWidgetCurrentPricing({'government-data': {}});
      return () => {
        setWidgetCurrentPricing({'government-data': {}});
        setPricing({services: {}, verification: {}});
      };
    }

    const servicesPricing = widgetPricing?.['government-data']?.services || {};
    const countryPrefix = CountryType(selectedCountry);

    setPricing(prev => {
      const newServices = {...prev.services};
      const newVerification = {...prev.verification};

      Object.keys(config).forEach(key => {
        if (typeof config[key] !== 'boolean') return;

        const priceKey = `${countryPrefix}_${key}`.replace(
          /^(ke|gh|zw|ao|za)-/,
          '',
        );
        const price = !['otp', 'selfie', 'liveness'].includes(key)
          ? servicesPricing[priceKey] / 100
          : undefined;

        if (config[key]) {
          newServices[key] = price;
          if (key === 'bvnAdvance')
            newServices[key] = servicesPricing?.ng_bvn_advance / 100;
          if (key === 'emailOtp')
            newServices[key] = servicesPricing?.email_otp / 100;

          if (key === 'otp') newVerification[key] = servicesPricing?.otp / 100;
          if (key === 'selfie' && config.liveness) {
            newVerification[key] =
              servicesPricing[
                config.version === 2 ? 'liveness_video' : 'liveness'
              ] / 100;
          }
        } else {
          delete newServices[key];
          delete newVerification[key];
        }
      });

      return {services: newServices, verification: newVerification};
    });

    setWidgetCurrentPricing({'government-data': pricing});

    return () => {
      setWidgetCurrentPricing({'government-data': {}});
      setPricing({services: {}, verification: {}});
    };
  }, [
    widgetScreen,
    update,
    widgetPages,
    selectedCountry.value,
    setWidgetCurrentPricing,
    config,
  ]);

  useEffect(() => {
    if (!w_loading && foundWidget) {
      if (Object.values(foundWidget?.config || {}).length === 0) {
        setSelectedCountry(Constants.lookups.countries2[0]);
        const defaultConfig =
          countryDefaults[Constants.lookups.countries2[0]?.value] || {};
        setConfig({...deleteTypeIfLivenessFalse(defaultConfig)});
        setUpdate(false);
        setWidgetPages({
          ...foundWidget,
          config: {...deleteTypeIfLivenessFalse(defaultConfig)},
        });

        updateConfig(
          {...deleteTypeIfLivenessFalse(defaultConfig)},
          foundWidget,
        );
      } else {
        const data = foundWidget.config.type
          ? {
              ...config,
              ...foundWidget?.config,
              liveness: foundWidget?.config?.liveness || true,
              type: foundWidget?.config?.type,
            }
          : {
              ...config,
              ...foundWidget?.config,
              liveness: foundWidget?.config?.liveness,
            };
        setConfig({
          ...deleteTypeIfLivenessFalse(data),
          ...(data?.bvnAdvance ? {bvn: false} : {emailOtp: false}),
        });
        const {brightnessThreshold, glassesCheck} = data;
        setDetectWithLiveness(prev => ({
          brightnessThreshold:
            (brightnessThreshold !== undefined && brightnessThreshold) ||
            prev?.brightnessThreshold,
          glassesCheck:
            (glassesCheck !== undefined && glassesCheck) || prev?.glassesCheck,
        }));
        setWidgetPages({
          ...foundWidget,
          config: {
            ...deleteTypeIfLivenessFalse(data),
            ...(data?.bvnAdvance ? {bvn: true} : {emailOtp: false}),
          },
        });
        updateConfig(
          {
            ...deleteTypeIfLivenessFalse(data),
            ...(data?.bvnAdvance ? {bvn: true} : {emailOtp: false}),
          },
          foundWidget,
        );
      }
    }
  }, [widgetScreen, w_loading, deleteTypeIfLivenessFalse, updateConfig]);

  const handleUpdateConfig = (key, value) => {
    const newData = widgetPages?.find(
      data => data?.pageId === widgetScreen?.pageId,
    );

    if (!newData) {
      return toast.error(
        'Oops! Please toggle ON before configuring this widget',
      );
    }

    const nigeriaOverrides =
      selectedCountry?.value === 'nigeria'
        ? {
            version: value === 'advanced' ? 2 : 3,
            ...(key === 'bvnAdvance' &&
              value && {bvnAdvance: value, bvn: !value, emailOtp: value}),
            ...(key === 'bvn' &&
              value && {bvn: value, bvnAdvance: !value, emailOtp: !value}),
          }
        : {};

    const updatedConfig = deleteTypeIfLivenessFalse({
      ...config,
      ...widgetScreen?.config,
      ...nigeriaOverrides,
      [key]: value,
    });

    const newConfig = {
      ...updatedConfig,
      ...(updatedConfig?.bvnAdvance && {bvn: true}),
    };

    setConfig(newConfig);
    setWidgetScreen({...widgetScreen, config: newConfig});
    setWidgetPages({...newData, config: newConfig});
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="max-w-[339px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Configuration"
    >
      <div className="mt-8">
        <CountryInput
          defaultValue={selectedCountry}
          value={selectedCountry}
          options={Constants.lookups.countries2}
          onChange={country => {
            setSelectedCountry(country);
            const selectedConfig = countryDefaults[country?.value] || {};
            setConfig(selectedConfig);
            setWidgetScreen({...widgetScreen, config: selectedConfig});
            setWidgetPages({...foundWidget, config: selectedConfig});
          }}
          label="Select country"
        />
        <ToggleCountriesData
          selectedCountry={selectedCountry}
          config={config}
          handleUpdateConfig={handleUpdateConfig}
          setPricing={setPricing}
          widgetPricing={widgetPricing}
          userDetails={userDetails}
          userSettings={userSettings}
          displayPrice={displayPrice}
        />
        <div className="mt-6">
          <p className="text-left text-body text-xs mb-2 font-medium uppercase">
            Mode of verification
          </p>
          <div className="bg-white80 rounded-lg p-2">
            <Checkbox
              label="OTP Verification"
              className="mb-2 !pl-0"
              labelStyle="text-sm text-body leading-5"
              align="items-start"
              isChecked={config?.otp}
              onChange={e => {
                handleUpdateConfig('otp', e.target.checked);
                setPricing(prev => {
                  if (e.target.checked) {
                    return {
                      ...prev,
                      verification: {
                        ...prev.verification,
                        ...{
                          otp:
                            widgetPricing['government-data']?.services?.otp /
                            100,
                        },
                      },
                    };
                  } else {
                    delete prev.verification?.otp;
                    return {...prev};
                  }
                });
                setWidgetCurrentPricing({
                  'government-data': pricing,
                });
              }}
            />
            {config?.bvnAdvance && (
              <Checkbox
                isChecked={config?.emailOtp}
                label="Email OTP"
                onChange={e => {
                  handleUpdateConfig('emailOtp', e.target.checked);
                  setPricing(prev => {
                    if (e.target.checked) {
                      return {
                        ...prev,
                        services: {
                          ...prev.services,
                          ...{
                            email_otp:
                              widgetPricing['government-data']?.services
                                ?.email_otp / 100,
                          },
                        },
                      };
                    } else {
                      delete prev.services?.email_otp;
                      return {...prev};
                    }
                  });
                  setWidgetCurrentPricing({
                    'government-data': pricing,
                  });
                }}
                className="py-[10px]"
                labelStyle="text-sm text-body leading-5"
                align="items-start"
              />
            )}

            <div className="relative">
              <OTPVerificationMode
                handleUpdateConfig={handleUpdateConfig}
                config={config}
                isConfigurable={!foundWidget}
                whatsapp={'whatsappOtp'}
                action={
                  <div className="flex gap-2 items-start p-2 mb-2 bg-statusBg-404 rounded">
                    <img src={infoRedSm} alt="" width={11} height={11} />
                    <p className="text-[8px] text-start text-grey -mb-1 leading-normal">
                      Driver’s License{' '}
                      {selectedCountry.value === 'kenya' && 'and Passport'}{' '}
                      cannot be used with OTP Verification mode
                    </p>
                  </div>
                }
                pricing={{
                  sms: displayPrice(
                    widgetPricing &&
                      widgetPricing['government-data']?.services?.otp / 100,
                  ),
                  whatsapp: displayPrice(
                    widgetPricing &&
                      widgetPricing['government-data']?.services?.whatsappOtp /
                        100,
                  ),
                }}
              />
            </div>
          </div>

          <Checkbox
            label="Liveness Verification"
            otherText=" "
            className="py-[10px]"
            labelStyle="text-sm text-body leading-5"
            align="items-start"
            isChecked={config?.liveness}
            onChange={e => {
              handleUpdateConfig('liveness', e.target.checked);
              setPricing(prev => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    verification: {
                      ...prev.verification,
                      ...{
                        selfie:
                          widgetPricing &&
                          widgetPricing['government-data']?.services?.liveness /
                            100,
                      },
                    },
                  };
                } else {
                  delete prev.verification?.selfie;
                  return {...prev};
                }
              });
              setWidgetCurrentPricing({
                'government-data': pricing,
              });
            }}
          />
        </div>
        {config?.liveness && (
          <>
            <div className="mt-6">
              <p className="text-left text-body text-xs mb-2 font-medium uppercase">
                Liveliness Check
              </p>
              <CustomRadio
                options={[
                  {
                    label: 'Selfie Verification',
                    value: 'basic',
                    price: displayPrice(
                      widgetPricing &&
                        widgetPricing['government-data']?.services?.liveness /
                          100,
                    ),
                  },
                ]}
                onChange={type => {
                  if (config.liveness) {
                    handleUpdateConfig('type', type);
                    setPricing(prev => {
                      if (config.liveness === true) {
                        return {
                          ...prev,
                          verification: {
                            ...prev.verification,
                            ...{
                              selfie:
                                widgetPricing &&
                                widgetPricing['government-data']?.services
                                  ?.liveness / 100,
                            },
                          },
                        };
                      } else {
                        delete prev.verification?.liveness;
                        return {...prev};
                      }
                    });
                    setWidgetCurrentPricing({
                      'government-data': pricing,
                    });
                  }
                }}
                value={config?.type}
              />
            </div>
            <div>
              <p className="text-left text-body text-xs mt-8 mb-2 font-medium uppercase">
                CAMERA FLIP OPTION
              </p>
              <div className="flex items-center gap-2 pl-4 pr-2 py-[10px] cursor-pointer">
                <VerificationToggle
                  isChecked={config?.flipCamera}
                  onCheck={value => handleUpdateConfig('flipCamera', value)}
                />
                <div className="flex justify-between w-48">
                  <p className="text-xs leading-[18px] text-body">
                    Flip to back Camera
                  </p>
                </div>
              </div>
              <p className="text-left text-grey40 text-xs mt-2 mb-2 leading-5 ">
                Toggle on to add camera icon to the widget and easily flip to
                back camera
              </p>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
export default connect(state => state, Actions)(GovernmentData);
