import {useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {fetchBAASSettings} from '../../../../requests/queries/baas';
import {useSelector} from 'react-redux';
import {useBankCodes} from '../../../../hooks/useBankCodes';
import {settlementType} from '../constants';

export const useBaasSettings = ({type = 'transfer'}) => {
  const {banks, isLoading: banksLoading} = useBankCodes();
  const {userDetails, user} = useSelector(state => state?.auth);
  const [selectedBank, setSelectedBank] = useState({});
  const [accountNumber, setAccountNumber] = useState('');
  const [supportEmail, setSupportEmail] = useState('');
  const [settlement, setSettlement] = useState({});

  const params = useMemo(
    () => ({
      company_id: userDetails && userDetails?.company?.id,
    }),
    [userDetails],
  );
  const {data, isLoading: settingsLoading} = useQuery(
    ['baas-settings', {params}],
    () => fetchBAASSettings({params}),
  );

  const userSettings = useMemo(
    () => !settingsLoading && data && data?.entity,
    [data, settingsLoading],
  );
  const currentSettlement = useMemo(
    () =>
      settlementType?.find(
        settlement =>
          settlement?.value === userSettings?.['settlement_options'],
      ),
    [userSettings],
  );
  const currentBank = useMemo(
    () =>
      !banksLoading &&
      banks &&
      banks?.find(bank => bank?.value === userSettings?.bank_code),
    [banks, banksLoading, userSettings?.bank_code],
  );
  useEffect(() => {
    setAccountNumber(userSettings?.account_number || '');
    setSupportEmail(userSettings?.email_support || '');
    type !== 'transfer' && setSelectedBank(currentBank || null);
    setSettlement(currentSettlement);
  }, [
    currentBank,
    currentSettlement,
    type,
    userSettings?.account_number,
    userSettings?.email_support,
    userSettings.settlement_options,
  ]);

  const isEmailUpdated = useMemo(
    () => userSettings?.email_support === supportEmail,
    [supportEmail, userSettings?.email_support],
  );

  return {
    isEmailUpdated,
    banksLoading,
    currentSettlement,
    selectedBank,
    accountNumber,
    supportEmail,
    setSupportEmail,
    currentBank,
    setAccountNumber,
    setSelectedBank,
    setSettlement,
    userSettings,
    userDetails,
    settlement,
    banks,
    user,
  };
};
