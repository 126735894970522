import React from 'react';
import GovernmentData from './GovernmentData';
import GovernmentID from './GovernmentID';
import {PhoneNumber} from './PhoneNumber';
import {UserData} from './UserData';
import {Address} from './Address';
import BusinessData from './BusinessData';
import Selfie from './Selfie';
import {Email} from './Email';
import store from '../../../store/index';
import FraudCheckComponents from '../FraudCheckScreens';
import UploadOtherIds from './UploadOtherIds';
import WebSdk from '../Integration/WebSdk';
import FlowLink from '../Integration/FlowLink';
import Mobile from '../Integration/Mobile';
import SignaturePage from './SignaturePage';
import {DetectWithDuplicateID} from '../FraudCheckConfigScreens/DetectWIthDuplicateID';
import ConfigDisclaimer from './ConfigDisclaimer';
import Questions from './Questions';
import BusinessID from './BusinessID';

const currentWidget = store.getState()?.widgets?.singleApp;
const singleApp = store.getState()?.widgets?.singleApp;

const logo = currentWidget?.logo;
const color = currentWidget?.color;
const {
  DetectWithAgeLimit,
  DetectWithUserData,
  DetectWithAmlScreening,
  DetectWithIpDeviceScreening,
  DetectWithLivenessMatch,
} = FraudCheckComponents;

export const PreviewScreens = {
  'business-data': <BusinessData appLogo={logo} color={color} />,
  'government-data': <GovernmentData appLogo={logo} />,
  'business-id': <BusinessID appLogo={logo} color={color} />,
  id: <GovernmentID appLogo={logo} />,
  indemnity: <ConfigDisclaimer appLogo={logo} color={color} />,
  'user-data': <UserData appLogo={logo} />,
  address: <Address appLogo={logo} />,
  'phone-number': <PhoneNumber appLogo={logo} />,
  'custom-questions': <Questions appLogo={logo} />,
  email: <Email appLogo={logo} />,
  selfie: <Selfie appLogo={logo} />,
  signature: <SignaturePage appLogo={logo} />,
  'additional-document': (
    <UploadOtherIds
      appLogo={logo}
      customButton={
        <div className="flex flex-col items-center gap-4 w-full">
          <button
            disabled
            className="w-full h-12 max-w-sm mt-6 font-medium !text-white rounded text-sm bg-brandBlue"
            style={{
              backgroundColor: singleApp?.color || singleApp?.color_code || '',
            }}
          >
            Capture
          </button>
          <button
            disabled
            className="w-full h-12 max-w-sm font-medium text-grey rounded text-sm border border-[#12376914]"
          >
            Upload Instead
          </button>
        </div>
      }
    />
  ),
  age_limit: <DetectWithAgeLimit />,
  liveness_match: <DetectWithLivenessMatch />,
  user_data: <DetectWithUserData />,
  aml_screening: <DetectWithAmlScreening />,
  duplicate_check: <DetectWithDuplicateID />,
  ip_screening: <DetectWithIpDeviceScreening />,
  web_sdk: <WebSdk />,
  flow_link: <FlowLink />,
  mobile: <Mobile />,
};
