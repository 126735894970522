import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {
  DashboardLayout,
  PrimaryButton,
  TableLayout,
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  // SecondaryButton,
} from '../../components';
import {easyCreditCheckIc, eye, lookupEmpty} from '../../assets/images/images';
import PerformAssessmentModal from './PerformAssessmentModal';
import {useQuery} from 'react-query';
import {fetchCreditChecks} from '../../requests/queries/creditChecks';
import moment from 'moment';
import {PageContext} from '../../helpers/context';
const tableHeader = [
  {
    label: 'Full name',
    key: 'full_name',
  },
  {
    label: 'Phone number',
    key: 'mobile',
  },
  {
    label: 'bvn',
    key: 'bvn',
  },
  {
    label: 'status',
    key: 'status',
  },
  {
    label: 'Date/time created',
    key: 'date_created',
  },
];
function EasyCreditCheck({getAllApps, apps: {allApps}}) {
  const navigate = useNavigate();
  const [openAssessmentModal, setOpenAssessmentModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('creditcheck');
  }, [setPageName]);
  const userApps =
    allApps &&
    allApps?.apps?.map(app => ({
      value: app._id,
      label: app.name,
      name: app.name,
    }));
  useEffect(() => {
    getAllApps({limit: 10000});
  }, [getAllApps]);
  const pageClick = selected => {
    setCurrentPage(selected);
  };
  // Queries
  const {data, isLoading, isFetching} = useQuery(
    ['credit_checks', {page: currentPage, limit}],
    () => fetchCreditChecks({page: currentPage, limit}),
    {
      retry: false,
    },
  );

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyCreditCheckIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">EasyCreditCheck</p>
        </div>
      }
    >
      <PerformAssessmentModal
        open={openAssessmentModal}
        setOpen={setOpenAssessmentModal}
        userApps={userApps}
      />
      <>
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-base font-medium text-grey sm:text-lg">
            Credit Assessment
          </h2>
          <PrimaryButton
            buttonText="Perform Assessment"
            xPadding="px-[35px]"
            onClick={() => setOpenAssessmentModal(!openAssessmentModal)}
            className="hidden h-12 sm:flex min-w-[180px]"
          />
        </div>
        <TableLayout negativeMargins>
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              {tableHeader.map(({label, key}) => (
                <th
                  key={key}
                  className={`${
                    label === 'Full name'
                      ? 'p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap'
                      : 'p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap'
                  }`}
                >
                  <div className="flex items-end gap-1 cursor-pointer">
                    <span>{label}</span>
                  </div>
                </th>
              ))}
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {isLoading ? (
                  <Loader height={45} />
                ) : data?.totalRecords === 0 && !isLoading ? (
                  <EmptyState
                    height={45}
                    body="Perform assessments with ease."
                    heading="No record found!"
                    src={lookupEmpty}
                    noBtn
                    customBtn={
                      <PrimaryButton
                        buttonText="Perform Assessment"
                        className="w-fit"
                        onClick={() =>
                          setOpenAssessmentModal(!openAssessmentModal)
                        }
                      />
                    }
                  />
                ) : null}
              </td>
            </tr>
          </tbody>

          <tbody className="text-sm" style={{opacity: isFetching ? 0.3 : 1}}>
            {data &&
              data?.records.map((record, index) => (
                <tr
                  className={`border-b ${
                    record?.v_status === 'Valid' && 'cursor-pointer'
                  } border-grey60`}
                  key={index}
                  onClick={
                    record?.v_status === 'Valid'
                      ? () =>
                          navigate(
                            `/easy-creditcheck/credit-summary/${record?.customer_id}`,
                            {
                              state: {
                                ...record,
                              },
                            },
                          )
                      : null
                  }
                >
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {`${record?.first_name || '-'} ${record?.last_name || ''}`}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {record?.mobile || '-'}
                  </td>

                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {record?.bvn || '-'}
                  </td>

                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    <div
                      className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${
                        record?.v_status?.toLowerCase() === 'valid'
                          ? 'text-success bg-success20'
                          : 'text-danger bg-statusBg-404'
                      }`}
                    >
                      {record?.v_status || '-'}
                    </div>
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(record?.createdAt || new Date()).format(
                      'Do MMM YYYY hh:mm a',
                    )}
                  </td>
                  {record?.v_status === 'Valid' && (
                    <td className="p-4 pl-0 whitespace-nowrap">
                      <div className="flex items-center gap-4">
                        <button
                          onClick={
                            record?.v_status === 'Valid'
                              ? () =>
                                  navigate(
                                    `/easy-creditcheck/credit-summary/${record?.customer_id}`,
                                    {
                                      state: {
                                        ...record,
                                      },
                                    },
                                  )
                              : null
                          }
                          title="View"
                          // onClick={() =>
                          //   openModalWithSelectedData(verification)
                          // }
                          className="h-4 aspect-square"
                        >
                          <img src={eye} alt="" />
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </TableLayout>
        {!isLoading && data?.totalRecords > 0 && (
          <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={v => {
                  setLimit(v);
                  const searchParams = new URLSearchParams(
                    document.location.search,
                  );
                  searchParams.set('limit', v);
                  const newSearchParams = searchParams.toString();
                  navigate(`?${newSearchParams}`);
                }}
                total={data && data?.totalRecords}
                length={data?.length}
                limit={limit}
              />
            </div>
            <div className="mb-8 sm:mb-0">
              <Pagination
                total={data && Math.ceil(data?.totalRecords / limit)}
                current={+currentPage}
                onPageChange={activePage => {
                  pageClick(activePage);
                  const searchParams = new URLSearchParams(
                    document.location.search,
                  );
                  searchParams.set('page', activePage);
                  const newSearchParams = searchParams.toString();
                  navigate(`?${newSearchParams}`);
                }}
              />
            </div>
          </div>
        )}
      </>
    </DashboardLayout>
  );
}
export default connect(state => state, Actions)(EasyCreditCheck);
