import React from 'react';
import {Constants} from '../helpers';
const Signin = React.lazy(() => import('./SignInUser'));
const GoogleSignUpComplete = React.lazy(() =>
  import('./Auths/GoogleSignUpComplete'),
);
const ForgotPasswordUser = React.lazy(() => import('./ForgotPasswordUser'));
const ResetPasswordUser = React.lazy(() => import('./ResetPasswordUser'));
const Team = React.lazy(() => import('./Team'));
const CreatePasswordUser = React.lazy(() => import('./CreatePasswordUser'));
const Customer360 = React.lazy(() => import('./Customer360'));

import Dashboard from './Dashboard';
import QuickActions from './QuickActions';
import QuickActionsExisting from './QuickActions/Existing';
import AMLScreening from './AMLScreening';
import AMLScreeningResult from './AMLScreening/AMLScreeningResult';
import EasyLookup from './EasyLookup';
import IndividualLookup from './EasyLookup/IndividualLookup';
import EasyConnect from './EasyConnect';
import EasyOnboard from './EasyOnboard';
import FraudDetectionOverview from './FraudDetection/Overview';
import CustomLists from './FraudDetection/CustomLists';
import CustomListsItems from './FraudDetection/CustomLists/CustomListsItems';
import FraudDetectionOverviewPendingCases from './FraudDetection/Overview/PendingCases';
import FraudDetectionFlows from './FraudDetection/Flows';
import FraudDetectionFlowsAnalysis from './FraudDetection/Flows/Analysis';
import FraudDetectionFlowsCases from './FraudDetection/Flows/Cases';
import FraudDetectionFlowsCasesDetails from './FraudDetection/Flows/Cases/CasesDetails';
import FraudDetectionFlowsBehaviouralAnalysis from './FraudDetection/Flows/Cases/BehaviouralAnalysis';
import FraudDetectionFlowsSupportingDoc from './FraudDetection/Flows/Cases/SupportingDoc';
import FraudDetectionFlowsEvents from './FraudDetection/Flows/Events';
import FraudDetectionFlowsAccount from './FraudDetection/Flows/Account';
import FraudDetectionFlowsAccountTransactions from './FraudDetection/Flows/Account/AccountTransactions';
import FraudDetectionFlowsSettings from './FraudDetection/Flows/Settings';
import FraudDetectionFlowsEditProcess from './FraudDetection/Flows/EditProcess';
import EasyOnboardCreateFlow from './EasyOnboard/CreateFlow';
import EasyOnboardCustomFlow from './EasyOnboard/CustomFlow';
import EasyOnboardUpdateFlow from './EasyOnboard/UpdateFlow';
import Verifications from './Verifications';
import PendingVerifications from './Verifications/PendingVerifications';
import VerificationsAddress from './Verifications/Address';
import VerificationsAmlCheck from './Verifications/AmlCheck';
import VerificationsBiodata from './Verifications/Biodata';
import VerificationsIpDeviceCheck from './Verifications/IpDeviceCheck';
import LinkAccounts from './LinkAccounts';
import SettingsProfile from './Settings/Profile';
import DevelopersApiLogs from './Developers/ApiLogs';
import DevelopersWebhook from './Developers/Webhook';
import DevelopersWebhookDetails from './Developers/WebhookDetails';
import DevelopersConfiguration from './Developers/Configuration';
import SettingsTeam from './Settings/Team';
import SettingsCompliance from './Settings/Compliance';
import SettingsBilling from './Settings/Billing';
import SettingsAuditLogs from './Settings/AuditLogs';
import SettingsTeamOwner from './Settings/Team/Owner';
import SettingsTeamLayout from './Settings/Team/Layout';
import SettingsBillingPricing from './Settings/Billing/BillingsTransactions';
import FailedGoogleLogin from '../components/AcountNotFound';
import AddressVerification from './AddressVerification';
import BatchAddressVerification from './AddressVerification/BatchUpload';
import IndividualSingleLookup from './EasyLookup/IndividualLookup/SingleLookup';
import IndividualBatchLookup from './EasyLookup/IndividualLookup/BatchLookup';
import BusinessLookup from './EasyLookup/BusinessLookup';
import BusinessSingleLookup from './EasyLookup/BusinessLookup/SingleLookup';
import BusinessBatchLookup from './EasyLookup/BusinessLookup/BatchLookup';
import ApiTokens from './Developers/ApiTokens';
import GlobalSearchResults from './EasyLookup/GlobalSearchResults';
import ApiStatus from './ApiStatus';
import EasyCreditCheck from './EasyCreditCheck';
import EasyCreditCheckSummary from './EasyCreditCheck/CreditSummary';
import EasyCreditAffordability from './EasyCreditCheck/Affordability';
import EasyCreditAmlScreening from './EasyCreditCheck/AmlScreening';
import EasyCreditBehaviouralAnalysis from './EasyCreditCheck/BehaviouralAnalysis';
import EasyCreditKycInformation from './EasyCreditCheck/KycInformation';
import ApiReport from './Report/ApiReport';
import EasyDetectReport from './Report/EasyDetectReport';
import AmlUserScreening from './AMLScreening/UserScreening';
import AmlBusinessScreening from './AMLScreening/BusinessScreening';
import AmlUserScreeningSingle from './AMLScreening/UserScreening/SingleScreening';
import AmlSearchResults from './AMLScreening/SearchResults';
import AmlUserScreeningBatch from './AMLScreening/UserScreening/BatchScreening';

import Customer360GovernmentLookup from './Customer360/GovernmentLookup';
import Customer360IpDeviceInfo from './Customer360/IPDeviceInfo';
import Customer360LivenessCheck from './Customer360/LivenessCheck';
import Customer360ValidationInfo from './Customer360/ValidationInfo';
import Customer360GovernmentID from './Customer360/GovernmentID';
import Customer360AddressInfo from './Customer360/AddressInfo';
import Customer360AmlCheck from './Customer360/AmlCheck';
import DocumentAnalysis from './DocumentAnalysis';
import SingleDocumentAnalysis from './DocumentAnalysis/SingleAnalysis';
import MessagingReport from './Report/MessagingReport';
import AmlBusinessScreeningSingle from './AMLScreening/BusinessScreening/SingleScreening';
import Business360 from './Business360';
import Business360BusinessLookup from './Business360/BusinessLookup';
import Business360BusinessId from './Business360/BusinessID';
import Business360AmlSearch from './Business360/AmlSearch';
import Business360DirectorInfo from './Business360/DirectorInfo';
import ProjectOverview from './FraudDetection/Reconciliation/Projects/ProjectOverview';
import ProjectAccounts from './FraudDetection/Reconciliation/Projects/ProjectAccounts';
import Projects from './FraudDetection/Reconciliation/Projects';
import ProjectTransactions from './FraudDetection/Reconciliation/Projects/ProjectTransactions';
import ProjectReconciliation from './FraudDetection/Reconciliation/Projects/ProjectReconciliation';
import ProjectCustomers from './FraudDetection/Reconciliation/Projects/ProjectCustomers';
import ProjectSettings from './FraudDetection/Reconciliation/Projects/ProjectSettings';
import Overview from './FraudDetection/Reconciliation/Overview';
import Transactions from './FraudDetection/Reconciliation/Transactions';
import Reconciliation from './FraudDetection/Reconciliation/Reconciliation';
import Customers from './FraudDetection/Reconciliation/Customers';
import CustomerDetails from './FraudDetection/Reconciliation/Customers/CustomerDetails';
import ProjectAccountDetails from './FraudDetection/Reconciliation/Projects/ProjectAccountDetails';
import VerificationAnalytics from './Verifications/VerificationAnalytics';
import Signup from './FraudDetection/Reconciliation/Auth/Signup';
import ReconciloSignIn from './FraudDetection/Reconciliation/Auth/ReconciloSignIn';
import ForgotPassword from './FraudDetection/Reconciliation/Auth/ForgotPassword';
import SetPassword from './FraudDetection/Reconciliation/Auth/SetPassword';
import ResetPassword from './FraudDetection/Reconciliation/Auth/ResetPassword';
import GoogleSignup from './FraudDetection/Reconciliation/Auth/GoogleSignup';
import Index from './FraudDetection/Baas';
import BaasTransactions from './FraudDetection/Baas/BaasTransactions';
import BaasWallets from './FraudDetection/Baas/BaasWallets';
import BaasPayouts from './FraudDetection/Baas/BaasPayouts';
import BaasSettings from './FraudDetection/Baas/BaasSettings';
import BaasAccountDetails from './FraudDetection/Baas/BaasAccountDetails';
import Billing from './FraudDetection/Reconciliation/Billing';
import BillingPaymentHistory from './FraudDetection/Reconciliation/BillingPaymentHistory';
import OnboardingRedirect from './FraudDetection/Reconciliation/Auth/OnboardingRedirect';
import CompanyVerification from './Settings/CompanyVerification';
import ExportLogs from './Logs/ExportLogs';
import AuditLogs from './Logs/AuditLogs';

const baseRoutes = {
  private: [
    {
      component: <Dashboard />,
      exact: true,
      path: Constants.PAGES_URL.DASHBOARD,
    },

    {
      component: <QuickActions />,
      exact: true,
      path: Constants.PAGES_URL.QUICKACTIONS,
    },
    {
      component: <QuickActionsExisting />,
      exact: true,
      path: Constants.PAGES_URL.QUICKACTIONS_EXISTING,
    },
    {
      component: <EasyLookup />,
      exact: true,
      path: Constants.PAGES_URL.EASY_LOOKUP,
    },
    {
      component: <AMLScreening />,
      exact: true,
      path: Constants.PAGES_URL.AML_SCREENING,
    },
    {
      component: <AMLScreeningResult />,
      exact: true,
      path: Constants.PAGES_URL.AML_SCREENING_RESULT,
    },
    {
      component: <IndividualLookup />,
      exact: true,
      path: Constants.PAGES_URL.INDIVIDUAL_LOOKUP,
    },
    {
      component: <IndividualSingleLookup />,
      exact: true,
      path: Constants.PAGES_URL.INDIVIDUAL_LOOKUP_SINGLE,
    },
    {
      component: <IndividualBatchLookup />,
      exact: true,
      path: Constants.PAGES_URL.INDIVIDUAL_LOOKUP_BATCH,
    },
    {
      component: <BusinessLookup />,
      exact: true,
      path: Constants.PAGES_URL.BUSINESS_LOOKUP,
    },
    {
      component: <BusinessSingleLookup />,
      exact: true,
      path: Constants.PAGES_URL.BUSINESS_LOOKUP_SINGLE,
    },
    {
      component: <BusinessBatchLookup />,
      exact: true,
      path: Constants.PAGES_URL.BUSINESS_LOOKUP_BATCH,
    },
    {
      component: <EasyConnect />,
      exact: true,
      path: Constants.PAGES_URL.EASY_CONNECT,
    },
    {
      component: <EasyOnboard />,
      exact: true,
      path: Constants.PAGES_URL.EASY_ONBOARD,
    },
    {
      component: <EasyOnboardCreateFlow />,
      exact: true,
      path: Constants.PAGES_URL.EASY_ONBOARD_CREATE_FLOW,
    },
    {
      component: <EasyOnboardCustomFlow />,
      exact: true,
      path: Constants.PAGES_URL.EASY_ONBOARD_CUSTOM_FLOW,
    },
    {
      component: <EasyOnboardUpdateFlow />,
      exact: true,
      path: Constants.PAGES_URL.EASY_ONBOARD_UPDATE_FLOW,
    },
    {
      component: <FraudDetectionOverview />,
      exact: true,
      path: Constants.PAGES_URL.OVERVIEW,
    },
    {
      component: <AuditLogs />,
      exact: true,
      path: Constants.PAGES_URL.AUDIT_LOGS,
    },
    {
      component: <ExportLogs />,
      exact: true,
      path: Constants.PAGES_URL.EXPORT_LOGS,
    },
    {
      component: <CustomLists />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOM_LISTS,
    },
    {
      component: <CustomListsItems />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOM_LISTS_ITEMS,
    },
    {
      component: <FraudDetectionOverviewPendingCases />,
      exact: true,
      path: Constants.PAGES_URL.OVERVIEW_PENDING_CASES,
    },
    {
      component: <FraudDetectionFlows />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS,
    },
    {
      component: <FraudDetectionFlowsAnalysis />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_ANALYTICS,
    },

    {
      component: <ProjectOverview />,
      exact: true,
      path: Constants.PAGES_URL.PROJECT_OVERVIEW,
    },
    {
      component: <ProjectReconciliation />,
      exact: true,
      path: Constants.PAGES_URL.PROJECT_RECONCILIATION,
    },

    {
      component: <ProjectCustomers />,
      exact: true,
      path: Constants.PAGES_URL.PROJECT_CUSTOMERS,
    },
    {
      component: <ProjectSettings />,
      exact: true,
      path: Constants.PAGES_URL.PROJECT_SETTINGS,
    },
    {
      component: <ProjectTransactions />,
      exact: true,
      path: Constants.PAGES_URL.PROJECT_TRANSACTIONS,
    },
    {
      component: <ProjectAccounts />,
      exact: true,
      path: Constants.PAGES_URL.PROJECT_ACCOUNTS,
    },
    {
      component: <ProjectAccountDetails />,
      exact: true,
      path: Constants.PAGES_URL.PROJECT_ACCOUNTS_DETAILS,
    },
    {
      component: <Projects />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_PROJECTS,
    },
    {
      component: <Overview />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_OVERVIEW,
    },
    {
      component: <Transactions />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_TRANSACTIONS,
    },
    {
      component: <Reconciliation />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION,
    },
    {
      component: <Customers />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_CUSTOMERS,
    },
    {
      component: <CustomerDetails />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_CUSTOMER_DETAILS,
    },
    {
      component: <FraudDetectionFlowsCases />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_CASES,
    },
    {
      component: <FraudDetectionFlowsCasesDetails />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_CASES_DETAILS,
    },
    {
      component: <FraudDetectionFlowsBehaviouralAnalysis />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_CASES_BEHAVIOURAL_ANALYSIS,
    },
    {
      component: <FraudDetectionFlowsSupportingDoc />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_CASES_SUPPORTING_DOC,
    },
    {
      component: <FraudDetectionFlowsCasesDetails />,
      exact: true,
      path: Constants.PAGES_URL.PENDING_CASES_DETAILS,
    },
    {
      component: <FraudDetectionFlowsEvents />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_EVENTS,
    },
    {
      component: <FraudDetectionFlowsAccount />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_ACCOUNT,
    },
    {
      component: <FraudDetectionFlowsAccountTransactions />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_ACCOUNT_TRANSACTIONS,
    },
    {
      component: <FraudDetectionFlowsSettings />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_SETTINGS,
    },
    {
      component: <FraudDetectionFlowsEditProcess />,
      exact: true,
      path: Constants.PAGES_URL.FLOWS_EDIT_PROCESS,
    },
    {
      component: <Verifications />,
      exact: true,
      path: Constants.PAGES_URL.VERIFICATIONS,
    },
    {
      component: <VerificationAnalytics />,
      exact: true,
      path: Constants.PAGES_URL.VERIFICATION_ANALYTICS,
    },
    {
      component: <PendingVerifications />,
      exact: true,
      path: Constants.PAGES_URL.PENDING_VERIFICATIONS,
    },
    {
      component: <VerificationsAddress />,
      exact: true,
      path: Constants.PAGES_URL.VERIFICATIONS_ADDRESS,
    },
    {
      component: <VerificationsAmlCheck />,
      exact: true,
      path: Constants.PAGES_URL.VERIFICATIONS_AML_CHECK,
    },
    {
      component: <VerificationsBiodata />,
      exact: true,
      path: Constants.PAGES_URL.VERIFICATIONS_BIO_DATA,
    },
    {
      component: <VerificationsIpDeviceCheck />,
      exact: true,
      path: Constants.PAGES_URL.VERIFICATIONS_IP_DEVICE_CHECK,
    },
    {
      component: <LinkAccounts />,
      exact: true,
      path: Constants.PAGES_URL.LINK_ACCOUNTS,
    },
    {
      component: <DevelopersApiLogs />,
      exact: true,
      path: Constants.PAGES_URL.DEVELOPERS_API_LOGS,
    },
    {
      component: <DevelopersWebhook />,
      exact: true,
      path: Constants.PAGES_URL.DEVELOPERS_WEBHOOK,
    },
    {
      component: <ApiStatus />,
      exact: true,
      path: Constants.PAGES_URL.API_STATUS,
    },
    {
      component: <DevelopersWebhookDetails />,
      exact: true,
      path: Constants.PAGES_URL.DEVELOPERS_WEBHOOK_DETAILS,
    },
    {
      component: <DevelopersConfiguration />,
      exact: true,
      path: Constants.PAGES_URL.DEVELOPERS_CONFIGURATION,
    },
    {
      component: <SettingsProfile />,
      exact: true,
      path: Constants.PAGES_URL.SETTINGS_PROFILE,
    },
    {
      component: <SettingsCompliance />,
      exact: true,
      path: Constants.PAGES_URL.SETTINGS_COMPLIANCE,
    },
    {
      component: <SettingsTeam />,
      exact: true,
      path: Constants.PAGES_URL.SETTINGS_TEAM,
    },
    {
      component: <SettingsTeamOwner />,
      exact: true,
      path: Constants.PAGES_URL.SETTINGS_TEAM_OWNER,
    },
    {
      component: <SettingsAuditLogs />,
      exact: true,
      path: Constants.PAGES_URL.SETTINGS_AUDIT_LOGS,
    },
    {
      component: <SettingsBilling />,
      exact: true,
      path: Constants.PAGES_URL.SETTINGS_BILLING,
    },
    {
      component: <SettingsBillingPricing />,
      exact: true,
      path: Constants.PAGES_URL.SETTINGS_BILLING_PRICING,
    },
    {
      component: <SettingsTeamLayout />,
      exact: true,
      path: Constants.PAGES_URL.SETTINGS_TEAM_LAYOUT,
    },
    {
      component: <AddressVerification />,
      exact: true,
      path: Constants.PAGES_URL.ADDRESS_VERIFICATION,
    },
    {
      component: <CompanyVerification />,
      exact: true,
      path: Constants.PAGES_URL.SETTINGS_COMPANY_VERIFICATION,
    },
    {
      component: <BatchAddressVerification />,
      exact: true,
      path: Constants.PAGES_URL.BATCH_ADDRESS_VERIFICATION,
    },
    {
      component: <ApiTokens />,
      exact: true,
      path: Constants.PAGES_URL.API_TOKENS,
    },
    {
      component: <GlobalSearchResults />,
      exact: true,
      path: Constants.PAGES_URL.GLOBAL_SEARCH_RESULTS,
    },
    {
      component: <EasyCreditCheck />,
      exact: true,
      path: Constants.PAGES_URL.EASY_CREDIT_CHECK,
    },
    {
      component: <EasyCreditCheckSummary />,
      exact: true,
      path: Constants.PAGES_URL.EASY_CREDIT_CREDIT_SUMMARY,
    },
    {
      component: <EasyCreditAffordability />,
      exact: true,
      path: Constants.PAGES_URL.EASY_CREDIT_AFFORDABILITY,
    },
    {
      component: <EasyCreditAmlScreening />,
      exact: true,
      path: Constants.PAGES_URL.EASY_CREDIT_AML_SCREENING,
    },
    {
      component: <EasyCreditBehaviouralAnalysis />,
      exact: true,
      path: Constants.PAGES_URL.EASY_CREDIT_BEHAVIOURAL_ANALYSIS,
    },
    {
      component: <EasyCreditKycInformation />,
      exact: true,
      path: Constants.PAGES_URL.EASY_CREDIT_KYC_INFORMATION,
    },
    {
      component: <ApiReport />,
      exact: true,
      path: Constants.PAGES_URL.API_REPORT,
    },
    {
      component: <EasyDetectReport />,
      exact: true,
      path: Constants.PAGES_URL.EASY_DETECT_REPORT,
    },
    {
      component: <AmlUserScreening />,
      exact: true,
      path: Constants.PAGES_URL.AML_USER_SCREENING,
    },
    {
      component: <AmlUserScreeningSingle />,
      exact: true,
      path: Constants.PAGES_URL.AML_USER_SCREENING_SINGLE,
    },
    {
      component: <AmlUserScreeningBatch />,
      exact: true,
      path: Constants.PAGES_URL.AML_USER_SCREENING_BATCH,
    },
    {
      component: <AmlBusinessScreening />,
      exact: true,
      path: Constants.PAGES_URL.AML_BUSINESS_SCREENING,
    },
    {
      component: <AmlSearchResults />,
      exact: true,
      path: Constants.PAGES_URL.AML_USER_SCREENING_SEARCH_RESULTS,
    },
    {
      component: <AmlSearchResults />,
      exact: true,
      path: Constants.PAGES_URL.AML_BUSINESS_SCREENING_SEARCH_RESULTS,
    },
    {
      component: <Customer360 />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOMER_360,
    },
    {
      component: <Customer360GovernmentLookup />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOMER_360_GOVT_LOOKUP,
    },
    {
      component: <Customer360IpDeviceInfo />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOMER_360_IP_DEVICE_INFO,
    },
    {
      component: <Customer360LivenessCheck />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOMER_360_LIVENESS_CHECK,
    },
    {
      component: <Customer360ValidationInfo />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOMER_360_VALIDATION_INFO,
    },
    {
      component: <Customer360GovernmentID />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOMER_360_GOVT_ID,
    },
    {
      component: <Customer360AddressInfo />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOMER_360_ADDRESS_INFO,
    },
    {
      component: <Customer360AmlCheck />,
      exact: true,
      path: Constants.PAGES_URL.CUSTOMER_360_AML_CHECK,
    },
    {
      component: <DocumentAnalysis />,
      exact: true,
      path: Constants.PAGES_URL.DOCUMENT_ANALYSIS,
    },
    {
      component: <SingleDocumentAnalysis />,
      exact: true,
      path: Constants.PAGES_URL.DOCUMENT_ANALYSIS_SINGLE,
    },
    {
      component: <MessagingReport />,
      exact: true,
      path: Constants.PAGES_URL.MESSAGING_REPORT,
    },
    {
      component: <AmlBusinessScreeningSingle />,
      exact: true,
      path: Constants.PAGES_URL.AML_BUSINESS_SCREENING_SINGLE,
    },
    {
      component: <Business360 />,
      exact: true,
      path: Constants.PAGES_URL.BUSINESS_360,
    },
    {
      component: <Business360BusinessLookup />,
      exact: true,
      path: Constants.PAGES_URL.BUSINESS_360_BUSINESS_LOOKUP,
    },
    {
      component: <Business360BusinessId />,
      exact: true,
      path: Constants.PAGES_URL.BUSINESS_360_BUSINESS_ID,
    },
    {
      component: <Business360AmlSearch />,
      exact: true,
      path: Constants.PAGES_URL.BUSINESS_360_AML_SEARCH,
    },
    {
      component: <Business360DirectorInfo />,
      exact: true,
      path: Constants.PAGES_URL.BUSINESS_360_DIRECTOR_INFO,
    },
    {
      component: <Billing />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_BILLING,
    },
    {
      component: <BillingPaymentHistory />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_BILLING_HISTORY,
    },
    {
      component: <Index />,
      exact: true,
      path: Constants.PAGES_URL.BAAS_OVERVIEW,
    },
    {
      component: <BaasTransactions />,
      exact: true,
      path: Constants.PAGES_URL.BAAS_TRANSACTIONS,
    },
    {
      component: <BaasWallets />,
      exact: true,
      path: Constants.PAGES_URL.BAAS_WALLETS,
    },
    {
      component: <BaasPayouts />,
      exact: true,
      path: Constants.PAGES_URL.BAAS_PAYOUTS,
    },
    {
      component: <BaasSettings />,
      exact: true,
      path: Constants.PAGES_URL.BAAS_SETTINGS,
    },
    {
      component: <BaasAccountDetails />,
      exact: true,
      path: Constants.PAGES_URL.BAAS_ACCOUNT_DETAIL,
    },
  ],
  public: [
    {
      component: <Signin />,
      exact: true,
      path: Constants.PAGES_URL.SIGNIN,
    },

    {
      component: <GoogleSignup />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_GOOGLE_SIGN_UP,
    },

    {
      component: <ForgotPasswordUser />,
      exact: true,
      path: Constants.PAGES_URL.FORGOT_PASSWORD,
    },
    {
      component: <ResetPasswordUser />,
      exact: true,
      path: Constants.PAGES_URL.RESET_PASSWORD,
    },
    {
      component: <OnboardingRedirect />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_REDIRECT,
    },
    {
      component: <Team />,
      exact: true,
      path: Constants.PAGES_URL.TEAM,
    },
    {
      component: <CreatePasswordUser />,
      exact: true,
      path: Constants.PAGES_URL.CREATE_PASSWORD,
    },
    {
      component: <FailedGoogleLogin />,
      exact: true,
      path: Constants.PAGES_URL.ACCOUNT_NOT_FOUND,
    },
    {
      component: <GoogleSignUpComplete />,
      exact: true,
      path: '/',
    },
    {
      component: <Signup />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_SIGNUP,
    },
    {
      component: <ReconciloSignIn />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_SIGNIN,
    },
    {
      component: <ForgotPassword />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_FORGOT_PASSWORD,
    },
    {
      component: <SetPassword />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_SET_PASSWORD,
    },
    {
      component: <ResetPassword />,
      exact: true,
      path: Constants.PAGES_URL.RECONCILIATION_RESET_PASSWORD,
    },
  ],
};

export default baseRoutes;
