import React, {createContext, useMemo} from 'react';
import {useQuery} from 'react-query';
import {fetchCompanyLogo} from '../fetchCompanyLogoAndColor';
import {Loader} from '../../components';
import {reconciloLogo, trypilaLogo} from '../../assets/images/images';

const LogoContext = createContext(null);

function LogoProvider({children}) {
  const {data: logo = '', status} = useQuery('companyLogo', fetchCompanyLogo);

  const companyLogo = useMemo(() => {
    if (status !== 'success') return null;

    const subdomain = localStorage.getItem('subdomain') || '';
    const storedLogo = localStorage.getItem('companyLogo');

    const isReconcilo = subdomain.includes('reconcilo');
    const isTrypila = subdomain.includes('trypila');

    const newLogo = isReconcilo
      ? reconciloLogo
      : isTrypila
      ? trypilaLogo
      : logo;

    if (storedLogo !== newLogo) {
      localStorage.setItem('companyLogo', newLogo);
    }

    return newLogo;
  }, [logo, status]);

  if (status === 'loading') {
    return <Loader />;
  }

  return (
    <LogoContext.Provider value={companyLogo}>{children}</LogoContext.Provider>
  );
}

export {LogoContext, LogoProvider};
