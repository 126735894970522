import React from 'react';
import {info} from '../../../assets/images/images';

export const UserData = () => (
  <>
    <header className="flex flex-col items-center mt-2">
      <div className="flex items-center gap-2 p-2 mt-4 bg-[#F6F6F8] rounded">
        <img src={info} alt="" width={20} height={20} className="" />
        <p className="text-base rounded-lg text-black font-medium font-inter">
          Fill the form as it appears on your valid ID
        </p>
      </div>
    </header>
    <form className="w-full max-w-sm mt-[30px] space-y-10">
      <label
        htmlFor="first_name"
        className="mb-1 text-[#1B2A4E] text-sm font-normal"
      >
        First name
        <input
          disabled
          type="text"
          id="first_name"
          className="bg-[#F6F6F8] text-[#8D8D91] text-sm border-none outline-none rounded"
        />
      </label>
      <label
        htmlFor="middle_name"
        className="mb-1 text-[#1B2A4E] text-sm font-normal"
      >
        Middle name
        <input
          disabled
          type="text"
          id="middle_name"
          className="bg-[#F6F6F8] text-[#8D8D91] text-sm border-none outline-none rounded"
        />
      </label>
      <label
        htmlFor="last_name"
        className="mb-1 text-[#1B2A4E] text-sm font-normal"
      >
        Last name/Surname
        <input
          disabled
          type="text"
          id="last_name"
          className="bg-[#F6F6F8] text-[#8D8D91] text-sm border-none outline-none rounded"
        />
      </label>
      <label htmlFor="dob" className="mb-1 text-[#1B2A4E] text-sm font-normal">
        Date of birth
        <input
          disabled
          type="date"
          id="dob"
          className="bg-[#F6F6F8] text-[#8D8D91] text-sm border-none outline-none rounded"
        />
      </label>
    </form>
  </>
);
