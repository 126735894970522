import React from 'react';
import {calendar, clear} from 'assets/images/images';

const AppliedFilters = ({
  selectedOptions,
  dateFilter,
  selectedTimeOption,
  timeOptions,
  filterOptions,
  handleRemoveFilter,
  handleClearAll,
  isMainPage = false,
}) => {
  const hasDateFilter = selectedTimeOption !== null;
  const hasOptionsFilter = Object.values(selectedOptions).some(
    arr => arr.length > 0,
  );

  if (!hasDateFilter && !hasOptionsFilter) {
    return null;
  }

  const hasOnlyOneSelection = optionName =>
    selectedOptions[optionName]?.length === 1;

  const getTimeOptionLabel = () => {
    const option = timeOptions.find(opt => opt.value === selectedTimeOption);
    return option ? option.label : '';
  };

  const formatDateRange = () => {
    if (
      selectedTimeOption === 'specificDateRange' &&
      dateFilter.start &&
      dateFilter.end
    ) {
      return `${dateFilter.start.split(' ')[0]} - ${
        dateFilter.end.split(' ')[0]
      }`;
    }
    return getTimeOptionLabel();
  };

  const getOptionTitle = optionName => {
    const option = filterOptions.find(opt => opt.name === optionName);
    return option ? option.title : optionName;
  };

  const getValueLabel = (optionName, value) => {
    const option = filterOptions.find(opt => opt.name === optionName);
    if (!option) return value;

    const valueObj = option.values.find(v => v.value === value);
    return valueObj ? valueObj.label : value;
  };

  const handleRemoveClick = (e, optionName, value) => {
    e.stopPropagation();
    handleRemoveFilter(optionName, value);
  };

  const handleClearAllClick = e => {
    e.stopPropagation();
    handleClearAll();
  };

  if (isMainPage) {
    const totalFilters =
      Object.values(selectedOptions).reduce(
        (count, values) => count + (Array.isArray(values) ? values.length : 0),
        0,
      ) + (hasDateFilter ? 1 : 0);

    const filtersToShow = [];

    if (hasDateFilter) {
      filtersToShow.push({
        type: 'date',
        label: formatDateRange(),
      });
    }

    Object.entries(selectedOptions).forEach(([optionName, values]) => {
      if (values.length > 0) {
        values.forEach(value => {
          if (filtersToShow.length < 2) {
            filtersToShow.push({
              type: optionName,
              label: getValueLabel(optionName, value),
              value,
              title: getOptionTitle(optionName),
            });
          }
        });
      }
    });

    const remainingCount = totalFilters - filtersToShow.length;

    return (
      <div className="flex flex-wrap items-center gap-2 !font-inter uppercase">
        {filtersToShow.map((filter, index) => (
          <div
            key={`${filter.type}-${index}`}
            className="flex items-center gap-1 px-2 py-1 text-xs rounded-[6px] bg-[#D9E5F8]"
          >
            {filter.type === 'date' ? (
              <span className="flex items-center">
                <img src={calendar} alt="calendar" className="w-3 h-3 mr-1" />
                <span className="text-[#3c4978] font-medium">
                  {filter.label}
                </span>
              </span>
            ) : (
              <span className="flex items-center">
                <span className="font-medium text-[#5C688E] mr-1">
                  BY {filter.title}:
                </span>{' '}
                <span className="text-[#3c4978] font-medium">
                  {filter.label}
                </span>{' '}
              </span>
            )}
            <button
              onClick={e => handleRemoveClick(e, filter.type, filter.value)}
              className="flex items-center justify-center w-4 h-4 rounded-full hover:bg-gray-200"
              aria-label={`Remove ${filter.label} filter`}
            >
              <img className="w-[6px] h-[6px]" src={clear} alt="clear" />
            </button>
          </div>
        ))}

        {remainingCount > 0 && (
          <div className="flex items-center gap-1 px-2 py-1 h-6 text-xs rounded-[6px] bg-[#6496e3] text-white">
            <span>+{remainingCount}</span>
          </div>
        )}

        {totalFilters > 0 && !isMainPage && (
          <button
            onClick={handleClearAllClick}
            className="text-xs text-blue-600 hover:text-blue-800 hover:underline"
          >
            Clear all
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="bg-white80 py-3 px-6 !font-inter">
      <div className="flex justify-between items-center mb-3">
        <span className="text-sm text-[#868C98]">Applied filters:</span>
        {(hasDateFilter || hasOptionsFilter) && (
          <button
            onClick={handleClearAllClick}
            className="text-sm text-[#B42318]"
          >
            Clear All
          </button>
        )}
      </div>

      <div className="flex flex-col gap-2">
        {hasDateFilter && (
          <div className="flex items-center bg-[#D9E5F8] text-[#3c4978] uppercase rounded-md px-3 py-2 text-[12px] w-fit">
            <span className="flex items-center">
              <span className="font-medium mr-2 flex items-center">
                <img src={calendar} alt="calendar" className="mr-[2px]" />:
              </span>{' '}
              {formatDateRange()}
            </span>
            <button
              onClick={e => handleRemoveClick(e, 'date')}
              className="ml-2"
            >
              <img className="w-[10px] h-[10px]" src={clear} alt="clear" />
            </button>
          </div>
        )}

        {Object.entries(selectedOptions).map(
          ([optionName, values]) =>
            values.length > 0 && (
              <div
                key={optionName}
                className={`rounded-md px-3 py-2 text-[12px] flex bg-[#D9E5F8] gap-[10px] text-[#0B1C56] justify-between ${
                  hasOnlyOneSelection(optionName)
                    ? 'w-fit items-center'
                    : 'w-full items-start'
                }`}
              >
                <div className="w-[95%]">
                  <div className="flex items-center flex-wrap gap-y-[2px] gap-x-2 uppercase font-medium">
                    <span className="font-medium uppercase text-[#5C688E]">
                      BY {getOptionTitle(optionName).toUpperCase()}:
                    </span>
                    {values.map(value => (
                      <div
                        key={`${optionName}-${value}`}
                        className={`text-[#3c4978] ${
                          hasOnlyOneSelection(optionName)
                            ? 'p-0 bg-transparent'
                            : 'p-1 flex items-center gap-1 rounded bg-[#BFD3F3]'
                        }`}
                      >
                        <span>{getValueLabel(optionName, value)}</span>
                        {!hasOnlyOneSelection(optionName) && (
                          <button
                            onClick={e =>
                              handleRemoveClick(e, optionName, value)
                            }
                          >
                            <img
                              className="w-[6px] h-[6px]"
                              src={clear}
                              alt="clear"
                            />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <button onClick={e => handleRemoveClick(e, optionName)}>
                  <img className="w-[10px] h-[10px]" src={clear} alt="clear" />
                </button>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default AppliedFilters;
