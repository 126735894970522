import React, {useRef, useState, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Modal, PrimaryButton, Loader} from '../../../../components';
import {toast} from 'react-toastify';
import {useMutation} from 'react-query';
import {
  resetPinOtp,
  resetPinValidateOtp,
  resetPinComplete,
} from 'requests/queries/baas';
import {verify2FA} from 'requests/queries/settings';
import {getCookieValue} from 'helpers/getCookie';
import {pinIcon, successFlair} from 'assets/images/images';

const OTP_LENGTH = 6;
const PIN_LENGTH = 4;
const OTP_ARRAY = Array(OTP_LENGTH).fill('');
const PIN_ARRAY = Array(PIN_LENGTH).fill('');

const STEPS = {
  CHECK_2FA: 'CHECK_2FA',
  VERIFY_2FA: 'VERIFY_2FA',
  EMAIL_OTP: 'EMAIL_OTP',
  SET_NEW_PIN: 'SET_NEW_PIN',
  CONFIRM_NEW_PIN: 'CONFIRM_NEW_PIN',
  SUCCESS: 'SUCCESS',
};

function ForgotPINModal({open, setOpen}) {
  const {
    auth: {user = {}, userDetails = {}},
  } = useSelector(state => state);
  const navigate = useNavigate();

  const [otp, setOtp] = useState(OTP_ARRAY);
  const [newPin, setNewPin] = useState(PIN_ARRAY);
  const [confirmPin, setConfirmPin] = useState(PIN_ARRAY);
  const [tempNewPin, setTempNewPin] = useState('');
  const otpRefs = useRef([]);
  const newPinRefs = useRef([]);
  const confirmPinRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [referenceId, setReferenceId] = useState(null);
  const [currentStep, setCurrentStep] = useState(STEPS.CHECK_2FA);
  const env = getCookieValue('env');
  const environment = env === 'Production' ? 'live' : env;

  const isTwoFactorAuthSetUp = user?.two_factor_auth;

  useEffect(() => {
    if (!open) {
      setOtp(OTP_ARRAY);
      setNewPin(PIN_ARRAY);
      setConfirmPin(PIN_ARRAY);
      setTempNewPin('');
      setReferenceId(null);
      setCurrentStep(STEPS.CHECK_2FA);
    }
  }, [open]);

  const {mutate: verify2FAMutation} = useMutation(verify2FA, {
    onSuccess: () => {
      toast.success(
        '2FA verified successfully. Please check your email for the OTP.',
      );
      setIsLoading(false);
      setCurrentStep(STEPS.EMAIL_OTP);
      setOtp(OTP_ARRAY);

      resetPinOtpMutation({
        company_id: userDetails?.company?.id,
        environment: environment?.toLowerCase() || 'sandbox',
        authenticator_verified: true,
        email: user?.email,
      });
    },
    onError: error => {
      console.error('verify2FA API Error:', error);
      toast.error(error.response?.data?.error || 'Failed to verify 2FA.');
      setIsLoading(false);
    },
  });

  const handle2FAStep = useCallback(() => {
    setIsLoading(true);
    const data = {
      id: user?.id,
      token: otp.join(''),
    };
    verify2FAMutation(data);
  }, [otp, user?.id, verify2FAMutation]);

  const {mutate: resetPinOtpMutation} = useMutation(resetPinOtp, {
    onSuccess: data => {
      if (data?.entity?.reference_id) {
        setReferenceId(data.entity.reference_id);
      }
    },
    onError: error => {
      console.error('resetPinOtp API Error:', error);
      toast.error(error.response?.data?.error || 'Failed to send OTP.');
    },
  });

  const {mutate: validateEmailOtpMutation} = useMutation(resetPinValidateOtp, {
    onSuccess: otpData => {
      if (otpData?.entity?.reference_id) {
        setReferenceId(otpData.entity.reference_id);
      }
      setIsLoading(false);
      setCurrentStep(STEPS.SET_NEW_PIN);
    },
    onError: error => {
      console.error('resetPinValidateOtp API Error:', error);
      toast.error(
        error.response?.data?.error || 'Failed to validate email OTP.',
      );
      setIsLoading(false);
    },
  });

  const handleEmailOTPStep = useCallback(() => {
    setIsLoading(true);
    const payload = {
      reference_id: referenceId,
      company_id: userDetails?.company?.id,
      environment: environment?.toLowerCase() || 'sandbox',
      otp_code: otp.join(''),
    };
    validateEmailOtpMutation(payload);
  }, [otp, userDetails, environment, validateEmailOtpMutation, referenceId]);

  const {mutate: completePinResetMutation} = useMutation(resetPinComplete, {
    onSuccess: () => {
      toast.success('Transaction PIN updated successfully.');
      setIsLoading(false);
      setCurrentStep(STEPS.SUCCESS);
    },
    onError: error => {
      console.error('resetPinComplete API Error:', error);
      toast.error(
        error.response?.data?.error || 'Failed to update transaction PIN.',
      );
      setIsLoading(false);
    },
  });

  const handleCompletePinReset = useCallback(() => {
    if (tempNewPin !== confirmPin.join('')) {
      toast.error('PINs do not match.');
      return;
    }
    setIsLoading(true);

    const data = {
      company_id: userDetails?.company?.id,
      environment: environment?.toLowerCase() || 'sandbox',
      new_pin: tempNewPin,
      confirm_pin: confirmPin.join(''),
      reference_id: referenceId,
    };
    completePinResetMutation(data);
  }, [
    tempNewPin,
    confirmPin,
    userDetails,
    environment,
    referenceId,
    completePinResetMutation,
  ]);

  const handleResendOtp = useCallback(() => {
    const data = {
      company_id: userDetails?.company?.id,
      environment: environment?.toLowerCase() || 'sandbox',
      authenticator_verified: true,
      email: user?.email,
      reference_id: referenceId,
    };
    resetPinOtpMutation(data);
  }, [userDetails, environment, user?.email, referenceId, resetPinOtpMutation]);

  const handleInputChange = useCallback((e, index, setState, refs, length) => {
    const value = e.target.value.replace(/\D/g, '');

    setState(prevState => {
      const newData = [...prevState];
      newData[index] = value;
      return newData;
    });

    if (value && index < length - 1) {
      refs.current[index + 1]?.focus();
    }
  }, []);

  const handleKeyDown = useCallback((e, index, state, refs) => {
    if (e.key === 'Backspace' && !state[index] && index > 0) {
      refs.current[index - 1]?.focus();
    }
  }, []);

  const handlePaste = useCallback((e, setState, refs, length) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData('text')
      .replace(/\D/g, '')
      .slice(0, length);

    const newData = Array(length).fill('');
    for (let i = 0; i < pastedData.length; i++) {
      newData[i] = pastedData[i];
    }
    setState(newData);

    const focusIndex = Math.min(pastedData.length, length - 1);
    setTimeout(() => {
      refs.current[focusIndex]?.focus();
    }, 0);
  }, []);

  if (!user?.email) {
    return null;
  }

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle={
        currentStep === STEPS.CHECK_2FA
          ? 'Forgot PIN'
          : currentStep === STEPS.VERIFY_2FA
          ? 'Authenticator Verification'
          : currentStep === STEPS.SUCCESS
          ? null
          : 'Reset Your PIN'
      }
    >
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-30 h-full overflow-y-hidden flex items-center justify-center z-50">
          <Loader height={45} />
        </div>
      )}
      {currentStep === STEPS.CHECK_2FA && (
        <>
          {!isTwoFactorAuthSetUp ? (
            <>
              <p className="py-6 text-center text-body text-sm max-w-[365px] mx-auto">
                Kindly set up your two-factor authentication to proceed.
              </p>
              <PrimaryButton
                buttonText="Set Up 2FA"
                className="w-full"
                onClick={() => navigate('/settings/profile')}
              />
            </>
          ) : (
            <>
              <p className="py-6 text-center text-body text-sm max-w-[365px] mx-auto">
                Click the button to reset your pin. A 6-digit code will be sent
                to your email{' '}
                {user?.email
                  ? user.email.replace(/(.{1}).+(.{1}@.+)/, '$1****$2')
                  : 'your email'}
                .
              </p>
              <PrimaryButton
                buttonText="Continue"
                className="w-full"
                onClick={() => setCurrentStep(STEPS.VERIFY_2FA)}
              />
            </>
          )}
        </>
      )}
      {currentStep === STEPS.VERIFY_2FA && (
        <>
          <div className="flex items-center justify-center w-full mt-4">
            {' '}
            <img src={pinIcon} alt="icon" />
          </div>
          <p className="py-3 pb-10 text-center text-body text-base max-w-[365px] mx-auto">
            Please enter the code from your authenticator app
          </p>
          <div className="flex justify-center space-x-4 mb-6">
            {otp.map((digit, index) => (
              <div key={index} className="w-16 h-16 relative">
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={1}
                  value={digit}
                  onChange={e =>
                    handleInputChange(e, index, setOtp, otpRefs, OTP_LENGTH)
                  }
                  onKeyDown={e => handleKeyDown(e, index, otp, otpRefs)}
                  onPaste={e => handlePaste(e, setOtp, otpRefs, OTP_LENGTH)}
                  ref={el => (otpRefs.current[index] = el)}
                  className="w-full h-full rounded-lg border border-[#E1E4EA]  text-center caret-brandBlue text-transparent outline-none"
                  disabled={isLoading}
                  autoComplete="off"
                />
                {digit && (
                  <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                    <div className="w-3 h-3 bg-black rounded-full"></div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <PrimaryButton
            buttonText="Submit"
            className="w-full"
            onClick={handle2FAStep}
          />
        </>
      )}
      {currentStep === STEPS.EMAIL_OTP && (
        <>
          <div className="flex items-center justify-center w-full mt-4">
            {' '}
            <img src={pinIcon} alt="icon" />
          </div>
          <p className="py-3 pb-10 text-center text-body text-base max-w-[365px] mx-auto">
            Enter the 6-digit OTP sent to your business email at{' '}
            {user?.email
              ? user.email.replace(/(.{1}).+(.{1}@.+)/, '$1****$2')
              : 'your email'}
          </p>
          <div className="flex gap-2 justify-center">
            {otp.map((digit, index) => (
              <div key={index} className="w-16 h-16 relative">
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={1}
                  value={digit}
                  onChange={e =>
                    handleInputChange(e, index, setOtp, otpRefs, OTP_LENGTH)
                  }
                  onKeyDown={e => handleKeyDown(e, index, otp, otpRefs)}
                  onPaste={e => handlePaste(e, setOtp, otpRefs, OTP_LENGTH)}
                  ref={el => (otpRefs.current[index] = el)}
                  className="w-full h-full rounded-lg border border-[#E1E4EA]  text-center caret-brandBlue text-transparent outline-none"
                  disabled={isLoading}
                  autoComplete="off"
                />
                {digit && (
                  <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                    <div className="w-3 h-3 bg-black rounded-full"></div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="text-base py-6 flex items-center w-full justify-center gap-2 font-inter text-[#858DAA]">
            <p>Didn&apos;t get a code?</p>
            <button
              onClick={handleResendOtp}
              className="flex items-center gap-3 text-brandBlue font-medium"
            >
              Resend OTP
            </button>
          </div>
          <PrimaryButton
            buttonText="Submit"
            className="w-full"
            onClick={handleEmailOTPStep}
          />
        </>
      )}
      {currentStep === STEPS.SET_NEW_PIN && (
        <>
          <div className="flex items-center justify-center w-full mt-4">
            {' '}
            <img src={pinIcon} alt="icon" />
          </div>
          <p className="py-3 pb-10 text-center text-body text-base max-w-[365px] mx-auto">
            Input your transaction PIN
          </p>
          <div className="flex gap-2 justify-center mb-6">
            {newPin.map((digit, index) => (
              <div key={index} className="w-16 h-16 relative">
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={1}
                  value={digit}
                  onChange={e =>
                    handleInputChange(
                      e,
                      index,
                      setNewPin,
                      newPinRefs,
                      PIN_LENGTH,
                    )
                  }
                  onKeyDown={e => handleKeyDown(e, index, newPin, newPinRefs)}
                  onPaste={e =>
                    handlePaste(e, setNewPin, newPinRefs, PIN_LENGTH)
                  }
                  ref={el => (newPinRefs.current[index] = el)}
                  className="w-full h-full rounded-lg border border-[#E1E4EA]  text-center caret-brandBlue text-transparent outline-none"
                  autoComplete="off"
                />
                {digit && (
                  <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                    <div className="w-3 h-3 bg-black rounded-full"></div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <PrimaryButton
            buttonText="Continue"
            className="w-full"
            onClick={() => {
              setTempNewPin(newPin.join(''));
              setNewPin(PIN_ARRAY);
              setCurrentStep(STEPS.CONFIRM_NEW_PIN);
            }}
          />
        </>
      )}
      {currentStep === STEPS.CONFIRM_NEW_PIN && (
        <>
          <div className="flex items-center justify-center w-full mt-4">
            {' '}
            <img src={pinIcon} alt="icon" />
          </div>
          <p className="py-3 pb-10 text-center text-body text-base max-w-[365px] mx-auto">
            Confirm your transaction PIN
          </p>
          <div className="flex gap-2 justify-center mb-6">
            {confirmPin.map((digit, index) => (
              <div key={index} className="w-16 h-16 relative">
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={1}
                  value={digit}
                  onChange={e =>
                    handleInputChange(
                      e,
                      index,
                      setConfirmPin,
                      confirmPinRefs,
                      PIN_LENGTH,
                    )
                  }
                  onKeyDown={e =>
                    handleKeyDown(e, index, confirmPin, confirmPinRefs)
                  }
                  onPaste={e =>
                    handlePaste(e, setConfirmPin, confirmPinRefs, PIN_LENGTH)
                  }
                  ref={el => (confirmPinRefs.current[index] = el)}
                  className="w-full h-full rounded-lg border border-[#E1E4EA]  text-center caret-brandBlue text-transparent outline-none"
                  autoComplete="off"
                />
                {digit && (
                  <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                    <div className="w-3 h-3 bg-black rounded-full"></div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <PrimaryButton
            buttonText="Submit"
            className="w-full"
            onClick={handleCompletePinReset}
          />
        </>
      )}

      {currentStep === STEPS.SUCCESS && (
        <div className="pt-6 pb-12">
          <h2 className="font-medium text-base mb-6 text-grey">
            Password reset successful
          </h2>
          <p className="text-sm text-body mb-6">
            Your password has successfully been reset
          </p>
          <div className="flex items-center justify-center w-full">
            {' '}
            <img src={successFlair} alt="success" />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default ForgotPINModal;
