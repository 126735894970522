import React, {useCallback, useState} from 'react';
import {
  reconciliationAuthBg,
  reconciliationLogo,
  trypilaLogo,
} from '../../../../assets/images/images';
import {PrimaryButton} from '../../../../components';
import {Constants, onSignupSuccessHelper} from '../../../../helpers';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useMutation} from 'react-query';
import {login} from '../../../../requests';
import {encryptData} from '../../../../helpers/encryptData';
import {toast} from 'react-toastify';
import * as EmailValidator from 'email-validator';
import {useDispatch} from 'react-redux';

function ReconciloSignIn() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputs, setInputValue] = useState({
    email: '',
    password: '',
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const {isLoading: sLoading, mutateAsync} = useMutation(data => login(data), {
    onSuccess: data =>
      onSignupSuccessHelper(data, dispatch, navigate, location),
    onError: error => {
      if (error.response?.data?.error === 'JsonWebTokenError: jwt malformed') {
        toast.error('Internal Server Error');
        return;
      }
      toast.error(error.response?.data?.error || error.message);
    },
  });

  const checkErrors = useCallback(() => {
    const {email, password} = inputs;
    if (email && !EmailValidator.validate(email))
      errors.email = 'Email is not valid';
    if (!email) errors.email = 'Email is required';
    if (!password.length) errors.password = 'Password is required';
    setErrors(errors);
  }, [errors, inputs]);

  const handleSubmit = async e => {
    e.preventDefault();
    checkErrors();
    if (Object.keys(errors).length === 0) {
      const encryptedLoginData = encryptData(JSON.stringify(inputs));
      await mutateAsync({payload: encryptedLoginData});
    } else {
      Object.keys(errors).find(key => toast.error(errors[key]));
    }
  };

  const handleInputChange = e => {
    setInputValue({...inputs, [e.target.name]: e.target.value});
    setErrors({});
  };

  const subDomainUser = localStorage.getItem('subdomain');
  const isReconcilo = subDomainUser?.includes('reconcilo');

  return (
    <div
      className="w-full h-full bg-cover bg-center bg-no-repeat min-h-screen"
      style={{backgroundImage: `url(${reconciliationAuthBg})`}}
    >
      <div className="py-[70px] px-5 lg:px-0 flex flex-col items-center">
        <img
          src={isReconcilo ? reconciliationLogo : trypilaLogo}
          alt="App logo"
          className={`${
            isReconcilo ? 'w-[192px] h-[37px] ' : 'h-8 mt-8'
          } w-auto mb-6`}
        />
        <div className="bg-white border m-auto border-batchBlue20 py-10 px-8 rounded-2xl w-full md:w-[536px] shadow-reconcilable">
          <div className="">
            <h2 className="text-deepBlue font-semibold text-md mb-[6px]">
              Welcome Back
            </h2>
            <p className="text-sm text-body">
              Log in to access and manage your{' '}
              {isReconcilo ? 'Reconcilo' : 'Trypila'} account
            </p>
            <form className="mt-8">
              <label htmlFor="email">
                Email address
                <input
                  type="email"
                  name="email"
                  id="op"
                  value={inputs.email}
                  onChange={handleInputChange}
                  className="mt-2"
                />{' '}
              </label>
              <label htmlFor="password" className="relative mt-6">
                Password
                <input
                  type={passwordShown ? 'text' : 'password'}
                  id="pw"
                  name="password"
                  className="mt-2"
                  value={inputs.password}
                  onChange={handleInputChange}
                />
                <span
                  role="button"
                  onClick={togglePassword}
                  className="absolute text-xs font-medium uppercase cursor-pointer text-grey40 right-3 bottom-4"
                >
                  {passwordShown ? 'hide password' : 'show password'}
                </span>
              </label>
              <div className="flex justify-end">
                <Link
                  to={Constants.PAGES_URL.FORGOT_PASSWORD}
                  className="mt-2 ml-auto text-xs font-medium uppercase text-body"
                >
                  forgot password?
                </Link>
              </div>
            </form>
            <PrimaryButton
              onClick={handleSubmit}
              buttonText="Sign in"
              className="mt-6 h-12 w-full"
              loading={sLoading}
              disabled={sLoading}
              loadColor="#2A5392"
            />{' '}
          </div>
        </div>

        {isReconcilo && (
          <div className="mt-8 flex items-center justify-center rounded-[30px] px-7 py-4 bg-white">
            <p className="text-sm font-medium text-body">
              Dont have an account?
              <Link
                to={Constants.PAGES_URL.RECONCILIATION_SIGNUP}
                className="text-brandBlue font-semibold ml-1"
              >
                Sign up
              </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReconciloSignIn;
