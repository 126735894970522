/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {connect} from 'react-redux';

import * as Assets from '../../../../assets/images/images';
import * as Components from '../../../../components';
import EndProcessModal from './EndProcessModal';
import RuleEditorModal from './RuleEditorModal';
import * as Actions from '../../../../store/actions';
import {TriggerCard} from './_TriggerCard';
import {Steps} from './_Steps';
import {ConditionCard} from './_ConditionsCard';
import DeleteModal from './DeleteModal';
import {isNestedObjectTruthy} from '../../../../helpers/isNestedObjectTruthy';
import {PageContext} from '../../../../helpers/context';
import {CreditCheckContext} from '../../../../helpers/context/CreditCheckContext';
import ButtonLoader from '../../../../components/ButtonLoader';
import {useFlowData} from './hooks/useFlowData';
import {useLogic} from './hooks/useLogic';
import ConditionInputs from './_ConditionsInputs';
import {getSubRuleTheme} from './utils';

function FlowsEditProcess({
  createFlowProcess,
  flows: {flowProcess},
  apps: {live},
}) {
  const {slug} = useParams();
  const flowIndex = localStorage.getItem('ruleIndex');
  const navigate = useNavigate();
  const {
    set: {setOpenModals, setRuleState, setCurrentSubRuleConditions},
    get: {
      tempFlow,
      ruleState,
      openModals: {actionRule, lastRule, steps},
    },
  } = React.useContext(CreditCheckContext);

  const {setPageName} = React.useContext(PageContext);
  React.useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  ////////////// REACT-QUERY /////////////////
  const FLowData = useFlowData({slug, flowProcess, createFlowProcess});

  const Logic = useLogic({
    createFlowProcess,
    addNewTask: FLowData.addNewTask,
    updateExistingTask: FLowData.updateExistingTask,
    saveFlowProcess: FLowData.saveFlowProcess,
  });

  const [addingOrUpdating, setAddingOrUpdating] = React.useState({
    [Logic.action]: true,
  });

  // const handleAllApply = async () => {
  //   let ruleName = ruleState.actionName[Logic.action];
  //   let taskId = localStorage.getItem('ruleIndex');
  //
  //   await tempFlow[1].map((rule, ruleIndex) => {
  //     Logic.setAction(rule.key);
  //
  //     setRuleState(prev => ({
  //       ...prev,
  //       actionName: {[rule.key]: rule?.name},
  //       currentRule: {
  //         ...rule,
  //         configurations: rule?.configurations,
  //       },
  //     }));
  //     localStorage.setItem('ruleIndex', ruleIndex.toString());
  //     localStorage.setItem('taskId', rule?.id);
  //     !taskId ? (taskId = rule.id) : taskId;
  //     !ruleName ? (ruleName = rule?.name) : ruleName;
  //   });
  //   ruleName.length > 0 &&
  //     taskId.length > 0 &&
  //     (await Logic.applyFlow(ruleName));
  // };

  React.useEffect(() => {
    tempFlow?.length > 0 &&
      tempFlow[1].map(data => {
        Logic.setError({
          ...Logic.errors,
          [+flowIndex]: !isNestedObjectTruthy(data),
        });
      });
    setAddingOrUpdating({
      [+flowIndex]: FLowData.addingTask || FLowData.updatingTask,
    });
  }, [Logic.setError, tempFlow, FLowData.addingTask, FLowData.updatingTask]);

  return (
    <Components.DashboardLayout
      bg="bg-[#DAE4F4]"
      fullScreen
      topPadding="pt-0"
      leftMargin="ml-0"
      xlLeftMargin="xl:ml-0"
      xlRightPadding="sm:pr-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={Assets.easyOnboardIc} alt="" />
          <p className="ml-2 -mb-1">Fraud detection</p>
        </div>
      }
    >
      <DeleteModal ruleIndex={Logic.hoveredIndex} />
      <EndProcessModal />
      <ConditionInputs
        fieldsType={FLowData.fieldsAndOperators}
        isLoading={FLowData.addingTask || FLowData.updatingTask}
        onClick={Logic.applyFlow}
        handleAddCondition={Logic.handleAddCondition}
        action={Logic.action}
      />
      <RuleEditorModal
        disabled={FLowData.addingTask || FLowData.updatingTask}
        action={Logic.action}
        onClick={Logic.applyFlow}
        handleAddSubRule={Logic.handleAddSubRule}
      />
      <section className="absolute z-10  shadow bg-white80 py-2  px-12 w-[102%] flex items-center gap-4 justify-end sm:top-0  sm:absolute  top-[41px] ">
        <div
          className="mr-auto flex items-center cursor-pointer hover:underline"
          onClick={() => navigate(-1)}
        >
          <img src={Assets.back} alt="" />
          <span className="text-sm text-body">Back</span>
        </div>

        {/*<Components.SecondaryButton*/}
        {/*  yPadding="pt-[7px] pb-[7px]"*/}
        {/*  btnHeight="h-[28px]"*/}
        {/*  fontSize="text-xs"*/}
        {/*  buttonText={*/}
        {/*    taskId && taskId !== 'undefined'*/}
        {/*      ? 'Update Rules Draft'*/}
        {/*      : 'Save as Draft'*/}
        {/*  }*/}
        {/*  onClick={handleAllApply}*/}
        {/*  // onClick={Logic.applyFlow}*/}
        {/*  loading={FLowData.addingTask || FLowData.updatingTask}*/}
        {/*/>*/}

        <Components.PrimaryButton
          yPadding="py-2"
          xPadding="px-2"
          loading={FLowData.publishingRules}
          fontSize="text-xs"
          buttonText="Publish Rule Process"
          className="bg-success "
          onClick={FLowData.publishRules}
          disabled={
            !JSON.parse(localStorage?.isModified || '{}') ||
            FLowData.publishingRules
          }
        />
        <Components.PrimaryButton
          onClick={
            () => FLowData.clearRule(slug)
            // clearFlow
          }
          loading={FLowData.clearingRule}
          yPadding="py-2"
          xPadding="px-2"
          buttonText="Clear Rule Process"
          disabled={!tempFlow[1]?.length > 0 || FLowData.clearingRule}
          className="text-xs text-white font-medium bg-danger rounded p-2 hover:opacity-95"
        />
      </section>

      <div className=" h-[93vh] overflow-auto relative py-20">
        {FLowData.isFetching && (
          <div className="fixed right-0 left-20 top-0 z-50  w-full h-full flex items-center justify-center">
            <ButtonLoader color="#3F7CDB" />
            <span className="text-sm mt-16 text-body font-medium italic">
              ...loading
            </span>
          </div>
        )}
        <section
          className="flex flex-col pb-20 relative "
          style={{opacity: FLowData.isFetching && 0.5}}
        >
          {!live ? (
            <Components.EmptyState
              body={'Ops! You can only access this resource in live mode'}
              src={Assets.corruptedFile}
              noBtn
            />
          ) : FLowData.gettingTasks ? (
            <Components.Loader />
          ) : (
            <section className="flex flex-col items-center mt-16 gap-[6px]">
              {tempFlow.length > 0 &&
                tempFlow?.map((process, index) => {
                  if (index === 0) {
                    return (
                      <React.Fragment key={index}>
                        <TriggerCard
                          title={process?.name}
                          subtitle="User begins process"
                          icon={Assets.startProcess}
                          isStarting
                        />
                      </React.Fragment>
                    );
                  }
                  if (index > 0 && index < tempFlow?.length - 1) {
                    return process?.map((rule, ruleIndex) => {
                      const ruleConditions =
                        (rule?.configurations &&
                          Array.isArray(rule?.configurations) &&
                          rule?.configurations) ||
                        [];

                      return (
                        <span
                          key={rule.key}
                          style={{
                            position: 'relative',
                            textAlign: 'center',
                          }}
                          className="flex flex-col items-center"
                        >
                          <div
                            onMouseEnter={e =>
                              Logic.handleMouseEnter(e, ruleIndex)
                            }
                            onMouseLeave={Logic.handleMouseLeave}
                            className="w-full mb-1 flex flex-col items-center justify-center"
                          >
                            {!steps ? (
                              <div
                                className="flex py-1 items-center justify-center flex-col"
                                onClick={() => Logic.handleAddSteps()}
                              >
                                <span>
                                  <img
                                    src={Assets.stepsPlus}
                                    alt=""
                                    width={20}
                                    height={20}
                                    title={steps ? '' : 'Add rules'}
                                    className="cursor-pointer"
                                  />
                                </span>
                              </div>
                            ) : (
                              <React.Fragment>
                                {!actionRule &&
                                  steps &&
                                  ruleIndex === Logic.hoveredIndex && (
                                    <>
                                      <img
                                        src={Assets.flowLine}
                                        alt=""
                                        width={8}
                                        height={4}
                                      />
                                      <Steps
                                        handleAddActionStep={
                                          Logic.handleAddActionStep
                                        }
                                        setAction={Logic.setAction}
                                        tempFlow={tempFlow}
                                        className="py-2"
                                      />
                                    </>
                                  )}
                              </React.Fragment>
                            )}
                            <img
                              src={Assets.flowLine}
                              alt=""
                              width={8}
                              height={4}
                            />
                          </div>

                          <div
                            onMouseEnter={e =>
                              Logic.handleMouseEnter(e, ruleIndex)
                            }
                            onMouseLeave={Logic.handleMouseLeave}
                            className="w-[420px] mb-1 flex items-center justify-center relative"
                          >
                            <TriggerCard
                              // isError={errors[idx]}
                              disabled={addingOrUpdating[ruleIndex]}
                              ref={Logic.cardRef}
                              icon={Assets.actionStepIc}
                              title={
                                ruleState.actionName[rule.key] ||
                                rule?.name ||
                                '-'
                              }
                              height
                              onClick={() => {
                                Logic.setAction(rule.key);

                                setRuleState(prev => ({
                                  ...prev,
                                  actionName: {[rule.key]: rule?.name},
                                  currentRule: {
                                    ...rule,
                                    configurations: ruleConditions,
                                  },
                                }));
                                setOpenModals({openRuleEditorModal: true});
                                localStorage.setItem(
                                  'ruleIndex',
                                  ruleIndex.toString(),
                                );
                                localStorage.setItem('taskId', rule?.id);
                              }}
                            />

                            {!steps && ruleIndex === Logic.hoveredIndex && (
                              <div
                                style={Logic.buttonStyle}
                                onClick={() => {
                                  setOpenModals({openDeleteAction: true});
                                  localStorage.setItem(
                                    'ruleIndex',
                                    ruleIndex.toString(),
                                  );
                                  localStorage.setItem('taskId', rule?.id);
                                }}
                                className="border border-danger rounded-full  p-1 absolute right-10 top-0"
                              >
                                <img
                                  src={Assets.trashRed}
                                  alt=""
                                  width={20}
                                  height={4}
                                />
                              </div>
                            )}
                          </div>
                          {ruleConditions?.length >= 0 ? (
                            <>
                              <img
                                src={Assets.flowLine}
                                alt=""
                                width={8}
                                height={4}
                              />
                              <div className="flex  items-center flex-col p-10 pb-5  relative outline-1 outline-dashed rounded ">
                                <span className="text-xs text-body absolute left-2 top-2">
                                  Sub Rules
                                </span>
                                <div className="flex ml-30 items-center gap-3  ">
                                  {ruleConditions?.map(
                                    (subRule, ruleConditionIndex) => {
                                      const ruleConditionsKey = Object.keys(
                                        subRule || {},
                                      )[0];

                                      const theme =
                                        getSubRuleTheme(ruleConditionsKey);

                                      return (
                                        ruleConditions[ruleConditionIndex]
                                          ?.id === subRule?.id && (
                                          <React.Fragment
                                            key={ruleConditionIndex}
                                          >
                                            <ConditionCard
                                              color={theme?.color || ''}
                                              key={ruleConditionIndex}
                                              icon={
                                                theme?.icon ||
                                                Assets.actionStepIc
                                              }
                                              subtitle={`Sub-Rule - ${
                                                ruleConditionIndex + 1
                                              } > ${ruleConditionsKey}`.toUpperCase()}
                                              // subtitle={`${
                                              //   condition?.field
                                              //     ?.replaceAll('_', ' ')
                                              //     ?.split(' ')
                                              //     ?.join(' ')
                                              //     .replace('is', '') || ''
                                              // } ${
                                              //   separateCamelCaseWithCaps(
                                              //     condition?.operator,
                                              //   ) || ''
                                              // } ${
                                              //   condition?.value?.toString() ||
                                              //   ''
                                              // }`}
                                              // height

                                              onClick={() => {
                                                setOpenModals({
                                                  openConditionEditorModal: true,
                                                });
                                                setRuleState(prev => ({
                                                  ...prev,
                                                  actionName: {
                                                    [rule.key]: rule?.name,
                                                  },
                                                  currentRule: {
                                                    ...rule,
                                                    configurations:
                                                      ruleConditions,
                                                  },
                                                }));
                                                Logic.setAction(rule.key);
                                                localStorage.setItem(
                                                  'ruleConditionsKey',
                                                  ruleConditionsKey.toString(),
                                                );
                                                localStorage.setItem(
                                                  'ruleIndex',
                                                  ruleIndex.toString(),
                                                );
                                                localStorage.setItem(
                                                  'ruleConditionIndex',
                                                  ruleConditionIndex.toString(),
                                                );
                                                setCurrentSubRuleConditions(
                                                  subRule[ruleConditionsKey],
                                                );
                                              }}
                                            />

                                            {ruleConditions?.length > 1 &&
                                            ruleConditionIndex !==
                                              ruleConditions?.length - 1 ? (
                                              // eslint-disable-next-line
                                              <div className="rotate-90 w-3">
                                                <img
                                                  src={Assets.flowLine}
                                                  alt=""
                                                  width={8}
                                                  height={4}
                                                />
                                              </div>
                                            ) : null}
                                          </React.Fragment>
                                        )
                                      );
                                    },
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </span>
                      );
                    });
                  }

                  if (index === tempFlow?.length - 1) {
                    return (
                      <React.Fragment key={index}>
                        {!actionRule && !steps && (
                          <button
                            onClick={Logic.handleAddSteps}
                            className="w-5 h-5"
                            title={steps ? '' : 'Add steps'}
                          >
                            <img
                              src={Assets.stepsPlus}
                              alt=""
                              width={20}
                              height={20}
                            />
                          </button>
                        )}
                        <img
                          src={Assets.flowLine}
                          alt=""
                          width={6}
                          height={44}
                        />
                        {!actionRule && steps && lastRule ? (
                          <Steps
                            handleAddActionStep={Logic.handleAddActionStep}
                            setAction={Logic.setAction}
                            tempFlow={tempFlow}
                          />
                        ) : null}
                        <TriggerCard
                          subtitle={'User output'}
                          title={process?.name || 'Ending process'}
                          onClick={() =>
                            setOpenModals({openEndProcessModal: true})
                          }
                          icon={Assets.cup}
                          isEnding
                        />
                      </React.Fragment>
                    );
                  }
                })}
            </section>
          )}
        </section>
      </div>
    </Components.DashboardLayout>
  );
}

export default connect(state => state, Actions)(FlowsEditProcess);
