import React, {useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import {PageContext} from '../../../helpers/context';
import BusinessLookupModal from '../BusinessLookupModal';
import Layout from '../Layout';
import AmlBusinessScreeningSingle from '../BusinessScreening/SingleScreening';

function BusinessScreening({getAllApps}) {
  const [businessOpen, setBusinessOpen] = useState(false);
  const [step, setStep] = useState(1);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('amllookup');
  }, [setPageName]);

  useEffect(() => {
    getAllApps({limit: 10000});
  }, [getAllApps]);

  return (
    <Layout customBg="bg-white80">
      <BusinessLookupModal
        setBusinessOpen={setBusinessOpen}
        businessOpen={businessOpen}
        setStep={setStep}
        step={step}
      />

      {/* {!allApps ? (
        <Loader height={35} />
      ) : (
        <>
          <div className="mt-4">
            <h1 className="text-grey text-xl font-medium">
              Business screening
            </h1>
            <p className="mt-2 text-body text-sm">
              Screen information about your businesses
            </p>
          </div>

          <div className="mt-12 flex flex-col sm:flex-row items-stretch gap-10 sm:gap-4 lg:gap-10">
            <div className="bg-white rounded-3xl px-4 lg:px-6 pt-8 pb-0 flex flex-col items-start sm:max-w-[406px] w-full">
              <h2 className="text-grey text-lg font-semibold">
                Perform Single AML
              </h2>
              <p className="mt-2 text-grey20 tracking-tight">
                Screen your user effortlessly
              </p>

              <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2">
                <PerformBtn
                  permitted={performSingleBusinessLookup}
                  setOpen={setBusinessOpen}
                  isCompanyVerified={isCompanyVerified}
                  btnText="Perform Single AML"
                />
                <ViewLink
                  permitted={viewSingleBusinessLookup}
                  route={PAGES_URL.AML_BUSINESS_SCREENING_SINGLE}
                />
              </div>

              <div className="mt-auto lg:mt-14 flex justify-center w-full">
                <img src={singleAmlImg} alt="" width={347} height={224} />
              </div>
            </div>

            <div className="bg-white rounded-3xl px-4 lg:px-6 pt-8 pb-0 flex flex-col items-start sm:max-w-[406px] w-full">
              <h2 className="text-grey text-lg font-semibold">
                Perform Batch AML
              </h2>
              <p className="mt-2 text-grey20 tracking-tight">
                Upload and examine multiple users
              </p>

              <div className="w-full mt-10 flex flex-col lg:flex-row items-start lg:items-center gap-2">
                <PerformBtn
                  permitted={performBatchBusinessLookup}
                  setOpen={setBusinessOpen}
                  isCompanyVerified={isCompanyVerified}
                  btnText="Perform Batch AML"
                />
                <ViewLink
                  permitted={viewBatchBusinessLookup}
                  route={PAGES_URL.AML_BUSINESS_SCREENING_SINGLE}
                />
              </div>

              <div className="mt-14 flex justify-center w-full">
                <img src={batchAmlImg} alt="" width={329} height={236} />
              </div>
            </div>
          </div>
        </>
      )} */}

      <AmlBusinessScreeningSingle />
    </Layout>
  );
}

export default connect(state => state, Actions)(BusinessScreening);
