import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import Loader from '../../../components/Loader';
import EmptyState from '../../../components/EmptyState';
import FilterButton from '../../../components/FilterButton';
import TableLayout from '../../../components/TableLayout';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../../helpers/formatNumberToCurrency';
import PageLimit from '../../../components/PageLimit';
import Pagination from '../../../components/Pagination';
import SecondaryButton from '../../../components/SecondaryButton';
import PrimaryButton from '../../../components/PrimaryButton';
import SettingsBilling from './index';
import * as Actions from '../../../store/actions';
import {renderServices} from '../../../helpers/renderServicies';
import {billingIc, corruptedFile} from '../../../assets/images/images';
import {
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../../helpers';
import {Filter} from '../../../components';
import {PageContext} from '../../../helpers/context';
import {useAllApps} from '../../../hooks/useApps';
import {useQuery} from 'react-query';
import {fetchApiLogsFilters} from '../../../requests/queries/dashboard';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import AppliedFilters from 'components/AppliedFilters';

const defaultFilterOptions = [
  {
    title: 'Apps',
    name: 'apps',
    values: [],
  },
  {
    title: 'Services',
    name: 'services',
    values: [],
  },
];

const BillingsTransactions = ({
  auth: {loading, userDetails, billingsApiCalls},
  apiLogs: {exportLoading},
  getBillingsApiCalls,
  exportApiLogs,
  getApiLogsFilters,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openFilter, setOpenFilter] = useState(false);
  const {data: filters, isLoading} = useQuery(['filters'], fetchApiLogsFilters);
  const {setPageName} = useContext(PageContext);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [filterProps, setFilterProps] = useState(null);

  useEffect(() => {
    setPageName('billings');
  }, [setPageName]);

  const {userApps} = useAllApps();

  const getAppName = useCallback(
    appId =>
      !isLoading && userApps && userApps?.find(app => app.value === appId),
    [isLoading, userApps],
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const canExportLogs =
    userDetails?.permissions?.billingPermissions?.exportLogs;

  useEffect(() => {
    getApiLogsFilters();
  }, [getApiLogsFilters]);

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }

    getBillingsApiCalls(apiParams);
  }, [currentPage, getBillingsApiCalls, limit]);

  const filterOptions = useMemo(() => {
    if (!isLoading && !filters) {
      return defaultFilterOptions;
    }

    const mapToOptionsArray = (items, valueKey = '_id') =>
      items?.map(item => ({
        name: getAppName(item)?.label || item,
        label: getAppName(item)?.label || item,
        value: item?.[valueKey] || item?.replace(/ /g, '_')?.toLowerCase(),
      })) || [];

    return [
      {
        title: 'Apps',
        name: 'apps',
        values: mapToOptionsArray(filters?.apps),
      },
      {
        title: 'Services',
        name: 'services',
        values: mapToOptionsArray(filters?.services),
      },
    ];
  }, [filters, isLoading, getAppName]);

  const handleFilter = selectedOptions => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);

    setCurrentPage(1);
    navigate(`?${queryParams}&page=1`);

    getBillingsApiCalls({
      page: currentPage,
      limit,
      ...filteredOptions,
    });

    setOpenFilter(false);
    setFiltersApplied(true);
  };

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);

    getBillingsApiCalls({limit, page: 1});
    setOpenFilter(true);
    setFiltersApplied(false);
  };

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <SettingsBilling pageTitle="Billing/Calls" pageIcon={billingIc}>
      <section className="mt-6">
        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          handleFilter={handleFilter}
          resetUrl={handleResetFilter}
          filterOptions={filterOptions}
          onFilterStateChange={handleFilterStateChange}
        />
        <div className="flex flex-wrap items-center justify-between gap-4 mb-6 sm:flex-nowrap sm:gap-0">
          <div className="flex items-center gap-4 flex-wrap">
            <p className="text-sm font-medium text-grey">Api Calls</p>
            {filtersApplied && filterProps && (
              <AppliedFilters {...filterProps} isMainPage={true} />
            )}
          </div>

          <div className="flex items-center gap-4">
            {/* <SearchInput className="bg-white outline-white" /> */}
            <FilterButton
              className="bg-white"
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />

            <PrimaryButton
              loading={exportLoading}
              onClick={() => {
                const apiParams = parseUrlParams();
                apiParams.limit = limit;
                apiParams.priced = true;

                // api expects first letter to be uppercase
                if (apiParams.type) {
                  apiParams.type =
                    apiParams.type.charAt(0).toUpperCase() +
                    apiParams.type.slice(1);
                }

                exportApiLogs(apiParams);
              }}
              buttonText="Export"
              disabled={!canExportLogs}
            />
          </div>
        </div>
        <TableLayout negativeMargins negativeRightMargin="-mr-4 xl:-mr-[76px]">
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-4 text-xs font-medium text-left sm:pl-6 xl:pl-12 text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>App name</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Date time</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Services</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Cost</span>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {loading ? (
                  <Loader height={45} />
                ) : !loading && billingsApiCalls === undefined ? (
                  <EmptyState
                    body={
                      billingsApiCalls === undefined
                        ? 'No data found'
                        : 'This filter did not return any data, try using different values.'
                    }
                    src={corruptedFile}
                    noBtn
                    customBtn={
                      billingsApiCalls === undefined ? null : (
                        <span
                          onClick={handleResetFilter}
                          className="text-brandBlue cursor-pointer"
                        >
                          Reset filter
                        </span>
                      )
                    }
                  />
                ) : !loading && !billingsApiCalls?.api_logs?.length ? (
                  <EmptyState
                    body={
                      billingsApiCalls === undefined
                        ? 'No data found'
                        : 'This filter did not return any data, try using different values.'
                    }
                    src={corruptedFile}
                    noBtn
                    customBtn={
                      billingsApiCalls === undefined ? null : (
                        <span
                          onClick={handleResetFilter}
                          className="text-brandBlue cursor-pointer"
                        >
                          Reset filter
                        </span>
                      )
                    }
                  />
                ) : null}
              </td>
            </tr>
          </tbody>

          <tbody className="text-sm bg-white">
            {!loading &&
              billingsApiCalls &&
              billingsApiCalls?.api_logs?.map((billing, i) => (
                <tr className="border-b cursor-pointer border-grey60" key={i}>
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {getAppName(billing?.app)?.label || '-'}
                    {/* {formatNumberToCurrency(billing?.amount / 100 || 0)} */}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(billing?.createdAt).format(
                      'Do MMM, YYYY, hh:mmA',
                    ) || '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <div className=" py-1 text-tiny text-body rounded-full  w-fit">
                      {renderServices(billing?.service) ||
                        billing?.service?.replaceAll('_', ' ') ||
                        '-'}
                    </div>
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {shortenLargeNumbers(
                      billing?.cost / 100 || 0,
                      userDetails?.company?.currency || 'NGN',
                      {},
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>

        {!loading && billingsApiCalls?.api_logs?.length !== 0 && (
          <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={setLimit}
                // className="mt-3 mt-sm-0"
                total={billingsApiCalls && billingsApiCalls?.totalRecords}
                length={billingsApiCalls?.api_logs?.length}
                limit={limit}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Pagination
                total={
                  billingsApiCalls &&
                  Math.ceil(billingsApiCalls?.totalRecords / limit)
                }
                current={+currentPage}
                onPageChange={activePage => {
                  pageClick(activePage);
                  const searchParams = new URLSearchParams(
                    document.location.search,
                  );
                  searchParams.set('page', activePage);
                  const newSearchParams = searchParams.toString();
                  navigate(`?${newSearchParams}`);
                }}
              />
            </div>
          </div>
        )}
        <div className="flex flex-wrap items-center gap-4 sm:hidden">
          <SecondaryButton
            buttonText="Create payment limit"
            className="w-full"
          />
          <PrimaryButton buttonText="Top up wallet" className="w-full" />
        </div>
      </section>
    </SettingsBilling>
  );
};

export default connect(state => state, Actions)(BillingsTransactions);
