import React, {useState, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import {PrimaryButton} from '../../../../components';
import {updateBAASPin} from '../../../../requests/queries/baas';
import {PinInput} from './PinInput';
import {Disclosure} from '@headlessui/react';
import {arrowRight} from 'assets/images/images';
import ForgotPINModal from './ForgotPINModal';

const PIN_LENGTH = 4;

export const UpdatePin = () => {
  const {company} = useSelector(state => state?.auth?.userDetails || {});
  const [open, setOpen] = useState(false);
  const [pins, setPins] = useState({
    oldPin: '',
    newPin: '',
    confirmPin: '',
  });

  const updatePinMutation = useMutation(updateBAASPin);

  const handlePinChange = useCallback((name, value) => {
    setPins(prev => ({...prev, [name]: value}));
  }, []);

  const isValid = useMemo(() => {
    const {oldPin, newPin, confirmPin} = pins;
    return (
      oldPin.length === PIN_LENGTH &&
      newPin.length === PIN_LENGTH &&
      confirmPin.length === PIN_LENGTH &&
      newPin === confirmPin
    );
  }, [pins]);

  const handleSubmit = useCallback(async () => {
    if (!isValid) return;

    try {
      await updatePinMutation.mutateAsync({
        old_pin: pins.oldPin,
        new_pin: pins.newPin,
        confirm_pin: pins.confirmPin,
        company_id: company?.id,
      });

      toast.success('PIN updated successfully');
      setPins({oldPin: '', newPin: '', confirmPin: ''});
    } catch (error) {
      setPins({oldPin: '', newPin: '', confirmPin: ''});
      toast.error(error.response?.data?.error || 'Failed to update PIN');
    }
  }, [
    isValid,
    company?.id,
    pins.oldPin,
    pins.newPin,
    pins.confirmPin,
    updatePinMutation,
  ]);

  return (
    <div className="lg:w-1/2 w-full ">
      <h2 className="text-body text-xs font-medium mb-6 uppercase">
        PAYMENT PIN
      </h2>

      <ForgotPINModal open={open} setOpen={setOpen} />

      <Disclosure>
        {({open}) => (
          <>
            <Disclosure.Button className="flex w-full bg-white rounded justify-between items-center py-7 px-6 text-left">
              <span className="text-body text-[14px] font-medium">
                Change Payment PIN
              </span>
              <img
                src={arrowRight}
                alt="chevron-right"
                className={`${open ? 'hidden' : ''}`}
              />
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="bg-white p-6 rounded flex flex-col gap-6">
                <PinInput
                  label="Current PIN"
                  name="oldPin"
                  value={pins.oldPin}
                  onChange={handlePinChange}
                />
                <PinInput
                  label="New PIN"
                  name="newPin"
                  value={pins.newPin}
                  onChange={handlePinChange}
                  isError={pins.newPin !== pins.confirmPin}
                />
                <PinInput
                  label="Confirm New PIN"
                  name="confirmPin"
                  value={pins.confirmPin}
                  onChange={handlePinChange}
                  isError={pins.newPin !== pins.confirmPin}
                />
                <PrimaryButton
                  buttonText="Confirm PIN Change"
                  className="w-[181px]"
                  loading={updatePinMutation.isLoading}
                  onClick={handleSubmit}
                  disabled={!isValid}
                />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({open}) => (
          <>
            <Disclosure.Button className="flex w-full bg-white rounded justify-between mt-6 items-center py-7 px-6 text-left">
              <span className="text-body text-[14px] font-medium">
                Forgot PIN
              </span>
              <img
                src={arrowRight}
                alt="chevron-right"
                className={`${open ? 'hidden' : ''}`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-6 pb-6 pt-0 bg-white rounded">
              <p className="text-sm text-body">
                Click this button to reset your PIN
              </p>
              <PrimaryButton
                buttonText="Reset PIN"
                className="mt-3"
                onClick={() => {
                  setOpen(true);
                }}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
