/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as Components from '../../../../components';
import Select from 'react-select';
import {CONDITION_TYPE} from './transactions';
import {trashDarkRed} from '../../../../assets/images/images';
import {CreditCheckContext} from '../../../../helpers/context/CreditCheckContext';
import {connect} from 'react-redux';
import * as Actions from '../../../../store/actions';

const SubRule = ({action, condition, ruleType}) => {
  const {
    set: {setRuleState},
    get: {
      ruleState: {actionName},
    },
    handleDeleteSubRule,
    handleUpdateSubRule,
  } = React.useContext(CreditCheckContext);

  const currentConditionType = React.useMemo(
    () => CONDITION_TYPE.find(type => type.value === ruleType),
    [ruleType],
  );

  const isOpen = Boolean(!actionName[action]);

  return (
    <>
      <Components.Accordion
        key={condition?.key}
        isOpen={isOpen}
        title={
          condition?.key === 0
            ? 'Sub-Rule - 1'
            : `Sub-Rule - ${condition?.key + 1}`
        }
        className={`bg-white80 rounded mb-5 `}
      >
        {condition?.key === 0 && (
          <p className="text-start text-[11px] mt-2 text-body ">
            Set customizable rules and conditions to spot fraud through your
            user’s journey
          </p>
        )}

        <div className="mt-4 flex gap-4 items-end ">
          <div className="mt-1 flex-1">
            <label htmlFor="end_process" className="mt-4 mb-2 relative">
              Sub Rule Condition Type
            </label>
            <Select
              defaultValue={currentConditionType}
              onChange={data => {
                handleUpdateSubRule('type', data?.value, condition?.key);

                setRuleState(prev => ({
                  ...prev,
                  conditionType: {
                    ...prev.conditionType,
                    [action]: data,
                  },
                }));
              }}
              options={CONDITION_TYPE}
              placeholder="And/Or"
              className="basic-multi-select white-select "
              classNamePrefix="react-select"
              components={{DropdownIndicator: Components.DropdownIndicator}}
              styles={Components.customStyles}
              menuPlacement="auto"
            />
          </div>
          {condition?.key >= 1 && (
            <div className="">
              <Components.SecondaryButton
                src={trashDarkRed}
                buttonText="Delete"
                className="text-[#A13E3F] bg-white outline outline-1 mb-1 outline-[#A13E3F] flex items-center justify-center gap-1 outline-none "
                leftImg
                width={15}
                onClick={() => {
                  handleDeleteSubRule(condition?.key);
                }}
                noOutline
                btnHeight="h-[40px]"
              />
            </div>
          )}
        </div>
      </Components.Accordion>
    </>
  );
};
export default connect(state => state, Actions)(SubRule);
