import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {
  customFlow,
  easyOnboardIc,
  identification,
} from '../../assets/images/images';
import {DashboardLayout} from '../../components';
import {Constants} from '../../helpers';
import * as Actions from '../../store/actions';
import ButtonLoader from '../../components/ButtonLoader';

function Flow({getWidget, setWidgetPages, widgets: {w_loading}}) {
  const navigate = useNavigate();
  const [templateName, setTemplateName] = useState('');

  const getTemplateIDs = environment => {
    const templateIDs = {
      development: {
        'Digital Businesses': '6501d26ff5292300400d3474',
        Lending: '6501d66af5292300400d3a5e',
        Crypto: '6501d7f1f5292300400d3bd9',
        BNPL: '6501d909f5292300400d3d3a',
      },
      staging: {
        'Digital Businesses': '6502f346240f89004024f025',
        Lending: '6502f5d2240f89004024f610',
        Crypto: '6502f681240f89004024f703',
        BNPL: '6502f71c240f89004024f7b6',
      },
      production: {
        'Digital Businesses': '65041c7433cd050043e4edd6',
        Lending: '6504439b05a8aa00417e1390',
        Crypto: '6504444305a8aa00417e2e3d',
        BNPL: '650444aa05a8aa00417e3edf',
      },
    };

    return templateIDs[environment] || templateIDs['development'];
  };

  const templateIDs = getTemplateIDs(process.env.REACT_APP_APP_ENV_MODE);
  const widgetUrlBase = `${process.env.REACT_APP_WIDGET_BASE_URL}/?widget_id=`;

  const handleGetTemplate = template => {
    setTemplateName(template);
    getWidget(templateIDs[template], navigate, true);
  };

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div
          className="flex items-center"
          onClick={() => navigate('/easy-onboard')}
        >
          <img src={easyOnboardIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">
            Easy onboard {'>'} Flow template
          </p>
        </div>
      }
    >
      {w_loading && (
        <div className="fixed  left-[150px] top-0 z-50  w-full h-full flex items-center justify-center">
          <ButtonLoader color="#3F7CDB" />
          <span className="text-sm mt-16 text-body font-medium italic">
            Setting up [ {templateName} ] template...
          </span>
        </div>
      )}

      <div style={{opacity: w_loading && 0.2}}>
        <h2 className="text-base font-medium text-grey sm:text-lg">
          Easy Onboard
        </h2>

        <div className="mt-6">
          <p className="text-sm font-medium text-grey">Build from scratch</p>
          <Link
            to={Constants.PAGES_URL.EASY_ONBOARD_CUSTOM_FLOW}
            onClick={() => setWidgetPages([])}
          >
            <div className="bg-white80 mx-auto mt-4 py-[19px] flex flex-col items-center card-link border-white80 border-opacity-0 hover:border-brandBlue hover:border-opacity-70">
              <img src={customFlow} alt="" width={53} height={53} />
              <div className="max-w-[245px] text-center mt-2">
                <p className="text-base font-medium text-grey">
                  Build your custom flow
                </p>
                <p className="mt-2 text-sm text-body">
                  Create onboarding flow from scratch to easily verify your
                  users.
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className="mt-12">
          <p className="text-sm font-medium text-grey">
            Or get started with a template
          </p>

          <div className="grid grid-cols-4 gap-4 mt-4">
            <div className="bg-white80 py-[19px] px-3 flex flex-col grow items-center card-link border-white80 border-opacity-0 hover:border-brandBlue hover:border-opacity-70">
              <img src={identification} alt="" width={53} height={53} />
              <div className="max-w-[245px] text-center mt-2">
                <p className="text-base font-medium text-grey">
                  Digital Businesses
                </p>
                <p className="mt-2 text-sm text-body">
                  Use this template for document verification, biometrics
                  verification & address verification
                </p>
                <div className="flex items-center justify-center gap-2 mt-4">
                  <a
                    href={`${widgetUrlBase}${templateIDs['Digital Businesses']}`}
                    target="_blank"
                    className="px-2 pt-[10px] pb-[6px] text-grey text-sm font-medium"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                  <button
                    disabled={w_loading}
                    onClick={() => handleGetTemplate('Digital Businesses')}
                    className="px-2 pt-[10px] pb-[6px] text-brandBlue text-sm font-medium outline outline-1 outline-brandBlue rounded"
                  >
                    Use template
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-white80 py-[19px] px-3 flex flex-col grow items-center card-link border-white80 border-opacity-0 hover:border-brandBlue hover:border-opacity-70">
              <img src={identification} alt="" width={53} height={53} />
              <div className="max-w-[245px] text-center mt-2">
                <p className="text-base font-medium text-grey">Lending</p>
                <p className="mt-2 text-sm text-body">
                  Use this template for phone number checks, Liveness checks and
                  bank account verifications
                </p>
                <div className="flex items-center justify-center gap-2 mt-4">
                  <a
                    href={`${widgetUrlBase}${templateIDs['Lending']}`}
                    target="_blank"
                    className="px-2 pt-[10px] pb-[6px] text-grey text-sm font-medium"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                  <button
                    disabled={w_loading}
                    onClick={() => handleGetTemplate('Lending')}
                    className="px-2 pt-[10px] pb-[6px] text-brandBlue text-sm font-medium outline outline-1 outline-brandBlue rounded"
                  >
                    Use template
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-white80 py-[19px] px-3 flex flex-col grow items-center card-link border-white80 border-opacity-0 hover:border-brandBlue hover:border-opacity-70">
              <img src={identification} alt="" width={53} height={53} />
              <div className="max-w-[245px] text-center mt-2">
                <p className="text-base font-medium text-grey">Crypto</p>
                <p className="mt-2 text-sm text-body">
                  Use this template to extracts data from legitimate
                  identification documents
                </p>
                <div className="flex items-center justify-center gap-2 mt-4">
                  <a
                    href={`${widgetUrlBase}${templateIDs['Crypto']}`}
                    target="_blank"
                    className="px-2 pt-[10px] pb-[6px] text-grey text-sm font-medium"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                  <button
                    disabled={w_loading}
                    onClick={() => handleGetTemplate('Crypto')}
                    className="px-2 pt-[10px] pb-[6px] text-brandBlue text-sm font-medium outline outline-1 outline-brandBlue rounded"
                  >
                    Use template
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-white80 py-[19px] px-3 flex flex-col grow items-center card-link border-white80 border-opacity-0 hover:border-brandBlue hover:border-opacity-70">
              <img src={identification} alt="" width={53} height={53} />
              <div className="max-w-[245px] text-center mt-2">
                <p className="text-base font-medium text-grey">BNPL</p>
                <p className="mt-2 text-sm text-body">
                  Use this template for government ID verification and
                  bio-metrics authentication
                </p>
                <div className="flex items-center justify-center gap-2 mt-4">
                  <a
                    href={`${widgetUrlBase}${templateIDs['BNPL']}`}
                    target="_blank"
                    className="px-2 pt-[10px] pb-[6px] text-grey text-sm font-medium"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                  <button
                    disabled={w_loading}
                    onClick={() => handleGetTemplate('BNPL')}
                    className="px-2 pt-[10px] pb-[6px] text-brandBlue text-sm font-medium outline outline-1 outline-brandBlue rounded"
                  >
                    Use template
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(Flow);
