import React from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import {getLighterShade} from '../colorUtils';
import {InfoIcon} from '../InfoIcon';
import {blackIcon, selfieImg} from 'assets/images/images';

const Selfie = ({widgets: {singleApp}}) => {
  const baseColor = singleApp?.color || singleApp?.color_code || '';

  const lighterShade = getLighterShade(baseColor, 90);

  const textColor = {
    color: baseColor,
  };

  const lighterBackgroundStyle = {
    backgroundColor: lighterShade,
  };

  return (
    <div className="mt-3 overflow-y-auto max-w-sm w-full">
      <div
        className="mx-auto w-fit px-2 pt-2 rounded-[10px] mt-2 mb-3 text-center"
        style={lighterBackgroundStyle}
      >
        <span
          className="text-sm font-medium inline-flex items-center font-inter"
          style={textColor}
        >
          <InfoIcon backgroundColor={baseColor} />
          To continue this on your smartphone.
          <span className="font-semibold ml-[2px]">Click here</span>
        </span>
      </div>

      <div className="mx-auto w-fit p-1 px-2 bg-[#D5D5D5] rounded-[10px] mt-6 mb-5 text-center">
        <span className="text-sm font-medium text-[#080917] inline-flex gap-2 items-center font-inter">
          <img src={blackIcon} />
          Please make sure you are in a well lit environment
        </span>
      </div>

      <div className="flex justify-center w-full max-w-sm">
        <div className="rounded-full border-[3px] border-dashed border-[#1EAE98] p-3 w-[250px] h-[250px]">
          <img src={selfieImg} />
        </div>
      </div>

      <p className="text-center font-medium text-[#080917] text-base mx-auto mt-14 max-w-[288px]">
        Place your face in the circle and click “Capture”
      </p>

      <div className="flex items-center justify-center max-w-sm w-full">
        <button
          disabled
          className="w-fit h-12 px-6 mt-6 font-medium !text-white rounded text-sm bg-brandBlue"
          style={{
            backgroundColor: singleApp?.color || singleApp?.color_code || '',
          }}
        >
          Capture
        </button>
      </div>
    </div>
  );
};

export default connect(state => state, Actions)(Selfie);
