import {api} from 'helpers';

export function fetchAllStatus() {
  return api.get('utils/status/all').then(res => res.data);
}

export function fetchService(service, params = {}) {
  return api
    .get(`utils/status/status/${service}`, {
      params: {
        ...params,
        range: '7d',
      },
    })
    .then(res => res.data);
}
