import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import {defaultFlowProcess} from '../../routes/FraudDetection/Flows/EditProcess/defaultProcess';
import {createFlowProcess, getLists} from '../../store/actions';
import {toast} from 'react-toastify';

export const CreditCheckContext = createContext(null);
function processArrayWithTypes(data = []) {
  return data?.map(item => {
    const itemType = Object.keys(item)[0];

    const updatedItem =
      Array.isArray(item[itemType]) &&
      item[itemType]?.map(con => {
        const {type, parent, id, ...rest} = con;
        return {...rest};
      });

    return updatedItem.length > 0 ? {[itemType]: updatedItem} : {any: [item]};
  });
}

const flowDataReset = {
  type: 'RULE',
  name: 'Rule name',
  configurations: {
    value: 'value',
    fact: 'fact, ',
    operator: 'operators',
    parent: 'Transactions',
    period: 0,
    score: 0,
  },
};
function CreditCheckContextProvider({children}) {
  const flowIndex = localStorage.getItem('ruleIndex');
  const [tempFlow, setTempFlow] = useState([]);
  const [currentFlowData, setCurrentFlowData] = useState({});
  const [currentSubRuleConditions, setCurrentSubRuleConditions] = useState({});
  const [rangeValue, setRangeValue] = useState([25, 76]);
  const {
    flows: {flowProcess},
    caseLists: {listLoading, lists},
  } = useSelector(state => state);

  const [ruleState, setRuleState] = useState({
    selectedField: {},
    selectedCount: {},
    selectedPeriod: {},
    selectedParent: {},
    actionName: {},
    selectedRuleConditionType: {},
    selectedOperator: {},
    actionValue: {},
    actionScore: {},
    conditionType: {},
    selectedRuleType: {},
    currentRule: {},
  });
  const [openModals, setOpenModals] = useState({
    openDeleteAction: false,
    openEndProcessModal: false,
    openRuleEditorModal: false,
    openConditionEditorModal: false,
    actionRule: false,
    lastRule: false,
    steps: false,
  });

  const handleDeleteSubRule = useCallback(
    index => {
      const newProcess = [...tempFlow];
      const rules = newProcess[1];
      rules.forEach(element => {
        if (element.key === ruleState?.currentRule.key) {
          element.configurations?.splice(+index, 1);
        }
      });

      setTempFlow(newProcess);
      setRuleState(prev => ({
        ...prev,
        currentRule: {
          ...prev.currentRule,
          configurations: rules[flowIndex]?.configurations,
        },
      }));
    },
    [flowIndex, ruleState?.currentRule.key, tempFlow],
  );
  const handleDeleteCondition = useCallback(
    index => {
      const ruleConditionIndex = localStorage.getItem('ruleConditionIndex');
      setCurrentSubRuleConditions(prev => prev.filter((_, i) => i !== index));

      return ruleState.currentRule?.configurations.map((item, idx) => {
        if (idx === +ruleConditionIndex) {
          const currentType = Object.keys(item)[0];
          return item[currentType].filter((_, i) => i !== index);
        }
        return item;
      });
    },
    [ruleState.currentRule?.configurations],
  );

  const handleUpdateSubRule = useCallback(
    (key, value, configIdx) => {
      const newProcess = [...tempFlow];
      const newData = {...ruleState?.currentRule};
      newProcess[1]?.forEach(element => {
        if (element.key === ruleState?.currentRule.key) {
          if (newProcess[1]?.[flowIndex]?.configurations) {
            const initialConfig =
              newProcess[1][flowIndex].configurations[configIdx];
            const currentType = Object.keys(initialConfig)[0];
            newProcess[1][flowIndex].configurations[configIdx] = {
              [value]: initialConfig[currentType],
            };
          }

          if (newData.configurations) {
            const initialConfig = newData.configurations[configIdx];
            const currentType = Object.keys(initialConfig)[0];
            newData.configurations[configIdx] = {
              ...initialConfig,
              [value]: initialConfig[currentType],
            };
          }
        }
      });
      setTempFlow(newProcess);
    },
    [flowIndex, ruleState?.currentRule, tempFlow],
  );
  const handleUpdateCondition = useCallback(
    (key, value, configIdx) => {
      const ruleConditionIndex = localStorage.getItem('ruleConditionIndex');

      ruleState.currentRule?.configurations.map((item, idx) => {
        if (idx === +ruleConditionIndex) {
          const currentType = Object.keys(item)[0];
          return (item[currentType] = item[currentType].map((data, i) =>
            i === configIdx ? {...data, [key]: value} : data,
          ));
        }
        return item;
      });
    },
    [ruleState.currentRule?.configurations],
  );

  const clearFlow = useCallback(() => {
    createFlowProcess(defaultFlowProcess);
    setTempFlow(defaultFlowProcess?.process);
    toast.success('Process cleared!');
    localStorage.removeItem('ruleIndex');
  }, []);

  const resetFlow = useCallback(() => {
    const newProcess = [...tempFlow];
    newProcess[1].forEach((element, index) => {
      if (element.key === ruleState?.currentRule.key) {
        newProcess[1][index] = flowDataReset;
      }
    });

    setRuleState(prev => ({
      ...prev,
      actionName: {},
      actionValue: {},
      selectedField: {},
      selectedOperator: {},
      selectedRuleType: {},
    }));
    setTempFlow(newProcess);
    createFlowProcess({process: newProcess});

    toast.success('Rule cleared successfully');
  }, [ruleState?.currentRule.key, tempFlow]);

  useEffect(() => {
    flowProcess == null && createFlowProcess(defaultFlowProcess);
    !lists && getLists({page: 1, limit: 1000});
  }, [flowProcess, listLoading, lists]);

  useEffect(() => {
    const threshold = tempFlow[2]?.threshold;
    threshold && setRangeValue([threshold?.review, threshold?.block]);
    // if(tempFlow.length){
    //   setRuleState(prev => ({
    //     ...prev,
    //     currentRule: tempFlow[1][flowIndex],
    //   }));
    // }
  }, [flowIndex, tempFlow]);

  const contextState = useMemo(
    () => ({
      get: {
        tempFlow,
        ruleState,
        openModals,
        rangeValue,
        currentFlowData,
        currentSubRuleConditions,
      },
      set: {
        setTempFlow,
        setRuleState,
        setOpenModals,
        setRangeValue,
        setCurrentFlowData,
        setCurrentSubRuleConditions,
      },
      handleDeleteSubRule,
      handleDeleteCondition,
      handleUpdateCondition,
      handleUpdateSubRule,
      processArrayWithTypes,
      clearFlow,
      resetFlow,
    }),
    [
      clearFlow,
      currentFlowData,
      currentSubRuleConditions,
      handleDeleteCondition,
      handleDeleteSubRule,
      handleUpdateCondition,
      handleUpdateSubRule,
      openModals,
      rangeValue,
      resetFlow,
      ruleState,
      tempFlow,
    ],
  );
  return (
    <CreditCheckContext.Provider value={contextState}>
      {children}
    </CreditCheckContext.Provider>
  );
}

export default CreditCheckContextProvider;
