import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
  EmptyState,
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  SearchInput,
  TableLayout,
} from '../../../../components';
import {profileIc} from '../../../../assets/images/images';
import Layout from '../Layout';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import {Constants} from '../../../../helpers';
import {PageContext} from '../../../../helpers/context';
import {useQuery} from 'react-query';
import {fetchAccounts} from '../../../../requests/queries/flows';
import AppliedFilters from 'components/AppliedFilters';

function Accounts() {
  const [currentPage, setCurrentPage] = useState('1');
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [filterProps, setFilterProps] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const {slug} = useParams();

  const params = useMemo(
    () => ({project_id: slug, limit, page: currentPage}),
    [currentPage, limit, slug],
  );
  const {data: accountsData, isLoading} = useQuery(
    ['users-accounts', {params}],
    () => fetchAccounts({params}),
  );

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const ACCOUNTS = useMemo(
    () => (!isLoading && accountsData && accountsData?.rows) || [],
    [accountsData, isLoading],
  );

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    setOpenFilter(true);
    setFiltersApplied(true);
  };
  const handleFilter = () => {
    navigate('');
    setCurrentPage(1);
    setOpenFilter(true);
    setFiltersApplied(false);
  };

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <Layout pageTitle="Accounts" pageIcon={profileIc}>
      <section>
        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          handleFilter={handleFilter}
          resetUrl={handleResetFilter}
          onFilterStateChange={handleFilterStateChange}
          filterOptions={[]}
        />
        <div className="flex items-center justify-between py-7 bg-white -ml-12 pl-12 -mr-4 xl:mr-[-76px] pr-7">
          <div className="flex items-center gap-4 flex-wrap">
            <h2 className="hidden text-lg font-medium text-grey sm:block">
              Accounts
            </h2>
            {filtersApplied && filterProps && (
              <AppliedFilters {...filterProps} isMainPage={true} />
            )}
          </div>

          <div className="flex items-center gap-4">
            <SearchInput onChange={() => []} className="outline-white80" />

            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
          </div>
        </div>

        <TableLayout negativeMargins negativeRightMargin="-mr-4 xl:-mr-[76px]">
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Name</span>
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Email</span>
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Phone</span>
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Registration time</span>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {isLoading ? (
                  <Loader height={65} />
                ) : !isLoading && ACCOUNTS?.length === 0 ? (
                  <EmptyState body="No account(s) found" noBtn height={65} />
                ) : null}
              </td>
            </tr>
          </tbody>

          <tbody className="text-sm bg-white cursor-pointer">
            {ACCOUNTS &&
              ACCOUNTS?.map((account, i) => (
                <tr
                  className="border-b border-grey60"
                  key={i}
                  onClick={() =>
                    navigate(
                      Constants.PAGES_URL.FLOWS_ACCOUNT_TRANSACTIONS.replace(
                        ':slug',
                        slug,
                      ),
                      {
                        state: {
                          ...account,
                          name: account?.user_name,
                        },
                      },
                    )
                  }
                >
                  <td className="p-4 capitalize pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {account?.user_name || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {account?.user_email || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {account?.user_mobile || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(account?.createdAt).format('Do MMM YYYY hh:mm') ||
                      '-'}
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>

        {!isLoading && ACCOUNTS.length > 0 && (
          <div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={setLimit}
                total={accountsData.count}
                length={ACCOUNTS.length}
                limit={limit}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Pagination
                total={Math.ceil(accountsData.count / limit)}
                current={+currentPage}
                onPageChange={activePage => {
                  localStorage.setItem('page', String(activePage));
                  pageClick(activePage);
                }}
              />
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
}

export default Accounts;
