import React, {useMemo} from 'react';
import {v4} from 'uuid';
import {useSelector} from 'react-redux';
import {CreditCheckContext} from '../../../../../helpers/context/CreditCheckContext';
import {isNestedObjectTruthy} from '../../../../../helpers/isNestedObjectTruthy';

export const useLogic = ({
  createFlowProcess,
  addNewTask,
  updateExistingTask,
  saveFlowProcess,
}) => {
  const uniqueID = v4();
  const taskId = localStorage.getItem('taskId');
  const user = useSelector(state => state?.auth?.user);
  const [buttonStyle, setButtonStyle] = React.useState({});
  const flowIndex = localStorage.getItem('ruleIndex');
  const [hoveredIndex, setHoveredIndex] = React.useState(-1);
  const [action, setAction] = React.useState();
  const cardRef = React.useRef(null);

  const [errors, setError] = React.useState({
    end: false,
    [taskId]: false,
  });
  const baseConditions = useMemo(
    () => ({value: '', fact: '', parent: '', operator: ''}),
    [],
  );

  const {
    set: {
      setTempFlow,
      setOpenModals,
      setRuleState,
      setCurrentSubRuleConditions,
    },
    get: {tempFlow, ruleState},
    processArrayWithTypes,
  } = React.useContext(CreditCheckContext);
  const ruleType = ruleState.selectedRuleType[action]?.value;
  const currentRuleCondition =
    ruleState.selectedRuleConditionType[action]?.value;

  const flowData = React.useMemo(
    () => ({
      type: ruleType || 'low_risk',
      key: uniqueID,
      name: ruleState.actionName[action] || '',
      currentRuleCondition: currentRuleCondition || 'all',
      risk_score: ruleState.currentRule.risk_score || 0,
      configurations: ruleState?.currentRule?.configurations || [
        {any: [{...baseConditions, id: 1}]},
      ],
      conditions: ruleState?.currentRule?.conditions || {
        any: [{any: [{...baseConditions, id: 1}]}],
      },
    }),
    [
      ruleType,
      uniqueID,
      ruleState.actionName,
      ruleState.currentRule.risk_score,
      ruleState.currentRule?.configurations,
      ruleState.currentRule?.conditions,
      action,
      currentRuleCondition,
      baseConditions,
    ],
  );

  const handleMouseEnter = React.useCallback(
    (e, index) => {
      if (hoveredIndex !== index) {
        setHoveredIndex(index);
        const ADD_BUTTON_TO_POSITION = 30;
        const bounding = e.currentTarget.getBoundingClientRect();
        setButtonStyle({
          top: bounding.y - bounding.top + ADD_BUTTON_TO_POSITION,
          position: 'absolute',
          cursor: 'pointer',
          alignSelf: 'center',
          right: 16,
        });
      }
    },
    [hoveredIndex],
  );
  const handleSaveProcess = React.useCallback(async () => {
    await saveFlowProcess(tempFlow);
  }, [saveFlowProcess, tempFlow]);
  const handleMouseLeave = React.useCallback(() => {
    setHoveredIndex(-1);
    setOpenModals(prev => ({
      ...prev,
      lastRule: false,
      steps: false,
    }));
  }, [setOpenModals]);
  const handleAddSteps = () => {
    setOpenModals(prev => ({
      ...prev,
      lastRule: true,
      steps: true,
    }));
    setRuleState(prev => ({...prev, actionName: {}, currentRule: {}}));
  };
  const handleAddSubRule = () => {
    const newProcess = [...tempFlow];
    newProcess[1].forEach(element => {
      if (element.key === ruleState.currentRule.key) {
        const newCondition = {
          any: [
            {
              ...baseConditions,
              ...(ruleState.currentRule?.configurations?.length >= 1 &&
              ruleState.conditionType[action]?.value
                ? {type: ''}
                : {}),
              id: ruleState.currentRule?.configurations?.length + 1 || 1,
            },
          ],
        };
        newProcess[1][flowIndex] = {
          ...ruleState.currentRule,
          configurations: ruleState.currentRule?.configurations &&
            Array.isArray(ruleState.currentRule?.configurations) && [
              ...(ruleState.currentRule?.configurations || []),
              newCondition,
            ],
        };

        setRuleState(prev => ({
          ...prev,
          currentRule: newProcess[1][flowIndex],
        }));
      }
    });
    setTempFlow(newProcess);
  };
  const handleAddCondition = () => {
    const newCondition = {
      ...baseConditions,
      ...(ruleState.currentRule?.configurations?.length >= 1 &&
      ruleState.conditionType[action]?.value
        ? {type: ''}
        : {}),
      id: ruleState.currentRule?.configurations?.length + 1 || 1,
    };
    setCurrentSubRuleConditions(prev => [...prev, newCondition]);

    const ruleConditionIndex = localStorage.getItem('ruleConditionIndex');
    ruleState.currentRule?.configurations.map((item, idx) => {
      if (idx === +ruleConditionIndex) {
        const currentType = Object.keys(item)[0];
        return item[currentType].push(newCondition);
      }
      return item;
    });
  };
  const handleAddActionStep = React.useCallback(() => {
    setOpenModals({actionRule: false, lastRule: false, steps: false});
    setHoveredIndex(-1);
    const newProcess = [...tempFlow];
    const index = hoveredIndex;
    localStorage.removeItem('taskId');

    if (index === -1) {
      newProcess[1].push({
        ...flowData,
      });
    } else {
      newProcess[1].splice(index, 0, {
        ...flowData,
      });
    }
    setTimeout(() => {
      if (newProcess[1].length) {
        cardRef.current.click();
      }
    }, 500);
    setTempFlow(newProcess);
    setError({...errors, [flowIndex]: !isNestedObjectTruthy(flowData)});
  }, [
    setOpenModals,
    tempFlow,
    hoveredIndex,
    setTempFlow,
    errors,
    flowIndex,
    flowData,
  ]);

  const applyFlow = React.useCallback(
    async ruleName => {
      const newProcess = [...tempFlow];
      newProcess[1].forEach(element => {
        if (element.key === ruleState.currentRule.key) {
          newProcess[1][flowIndex] = {
            ...flowData,
            ...ruleState.currentRule,
            configurations: [
              ...((ruleState.currentRule?.configurations &&
                ruleState.currentRule?.configurations) ||
                []),
            ],
          };
        }
      });
      setTempFlow(newProcess);
      createFlowProcess({process: newProcess});

      // eslint-disable-next-line no-unused-vars
      const {
        key,
        id,
        __v,
        flow,
        updatedAt,
        createdAt,
        type,
        name,
        currentRuleCondition,
        ...rest
      } = {
        ...ruleState.currentRule,
        ...flowData,
      };

      const config = processArrayWithTypes(rest?.configurations);
      delete rest?.configurations;
      delete rest?.period;
      delete config?.type;
      const action = {
        low_risk: 'ALLOW',
        high_risk: 'BLOCK',
        medium_risk: 'REVIEW',
      }[type];

      const ruleData = {
        event: {
          ...rest.event,

          type,
          params: {
            ...rest?.event?.params,
            message: name || ruleName,
            action,
            risk_score: rest?.risk_score,
          },
        },
        name: name || ruleName,
        conditions: {[currentRuleCondition]: config},
      };

      const taskId = id || localStorage.getItem('taskId');
      if (taskId === 'undefined') {
        await addNewTask({
          // key,
          ...ruleData,
          created_by: user?.id,
        });
      } else {
        await updateExistingTask({
          id: taskId,
          ...ruleData,
          updated_by: user?.id,
        });
      }
      setOpenModals({openRuleEditorModal: false});
      setError({...errors, [flowIndex]: !isNestedObjectTruthy(flowData)});
    },
    [
      tempFlow,
      setTempFlow,
      createFlowProcess,
      ruleState.currentRule,
      flowData,
      processArrayWithTypes,
      setOpenModals,
      errors,
      flowIndex,
      addNewTask,
      user?.id,
      updateExistingTask,
    ],
  );

  return {
    handleAddSteps,
    handleAddSubRule,
    handleAddCondition,
    handleAddActionStep,
    handleMouseLeave,
    handleMouseEnter,
    handleSaveProcess,
    hoveredIndex,
    applyFlow,
    buttonStyle,
    setError,
    flowData,
    setAction,
    action,
    cardRef,
    errors,
    taskId,
  };
};
