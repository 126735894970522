/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import {connect} from 'react-redux';
import {getLighterShade} from '../colorUtils';
import {InfoIcon} from '../InfoIcon';
import {redIcon} from 'assets/images/images';

const UploadOtherIds = ({
  widgets: {widgetPages, widgetScreen, singleApp},
  customButton,
}) => {
  const baseColor = singleApp?.color || singleApp?.color_code || '';
  const lighterShade = getLighterShade(baseColor, 90);

  const widgetData =
    widgetPages &&
    widgetPages?.find(
      widget =>
        widget?.page === 'additional-document' &&
        widget?.pageId === widgetScreen?.pageId,
    );

  const lighterBackgroundStyle = {
    backgroundColor: lighterShade,
  };

  const textColor = {
    color: baseColor,
  };

  return (
    <>
      <div
        className="mx-auto w-fit px-2 pt-2 rounded-[10px] mb-4 text-center"
        style={lighterBackgroundStyle}
      >
        <span
          className="text-sm font-medium inline-flex items-center font-inter"
          style={textColor}
        >
          <InfoIcon backgroundColor={baseColor} />
          To continue this on your smartphone.
          <span className="font-semibold ml-[2px]">Click here</span>
        </span>
      </div>

      <header className="flex flex-col items-center mt-3">
        <img src={singleApp?.logo || ''} alt="" className="max-h-10" />
      </header>

      <p className="text-center text-base font-medium mt-4 text-[#1B2A4E] mb-4">
        Upload document
      </p>

      <div className="mx-auto w-fit px-2 pt-2 bg-[#FFDDE1] rounded-[10px] mb-4 text-center">
        <span className="text-sm font-medium text-[#BB371A] inline-flex gap-2 items-center font-inter">
          <img src={redIcon} />
          Please make sure you are in a well lit environment
        </span>
      </div>

      <div className="h-[289px] relative bg-black rounded-lg border-[4px] p-3 mx-auto border-[#96a9c3] max-w-[450px] w-full">
        <div className="border-[2px] border-dashed border-[#198b7b] rounded-lg h-full flex items-center justify-center">
          <div className="absolute bottom-[-20px] left-0 right-0 mx-auto w-fit px-7 py-[10px] bg-[#1E2254] rounded-[10px] text-center">
            <span className="text-sm font-medium text-white inline-flex gap-2 items-center font-inter">
              Capture the front of your ID{' '}
            </span>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col justify-center items-center ">
        <p className="mt-10 text-center text-body text-sm max-w-[326px]">
          {widgetData?.config?.instruction}
        </p>
        {customButton}
      </div>
    </>
  );
};

export default connect(state => state, null)(UploadOtherIds);
