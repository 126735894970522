/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from 'react';
import {connect} from 'react-redux';

const BusinessData = ({widgets: {widgetPages, widgetScreen}}) => {
  const widgetData =
    widgetPages &&
    widgetPages?.find(
      widget =>
        widget?.page === 'business-data' &&
        widget?.pageId === widgetScreen?.pageId,
    );

  const data = useMemo(
    () => ({
      ...widgetData?.config,
    }),
    [widgetData?.config?.cac, widgetData?.config?.tin],
  );

  const companyTypeOptions = [
    {value: 'BUSINESS_NAME', label: 'Business Name'},
    {value: 'COMPANY', label: 'Company'},
    {value: 'INCORPORATED_TRUSTESS', label: 'Incorporated Trustess'},
    {value: 'LIMITED_PARTNERSHIP', label: 'Limited Partnership'},
    {
      value: 'LIMITED_LIABILITY_PARTNERSHIP',
      label: 'Limited Liability Partnership',
    },
  ];

  return (
    <>
      <header className="flex flex-col items-center mt-2">
        <h4 className="mt-4 font-medium text-[#1B2A4E] font-inter text-base">
          Business Data
        </h4>
      </header>
      <form className="w-full max-w-sm mt-10 mb-5">
        <label
          htmlFor="rc_number"
          className="mb-1 text-[#1B2A4E] text-sm font-normal"
        >
          RC Number
          <input
            type="text"
            id="rc_number"
            value="12345"
            readOnly
            className="mt-2 bg-[#F6F6F8] text-[#8D8D91] text-sm border-none outline-none rounded"
            placeholder="Enter RC Number"
          />
        </label>
        <label
          htmlFor="company_type"
          className="mt-10 mb-1 block text-[#1B2A4E] text-sm font-normal"
        >
          Company Type
          <select
            id="company_type"
            value={
              companyTypeOptions.find(option => option.value === data?.type)
                ?.value || ''
            }
            onChange={e => {
              const selectedOption = companyTypeOptions.find(
                option => option.value === e.target.value,
              );
            }}
            className="mt-2 bg-[#F6F6F8] text-[#8D8D91] text-sm border-none outline-none rounded"
          >
            <option value="" disabled>
              Select Company Type
            </option>
            {companyTypeOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </label>
      </form>
    </>
  );
};

export default connect(state => state, null)(BusinessData);
