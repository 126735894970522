import pdfMake from 'pdfmake/build/pdfmake';
import {formatNumberToCurrency} from '../../../helpers/formatNumberToCurrency';
import moment from 'moment';
import {dojahIcon} from 'assets/images/images';

const vfs = {
  Roboto: {
    normal: '',
    bold: '',
    italics: '',
    bolditalics: '',
  },
};

pdfMake.vfs = vfs;

pdfMake.fonts = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

export const generateBillingPDFStructure = ({data, currency}) => {
  const header = [
    {
      columns: [
        {
          image: dojahIcon,
          width: 28,
          height: 23,
          alignment: 'left',
          margin: [0, 0, 0, 10],
        },
        {
          stack: [
            {
              text: 'Generated by Company',
              style: 'generatedBy',
              alignment: 'right',
              color: '#4A4A68',
              italics: true,
            },
            {
              text: moment().format('Do MMM, YYYY h:mmA'),
              style: 'generatedDate',
              alignment: 'right',
              color: '#727272',
              italics: true,
            },
          ],
          alignment: 'right',
          margin: [0, 0, 0, 10],
        },
      ],
      width: '*',
    },
    {text: '\n'},

    {
      stack: [
        {
          text: 'Invoice Date',
          style: 'invoiceDateLabel',
        },
        {
          text: moment(data[0].createdAt).format('DD-MM-YYYY'),
          style: 'invoiceDate',
        },
      ],
    },

    {
      columns: [
        {
          width: 120,
          stack: [
            {text: 'From', style: 'sender'},
            {text: 'Dojah Technologies Limited', style: 'companyName'},
            {
              text: 'F1 First Floor Lagos City Mall, Victoria Island, Onikan Lagos. 01 Nigeria',
              style: 'companyAddress',
            },
          ],
          margin: [0, 0, 0, 24],
        },
      ],
    },
    {text: '\n'},

    {
      text: `Invoice ${data[0].invoice_number}`,
      style: 'invoiceNumber',
    },
    {text: '\n'},
  ];

  const tableBody = [
    [
      {text: 'Description', style: 'tableHeader'},
      {text: 'Quantity', style: 'tableHeader'},
      {text: 'Unit Price', style: 'tableHeader'},
      {text: 'Taxes', style: 'tableHeader'},
      {text: 'Amount', style: 'tableHeader'},
    ],
    ...data.map(billing => [
      {text: 'Wallet Top up', style: 'tableRow'},
      {text: '1.00', style: 'tableRow'},
      {
        text: new Intl.NumberFormat().format(billing.amount / 100),
        style: 'tableRow',
      },
      {text: '1', style: 'tableRow'},
      {
        text: formatNumberToCurrency(billing.amount / 100, currency),
        style: 'tableRowAmount',
      },
    ]),
    [
      {text: '', colSpan: 3},
      {},
      {},
      {text: 'Subtotal', style: 'subtotalLabel'},
      {
        text: formatNumberToCurrency(data[0].amount / 100, currency),
        style: 'subtotalAmount',
      },
    ],
    [
      {text: '', colSpan: 3},
      {},
      {},
      {text: 'Total', style: 'totalLabel'},
      {
        text: formatNumberToCurrency(data[0].amount / 100, currency),
        style: 'totalAmount',
      },
    ],
  ];

  const footer = [
    {
      text: 'Kindly make payments to',
      style: 'paymentInstructions',
      margin: [0, 38, 0, 10],
    },

    {text: 'Bank Name', style: 'bankNameLabel', margin: [0, 0, 0, 4]},

    {text: 'Globus Bank', style: 'bankNameValue', margin: [0, 0, 0, 12]},

    {text: 'Account Name', style: 'accountNameLabel', margin: [0, 0, 0, 4]},

    {
      text: 'Dojah Technologies Limited',
      style: 'accountNameValue',
      margin: [0, 0, 0, 12],
    },

    {text: 'Account Number', style: 'accountNumberLabel', margin: [0, 0, 0, 4]},

    {text: '1000110933', style: 'accountNumberValue'},
  ];

  const content = [
    ...header,
    {
      table: {
        headerRows: 1,
        widths: ['40%', '15%', '15%', '15%', '15%'],
        body: tableBody,
      },
      layout: {
        hLineWidth: (i, node) => {
          if (i === 0) {
            return 0;
          }
          return 0.5;
        },
        vLineWidth: (i, node) => 0,
        hLineColor: (i, node) => '#EBEBEB',
        paddingTop: (i, node) => 8,
        paddingBottom: (i, node) => 8,
        paddingLeft: (i, node) => 8,
        paddingRight: (i, node) => 8,
      },
    },
    ...footer,
  ];

  return content;
};

export const generateBillingPDF = (data, currency, userName, fileName) => {
  const content = generateBillingPDFStructure({data, currency});

  const documentDefinition = {
    defaultStyle: {
      fontSize: 8,
    },
    content,
    styles: {
      generatedBy: {
        fontSize: 10,
        alignment: 'left',
        margin: [0, 0, 0, 5],
      },
      generatedDate: {
        fontSize: 10,
        alignment: 'right',
        margin: [0, 0, 0, 10],
      },
      sender: {
        fontSize: 8,
        alignment: 'left',
        margin: [0, 0, 0, 4],
      },
      invoiceDateLabel: {
        color: '#868C98',
        fontSize: 12,
        margin: [0, 0, 0, 5],
      },
      companyName: {
        fontSize: 8,
        bold: true,
        alignment: 'left',
        color: '#0B1C56',
        margin: [0, 0, 0, 2],
      },
      companyAddress: {
        fontSize: 8,
        alignment: 'left',
        color: '#858DAA',
      },
      invoiceNumber: {
        fontSize: 14,
        alignment: 'left',
        margin: [0, 0, 0, 12],
        color: '#3F7CDB',
      },
      invoiceDate: {
        fontSize: 12,
        alignment: 'left',
        margin: [0, 0, 0, 29],
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: '#868C98',
        height: 8,
        padding: [8, 8, 8, 8],
      },
      tableRow: {
        fontSize: 8,
        color: '#4F555F',
        padding: [8, 8, 8, 8],
      },
      tableRowAmount: {
        fontSize: 8,
        color: 'black',
        padding: [8, 8, 8, 8],
      },
      subtotalLabel: {
        fontSize: 8,
        color: '#4F555F',
        alignment: 'right',
        bold: true,
        margin: [0, 0, 30, 0],
      },
      subtotalAmount: {
        fontSize: 8,
        color: 'black',
        bold: true,
      },
      totalLabel: {
        fontSize: 8,
        color: '#000000',
        alignment: 'right',
        bold: true,
        margin: [0, 0, 30, 0],
      },
      totalAmount: {
        fontSize: 8,
        color: 'black',
        bold: true,
      },
      paymentInstructions: {
        fontSize: 11,
        alignment: 'left',
        margin: [0, 0, 0, 10],
        color: '#444444',
      },
      bankNameLabel: {
        color: '#0B1C56',
        fontSize: 8,
      },
      accountNumberLabel: {
        color: '#0B1C56',
        fontSize: 8,
      },
      accountNameLabel: {
        color: '#0B1C56',
        fontSize: 8,
      },
      bankNameValue: {
        color: '#858DAA',
        fontSize: 8,
      },
      accountNumberValue: {
        color: '#858DAA',
        fontSize: 8,
      },
      accountNameValue: {
        color: '#858DAA',
        fontSize: 8,
      },
    },
  };

  try {
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.download(`${fileName}.pdf`.toLowerCase());
  } catch (error) {
    console.error('PDF Generation Error:', error);
    throw new Error(`PDF generation failed: ${error.message}`);
  }
};
