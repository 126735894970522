import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {connect} from 'react-redux';
import * as Actions from '../store/actions';
import {closeModal} from '../assets/images/images';

function Example({
  children,
  show = false,
  onClose,
  extraModalClasses = '',
  modalBg = 'bg-white',
  modalPosition = 'items-center justify-center',
  modalPadding = 'p-6',
  modalTitle,
  enterFrom,
  leaveFrom,
  leaveTo,
  changeLookupPage,
  noBottomPadding = false,
  borderRadius = 'rounded-lg',
  pointerEventsNone,
  overlayBackground = '',
  hideOnSmallerDevices,
  customHeadingSize = false,
  noHidden,
}) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-40 ${
          hideOnSmallerDevices ? 'hidden md:block' : ''
        }`}
        onClose={() => {
          changeLookupPage(1);
          onClose();
        }}
      >
        <div
          className={`flex ${modalPosition} ${modalPadding} ${
            overlayBackground
              ? 'bg-[#0F0F19] bg-opacity-70 backdrop-blur-sm'
              : ''
          } text-center h-full `}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={`fixed inset-0 transition-opacity bg-black bg-opacity-25 ${
                pointerEventsNone ? 'pointer-events-none' : ''
              } `}
            />
          </Transition.Child>

          <span
            // className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            // className='-translate-y-40'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom={`${
              enterFrom
                ? enterFrom
                : 'opacity-0 translate-y-20 sm:translate-y-0 sm:translate-x-20'
            }`}
            enterTo="opacity-100 translate-y-0 sm:translate-y-0 sm:translate-x-0 sm:scale-100"
            leave="ease-out duration-300"
            leaveFrom={`${
              leaveFrom
                ? leaveFrom
                : 'opacity-100 translate-y-0 sm:translate-x-0 sm:scale-100'
            }`}
            leaveTo={`${
              leaveTo
                ? leaveTo
                : 'opacity-0 translate-y-20 sm:translate-y-0 sm:translate-x-20 sm:scale-95'
            }`}
          >
            <div
              className={`inline-block  ${
                noHidden ? '' : 'overflow-hidden'
              }  w-full ${
                modalBg === 'bg-transparent' ? '' : 'shadow-xl bg-white'
              } ${borderRadius} transition-all transform sm:p-0 sm:w-full ${extraModalClasses}`}
            >
              {modalTitle ? (
                <div
                  className={`py-6 px-[19px] sm:px-6 ${
                    noBottomPadding ? 'sm:pt-12 sm:pb-0' : 'sm:py-12'
                  }`}
                >
                  <header className="flex items-center justify-between">
                    <h3
                      className={`font-medium text-grey ${
                        customHeadingSize ? 'text-lg' : 'text-base'
                      }`}
                    >
                      {modalTitle}
                    </h3>
                    <button
                      onClick={() => {
                        changeLookupPage();
                        onClose(false);
                      }}
                    >
                      <img src={closeModal} alt="" width={24} height={24} />
                    </button>
                  </header>
                  {children}
                </div>
              ) : (
                children
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default connect(null, Actions)(Example);
