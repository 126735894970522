/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {DEFAULT_PAGES} from '../../routes/EasyOnboard/defaultPages';
import {useSelector} from 'react-redux';
import {calculateWidgetCost} from '../getWidgetCost';

export const EasyOnboardContext = createContext(null);

const initialWidgetState = {
  name: '',
};
const initialQuestionData = {
  text: '',
  type: 'text',
};
function EasyOnboardContextProvider({children}) {
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [title, setTitle] = useState('');
  const [currentQuestions, setCurrentQuestions] = useState(initialQuestionData);

  const [widgetData, setWidgetData] = useState(initialWidgetState);
  const [trackChanges, setTrackChanges] = useState(false);
  const {defaultPages, singleWidget, widgetPages, fraudRules} = useSelector(
    state => state.widgets,
  );
  const [totalCost, setTotalMinMaxCost] = useState({});
  const [questions, setQuestions] = useState([]);

  const [indemnity, setIndemnity] = useState(false);
  const [governmentData, setGovernmentData] = useState(false);
  const [businessData, setBusinessData] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [selfie, setSelfie] = useState(false);
  const [email, setEmail] = useState(false);
  const [address, setAddress] = useState(false);
  const [question, setQuestion] = useState(false);
  const [businessID, setBusinessID] = useState(false);
  const [governmentId, setGovernmentId] = useState(false);
  const [uploadOtherIds, setUploadOtherIds] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [detectWithLiveness, setDetectWithLiveness] = useState({});
  const [configDisclaimerModal, setConfigDisclaimerModal] = useState(false);

  const handleOpenModal = useCallback(questions => {
    const newQuestion = {
      ...initialQuestionData,
      id: questions?.length + 1,
    };
    setCurrentQuestions({...newQuestion});
    setQuestions([...questions, newQuestion]);
    setTitle(title || singleWidget?.config?.title);
    setQuestion(prev => !prev);
    setEditingQuestion(null);
  }, []);

  const openQuestionModal = useCallback(() => {
    handleOpenModal(questions);
  }, [questions]);
  const openIndemnityModal = useCallback(() => {
    setIndemnity(prev => !prev);
  }, []);
  const openGovernmentDataModal = useCallback(() => {
    setGovernmentData(prev => !prev);
  }, []);
  const openBusinessModal = useCallback(() => {
    setBusinessData(prev => !prev);
  }, []);
  const openPhoneNumberModal = useCallback(() => {
    setPhoneNumber(prev => !prev);
  }, []);
  const openSelfieModal = useCallback(() => {
    setSelfie(prev => !prev);
  }, []);
  const openEmailModal = useCallback(() => {
    setEmail(prev => !prev);
  }, []);
  const openAddressModal = useCallback(() => {
    setAddress(prev => !prev);
  }, []);
  const openGovernmentIdModal = useCallback(() => {
    setGovernmentId(prev => !prev);
  }, []);
  const openUploadOtherIdsModal = useCallback(() => {
    setUploadOtherIds(prev => !prev);
  }, []);
  const openSignatureModal = useCallback(() => {
    setSignatureModal(prev => !prev);
  }, []);
  const openBusinessIdModal = useCallback(() => {
    setBusinessID(prev => !prev);
  }, []);
  const openConfigDisclaimerModal = useCallback(() => {
    setConfigDisclaimerModal(prev => !prev);
  }, []);

  const getSettings = useCallback(
    key => {
      switch (key) {
        case 'custom-questions':
          return () => openQuestionModal();
        case 'business-id':
          return () => openBusinessIdModal();
        case 'business-data':
          return () => openBusinessModal();
        case 'government-data':
          return () => openGovernmentDataModal();
        case 'phone-number':
          return () => openPhoneNumberModal();
        case 'selfie':
          return () => openSelfieModal();
        case 'address':
          return () => openAddressModal();
        case 'email':
          return () => openEmailModal();
        case 'id':
          return () => openGovernmentIdModal();
        case 'additional-document':
          return () => openUploadOtherIdsModal();
        case 'signature':
          return () => openSignatureModal();
        case 'indemnity':
          return () => openConfigDisclaimerModal();

        default:
          break;
      }
    },
    [
      openQuestionModal,
      openIndemnityModal,
      openGovernmentDataModal,
      openPhoneNumberModal,
      openSelfieModal,
      openEmailModal,
      openGovernmentIdModal,
      openUploadOtherIdsModal,
      openAddressModal,
      openBusinessModal,
      openSignatureModal,
      openBusinessIdModal,
      openConfigDisclaimerModal,
    ],
  );

  const PAGES_DATA = useMemo(
    () =>
      [...((defaultPages && defaultPages) || DEFAULT_PAGES)]?.map(data => {
        if (data?.config) {
          return {
            ...data,
            config: {
              ...data.config,
              ...singleWidget?.config,
            },
            settings: getSettings(data?.page),
          };
        }
        return data;
      }),
    [defaultPages, singleWidget, getSettings],
  );

  const [items, setItems] = useState([...PAGES_DATA]);
  function updateDataAWithConfigFromDataB(dataA, dataB) {
    for (let i = 0; i < dataA.length; i++) {
      const pageId = dataA[i].pageId;
      const matchingPage = dataB.find(page => page.pageId === pageId);
      if (dataA[i].config && matchingPage?.page === dataA[i]?.page) {
        dataA[i].config = matchingPage.config;
      }
    }
    return dataA;
  }
  useEffect(() => {
    const currentPages = updateDataAWithConfigFromDataB(
      [...PAGES_DATA],
      [...widgetPages],
    );
    setItems(currentPages);
    setDetectWithLiveness({
      glassesCheck: detectWithLiveness?.glassesCheck || false,
      brightnessThreshold: detectWithLiveness?.brightnessThreshold || 40,
    });
    localStorage.setItem('pages', JSON.stringify(currentPages));
    const totalCostMinMax = calculateWidgetCost(widgetData, currentPages);
    setTotalMinMaxCost(totalCostMinMax);
    if (singleWidget?.widget?.name) {
      setWidgetData({name: singleWidget?.widget?.name});
    }
  }, [PAGES_DATA, items?.length, widgetPages, calculateWidgetCost, fraudRules]);

  return (
    <EasyOnboardContext.Provider
      value={{
        title,
        items,
        widgetData,
        totalCost,
        detectWithLiveness,
        trackChanges,
        questions,
        editingQuestion,
        currentQuestions,
        initialQuestionData,
        settings: {
          question,
          indemnity,
          governmentData,
          businessData,
          phoneNumber,
          selfie,
          email,
          address,
          governmentId,
          uploadOtherIds,
          signatureModal,
          businessID,
          configDisclaimerModal,
        },
        setCurrentQuestions,
        setTitle,
        setEditingQuestion,
        setIndemnity,
        setQuestion,
        setQuestions,
        setItems,
        setWidgetData,
        setGovernmentData,
        setBusinessData,
        setPhoneNumber,
        setSelfie,
        setEmail,
        setAddress,
        setGovernmentId,
        setUploadOtherIds,
        setSignatureModal,
        setDetectWithLiveness,
        setBusinessID,
        setTrackChanges,
        setConfigDisclaimerModal,
      }}
    >
      {children}
    </EasyOnboardContext.Provider>
  );
}

export default EasyOnboardContextProvider;
