import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import moment from 'moment';
import * as Actions from '../../store/actions';
import {
  PrimaryButton,
  TableLayout,
  Pagination,
  FilterButton,
  PageLimit,
  Filter,
  DashboardLayout,
  EmptyState,
  Loader,
} from '../../components';
import {
  addressVerificationIc,
  corruptedFile,
  eye,
  lookupEmpty,
} from '../../assets/images/images';
import BatchUploadModal from './BatchUploadModal';
import AddressLayout from './AddressLayout';
import ViewBatchModal from './ViewBatchModal';
import {
  filterOutEmptyValues,
  generateQueryParams,
  getStatusColor,
  parseUrlParams,
} from '../../helpers';
import {
  fetchBatchAddressVerifications,
  fetchBatchVerificationCount,
} from '../../requests/queries/address-verification';
import AppliedFilters from 'components/AppliedFilters';

const filterOptions = [
  {
    title: 'Status',
    name: 'status',
    values: [
      {
        label: 'Pending',
        value: 'Pending',
        name: 'Pending',
      },
      {
        label: 'Verified',
        value: 'Verified',
        name: 'Verified',
      },
      {
        label: 'Not Verified',
        value: 'Not Verified',
        name: 'Not Verified',
      },
      {
        label: 'Failed',
        value: 'Failed',
        name: 'Failed',
      },
    ],
  },
];

const tableHeader = [
  {
    label: 'Name of file',
    key: 'file_name',
  },
  {
    label: 'Verification type',
    key: 'model_type',
  },
  {
    label: 'status',
    key: 'status',
  },
  {
    label: 'Date uploaded',
    key: 'date_created',
  },
];

const initialFilters = {
  start: '',
  end: '',
  status: '',
};

function BatchAddressVerification({auth: {userDetails}}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openBatchVerification, setOpenBatchVerification] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [filterProps, setFilterProps] = useState(null);

  const {data: addressVerifications, isLoading} = useQuery(
    ['batch-address-verifications', {page: currentPage, limit, ...filters}],
    () =>
      fetchBatchAddressVerifications({
        page: currentPage,
        limit,
        ...filters,
      }),
    {
      onSuccess: data => {
        toast.success(data.message);
        setFilters({
          ...filters,
          download: undefined,
        });
      },
      onError: error => {
        toast.error(error.response.data.error);
      },
    },
  );

  const openModalWithSelectedData = data => {
    setSelectedData(data);
    setOpenBatchVerification(true);
  };

  const closeModal = () => {
    setOpenBatchVerification(false);
  };

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const data = !isLoading && addressVerifications?.data;

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(pageParam);
    }

    const statusParam = searchParams.get('status');
    if (statusParam) {
      apiParams.status =
        statusParam.charAt(0).toUpperCase() + statusParam.slice(1);
    }

    if (
      !apiParams.start &&
      !apiParams.end &&
      (!apiParams.status || apiParams.status === '')
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }

    if (Object.keys(apiParams).length > 1) {
      setFilters(apiParams);
    }
  }, [limit]);

  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);

    const {status} = filteredOptions;

    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }

    setCurrentPage(1);
    let baseUrl = '';

    if (queryParams) {
      baseUrl += `?${queryParams}`;
    }

    if (shouldDownload) {
      filteredOptions.download = true;
      setFilters({
        ...filters,
        ...filteredOptions,
      });
    } else {
      navigate(baseUrl);
    }

    setFilters(filteredOptions);
    setFiltersApplied(true);
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    setFilters(initialFilters);
    setFiltersApplied(false);
    setOpenFilter(true);
  };

  const {performBatchLookupAndViewHistory, downloadBatchHistory} =
    useMemo(
      () => userDetails?.permissions?.addressVerificationPermissions,
      [userDetails],
    ) || {};

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={addressVerificationIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Address Verification</p>
        </div>
      }
    >
      <div className="flex justify-end">
        <PrimaryButton
          buttonText="Submit Address"
          xPadding="px-[35px]"
          onClick={() => setOpen(!open)}
          className="hidden sm:flex min-w-[180px] h-12"
          disabled={!performBatchLookupAndViewHistory}
          style={{
            cursor: !userDetails?.company?.verified && 'not-allowed',
          }}
        />
      </div>
      <BatchUploadModal open={open} setOpen={setOpen} />
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
        onFilterStateChange={handleFilterStateChange}
      />
      <ViewBatchModal
        open={openBatchVerification}
        setOpen={closeModal}
        selectedData={selectedData}
      />

      <AddressLayout>
        <>
          {!performBatchLookupAndViewHistory ? (
            <EmptyState
              height={45}
              body="Opps!! sorry, you do not have access to this resource"
              src={lookupEmpty}
              noBtn
            />
          ) : data?.length === 0 && !isLoading && !filtersApplied ? (
            <EmptyState
              body="Perform multiple address verification with ease."
              src={lookupEmpty}
              noBtn
              customBtn={
                <PrimaryButton
                  buttonText="Submit Address"
                  className="w-fit"
                  onClick={() => setOpen(!open)}
                  disabled={!performBatchLookupAndViewHistory}
                />
              }
            />
          ) : data?.length === 0 && !isLoading && filtersApplied ? (
            <EmptyState
              body={
                'This filter did not return any data, try using different values.'
              }
              src={corruptedFile}
              noBtn
              customBtn={
                <button
                  onClick={handleResetFilter}
                  className="text-brandBlue p-4 text-sm font-medium"
                >
                  Update preferences
                </button>
              }
            />
          ) : (
            <>
              <div className="flex flex-col pt-5 items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
                <div className="flex items-center gap-4 flex-wrap">
                  <h2 className="text-base font-medium text-grey sm:text-lg">
                    Batch Verification
                  </h2>
                  {filtersApplied && filterProps && (
                    <AppliedFilters {...filterProps} isMainPage={true} />
                  )}
                </div>
                <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
                  <FilterButton
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                  />
                  <div className="flex flex-wrap items-center gap-4 ">
                    <PrimaryButton
                      buttonText="Export"
                      className="w-[100px]"
                      onClick={() => handleFilter(filters, true)}
                      disabled={!downloadBatchHistory}
                    />
                  </div>

                  <div
                    className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80"
                    style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
                  >
                    <PrimaryButton
                      buttonText="Submit Address"
                      xPadding="p-[35px]"
                      onClick={() => setOpen(!open)}
                      className="w-full bottom-2"
                      disabled={!performBatchLookupAndViewHistory}
                    />
                  </div>
                </div>
              </div>

              {isLoading ? (
                <Loader height={50} />
              ) : (
                performBatchLookupAndViewHistory && (
                  <TableLayout negativeMargins>
                    <thead className="text-xs font-semibold uppercase">
                      <tr className="bg-white80">
                        {tableHeader.map(({label, key}) => (
                          <th
                            key={key}
                            className={`${
                              label === 'Name of file'
                                ? 'p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap'
                                : 'p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap'
                            }`}
                          >
                            <div className="flex items-end gap-1 cursor-pointer">
                              <span>{label}</span>
                            </div>
                          </th>
                        ))}

                        <th></th>
                      </tr>
                    </thead>

                    <tbody className="text-sm">
                      {data?.map(verification => (
                        <tr
                          onClick={() =>
                            openModalWithSelectedData(verification)
                          }
                          className="border-b cursor-pointer border-grey60"
                          key={verification.id}
                          onMouseEnter={async () => {
                            await queryClient.prefetchQuery({
                              queryKey: [
                                'batch-verification-count',
                                {id: verification.id},
                              ],
                              queryFn: () =>
                                fetchBatchVerificationCount(verification.id),
                              staleTime: 10 * 1000, // only prefetch if older than 10 seconds
                            });
                          }}
                        >
                          <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                            {verification.file_name}
                          </td>
                          <td className="p-4 pl-0 whitespace-nowrap">
                            <div className="px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full text-batchBlue bg-batchBlue20 w-fit">
                              BATCH UPLOAD
                            </div>
                          </td>
                          <td className="p-4 pl-0 whitespace-nowrap">
                            <div
                              className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full w-fit ${getStatusColor(
                                verification.status,
                              )}`}
                            >
                              {verification.status}
                            </div>
                          </td>
                          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                            {moment(verification?.date_created).format(
                              'Do MMM, YYYY h:mm A',
                            )}
                          </td>
                          <td className="p-4 pl-0 whitespace-nowrap">
                            <div className="flex items-center gap-4">
                              <button
                                title="View"
                                onClick={() =>
                                  openModalWithSelectedData(verification)
                                }
                                className="h-4 aspect-square"
                              >
                                <img src={eye} alt="" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableLayout>
                )
              )}

              {data?.length > 0 && (
                <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
                  <div className="flex items-center text-body text-tiny">
                    <PageLimit
                      onLimitChange={setLimit}
                      total={
                        addressVerifications &&
                        addressVerifications?.totalRecords
                      }
                      length={data?.length}
                      limit={limit}
                    />
                  </div>

                  <div className="mb-8 sm:mb-0">
                    <Pagination
                      total={
                        data &&
                        Math.ceil(addressVerifications?.totalRecords / limit)
                      }
                      current={+currentPage}
                      onPageChange={activePage => {
                        pageClick(activePage);
                        const searchParams = new URLSearchParams(
                          document.location.search,
                        );
                        searchParams.set('page', activePage);
                        const newSearchParams = searchParams.toString();
                        navigate(`?${newSearchParams}`);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </AddressLayout>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(BatchAddressVerification);
