import React, {useCallback} from 'react';
import {Constants} from '../../../helpers';
import {VerificationToggle} from '../../../components';
import {infoRedSm} from '../../../assets/images/images';

const countryIdMap = {
  nigeria: Constants.lookups?.nigeriaId_Onboard,
  kenya: Constants.lookups?.kenyaId_Onboard,
  ghana: Constants.lookups?.ghanaId_Onboard,
  zimbabwe: Constants.lookups?.zimbabweId_Onboard,
  angola: Constants.lookups?.angolaId_Onboard,
  southafrica: Constants.lookups?.southAfricaId_Onboard,
};
function ToggleCountriesData({
  selectedCountry = 'nigeria',
  config,
  handleUpdateConfig,
  setPricing,
  widgetPricing,
  displayPrice,
}) {
  const DATA = useCallback(country => countryIdMap[country.value], []);
  return (
    <>
      <p className="text-left text-body text-xs mt-8 font-medium uppercase">
        Select Government Data to display
      </p>
      <div className="mt-2 flex flex-col justify-between">
        {DATA(selectedCountry)?.map(type => {
          const isBvnAdvancePriceKey = type?.value === 'bvnAdvance';
          const price = isBvnAdvancePriceKey
            ? widgetPricing?.['government-data']?.services?.ng_bvn_advance
            : widgetPricing &&
              widgetPricing?.['government-data']?.services[
                `${CountryType(selectedCountry)}_${type?.value}`.replace(
                  /^(ke|gh|zw|ao|za)-/,
                  '',
                )
              ];
          return (
            <div
              key={type?.value}
              className="flex items-center gap-2 pl-4 pr-2 py-[10px] cursor-pointer"
            >
              <VerificationToggle
                isChecked={config[type?.value]}
                onCheck={value => {
                  handleUpdateConfig(type?.value, value);
                  setPricing(prev => {
                    if (config[type?.value] === false) {
                      return {
                        ...prev,
                        services: {
                          ...{...prev.services, [type?.value]: price / 100},
                        },
                      };
                    } else {
                      delete prev.services[type?.value];
                      return prev;
                    }
                  });
                }}
              />
              <div className="flex justify-between w-40">
                <p className="text-xs leading-[18px] text-body">
                  {type?.label}
                </p>
                <p className="text-xs ml-4 leading-[18px] text-grey20">
                  {displayPrice(price / 100)}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex gap-2 items-start p-2 bg-statusBg-404 rounded">
        <img src={infoRedSm} alt="" width={11} height={11} />
        <p className="text-[8px] text-start text-grey -mb-1 leading-normal">
          The BVN Basic and BVN advance data cannot be added to your
          verification flow simultaneously
        </p>
      </div>
    </>
  );
}

const getMatchedCountries = foundWidget =>
  Constants.lookups.countries2?.filter(country => {
    const countryIdList = countryIdMap[country?.value] || [];
    return countryIdList?.some(
      id =>
        foundWidget?.config[id?.value] !== undefined &&
        foundWidget?.config[id?.value] === true,
    );
  });

const CountryType = country => {
  switch (country?.value) {
    case 'nigeria':
      return 'ng';
    case 'kenya':
      return 'ke';
    case 'ghana':
      return 'gh';
    case 'zimbabwe':
      return 'zw';
    case 'angola':
      return 'ao';
    case 'southafrica':
      return 'za';
  }
};

const ngDefaultConfig = {
  bvn: true,
  dl: true,
  // mobile: true,
  vnin: true,
  nin: true,
  otp: true,
  whatsappOtp: false,
  bvnAdvance: false,
  selfie: false,
  version: 3,
  brightnessThreshold: 40,
  glassesCheck: true,
  flipCamera: false,
};
const countryDefaults = {
  nigeria: ngDefaultConfig,
  ghana: {'gh-dl': true, 'gh-voter': true},
  zimbabwe: {'zw-id': true},
  kenya: {'ke-dl': true, 'ke-passport': true, 'ke-kra': true},
  uganda: {'ug-id': true, 'ug-telco': true},
  angola: {'ao-nin': true},
  southafrica: {'za-id': true},
};
const ALL_PAGES = JSON.parse(localStorage.getItem('pages'));
function deleteTypeIfLivenessFalse(obj) {
  if (
    obj?.liveness === false ||
    !Object.hasOwn(obj, 'liveness') ||
    obj?.liveness === undefined
  ) {
    const {
      type,
      version,
      liveness,
      brightnessThreshold,
      glassesCheck,
      ...updatedObj
    } = obj;
    return {...updatedObj, selfie: false, flipCamera: false};
  }
  return {
    ...obj,
    selfie: true,
    type: obj.type || 'basic',
    version: obj.version || 3,
    flipCamera: obj?.flipCamera,
  };
}
const updateConfig = (newConfig, foundWidget) => {
  const newPages = ALL_PAGES?.map(page => {
    if (page?.page === foundWidget?.page) {
      return {
        ...page,
        config: newConfig,
      };
    }
    return page;
  });
  localStorage.setItem('pages', JSON.stringify(newPages));
};

export {
  getMatchedCountries,
  CountryType,
  ngDefaultConfig,
  deleteTypeIfLivenessFalse,
  updateConfig,
  countryIdMap,
  countryDefaults,
  ToggleCountriesData,
};
