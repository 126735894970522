/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {isObjectEmpty} from '../../../helpers/isObjectEmpty';

const GovernmentData = ({
  widgets: {widgetPages, singleWidget, widgetScreen},
}) => {
  const widgetData =
    widgetPages &&
    widgetPages?.find(
      widget =>
        widget?.page === 'government-data' &&
        widget?.pageId === widgetScreen?.pageId,
    );

  const data = useMemo(
    () => ({
      ...widgetData?.config,
      mobile: widgetData?.config?.mobile,
    }),
    [
      widgetData?.config?.mobile,
      widgetData?.config?.selfie,
      widgetData?.config?.otp,
      widgetData?.config?.bvn,
      widgetData?.config?.vnin,
      widgetData?.config?.dl,
      widgetData?.config?.type,
    ],
  );

  const color = singleWidget?.widget?.app?.color_code;

  return (
    <>
      <header className="flex flex-col items-center mt-2">
        <h4 className="mt-4 font-medium text-[#1B2A4E] font-inter text-base">
          Government Data
        </h4>
      </header>
      <form className="w-full max-w-sm mt-10">
        <label
          htmlFor="address"
          className="mb-1 text-[#1B2A4E] text-sm font-normal"
        >
          Government data
        </label>
        <select
          name=""
          id=""
          className="font-medium text-[#8D8D91] bg-[#F6F6F8] text-sm border-none outline-none rounded"
        >
          {data?.bvn && <option value="">Bank Verification Number</option>}
          {data?.nin && (
            <option value="">National Identification Number</option>
          )}
          {data?.dl && <option value="">Driver&apos;s License</option>}
          {data?.mobile && <option value="">Phone Number</option>}
        </select>
        <label
          htmlFor="id_type"
          className="mt-10 mb-1 text-[#1B2A4E] text-sm font-normal"
        >
          BVN Number
          <input
            type="text"
            id="id_type"
            value="10253665114"
            readOnly
            className="mt-2 text-sm text-[#8D8D91] bg-[#F6F6F8] border-none outline-none rounded"
          />
        </label>
        <label
          htmlFor="verification_type"
          className="mt-10 mb-2 text-[#1B2A4E] text-sm font-normal"
        >
          Verify with
        </label>
        <div className="flex items-center gap-2">
          {(!data || isObjectEmpty(data) || data?.otp || data?.bvnAdvance) && (
            <div className="flex items-center gap-2 w-fit p-3 bg-[#F6F6F8] border-b rounded border-[#ECECEC]">
              <input
                type="radio"
                name="type"
                id="otp"
                checked={true}
                className="w-[13px] h-[13px]"
                onChange={() => []}
              />
              <label htmlFor="otp">OTP (One-time Password)</label>
            </div>
          )}

          {(!data || isObjectEmpty(data) || data?.type) && (
            <div className="flex items-center gap-2 w-fit p-3 bg-[#F6F6F8] border-b rounded border-[#ECECEC]">
              <input
                type="radio"
                name="type"
                id="selfie"
                checked={true}
                className="w-[13px] h-[13px]"
                onChange={() => []}
              />
              <label htmlFor="selfie">
                Take a {data?.type === 'basic' ? 'selfie' : 'video'}
              </label>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default connect(state => state, null)(GovernmentData);
