/* eslint-disable react-hooks/exhaustive-deps */

import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {
  amlScreeningIc,
  eye,
  lookupEmpty,
  backNofill,
} from '../../../assets/images/images';
import {
  DashboardLayout,
  EmptyState,
  Loader,
  PageLimit,
  Pagination,
  PrimaryButton,
  TableLayout,
} from '../../../components';
import {PAGES_URL} from '../../../helpers/constants';
import {AMLScreeningTour} from '../../../components/Tour';
import * as Actions from '../../../store/actions';
import {PageContext, TourContext} from '../../../helpers/context';
import ToggleOngoingMonitoring from '../ToggleOngoingMonitoring';
import BatchAmlModal from './BatchAmlModal';
import BatchAmlResultModal from './BatchAmlResultModal';
import {OverviewCards} from '../../../components/OverviewCards';
import {buildTableCardData} from '../../../helpers/buildTableCardData';

function AmlUserScreeningBatch({
  getBatchAmlHistory,
  getAllApps,
  amlConfigApi,
  auth: {userDetails, updated},
  aml: {loading, configLoading, batchAmlHistory},
  apps: {allApps},
}) {
  const [currentPage, setCurrentPage] = useState('1');
  const [limit, setLimit] = useState(10);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openBatchAml, setOpenBatchAml] = useState(false);
  const [amlResultModal, setAmlResultModal] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [downloaded, setDownloaded] = useState(false);
  const [, setUploadPercentage] = useState(0);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('amllookup');
  }, [setPageName]);

  const appIds =
    useMemo(
      () => allApps?.apps && allApps?.apps?.map(app => app?._id)?.toString(),
      [allApps],
    ) || localStorage.getItem('appIds');

  const calculateUploadProgress = data => {
    let totalRecords = 0;
    let processedRecords = 0;

    data.forEach(item => {
      totalRecords += item.total_records;
      processedRecords += item.processed_records;
    });

    return totalRecords !== 0 ? (processedRecords / totalRecords) * 100 : 0;
  };

  useEffect(() => {
    if (batchAmlHistory && batchAmlHistory.records) {
      const percentage = calculateUploadProgress(batchAmlHistory.records);
      setUploadPercentage(percentage);
    }

    const interval = setInterval(() => {
      if (batchAmlHistory && batchAmlHistory.records) {
        const percentage = calculateUploadProgress(batchAmlHistory.records);
        if (percentage !== 100) {
          getBatchAmlHistory({
            page: currentPage,
            company_id: userDetails?.company?.id,
            limit,
            appIds,
            includeAmlIndividual: true,
          });
          setUploadPercentage(percentage);
        } else {
          clearInterval(interval);
        }
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [
    appIds,
    batchAmlHistory,
    currentPage,
    getBatchAmlHistory,
    limit,
    userDetails?.company?.id,
  ]);

  useEffect(() => {
    getAllApps({});
    appIds &&
      userDetails?.company?.id &&
      getBatchAmlHistory({
        page: currentPage,
        company_id: userDetails?.company?.id,
        limit,
        appIds,
        includeAmlIndividual: true,
      });
  }, [
    getBatchAmlHistory,
    userDetails?.company?.id,
    amlConfigApi,
    limit,
    currentPage,
    getAllApps,
    updated,
    Boolean(appIds?.split(',')?.length),
  ]);

  // to populate with latest data
  useEffect(() => {
    if (selectedHistory && batchAmlHistory && batchAmlHistory.records) {
      const newSelectedHistory = batchAmlHistory.records.find(
        item => item.id === selectedHistory.id,
      );
      if (newSelectedHistory) {
        setSelectedHistory(newSelectedHistory);
      }
    }
  }, [batchAmlHistory, selectedHistory]);

  const calculateStatus = history => {
    const totalRecords = history?.total_records || 0;
    const processedRecords = history?.processed_records || 0;

    const percentage =
      totalRecords !== 0 ? (processedRecords / totalRecords) * 100 : 0;

    if (percentage === 100) {
      return 'COMPLETED';
    } else {
      return 'PROCESSING';
    }
  };

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const DATA = batchAmlHistory?.records;

  const {tourState, updateTourState} = useContext(TourContext);

  const {performBatchIndividualLookup: canPerformAndViewAML} = useMemo(
    () => userDetails?.permissions?.amlScreeningPermissions || {},
    [],
  );
  // hack to fetch back the original data after downloading
  const fetchDataWithoutDownload = () => {
    getBatchAmlHistory({
      page: currentPage,
      company_id: userDetails?.company?.id,
      limit,
      appIds,
      includeAmlIndividual: true,
    });
  };

  const handleDownloadAndFetch = () => {
    setDownloaded(true);
    getBatchAmlHistory({
      page: currentPage,
      company_id: userDetails?.company?.id,
      limit,
      appIds,
      includeAmlIndividual: true,
      download: true,
    });
  };

  useEffect(() => {
    if (downloaded) {
      fetchDataWithoutDownload();
      setDownloaded(false);
    }
  }, [downloaded]);
  // end of hack

  const tableCardData = buildTableCardData({
    count: batchAmlHistory?.totalRecords,
  });

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={amlScreeningIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">AML Screening</p>
        </div>
      }
    >
      {tourState?.aml_screening_tour ? (
        <AMLScreeningTour
          updateTourState={updateTourState}
          DATA={DATA}
          loading={loading}
        />
      ) : null}
      {/*<OngoingMonitoringConfig setOpen={setOnGoing} open={onGoing} />*/}
      <ToggleOngoingMonitoring
        loading={configLoading}
        setOpen={setOpenConfirm}
        open={openConfirm}
      />

      <BatchAmlResultModal
        open={amlResultModal}
        setOpen={setAmlResultModal}
        selectedHistory={selectedHistory}
      />

      <BatchAmlModal open={openBatchAml} setOpen={setOpenBatchAml} />

      <Link
        to={PAGES_URL.AML_USER_SCREENING}
        className="flex items-center gap-2"
      >
        <img src={backNofill} alt="" />
        <span className="text-small text-grey80 font-medium -mb-1">Back</span>
      </Link>
      <div className="flex pt-5 flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
        <h2 className="text-base  relative flex items-center font-medium text-grey sm:text-lg">
          AML Screening
        </h2>
        <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
          <PrimaryButton
            buttonText="Perform AML Lookup"
            xPadding="px-4"
            className="hidden sm:flex"
            onClick={() => {
              setOpenBatchAml(true);
            }}
            disabled={!canPerformAndViewAML}
            title={
              !canPerformAndViewAML
                ? 'You do not have permission to do this'
                : ''
            }
          />
          <PrimaryButton
            buttonText="Export"
            xPadding="px-4"
            className="hidden sm:flex"
            onClick={() => {
              handleDownloadAndFetch();
            }}
          />
          <div className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80">
            <PrimaryButton
              buttonText="Perform AML Lookup"
              xPadding="p-4"
              className="w-full bottom-2"
              onClick={() => {
                setOpenBatchAml(true);
              }}
            />
          </div>
        </div>
      </div>

      <OverviewCards
        cardData={tableCardData}
        overviewLoading={loading}
        className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-1 gap-2 lg:gap-4 flex-wrap my-5`}
      />

      <TableLayout negativeMargins>
        <thead className="text-xs font-semibold uppercase">
          <tr className="bg-white80">
            <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
              name
            </th>
            <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
              AML Status
            </th>
            <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
              Type
            </th>
            <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
              Date created
            </th>{' '}
            <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
              Entity type
            </th>
            <th className="w-[18.69%]" />
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={8}>
              {loading ? (
                <Loader height={45} />
              ) : !loading && DATA?.length === 0 ? (
                <EmptyState
                  height={45}
                  noBtn
                  className="w-full"
                  src={lookupEmpty}
                  body="Perform multiple identity lookup with ease."
                  maxWidth="max-w-[500px]"
                  customBtn={
                    <div className="flex gap-6 mt-2 w-full justify-center">
                      <PrimaryButton
                        buttonText="Perform AML Lookup"
                        onClick={() => {
                          setOpenBatchAml(true);
                        }}
                      />
                    </div>
                  }
                />
              ) : null}
            </td>
          </tr>
        </tbody>

        <tbody className="text-sm bg-white">
          {!loading &&
            DATA?.map((history, i = 0) => (
              <tr
                className={`border-b border-grey60 ${
                  history?.aml_entity_lookup_id &&
                  history?.status === 'available'
                    ? 'cursor-pointer'
                    : history?.status === 'not_availbale'
                    ? 'cursor-pointer'
                    : 'cursor-default'
                }`}
                key={i}
              >
                <td
                  className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body"
                  onClick={() => {
                    setSelectedHistory(history);
                    setAmlResultModal(true);
                  }}
                >
                  {history?.name || ''}
                </td>
                <td
                  className="p-4 pl-0"
                  onClick={() => {
                    setSelectedHistory(history);
                    setAmlResultModal(true);
                  }}
                >
                  <div
                    className={`flex items-center gap-2 uppercase py-1 px-2 text-xs ${
                      calculateStatus(history) === 'COMPLETED'
                        ? 'text-success  bg-statusBg-200'
                        : 'text-brandBlue50  bg-brandBlue40'
                    } font-medium  rounded-full w-fit`}
                  >
                    {calculateStatus(history)}
                  </div>
                </td>
                <td
                  className="min-w-[100px]"
                  onClick={() => {
                    setSelectedHistory(history);
                    setAmlResultModal(true);
                  }}
                >
                  <p className="py-1 px-2 text-xs font-medium w-fit uppercase rounded-full text-success bg-statusBg-200">
                    no-code
                  </p>
                </td>

                <td
                  className="p-4 pl-0 text-tiny text-body"
                  onClick={() => {
                    setSelectedHistory(history);
                    setAmlResultModal(true);
                  }}
                >
                  {moment(history?.date_created).format(
                    'Do, MMM YYYY, hh:mm a',
                  )}
                </td>

                <td
                  className="min-w-[100px]"
                  onClick={() => {
                    setSelectedHistory(history);
                    setAmlResultModal(true);
                  }}
                >
                  <p
                    className={`py-1 px-2 text-xs font-medium w-fit uppercase rounded-full ${
                      history?.entity_type?.toLowerCase() === 'business'
                        ? 'text-brandBlue50  bg-brandBlue40'
                        : 'text-danger  bg-statusBg-500'
                    }`}
                  >
                    {history?.entity_type || 'Individual'}
                  </p>
                </td>

                <td
                  className="p-4 pl-0"
                  onClick={() => {
                    setSelectedHistory(history);
                    setAmlResultModal(true);
                  }}
                >
                  <button
                    className={
                      history?.aml_entity_lookup_id &&
                      history?.status === 'not_available'
                        ? 'cursor-pointer'
                        : history?.status === 'not_availbale'
                        ? 'cursor-pointer'
                        : 'cursor-default'
                    }
                    title={
                      !canPerformAndViewAML
                        ? 'You do not have permission to view'
                        : 'View'
                    }
                    onClick={() => {
                      setSelectedHistory(history);
                      setAmlResultModal(true);
                    }}
                    disabled={!canPerformAndViewAML}
                  >
                    <img src={eye} alt="" width={16} height={16} />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </TableLayout>

      {!loading && batchAmlHistory?.totalRecords > 0 && (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
          <div className="flex items-center text-body text-tiny">
            <PageLimit
              limit={limit}
              onLimitChange={setLimit}
              total={batchAmlHistory && batchAmlHistory?.totalRecords}
              length={batchAmlHistory && batchAmlHistory?.records?.length}
            />
          </div>

          <div className="mb-8 sm:mb-0">
            <Pagination
              total={
                batchAmlHistory &&
                Math.ceil(batchAmlHistory?.totalRecords / limit)
              }
              current={+currentPage}
              onPageChange={activePage => {
                localStorage.setItem('page', String(activePage));
                pageClick(activePage);
              }}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(AmlUserScreeningBatch);
