/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {
  backNofill,
  backWhitefill,
  chevronRight,
  corruptedFile,
  smiley,
  verificationsIc,
} from '../../assets/images/images';
import {
  ButtonLoader,
  DashboardLayout,
  RowData,
  SecondaryButton,
} from '../../components';
import * as Actions from '../../store/actions';
import ToggleOngoingMonitoring from '../AMLScreening/OngoingMonitoringConfig';
import {convertSVGToBase64} from '../../helpers/convertSVGToBase64';
import {generateAmlPDF} from '../AMLScreening/generatePDF';
import {generatePDF as downloadPdf} from '../EasyLookup/generatePDFStructureForEasyLookup';
import {PageContext} from '../../helpers/context';
import moment from 'moment';
import {capitalizeEveryWord, Constants} from '../../helpers';
import Layout from './Layout';

function AMLScreeningResult({
  amlCheck = 1,
  getAMLProfileByName,
  getAMLHistory,
  aml: {amlProfile, amlHistory, loading, configLoading},
  auth: {user, userDetails},
  verifications: {session_summary},
}) {
  const location = window.location.pathname;

  const route = location.split('/')[2];

  const {slug} = useParams();
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [base64URL, setBase64URL] = useState('');

  const [activeRelationships, setActiveRelationships] = useState({});
  const profileId = window.location.pathname?.split('/')?.pop();
  const [profileID, setProfileID] = useState('');
  const [prevIDS, setPreviousIds] = useState([]);
  const appId = localStorage.getItem('appId');
  const oldId = useRef('');

  const VERIFICATION = session_summary?.data?.Verification;

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('amllookup');
  }, [setPageName]);

  function handleActiveRelationships(index) {
    setActiveRelationships(index);
  }
  function setPreviousId(previousId) {
    if (previousId.toName !== undefined) {
      oldId.current = previousId?.toName;
    }
  }
  const newSlug = slug?.includes('=') ? slug?.split('=')[0] : slug;
  const currentId = slug?.includes('=') ? slug?.split('=')[1] : '';

  useEffect(() => {
    if (VERIFICATION?.aml_reference && VERIFICATION?.aml_reference !== '-')
      getAMLHistory({
        id: VERIFICATION?.aml_reference,
        verification: true,
        appId,
        slug: newSlug,
        navigate,
        selectedProfileId: currentId,
      });
  }, [appId, VERIFICATION?.aml_reference, getAMLHistory, navigate, currentId]);

  const PROFILES = amlProfile?.entity?.result;
  const PROFILE = useMemo(
    () => [
      {
        leftText: 'Country',
        rightText: PROFILES?.countryName || '-',
      },
      {leftText: 'Category', rightText: PROFILES?.entryCategory || '-'},
      {leftText: 'Subcategory', rightText: PROFILES?.subCatDesc || '-'},
      {leftText: 'Date of birth', rightText: PROFILES?.dob || '-'},
      {leftText: 'Place of birth', rightText: PROFILES?.pob || '-'},
      {leftText: 'Risk Level', rightText: PROFILES?.riskLevel || '-'},
    ],
    [PROFILES],
  );

  const newData = useMemo(
    () =>
      !loading &&
      PROFILES?.relationships?.find(profile => profile?.toName === profileID),
    [PROFILES, loading, profileID],
  );
  useEffect(() => {
    newData && setActiveRelationships(newData);
  }, [newData]);

  const entityResults =
    amlHistory?.entity?.profile_ids || amlHistory?.entity.MatchResults;

  const person = entityResults?.find(
    person => (person?.profile_id || person?.ProfileId) === slug,
  );

  useEffect(() => {
    convertSVGToBase64().then(logo => setBase64URL(logo));
  }, []);

  const USER_PROFILE = {
    name: activeRelationships?.toName ?? person?.name ?? 'N/A',
    country: PROFILES?.countryName || 'N/A',
    category: PROFILES?.entryCategory || 'N/A',
    subCategory: PROFILES?.subCatDesc || 'N/A',
    date_of_birth: PROFILES?.dob || 'N/A',
    place_of_birth: PROFILES?.pob || 'N/A',
    riskLevel: PROFILES?.riskLevel || 'N/A',
  };

  const Links =
    PROFILES?.media?.map((item, i) => ({
      [`link-${i}`]: item?.url || 'N/A',
    })) || {};

  const Relationships = PROFILES?.relationships?.map(item => ({
    [`${item.is}`]: item?.toName || 'N/A',
  }));

  const SourceLinks =
    PROFILES?.sources?.map((item, i) => ({
      [`link-${i}`]: item?.url || 'N/A',
      name: item?.source_name || 'N/A',
    })) || {};

  const Remarks = {
    remark: PROFILES?.remarks || 'N/A',
  };

  const pdfData = [
    {
      title: 'Profile',
      data: USER_PROFILE,
    },
    {
      title: 'Scores',
      data: {
        match_score: PROFILES?.match_core || 'N/A',
        match_result: entityResults?.length?.toString() || 'N/A',
      },
    },
  ];

  const pdfArray = [
    {
      title: 'Relationships',
      data: Relationships,
    },
    {
      title: 'Remarks',
      data: [{...Remarks}],
    },
    {
      title: 'Media weblinks',
      data: Links,
    },
    {
      title: 'Sources',
      data: SourceLinks,
    },
  ];

  const handlePdfDownload = () => {
    const companyName = userDetails?.company?.name;
    const content = generateAmlPDF({
      pdfData,
      logo: base64URL,
      pdfArray,
      companyName,
    });
    const pdfFooter = {
      name: user && `${user?.first_name} ${user?.last_name}`,
      generated: new Date(),
      logo: base64URL,
    };
    downloadPdf(content, pdfFooter, 'AML lookup');
  };

  return (
    <>
      {!amlCheck || !VERIFICATION?.aml_reference ? (
        <Layout>
          <div>
            <p className="font-medium text-grey">AML Check</p>
            <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white80">
              <img src={corruptedFile} alt="" width={48} height={48} />
              <p className="max-w-[179px] mt-2 text-sm text-center text-body">
                We did not find any information on this person
              </p>
            </div>
          </div>
        </Layout>
      ) : (
        <DashboardLayout
          topPadding="pt-0"
          bg="bg-white80"
          xlLeftMargin="xl:ml-12"
          overFlow=""
          breadCrumbs={
            <div className="flex items-center">
              <img src={verificationsIc} alt="" width={18} height={18} />
              <Link
                to={Constants.PAGES_URL.VERIFICATIONS}
                className="ml-2 -mb-1"
              >
                Verifications
              </Link>
              <img src={chevronRight} alt="" width={18} height={18} />
              <p
                className="-mb-1 cursor-pointer"
                onClick={() =>
                  navigate(
                    `${Constants.PAGES_URL.VERIFICATIONS_BIO_DATA.replace(
                      ':slug',
                      slug?.split('=')[0],
                    )}`,
                  )
                }
              >
                {capitalizeEveryWord(route.split('-').join(' '))}
              </p>
            </div>
          }
        >
          <ToggleOngoingMonitoring
            loading={configLoading}
            setOpen={setOpenConfirm}
            open={openConfirm}
          />
          {loading && (
            <div className="fixed  left-0 top-0 z-50  w-full h-full flex items-center justify-center">
              <ButtonLoader color="#3F7CDB" />
              <span className="text-sm mt-16 text-body font-medium italic">
                ...loading
              </span>
            </div>
          )}
          <div
            style={{opacity: loading && 0.3}}
            className="flex-wrap items-start min-h-full xl:flex xl:flex-nowrap basis-full print:hidden"
          >
            <div className="basis-[17.58%] flex flex-col gap-6 pt-16 sm:pt-6 xl:pt-[35px] xl:sticky xl:top-[35px]">
              <h2 className="hidden text-base font-medium sm:block text-grey sm:text-lg whitespace-nowrap">
                AML Screening
              </h2>

              <div className="flex flex-col gap-6 mt-11">
                <p className="flex items-center text-sm text-grey40 ">
                  {`Search Results>>`}
                  <span className="flex ml-2 items-center justify-center w-4 h-4 text-xs font-medium text-white rounded-full bg-brandBlue">
                    {entityResults?.length || 0}
                  </span>
                </p>
                {!activeRelationships?.toName ? (
                  entityResults &&
                  entityResults?.map((item, index) => (
                    <Link
                      to={`/verifications/aml-check/${slug?.split('=')[0]}=${
                        item.profile_id || item.ProfileId
                      }`}
                      key={index}
                      className={`${
                        (item.profile_id || item.ProfileId) === currentId
                          ? 'text-brandBlue border-l-2 border-brandBlue pl-3'
                          : ''
                      } flex flex-col gap-2 text-body font-medium`}
                    >
                      <p className="text-sm">{item.name || item.Name}</p>
                      <div className="flex">
                        {item.entry_category
                          ?.split(',')
                          ?.slice(0, 2)
                          ?.map((type, idx) => (
                            <p
                              key={idx}
                              className={`p-[4px] mr-2 px-2  text-[8px] font-medium  w-fit uppercase rounded-full ${
                                type?.toLowerCase() === 'pep'
                                  ? 'text-success  bg-statusBg-200'
                                  : type?.toLowerCase() === 'adverse-media'
                                  ? 'text-brandBlue50  bg-brandBlue40'
                                  : 'bg-brandBlue4'
                              }`}
                            >
                              {type.replace('-', ' ')}
                            </p>
                          ))}
                        {item.entry_category?.split(',')?.length > 2 && (
                          <p className="p-[4px] text-danger px-2 text-[8px] font-medium w-fit uppercase rounded-full bg-statusBg-404">
                            more
                          </p>
                        )}
                      </div>
                    </Link>
                  ))
                ) : (
                  <button
                    onClick={() => {
                      handleActiveRelationships({});
                      window.location.reload();
                    }}
                    className="flex items-start gap-2"
                  >
                    <img src={backWhitefill} alt="" width={16} height={16} />
                    <p className="ml-2 text-sm font-medium text-left">
                      Back to {person.name}
                    </p>
                  </button>
                )}
              </div>
            </div>

            <div className="flex flex-col xl:basis-[45.67%]">
              <div>
                {!loading && !amlProfile ? (
                  <div className="xl:ml-8 mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white80 xl:bg-white">
                    <div className="flex items-center justify-between w-full">
                      <p className="font-medium text-grey ">AML Check</p>
                      <button
                        onClick={() => navigate(-1)}
                        className="flex items-center gap-2"
                      >
                        <img src={backNofill} alt="" width={16} height={16} />
                        <p className="ml-2 text-sm">Back</p>
                      </button>
                    </div>
                    <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white80">
                      <img src={corruptedFile} alt="" width={48} height={48} />
                      <p className="max-w-[179px] mt-2 text-sm text-center text-body">
                        We did not find any information on this person
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="xl:ml-8 xl:basis-[45.67%] mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white80 xl:bg-white">
                    <div className="flex items-center justify-between">
                      <p className="text-grey">
                        {loading
                          ? '-'
                          : activeRelationships?.toName ??
                            person?.name ??
                            PROFILES?.name ??
                            PROFILES?.Name}
                      </p>
                      {prevIDS.length > 0 && (
                        <button
                          onClick={() => {
                            const back = prevIDS.pop();
                            const newID = prevIDS.filter(item => item !== back);
                            setPreviousIds(newID);
                            setProfileID(back);
                          }}
                          className="flex items-center gap-2"
                        >
                          <img src={backNofill} alt="" width={16} height={16} />
                          <p className="ml-2 text-sm">Back</p>
                        </button>
                      )}
                    </div>
                    <div className="mt-5 mb-6">
                      <h3 className="mb-6 font-medium text-grey">Profile</h3>
                      {PROFILE.map((profile, index) => (
                        <RowData
                          key={index}
                          {...profile}
                          alignStart
                          width="w-96"
                        />
                      ))}
                    </div>
                    <div className="flex flex-col gap-6 p-4 rounded-lg bg-white80">
                      <div>
                        <h3 className="mb-2 font-medium text-grey">Remarks</h3>
                        <p className="text-sm text-body">
                          {PROFILES?.remarks || '-'}
                        </p>
                      </div>
                      <div>
                        <h3 className="mb-2 font-medium text-grey">Sources</h3>
                        <ul className="flex flex-col gap-3 ml-4 max-h-[500px] overflow-y-auto list-none  md:max-w-full ">
                          {PROFILES?.sources?.map((item, i) => (
                            <li key={i} className="bg-white p-3 rounded">
                              <p className="  mb-3 text-grey">
                                {item?.source_name || '-'}
                              </p>

                              <a
                                href={item?.url || '-'}
                                target="_blank"
                                rel="noreferrer"
                                className="text-brandBlue text-sm   flex items-center hover:underline "
                              >
                                {item.url && (
                                  <div className="h-[5px] w-[5px] mr-1 rounded-full bg-brandBlue" />
                                )}
                                {`${item.url?.substring(0, 120)}.....` || '-'}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <h3 className="mb-2 font-medium text-grey">Media</h3>

                        <ul className="flex flex-col gap-3 ml-4 h-[500px] overflow-y-auto list-none  md:max-w-full ">
                          {PROFILES?.media?.map((item, i) => (
                            <li key={i} className="bg-white p-3 rounded">
                              <p className="text-xs text-grey40 mb-2">
                                {moment(item.date).format('YYYY-MM-DD') || '-'}
                              </p>
                              <p className="  mb-3 text-grey">
                                {item.title || '-'}
                              </p>
                              <p className="text-sm text-grey40 mb-2">
                                {item.snippet || '-'}
                              </p>
                              <a
                                href={item?.url || '-'}
                                target="_blank"
                                rel="noreferrer"
                                className="text-brandBlue text-sm   flex items-center hover:underline "
                              >
                                <div className="h-[5px] w-[5px] mr-1 rounded-full bg-brandBlue" />{' '}
                                {`${item.url?.substring(0, 120)}.....` || '-'}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="xl:basis-[36%] xl:px-6 pt-16 sm:pt-6 xl:pt-[3px] xl:sticky xl:top-[15px] rounded">
              <div className="mt-4 p-4 bg-white">
                <div className="flex items-center gap-1">
                  <h3 className="text-base font-medium text-grey">
                    Relationships
                  </h3>
                  <span className="flex items-center justify-center w-5 h-5 text-xs font-medium text-white rounded-full bg-brandBlue">
                    {PROFILES?.relationships?.length || 0}
                  </span>
                </div>
                <div className="mt-2 text-sm text-body">
                  {loading
                    ? ''
                    : PROFILES?.relationships?.length > 0
                    ? 'Click on any record'
                    : 'No relationship found'}
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  {!loading &&
                    PROFILES?.relationships?.map((relationship, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          setProfileID(relationship?.toName);

                          setActiveRelationships(prev => {
                            if (prev.toName === undefined) {
                              setPreviousIds([profileId]);
                              setPreviousId(prev);
                            } else {
                              !prevIDS.includes(prev.toName) &&
                                setPreviousIds([...prevIDS, prev.toName]);
                              setPreviousId(prev);
                            }
                          });
                          handleActiveRelationships(relationship);
                          const relativeName = relationship?.toName?.split(' ');
                          getAMLProfileByName({
                            first_name: relativeName[0],
                            last_name: relativeName[1],
                            middle_name: relativeName[2],
                            app_id: appId,
                          });
                        }}
                        className={`flex flex-col gap-2 items-center bg-white80 rounded card-link px-2 py-6 ${
                          profileID === relationship?.toName
                            ? 'border border-brandBlue'
                            : ''
                        }`}
                      >
                        <img src={smiley} alt="" width={24} height={24} />
                        <p className="text-xs font-medium uppercase text-body">
                          {relationship.is} to
                        </p>
                        <p className="text-sm font-medium text-grey">
                          {relationship.toName}
                        </p>
                      </button>
                    ))}
                </div>
              </div>

              <div className="flex bg-white p-4 mt-4 rounded print:hidden">
                <SecondaryButton
                  buttonText="Download report"
                  className="w-full"
                  onClick={handlePdfDownload}
                />
              </div>
            </div>
          </div>
        </DashboardLayout>
      )}
    </>
  );
}

export default connect(state => state, Actions)(AMLScreeningResult);
