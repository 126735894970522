import {totalEvents} from '../assets/images/images';
import {shortenLargeNumbers} from './shortenLargeNumbers';

export const buildTableCardData = ({count, title, icon}, moreData = []) => [
  {
    icon: icon || totalEvents,
    alt: 'total',
    title: title || 'Total',
    value: shortenLargeNumbers(count || 0, null, {
      startAt: 'M',
      decimals: 0,
    }),
  },
  ...moreData,
];
