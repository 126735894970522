import React from 'react';

export const InfoIcon = ({backgroundColor}) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{marginRight: '8px'}}
  >
    <g clipPath="url(#clip0_29887_112426)">
      <path
        d="M7.5 0C3.36448 0 0 3.36448 0 7.5C0 11.6355 3.36448 15 7.5 15C11.6355 15 15 11.6355 15 7.5C15 3.36448 11.6355 0 7.5 0ZM7.5 11.814C7.07939 11.814 6.73839 11.473 6.73839 11.0524C6.73839 10.6318 7.07939 10.2908 7.5 10.2908C7.92061 10.2908 8.26161 10.6318 8.26161 11.0524C8.26161 11.473 7.92061 11.814 7.5 11.814ZM8.26161 7.90446C8.26161 8.32507 7.92061 8.66607 7.5 8.66607C7.07939 8.66607 6.73839 8.32507 6.73839 7.90446V3.89333C6.73839 3.47272 7.07939 3.13173 7.5 3.13173C7.92061 3.13173 8.26161 3.47272 8.26161 3.89333V7.90446Z"
        fill={backgroundColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_29887_112426">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
