/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useMemo, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {pendingCasesBreadcrumb} from '../../../assets/images/images';
import {
  DashboardLayout,
  EmptyState,
  Filter,
  FilterButton,
  Loader,
  PageLimit,
  Pagination,
  TableLayout,
} from '../../../components';
import * as Actions from '../../../store/actions';
import {
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../../helpers';
import {useQuery} from 'react-query';
import {fetchCases} from '../../../requests/queries/flows';
import {shortenLargeNumbers} from '../../../helpers/shortenLargeNumbers';
import {getStatusTextAndStyles} from '../../../helpers/getStatusTextAndStyles';
import {OverviewCards} from '../../../components/OverviewCards';
import {buildTableCardData} from '../../../helpers/buildTableCardData';
import {EmptyFilterState} from '../../../components/EmptyFilterState';
import AppliedFilters from 'components/AppliedFilters';

const defaultFilterOptions = [
  {
    title: 'Flow',
    name: 'flow',
    values: [],
  },
];

function PendingCases({getCases, getFlows, flows: {flows}}) {
  const {
    auth: {userDetails},
  } = useSelector(state => state);
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterProps, setFilterProps] = useState(null);

  const params = useMemo(
    () => ({
      company_id: userDetails?.company?.id,
      limit,
      page: currentPage,
      type: 'pending',
    }),
    [currentPage, limit],
  );

  const {data: casesData, isLoading} = useQuery(
    ['pending-flow-cases', {params}],
    () => fetchCases({params}),
  );

  const CASES = useMemo(
    () => (!isLoading && casesData && casesData?.rows) || [],
    [casesData, isLoading],
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;
    apiParams.type = 'pending';

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }

    // hack to check if there were filters in the url
    if (
      Object.keys(apiParams).length === 3 &&
      'limit' in apiParams &&
      'page' in apiParams &&
      'type' in apiParams
    ) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }
  }, [currentPage, limit, getCases, getFlows]);

  const flowOptions = flows?.flows?.map(f => ({
    label: f.name,
    value: f._id,
    name: f.name,
  }));

  const filterOptions = useMemo(() => {
    if (!flowOptions) {
      return defaultFilterOptions;
    }

    return [
      {
        title: 'Flow',
        name: 'flow',
        values: flowOptions,
      },
    ];
  }, [flowOptions]);

  const handleFilter = selectedOptions => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);

    if (selectedOptions['flow'] && selectedOptions['flow'].length > 1) {
      toast.error('You can only filter by one flow at a time.');
      return;
    }

    const queryParams = generateQueryParams(filteredOptions);

    setCurrentPage(1);
    navigate(`?${queryParams}&page=1`);

    getCases({
      page: 1,
      limit,
      type: 'pending',
      ...filteredOptions,
    });
    setOpenFilter(false);
    setFiltersApplied(true);
  };

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    getCases({
      page: 1,
      limit,
      type: 'pending',
    });
    setOpenFilter(true);
  };

  const tableCardData = buildTableCardData({
    count: casesData?.count,
  });

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <DashboardLayout
      bg="bg-white80"
      topPadding="pt-6"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={pendingCasesBreadcrumb} alt="" />
          <p className="ml-2 -mb-1">Pending cases</p>
        </div>
      }
      action={
        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          handleFilter={handleFilter}
          resetUrl={handleResetFilter}
          filterOptions={filterOptions}
          onFilterStateChange={handleFilterStateChange}
        />
      }
    >
      <>
        <div className="flex items-center justify-between w-full mb-6">
          <div className="flex items-center gap-4 flex-wrap">
            <h2 className="text-grey text-base sm:text-lg font-medium">
              Cases
            </h2>
            {filtersApplied && filterProps && (
              <AppliedFilters {...filterProps} isMainPage={true} />
            )}
          </div>
          <div className="flex items-center gap-4">
            {/*<SearchInput />*/}
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />
          </div>
        </div>

        <OverviewCards
          cardData={tableCardData}
          overviewLoading={isLoading}
          className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-1 gap-2 lg:gap-4 flex-wrap my-5`}
        />

        <TableLayout negativeMargins>
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              {/* <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                      <div className="flex items-end gap-1">
                        <span>Name</span>
                        <img src={dropdownBlue} alt="" width={16} height={16} />
                      </div>
                    </th> */}
              <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>Use Case</span>
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>case id</span>
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>STATUS</span>
                </div>
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>date created</span>
                </div>
              </th>
            </tr>
          </thead>

          <tbody className="text-sm text-body">
            <tr>
              <td colSpan={8}>
                {isLoading ? (
                  <Loader height={45} />
                ) : (CASES && CASES?.length === 0) || CASES === null ? (
                  filtersApplied ? (
                    <EmptyState
                      body="No results found"
                      noBtn
                      customBtn={
                        <button
                          onClick={handleResetFilter}
                          className="text-brandBlue p-4 text-sm font-medium"
                        >
                          Update preferences
                        </button>
                      }
                    />
                  ) : (
                    <EmptyState height={55} body="No case(s) found" noBtn />
                  )
                ) : null}
              </td>
            </tr>
          </tbody>

          <tbody className="text-sm bg-white">
            {CASES?.length !== 0 &&
              CASES?.map((_case, i) => (
                <tr
                  className="border-b border-grey60 cursor-pointer"
                  key={i}
                  onClick={() =>
                    navigate(`/pending-cases/details/${_case.id}`, {
                      state: {
                        status: _case.status,
                        createdAt: moment(_case?.createdAt).format(
                          'Do MMM YYYY h:m a',
                        ),
                      },
                    })
                  }
                >
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    {`${_case.id.toUpperCase()}` || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {shortenLargeNumbers(_case.transaction?.amount, true, {
                      startAt: 'B',
                    }) || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    <div
                      className={`uppercase py-1 px-2 text-xs font-medium ${
                        getStatusTextAndStyles(_case?.status?.toLowerCase())
                          ?.solid
                      } rounded-full flex items-center w-fit`}
                    >
                      <span className="-mb-[2px]">{_case.status || '-'}</span>
                    </div>
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(_case?.createdAt).format('Do MMM YYYY h:m a')}
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
      </>

      {!isLoading && CASES?.length !== 0 && (
        <div className="flex items-center justify-between flex-wrap gap-4 sm:gap-0 mt-8">
          <div className="flex items-center text-body text-tiny">
            <PageLimit
              onLimitChange={setLimit}
              total={casesData?.count}
              length={CASES?.length}
              limit={limit}
            />
          </div>

          <div className="mb-8 sm:mb-0">
            <Pagination
              total={casesData && Math.ceil(casesData?.count / limit)}
              current={+currentPage}
              onPageChange={activePage => {
                localStorage.setItem('casesPageNumber', String(activePage));
                pageClick(activePage);
              }}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(PendingCases);
