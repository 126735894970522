/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import {connect} from 'react-redux';
import {signaturePageDefaultInfo as defaultInformation} from '../widgetHelpers';

const SignaturePage = ({widgets: {widgetPages, widgetScreen, singleApp}}) => {
  const widgetData =
    widgetPages &&
    widgetPages?.find(
      widget =>
        widget?.page === 'signature' && widget?.pageId === widgetScreen?.pageId,
    );

  return (
    <>
      <header className="flex flex-col items-center my-4">
        <p className="text-center font-medium text-[#1B2A4E] text-base">
          Sign and confirm information
        </p>
      </header>

      <p className="mt-4 text-sm w-[367px] text-justify text-grey80 max-h-[200px] overflow-y-auto">
        {widgetData?.config?.information || defaultInformation}
      </p>

      <div className="w-[73%] mt-6">
        <label
          htmlFor="title"
          className="mb-1 text-[#1B2A4E] text-sm font-normal"
        >
          Enter your full name
        </label>
        <input
          type="text"
          name="title"
          id="title"
          placeholder="Input document name"
          className="text-sm text-[#8D8D91] bg-[#F6F6F8] border-none outline-none rounded"
          value="John Doe"
          disabled
        />
      </div>

      <div className="w-[73%] mt-6">
        <label
          htmlFor="signature"
          className="mb-1 text-[#1B2A4E] text-sm font-normal"
        >
          Add your signature and confirm below{' '}
        </label>
        <textarea
          name="signature"
          id="signature"
          rows="4"
          className="text-sm text-[#8D8D91] bg-[#F6F6F8] border-none outline-none rounded w-full"
        />
      </div>

      <button
        disabled
        className="w-full h-12 max-w-sm mt-6 font-medium !text-white rounded text-sm bg-brandBlue"
        style={{
          backgroundColor: singleApp?.color || singleApp?.color_code || '',
        }}
      >
        I Sign and Confirm
      </button>
    </>
  );
};

export default connect(state => state, null)(SignaturePage);
