import React, {useMemo} from 'react';
import moment from 'moment/moment';
import {shortenLargeNumbers} from '../../../../helpers/shortenLargeNumbers';
import {collapseNestedObject} from '../../../../helpers/collapseNestedObject';
import {RowData} from '../../../../components';
import {isObjectEmpty} from '../../../../helpers/isObjectEmpty';

export const useCasesDetails = ({caseDetails}) => {
  const USER = useMemo(() => caseDetails?.transaction?.esd_user, [caseDetails]);
  const TRANSACTION = useMemo(() => caseDetails?.transaction, [caseDetails]);
  const ADDRESS = useMemo(
    () => caseDetails?.transaction?.esd_address,
    [caseDetails],
  );
  const PAYMENT = useMemo(
    () => caseDetails?.transaction?.payment,
    [caseDetails],
  );
  const SENDER = useMemo(
    () => caseDetails?.transaction?.senderAccount,
    [caseDetails],
  );
  const DEVICE = useMemo(
    () => caseDetails?.transaction?.esd_device,
    [caseDetails],
  );
  const RECEIVER = useMemo(
    () => caseDetails?.transaction?.receiverAccount,
    [caseDetails],
  );
  const META = useMemo(
    () => caseDetails?.transaction?.event?.meta,
    [caseDetails],
  );

  const USER_DETAILS = useMemo(
    () => [
      {
        leftText: 'Full name',
        rightText: USER?.user_name || '-',
      },
      {
        leftText: 'email address',
        rightText: USER?.user_email || '-',
      },
      {
        leftText: 'phone number',
        rightText: USER?.user_mobile || '-',
      },
    ],
    [USER],
  );

  const TRANSACTION_DETAILS = useMemo(
    () => [
      {
        leftText: 'Transaction gateway',
        rightText: TRANSACTION?.channel?.replaceAll('_', ' ') || '-',
      },
      {
        leftText: 'Transaction Type',
        rightText: TRANSACTION?.event?.transaction?.type || '-',
      },
      {
        leftText: 'transaction time',
        rightText:
          moment(
            !isNaN(TRANSACTION?.event?.transaction?.time)
              ? +TRANSACTION?.event?.transaction?.time
              : TRANSACTION?.event?.transaction?.time,
          ).format('HH:mm a') || '-',
      },
      {
        leftText: 'Amount',
        rightText: shortenLargeNumbers(
          TRANSACTION?.event?.transaction?.amount || 0,
          TRANSACTION?.currency || 'NGN',
          {startAt: 'B'},
        ),
      },
      {
        leftText: 'Currency',
        rightText: TRANSACTION?.currency || 'NGN',
      },
    ],
    [TRANSACTION],
  );

  const metaDetails = Array.isArray(META)
    ? Object.entries(collapseNestedObject(META) || {})
    : Object.entries(collapseNestedObject(META) || {});
  const OTHER_DETAILS = metaDetails?.map(d => ({
    leftText: d[0].replaceAll('_', ' '),
    rightText: d[1]?.toString() || '-',
  }));

  const ADDRESS_DETAILS = [
    {
      leftText: 'STREET',
      rightText: ADDRESS?.street || '-',
    },
    {
      leftText: 'COUNTRY',
      rightText: ADDRESS?.country || '-',
    },
    {
      leftText: 'CITY',
      rightText: ADDRESS?.city || '-',
    },
  ];
  const DEVICE_DETAILS = [
    {
      leftText: 'TYPE',
      rightText: DEVICE?.device_type || '-',
    },
    {
      leftText: 'MODEL',
      rightText: DEVICE?.device_model || '-',
    },
    {
      leftText: 'OS',
      rightText: DEVICE?.os || '-',
    },
    {
      leftText: 'IP ADDRESS',
      rightText: DEVICE?.ip_address || '-',
    },
  ];
  const CARD = [
    {
      leftText: 'card last four',
      rightText: PAYMENT?.card_last_four || '-',
    },
    {
      leftText: 'expired date',
      rightText: PAYMENT?.expired_date || '-',
    },
    {
      leftText: 'name of card',
      rightText: PAYMENT?.name_of_card || '-',
    },
    {
      leftText: 'payment method type',
      rightText: PAYMENT?.payment_method_type || '-',
    },
    {
      leftText: 'scheme',
      rightText: PAYMENT?.scheme || '-',
    },
  ];
  const BANK_SENDER = [
    {
      leftText: 'Account name',
      rightText: SENDER?.account_name || '-',
    },
    {
      leftText: 'Bank name',
      rightText: SENDER?.bank_name || '-',
    },
    {
      leftText: 'Account number',
      rightText: SENDER?.account_number || '-',
    },
    {
      leftText: 'Country',
      rightText: SENDER?.country || '-',
    },
  ];
  const BANK_RECEIVER = [
    {
      leftText: 'Account name',
      rightText: RECEIVER?.account_name || '-',
    },
    {
      leftText: 'Bank name',
      rightText: RECEIVER?.bank_name || '-',
    },
    {
      leftText: 'Account number',
      rightText: RECEIVER?.account_number || '-',
    },
    {
      leftText: 'Country',
      rightText: RECEIVER?.country || '-',
    },
  ];

  const ALL_DATA = [
    {
      title: 'User Data',
      data: USER_DETAILS?.map((item, index) => (
        <RowData key={index} {...item} />
      )),
    },
    {
      title: 'Transaction Details',
      data: TRANSACTION_DETAILS?.map((item, index) => (
        <RowData key={index} {...item} />
      )),
    },
    {
      title: 'Address Details',
      data: ADDRESS_DETAILS?.map((item, index) => (
        <RowData key={index} {...item} />
      )),
    },
    ...(!isObjectEmpty(PAYMENT)
      ? [
          {
            title: 'Card Transaction',
            data: CARD?.map((item, index) => <RowData key={index} {...item} />),
          },
        ]
      : []),
    ...(!isObjectEmpty(SENDER)
      ? [
          {
            title: 'Sender',
            data: BANK_SENDER?.map((item, index) => (
              <RowData key={index} {...item} />
            )),
          },
        ]
      : []),
    ...(!isObjectEmpty(RECEIVER)
      ? [
          {
            title: 'Receiver',
            data: BANK_RECEIVER?.map((item, index) => (
              <RowData key={index} {...item} />
            )),
          },
        ]
      : []),
    ...(!isObjectEmpty(DEVICE)
      ? [
          {
            title: 'Device',
            data: DEVICE_DETAILS?.map((item, index) => (
              <RowData key={index} {...item} />
            )),
          },
        ]
      : []),
    ...(!isObjectEmpty(META)
      ? [
          {
            title: 'Meta',
            data: OTHER_DETAILS?.map((item, index) => (
              <RowData key={index} {...item} />
            )),
          },
        ]
      : []),
  ];
  return {
    ALL_DATA,
    USER,
    TRANSACTION,
    ADDRESS,
    PAYMENT,
    SENDER,
    RECEIVER,
    META,
  };
};
