import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {
  conversionIcon,
  timerIcon,
  verificationsIc,
  chevronRight,
} from '../../assets/images/images';
import {
  DashboardLayout,
  Filter,
  FilterButton,
  SkeletonLoader,
} from '../../components';
import VerificationAnalyticsPieChart from '../../components/VerificationAnalyticsPieChart';
import {renderFlowCards, renderStatusDistribution} from './utils';
import {fetchVerificationsAnalysis} from '../../requests/queries/verifications';
import {useAllApps} from '../../hooks/useApps';
import VerificationLineChart from './VerificationLineChart';
import {Period} from '../FraudDetection/Reconciliation/components/Period';
import {
  filterOutEmptyValues,
  formatDuration,
  generateQueryParams,
  parseUrlParams,
} from '../../helpers';
import {useNavigate} from 'react-router-dom';
import {verificationChartPeriod} from '../FraudDetection/Reconciliation/constants';
import {getCookieValue} from '../../helpers/getCookie';
import {useMediaQuery} from '../../hooks';
import moment from 'moment';

const initialFilters = {
  start: '',
  end: '',
  flows: '',
};

function VerificationAnalytics() {
  const navigate = useNavigate();

  const [date, setDate] = useState('monthly');
  const [filters, setFilters] = useState(initialFilters);
  const [openFilter, setOpenFilter] = useState(false);
  const {appIds, isLoading: appsLoading} = useAllApps();

  const env = getCookieValue('env');
  const envData = env === 'Live' ? 'production' : env;

  const params = useMemo(
    () => ({
      app_ids: appIds?.toString(),
      period: date,
      flow_ids: filters?.flows?.toString(),
      start: filters?.start,
      end: filters?.end,
      environment: envData?.toLowerCase(),
    }),
    [appIds, date, envData, filters?.end, filters?.flows, filters?.start],
  );
  const {data, isLoading} = useQuery(
    ['verification-analysis', {...params}],
    () => fetchVerificationsAnalysis({params}),
    {enabled: Boolean(!appsLoading && appIds)},
  );

  const ANALYSIS_DATA = useMemo(
    () => !isLoading && data?.data,
    [data?.data, isLoading],
  );
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  useEffect(() => {
    const apiParams = parseUrlParams();
    const statusParam = queryParams.get('status');
    if (statusParam) {
      apiParams.status = statusParam;
    }

    if (Object.keys(apiParams).length > 1) {
      const {start, end, ...rest} = apiParams;
      const decodedStart = decodeURIComponent(start);
      const decodedEnd = decodeURIComponent(end);
      setFilters({...rest, start: decodedStart, end: decodedEnd});
    }
  }, [queryParams]);

  const handleFilter = (selectedOptions, shouldDownload = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);

    const {status} = filteredOptions;

    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }

    let baseUrl = '';

    if (queryParams) {
      baseUrl += `?${queryParams}`;
    }

    if (shouldDownload) {
      filteredOptions.download = true;
      setFilters({
        ...filters,
        ...filteredOptions,
      });
    } else {
      navigate(baseUrl);
    }

    setFilters(filteredOptions);
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    navigate('');
    setFilters(initialFilters);
    setOpenFilter(true);
  };

  const topCountries = useMemo(
    () =>
      ANALYSIS_DATA?.country_distribution
        ?.filter(country => country.country !== 'Unknown')
        .sort((a, b) => b.percentage - a.percentage)
        .slice(0, 5),
    [ANALYSIS_DATA?.country_distribution],
  );

  const media = useMediaQuery('(min-width: 768px)');

  return (
    <DashboardLayout
      bg="bg-white80"
      overFlow="!pr-0"
      leftMargin="!ml-0"
      breadCrumbs={
        <div className="flex items-center">
          <img src={verificationsIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Verifications</p>
          <img src={chevronRight} alt="" width={18} height={18} />{' '}
          <p className="-mb-1 cursor-pointer">Verification Analytics</p>
        </div>
      }
    >
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={[]}
      />

      <div className="px-2 lg:px-10">
        <div className="flex gap-5 items-start md:items-center justify-between px-3 lg:p-0 py-2">
          <div className="flex flex-col md:flex-row gap-2">
            <h1 className="text-base lg:text-lg font-medium text-grey leading-[38px] lg:leading-normal">
              Verification Analytics
            </h1>
            <span className="rounded flex bg-brandBlue4 shadow-inner capitalize h-8 w-fit px-4 py-2 font-medium text-deepBlue text-sm   items-center justify-center">
              {!filters?.start
                ? 'ALL TIME'
                : `${moment(filters?.start).format('Do MMM, YYYY')} - ${moment(
                    filters?.end,
                  ).format('Do MMM, YYYY')}`}

              {filters?.start && (
                <span
                  onClick={handleResetFilter}
                  className="pl-3 cursor-pointer lowercase text-sm font-extrabold text-danger"
                  title="Reset Filters"
                >
                  ✕
                </span>
              )}
            </span>
          </div>

          <div className="flex items-center gap-3">
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              className="rounded-lg h-[46px]"
            />
            {/* <PrimaryButton
              buttonText="Export"
              className="!py-[14px] !px-[64px] hidden md:block rounded-lg font-inter"
            />
            <button>
              <img src={exportMobile} alt="export" className="md:hidden" />
            </button> */}
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 lg:gap-4 flex-wrap mt-5">
          {renderFlowCards({cards: ANALYSIS_DATA?.cards, isLoading})}
          <div className="bg-white relative lg:py-6 lg:pl-6 lg:pr-2 p-3 rounded-lg  xl:hidden flex items-center gap-2 xl:min-w-[270px] w-full">
            <div className="h-fit flex items-center gap-2">
              <img src={conversionIcon} alt="" />
              <div>
                <p className="mb-1 text-small text-body font-medium">
                  Conversion Rate
                </p>
                <h1 className="text-grey text-base lg:text-md font-semibold">
                  {ANALYSIS_DATA?.conversion_rate || 0}%
                </h1>
              </div>
            </div>
          </div>
          <div className="bg-white relative lg:py-6 lg:pl-6 lg:pr-2 p-3 rounded-lg xl:hidden flex items-center gap-2 xl:min-w-[270px] w-full">
            <div className="h-fit flex items-center gap-2">
              <img src={timerIcon} alt="" />
              <div>
                <p className="mb-1 text-small text-body font-medium">
                  {media ? ' Average completion time' : ' Average time'}
                </p>
                {isLoading ? (
                  <SkeletonLoader
                    style={{width: 150, height: 25, marginBottom: 10}}
                  />
                ) : (
                  <h1 className="text-grey  text-base lg:text-md font-semibold">
                    {formatDuration(
                      ANALYSIS_DATA?.time_taken?.avg_duration_seconds || 0,
                    )}
                  </h1>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center flex-col md:flex-row gap-4 md:h-[280px] mt-6">
          <div className="bg-white w-full md:w-[50%] xl:w-[36%] h-full rounded-lg py-4 px-6">
            <p className="font-medium text-sm xl:text-base text-body">
              Status Distribution
            </p>
            <div className="flex md:items-center justify-between gap-5 mt-6">
              <div className="lg:ml-[-40px]">
                <VerificationAnalyticsPieChart
                  verifications={ANALYSIS_DATA?.cards}
                  isLoading={isLoading}
                />
              </div>

              <div className="xl:w-[50%] max-w-[191px] ml-[-30px] w-full flex items-center flex-col gap-4 font-inter">
                {renderStatusDistribution({stats: ANALYSIS_DATA?.percentage})}
              </div>
            </div>
          </div>

          <div className="bg-white w-full md:w-[50%] xl:w-[36%] h-full rounded-lg py-4 px-6">
            <p className="font-medium text-sm xl:text-base text-body">
              Country Distribution
            </p>

            {isLoading ? (
              <div className="flex items-start flex-col w-full gap-3 mt-6">
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="w-full">
                    <div className="flex justify-between items-center">
                      <SkeletonLoader
                        style={{width: '80%', height: 13, borderRadius: 6}}
                      />
                      <SkeletonLoader style={{width: 30, height: 16}} />
                    </div>
                  </div>
                ))}
              </div>
            ) : !isLoading && topCountries && topCountries.length === 0 ? (
              <div className="flex items-center justify-center  w-full h-full">
                <p className="text-[#464646] font-medium text-sm capitalize font-inter tracking-[-0.5px]">
                  No Country Distribution found
                </p>
              </div>
            ) : (
              <div className="flex items-start gap-4 mt-6">
                <div className="flex gap-[15px] w-[48%] flex-col items-start">
                  {topCountries &&
                    topCountries?.map((country, index) => (
                      <div key={index} className="flex items-center gap-2">
                        {country.flag ? (
                          <img
                            src={country.flag}
                            alt={country.country || 'country flag'}
                            className="min-w-[24px] min-h-[24px] rounded-full w-6 h-6 object-cover"
                          />
                        ) : (
                          <div className="min-w-[24px] min-h-[24px] w-6 h-6 rounded-full bg-[#E9EBF0] font-inter flex items-center justify-center text-batchBlue font-bold">
                            {country.country.charAt(0) || 'N/A'}
                          </div>
                        )}
                        <p className="text-[#464646] font-medium text-xs uppercase font-inter tracking-[-0.5px]">
                          {country.country || 'N/A'}
                        </p>
                      </div>
                    ))}
                </div>

                <div className="flex items-start flex-col w-full gap-[18px]">
                  {topCountries &&
                    topCountries?.map((country, index) => (
                      <div key={index} className="w-full">
                        <div className="flex justify-between items-center">
                          <div className="w-[80%] h-[13px] bg-[#E9EBF0] rounded overflow-hidden">
                            <div
                              style={{width: `${country.percentage}%`}}
                              className="h-full bg-batchBlue rounded transition-all duration-200 ease-out"
                            />
                          </div>
                          <p className="text-grey font-semibold text-tiny ml-2">
                            {country.percentage.toFixed(1) || 0}%
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>

          <div className="w-full lg:w-[28%] h-full hidden flex-col gap-4 font-inter xl:flex">
            <div className="bg-white rounded-lg h-[100px] lg:h-full px-6 flex items-center">
              <div className="h-fit flex items-center gap-2">
                <img src={conversionIcon} alt="" />
                <div>
                  <p className="mb-1 text-sm text-body font-medium">
                    Conversion Rate
                  </p>
                  <h1 className="text-grey text-md font-semibold">
                    {ANALYSIS_DATA?.conversion_rate || 0}%
                  </h1>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg h-[100px] lg:h-full px-6 flex items-center">
              <div className="h-fit flex items-center gap-2">
                <img src={timerIcon} alt="" />
                <div>
                  <p className="mb-1 text-sm text-body font-medium">
                    Average completion time{' '}
                  </p>
                  {isLoading ? (
                    <SkeletonLoader
                      style={{width: 150, height: 25, marginBottom: 10}}
                    />
                  ) : (
                    <h1 className="text-grey text-md font-semibold">
                      {formatDuration(
                        ANALYSIS_DATA?.time_taken?.avg_duration_seconds || 0,
                      )}
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <VerificationLineChart
          customElements={
            <Period
              date={date}
              setDate={setDate}
              datePeriod={queryParams.get('period')}
              otherChartPeriod={verificationChartPeriod}
            />
          }
          chartData={ANALYSIS_DATA?.monthly_chart}
          periodType={date}
          loading={isLoading}
        />
      </div>
    </DashboardLayout>
  );
}

export default VerificationAnalytics;
