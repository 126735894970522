import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import {
  DashboardLayout,
  EmptyState,
  Filter,
  FilterButton,
  Loader,
} from '../../../components';
import {easyDetectBreadcrumb} from '../../../assets/images/images';
import {mapToOptionsArray} from '../../../helpers';
import {fetchReport} from '../../../requests/queries/report';
import {PageContext} from '../../../helpers/context';
import {useAllApps} from '../../../hooks/useApps';
import AppliedFilters from 'components/AppliedFilters';

const defaultFilterOptions = [
  {
    title: 'Flow name',
    name: 'flow',
    values: [],
  },
];

function EasyDetectReport({flows: {flows}, auth: {userDetails}}) {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterProps, setFilterProps] = useState(null);

  const {appIds} = useAllApps();

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('easydetect');
  }, [setPageName]);

  const {data, isLoading} = useQuery(
    ['easy-detect-report', {query: 'easy-detect'}],
    () => fetchReport(appIds.join(',')),
    {
      enabled: appIds.length > 0,
    },
  );

  const filterOptions = useMemo(() => {
    if (!isLoading && !flows) {
      return defaultFilterOptions;
    }

    return [
      {
        title: 'Flow name',
        name: 'flow',
        values: mapToOptionsArray(flows?.flows),
      },
    ];
  }, [flows, isLoading]);

  const viewEasyDetect =
    userDetails?.permissions?.analyticsPermissions?.viewEasyDetect;

  const exportEasyDetect =
    userDetails?.permissions?.analyticsPermissions?.exportEasyDetect;

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <DashboardLayout
      xlLeftMargin="xl:ml-12"
      xlRightPadding="xl:pr-[76px]"
      overFlow="overflow-none"
      breadCrumbs={
        <div className="flex items-center">
          <img src={easyDetectBreadcrumb} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1">Easy Detect report</p>
        </div>
      }
    >
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={() => {}}
        resetUrl={() => {}}
        filterOptions={filterOptions}
        onFilterStateChange={handleFilterStateChange}
      />
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-4 flex-wrap">
            <FilterButton
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              className="ml-auto w-fit"
            />
            {filterProps && (
              <AppliedFilters {...filterProps} isMainPage={true} />
            )}
          </div>
          {!viewEasyDetect ||
            (exportEasyDetect && (
              <a
                download
                href="Easy Detect Report.pdf"
                target="_blank"
                rel="noreferrer"
                className="text-sm text-white font-medium bg-brandBlue h-12 hover:opacity-90 duration-200 transition rounded px-4 pt-[18px] pb-[14px] w-20 flex items-center justify-center"
              >
                Export
              </a>
            ))}
        </div>

        {isLoading ? (
          <Loader height={45} />
        ) : !isLoading && (!data?.url || data?.url?.length === 0) ? (
          <EmptyState
            height={45}
            noBtn
            heading="No report data"
            body="No Easy Detect Report found"
          />
        ) : (
          <iframe
            id="easy-detect-report-iframe"
            title="Easy Detect Report"
            className="w-full min-h-screen"
            src={data?.url}
          ></iframe>
        )}
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(EasyDetectReport);
