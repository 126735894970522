import {api} from '../../helpers';
import {getCookieValue} from '../../helpers/getCookie';
const subUrl = '/reconciliation';
const env = getCookieValue('env');
const environment = env === 'Production' ? 'live' : env;

function fetchOverview(params) {
  return api.get(`${subUrl}/fraud/overview`, {...params}).then(res => res.data);
}
function fetchFlaggedOverview(params) {
  return api
    .get(`${subUrl}/fraud/overview/flagged`, {...params})
    .then(res => res.data);
}
function fetchResolvedCasesOverview(params) {
  return api
    .get(`${subUrl}/fraud/overview/cases`, {...params})
    .then(res => res.data);
}

function fetchAccounts(params) {
  return api.get(`${subUrl}/fraud/users`, {...params}).then(res => res.data);
}
function fetchAccountTransactions({account_id}) {
  return api
    .get(`${subUrl}/fraud/user/${account_id}/events`)
    .then(res => res.data);
}
function fetchFlows(params) {
  return api.get(`${subUrl}/fraud/all`, {...params}).then(res => res.data);
}
function fetchFlow(flow_id) {
  return api.get(`${subUrl}/fraud/${flow_id}`).then(res => res.data);
}
function createFlow(data) {
  return api
    .post(`${subUrl}/fraud`, {...data, env: environment?.toLowerCase()})
    .then(res => res.data);
}
function updateFlow({id, data}) {
  return api
    .put(`${subUrl}/fraud/project/${id}`, {...data})
    .then(res => res.data);
}
function generateWebhookUrl(data) {
  return api
    .post(`/baas/wallet/setup_webhook_url`, {...data})
    .then(res => res.data);
}
function deleteFlow(flow_id) {
  return api.delete(`${subUrl}/fraud/project/${flow_id}`).then(res => res.data);
}
function fetchEvents(params) {
  return api.get(`${subUrl}/fraud/events`, {...params}).then(res => res.data);
}
function fetchCases(params) {
  return api.get(`${subUrl}/fraud/cases`, {...params}).then(res => res.data);
}
function fetchCase(case_id) {
  return api.get(`${subUrl}/fraud/case/${case_id}`).then(res => res.data);
}
function updateCase({case_id, data}) {
  return api
    .put(`${subUrl}/fraud/case/${case_id}`, {...data})
    .then(res => res.data);
}
function generateSampleData(id) {
  return api
    .get(`${subUrl}/fraud/generate-transactions/${id}`)
    .then(res => res.data);
}
function fetchAvailableFields() {
  return api.get(`${subUrl}/rule/available-facts`).then(res => res.data);
}

function fetchTasks(flowId) {
  return api
    .get(`${subUrl}/rule/project/${flowId}/staged`)
    .then(res => res.data);
}

function fetchPublishedTasks(flowId) {
  return api.get(`${subUrl}/rule/rules/${flowId}`).then(res => res.data);
}

// function fetchTasks(flowId) {
//   return api
//     .get(`/users/fraud-detection/${flowId}/tasks`)
//     .then(res => res.data);
// }

function addTask(flowId, newTask) {
  return api
    .post(`${subUrl}/rule/rule`, {...newTask, project_id: flowId})
    .then(res => res.data);
}
function publishTasks(flowId, userId) {
  return api
    .post(`${subUrl}/rule/project/${flowId}/commit`, {updated_by: userId})
    .then(res => res.data);
}

// function addTask(flowId, newTask) {
//   return api
//     .post(`/users/fraud-detection/${flowId}/tasks`, {...newTask})
//     .then(res => res.data);
// }

function updateTask(flowId, {id, ...task}) {
  return api
    .put(`${subUrl}/rule/rule/${id}`, {...task, project_id: flowId})
    .then(res => res.data);
}
// function updateTask(flowId, {id, ...task}) {
//   return api
//     .put(`/users/fraud-detection/${flowId}/tasks/${id}`, {...task})
//     .then(res => res.data);
// }

function saveProcess(flowId, flowProcess) {
  const start = flowProcess[0];
  const end = flowProcess[2];
  const FLOW = [start, ...flowProcess[1], end];
  const threshold = flowProcess[2]?.threshold;

  const finalProcess = {
    process: FLOW.map(process => process?._id).filter(process => !!process),
    ...(threshold && {threshold}),
  };

  return api
    .put(`/users/fraud-detection/${flowId}/process`, {...finalProcess})
    .then(res => res.data);
}
function deleteTask({id, userId}) {
  return api
    .delete(`${subUrl}/rule/rule/${id}`, {data: {updated_by: userId}})
    .then(res => res.data);
}
function clearProjectRule(flowId) {
  return api
    .delete(`${subUrl}/rule/project/${flowId}/staged`)
    .then(res => res.data);
}
// function deleteTask(flowId, id) {
//   return api
//     .delete(`/users/fraud-detection/${flowId}/tasks/${id}`)
//     .then(res => res.data);
// }

export {
  addTask,
  fetchFlow,
  fetchCase,
  fetchFlows,
  fetchCases,
  deleteTask,
  fetchTasks,
  deleteFlow,
  updateFlow,
  updateCase,
  createFlow,
  updateTask,
  saveProcess,
  fetchEvents,
  publishTasks,
  fetchOverview,
  fetchAccounts,
  clearProjectRule,
  generateWebhookUrl,
  generateSampleData,
  fetchPublishedTasks,
  fetchAvailableFields,
  fetchFlaggedOverview,
  fetchAccountTransactions,
  fetchResolvedCasesOverview,
};
