/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react';
import Select from 'react-select';
import {userGreen, userRed} from '../../../assets/images/images';
import {
  customStyles,
  SettingsToggle,
  DropdownIndicator,
} from '../../../components';
import {useFraudCheck, usePersistFraudData} from '../../../hooks/useFraudCheck';
import {Card, Wrapper} from '../FraudCheckScreens';

export const DetectWithUserData = () => {
  // const [selected, setSelected] = useState([]);
  const [enabled, setEnabled] = useState({});
  const {setSelectedStatus, selectedStatus, setConfig, config, STATUSES} =
    useFraudCheck({
      fields: ['first_name'],
      action: 'pending',
    });
  const statusData = usePersistFraudData({
    config,
    selectedStatus,
    actionKey: 'action',
    COLLECTION: STATUSES,
  });

  const handleFieldCheck = useCallback(
    value => {
      !enabled[value]
        ? setConfig({...config, fields: [...(config.fields || []), value]})
        : setConfig({
            ...config,
            fields: config?.fields.filter(field => field !== value),
          });
    },
    [enabled, setConfig, config],
  );

  useEffect(() => {
    if (!config?.fields?.length) {
      setEnabled({...enabled});
    } else {
      const userFields = config?.fields?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: true,
        }),
        {},
      );
      setEnabled({...enabled, ...userFields});
    }
  }, [Object.keys(enabled).length, setEnabled, config.fields.length]);

  return (
    <Wrapper>
      {/*<Card*/}
      {/*  extension={*/}
      {/*    <div className="flex items-center justify-between w-full">*/}
      {/*      <p className="text-grey font-medium">Choose mode of verification</p>*/}
      {/*      <div className="w-[1px] h-10 bg-grey60" />*/}
      {/*      <div className="flex items-center gap-4">*/}
      {/*        <CheckboxGroup*/}
      {/*          options={CHECKBOXES}*/}
      {/*          selected={selected}*/}
      {/*          onChange={setSelected}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*/>*/}
      <Card
        extension={
          <div className="flex flex-col">
            <p className="text-grey font-medium">Detect with user data</p>
            <p className="text-body text-sm gap-1">
              If the values do not match
            </p>
          </div>
        }
      />
      <Card
        extension={
          <div className="flex flex-col">
            <p className="text-grey font-medium">Select fields to match</p>
          </div>
        }
        src={userGreen}
      >
        <div className="flex flex-col gap-2 w-full">
          <p className="text-sm text-grey font-medium">Government ID</p>

          <div className="flex items-center gap-2 p-4 rounded bg-white80">
            <SettingsToggle
              isChecked={
                config?.fields?.includes('first_name') || enabled['first_name']
              }
              onCheck={data => {
                setEnabled({...enabled, first_name: data});
                handleFieldCheck('first_name');
              }}
            />
            <p className="text-grey text-sm">First name</p>
          </div>
          <div className="flex items-center gap-2 p-4 rounded bg-white80">
            <SettingsToggle
              isChecked={
                config?.fields?.includes('last_name') || enabled['last_name']
              }
              onCheck={data => {
                setEnabled({...enabled, last_name: data});
                handleFieldCheck('last_name');
              }}
            />
            <p className="text-grey text-sm">Last name</p>
          </div>
          <div className="flex items-center gap-2 p-4 rounded bg-white80">
            <SettingsToggle
              isChecked={
                config?.fields?.includes('middle_name') ||
                enabled['middle_name']
              }
              onCheck={data => {
                setEnabled({...enabled, middle_name: data});
                handleFieldCheck('middle_name');
              }}
            />
            <p className="text-grey text-sm">Middle name</p>
          </div>
          <div className="flex items-center gap-2 p-4 rounded bg-white80">
            <SettingsToggle
              isChecked={config?.fields?.includes('dob') || enabled['dob']}
              onCheck={data => {
                setEnabled({...enabled, dob: data});
                handleFieldCheck('dob');
              }}
            />
            <p className="text-grey text-sm">Date of birth</p>
          </div>
        </div>
      </Card>
      <Card
        extension={
          <p className="text-grey font-medium">If fields do not match</p>
        }
        src={userRed}
      >
        <div className="text-start w-full">
          <label htmlFor="status" className="mb-2 relative">
            Set Status to
          </label>
          {statusData !== undefined && (
            <Select
              defaultValue={statusData}
              value={statusData || STATUSES[0]}
              onChange={data => {
                setConfig({...config, action: data?.value});
                setSelectedStatus(data);
              }}
              options={STATUSES}
              className="basic-multi-select white-select"
              classNamePrefix="react-select"
              components={{DropdownIndicator}}
              styles={customStyles}
              menuPlacement="auto"
              isSearchable={false}
            />
          )}
        </div>
      </Card>
    </Wrapper>
  );
};
