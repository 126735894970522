/* eslint-disable react/jsx-indent */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import baseRoutes from './base';
import * as Actions from '../store/actions';
import {token, Constants} from '../helpers';
import NotFound from './NotFound';
import withRouter from '../hooks/withRouter';
import {dojahFavicon} from '../assets/images/images';
import {Sidebar} from '../components';
import {ErrorBoundary} from 'react-error-boundary';
import {intercomRequests} from '../requests/queries/auth';

class PageRoutes extends Component {
  state = {
    privateRoutes: null,
    publicRoutes: null,
  };

  constructor(props) {
    super(props);
    this.setRoutes(true);
  }

  componentDidMount() {
    const {getUserProfile} = this.props;

    if (token.get('user:token')) {
      getUserProfile();
      intercomRequests();
    }
  }

  componentDidCatch(...args) {
    console.log('An error occurred', args);
  }

  setRoutes = firstMount => {
    const state = {
      privateRoutes: baseRoutes.private.map((route, i) => (
        <Route
          key={route.path + i}
          path={route.path}
          exact={route.exact}
          element={
            <>
              <Sidebar />
              <ErrorBoundary
                FallbackComponent={NotFound}
                onReset={() => location.reload()}
              >
                {route.component}
              </ErrorBoundary>
            </>
          }
        />
      )),
      publicRoutes: baseRoutes.public.map((route, i) => (
        <Route
          key={route.path + i}
          path={route.path}
          exact={route.exact}
          element={
            <>
              <React.StrictMode>
                <ErrorBoundary
                  FallbackComponent={NotFound}
                  onReset={() => window.location.reload()}
                >
                  {route.component}
                </ErrorBoundary>
              </React.StrictMode>
            </>
          }
        />
      )),
    };
    if (firstMount) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = state;
    } else {
      this.setState(state);
    }
  };

  render() {
    const {privateRoutes, publicRoutes} = this.state;
    // const {auth} = this.props;

    // const nonce = `${Date.now()}-${auth?.user && auth?.user?.id}` ?? '';
    const dojahTagline =
      'Verify Customer Identity Easily | KYC\n' + '    Verification | Dojah';
    const companyFavicon = localStorage.companyFavicon || dojahFavicon;
    const subdomain = localStorage.subdomain;

    const isReconcilo = subdomain?.includes('reconcilo');

    return (
      <>
        <Helmet>
          <link rel="icon" href={companyFavicon} />
          <title>{!subdomain && dojahTagline}</title>
        </Helmet>
        {!subdomain && (
          <Helmet>
            {/*{process.env.REACT_APP_APP_ENV_MODE !== 'development' && (*/}
            {/*  <script*/}
            {/*    type="text/javascript"*/}
            {/*    nonce={nonce}*/}
            {/*    src="https://desk.zoho.com/portal/api/web/inapp/912971000004347003?orgId=837121245"*/}
            {/*    defer*/}
            {/*  ></script>*/}
            {/*)}*/}
            <meta
              name="description"
              content="Dojah offers more API data points for user identity verification, onboarding, authentication and digital KYC than any other platform in Africa."
            />
            {/**********   Open Graph / Facebook  ***********/}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://app.dojah.io/" />
            <meta
              property="og:title"
              content="Verify Customer Identity Easily | KYC Verification | Dojah"
            />
            <meta
              property="og:description"
              content="Dojah offers more API data points for user identity verification, onboarding, authentication and digital KYC than any other platform in Africa."
            />
            <meta
              property="og:image"
              content="http://dojah.io/_next/static/media/og-image.e54d2fb7.svg"
            />

            {/**********   Twitter or X ***********/}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://app.dojah.io/" />
            <meta property="twitter:site" content="@DojahInc" />
            <meta
              property="twitter:title"
              content="Verify Customer Identity Easily | KYC Verification | Dojah"
            />
            <meta
              property="twitter:description"
              content="Dojah offers more API data points for user identity verification, onboarding, authentication and digital KYC than any other platform in Africa."
            />
            <meta
              property="twitter:image"
              content="http://dojah.io/_next/static/media/og-image.e54d2fb7.svg"
            />
            <meta
              name="description"
              content="Dojah offers more API data points for user identity verification, onboarding, authentication and digital KYC than any other platform in Africa."
            />
            <meta
              name="keywords"
              content="API, Identification, Verification, Tech, Fintech, Open Banking, Banks, Financials, KYC, Developers, Authentication, Identity"
            />
            <meta
              property="og:title"
              content="Verify Customer Identity Easily | KYC Verification | Dojah"
            />
            <meta property="og:url" content="https://dojah.io/" />
            <meta
              property="og:description"
              content="Dojah offers more API data points for user identity verification, onboarding, authentication and digital KYC than any other platform in Africa."
            />
            <meta name="twitter:site" content="@DojahInc" />
            <meta
              name="twitter:title"
              content="Verify Customer Identity Easily | KYC Verification | Dojah"
            />
            <meta name="twitter:url" content="https://dojah.io/" />
            <meta
              name="twitter:description"
              content="Dojah offers more API data points for user identity verification, onboarding, authentication and digital KYC than any other platform in Africa."
            />
          </Helmet>
        )}
        <Routes>
          {token.get('user:token')
            ? privateRoutes
            : !isReconcilo && (
                <Route
                  path="/*"
                  element={
                    <Navigate
                      to={Constants.PAGES_URL.SIGNIN}
                      replace
                      state={{from: this.props?.location}}
                    />
                  }
                />
              )}
          {publicRoutes}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    );
  }
}

export default connect(state => state, Actions)(withRouter(PageRoutes));
