import {separateCamelCaseWithCaps} from '../../../../helpers';
import * as Assets from '../../../../assets/images/images';

const getSubRuleTheme = type =>
  ({
    all: {
      icon: Assets.andIcon,
      color: '#9FBDED',
    },
    any: {
      icon: Assets.orIcon,
      color: '#FB9855',
    },
  }[type || 'all']);

const extractFields = (obj = {}) =>
  Object.keys(obj || {}).map(c => ({
    value: c,
    label: c?.replaceAll('_', ' '),
  }));

const findParentByChild = (selectedChild, data = {}) => {
  if (!selectedChild) return null;
  const {common = {}, banking = {}, card = {}} = data;

  const allCategories = {
    ...common,
    ...banking,
    ...card,
    transaction: {...(common.transaction || {}), ...(banking.transaction || {})},
  };
  for (const [parent, children] of Object.entries(allCategories)) {
    if (children[selectedChild]) {
      return {label: parent?.replaceAll('_', ' '), value: parent};
    }
  }

  return null;
};

const getParentFields = (data = {}, type) => {
  const {common = {}, banking = {}, card = {}} = data;
  const currentType = type || localStorage.getItem('currentFlowData');
  const uniqueKeys = [
    ...new Set([
      ...Object.keys(common),
      ...(currentType?.includes('bank')
        ? Object.keys(banking)
        : Object.keys(card)),
    ]),
  ];

  return uniqueKeys.map(c => ({
    value: c,
    label: c?.replaceAll('_', ' '),
  }));
};
const getChildFields = (parentField, data = {}) => {
  if (!parentField) return [];

  const {common = {}, banking = {}, card = {}} = data;
  const parentData =
    common[parentField] || banking[parentField] || card[parentField] || {};

  return extractFields(parentData);
};
const getOperators = (parentField, childField, data = {}) => {
  if (!parentField || !childField) return [];
  const fieldData =
    data?.common[parentField]?.[childField] ||
    data?.banking[parentField]?.[childField] ||
    data?.card[parentField]?.[childField];

  const operatorType =
    fieldData?.type === 'number' ? 'numeric' : fieldData?.type;

  return (fieldData ? data?.operators[operatorType] || [] : []).map(c => ({
    value: c,
    label: separateCamelCaseWithCaps(c),
    operatorType,
  }));
};
const mergeRules = (stagedRules, publishedRules) => {
  const stagedChanges = stagedRules?.data?.changes || [];
  const latestModifications = new Map();
  const newAdditions = new Map();

  stagedChanges.forEach(change => {
    const ruleId = change.rule?.id || change.id;

    if (change.type === 'modify') {
      latestModifications.set(ruleId, change);
      newAdditions.delete(ruleId);
    } else if (change.type === 'add' && !latestModifications.has(ruleId)) {
      newAdditions.set(ruleId, change);
    }
  });

  return [
    ...publishedRules.rules.filter(
      rule => !latestModifications.has(rule.id) && !newAdditions.has(rule.id),
    ),
    ...latestModifications.values(),
    ...newAdditions.values(),
  ];
};

export {
  mergeRules,
  getOperators,
  getChildFields,
  getParentFields,
  getSubRuleTheme,
  findParentByChild,
};
