import {api} from '../../helpers';
import {getCookieValue} from '../../helpers/getCookie';

const env = getCookieValue('env');
const environment = env === 'Production' ? 'live' : env;

const baasBaseRoute = '/baas/wallet';
const cardsBaseRoute = '/baas';

api.interceptors.request.use(
  config => {
    const isBAASRequest =
      config?.url?.startsWith(baasBaseRoute) ||
      config?.url?.startsWith(cardsBaseRoute);

    const environmentParam = {
      environment: environment?.toLowerCase() || 'sandbox',
    };

    if (isBAASRequest) {
      if (['get', 'delete'].includes(config.method?.toLowerCase())) {
        config.params = {
          ...config.params,
          ...environmentParam,
        };
      } else if (
        ['post', 'put', 'patch'].includes(config.method?.toLowerCase())
      ) {
        if (typeof config.data === 'string') {
          try {
            config.data = JSON.parse(config.data);
          } catch (e) {
            console.error('Failed to parse config.data as JSON:', e);
          }
        }

        config.data = {
          ...config.data,
          ...environmentParam,
        };

        config.headers = {
          ...config.headers,
          'Content-Type': 'application/json',
        };
      }
    }

    return config;
  },
  error => Promise.reject(error),
);

function fetchBAASTransactions(params) {
  return api
    .get(`${baasBaseRoute}/pool_account_transactions/fetch`, {...params})
    .then(res => res.data);
}
function resetPinOtp(data) {
  return api.post(`baas/wallet/reset_pin/otp`, data).then(res => res.data);
}
function resetPinValidateOtp(data) {
  return api
    .post(`baas/wallet/reset_pin/validate_otp`, data)
    .then(res => res.data);
}
function resetPinComplete(data) {
  return api.post(`baas/wallet/reset_pin/complete`, data).then(res => res.data);
}
function fetchBAASCards(params) {
  return api
    .get(`${cardsBaseRoute}/dojah_cards/v2/card/fetch`, {...params})
    .then(res => res.data);
}
function fetchBAASCardsSummary(params) {
  return api
    .get(`${cardsBaseRoute}/dojah_cards/v2/card/summary`, {...params})
    .then(res => res.data);
}

function fetchBAASCardDetailsSummary(params) {
  return api
    .get(`${cardsBaseRoute}/dojah_cards/v2/transaction/summary`, {...params})
    .then(res => res.data);
}

function fetchBAASCardInfo(params) {
  return api
    .get(`${cardsBaseRoute}/dojah_cards/v2/card/info`, {...params})
    .then(res => res.data);
}
function fetchBAASCardTransactions(params) {
  return api
    .get(`${cardsBaseRoute}/dojah_cards/v2/transaction/fetch`, {...params})
    .then(res => res.data);
}
function fetchBAASTransactionsSummary(params) {
  return api
    .get(`${baasBaseRoute}/pool_account_transactions/summary`, {...params})
    .then(res => res.data);
}
function fetchBAASBreakdown(params) {
  return api
    .get(`${baasBaseRoute}/admin/transaction_breakdown`, {...params})
    .then(res => res.data);
}
function fetchBAASCurrencies(params) {
  return api
    .get(`${baasBaseRoute}/currency`, {...params})
    .then(res => res.data);
}
function fetchBAASWalletSettlement(params) {
  return api
    .get(`${baasBaseRoute}/settlement`, {...params})
    .then(res => res.data);
}
function fetchBAASCustomerSummary(params) {
  return api
    .get(`${baasBaseRoute}/customer/summary`, {...params})
    .then(res => res.data);
}
function fetchBAASCompanyWallets(params) {
  return api
    .get(`${baasBaseRoute}/fetch_company_wallets`, {...params})
    .then(res => res.data);
}
function fetchBAASCompanyWallet(params) {
  return api
    .get(`${baasBaseRoute}/retrieve`, {...params})
    .then(res => res.data);
}
function fetchBAASTransaction(params) {
  return api
    .get(`${baasBaseRoute}/transaction/retrieve`, {...params})
    .then(res => res.data);
}
function fetchBAASCustomers(params) {
  return api
    .get(`${baasBaseRoute}/customer/fetch`, {params: params})
    .then(res => res.data);
}
function fetchBAASSingleCustomer(params) {
  return api
    .get(`${baasBaseRoute}/customer/retrieve`, params)
    .then(res => res.data);
}

function fetchBAASTransactionAnalysis(params) {
  return api
    .get(`${baasBaseRoute}/transaction_analysis`, {...params})
    .then(res => res.data);
}
function fetchBAASTransactionPayouts(params) {
  return api
    .get(`${baasBaseRoute}/payout/fetch`, {...params})
    .then(res => res.data);
}
function fetchBAASTransactionPayoutsSummary(params) {
  return api
    .get(`${baasBaseRoute}/payout/summary`, {...params})
    .then(res => res.data);
}
function fetchBAASTransactionPayout(params) {
  return api.get(`${baasBaseRoute}/payout`, {...params}).then(res => res.data);
}
function fetchBAASChart(chartsParams) {
  return api
    .get(`${baasBaseRoute}/chart`, {params: {...chartsParams}})
    .then(res => res.data);
}
function fetchBAASSettings(params) {
  return api
    .get(`${baasBaseRoute}/settings`, {...params})
    .then(res => res.data);
}
function createBAASSettings(data) {
  return api.post(`${baasBaseRoute}/settings`, {...data}).then(res => res.data);
}
function updateEmailNotification(data) {
  return api
    .post(`${baasBaseRoute}/email_notification`, {...data})
    .then(res => res.data);
}
function createBAASPin(data) {
  return api.post(`${baasBaseRoute}/pin`, {...data}).then(res => res.data);
}
function updateBAASPin(data) {
  return api
    .put(`${baasBaseRoute}/pin`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => res.data);
}
function validateBAASPin(data) {
  return api
    .post(`${baasBaseRoute}/pin/validate`, {...data})
    .then(res => res.data);
}

function updateBAASSettings(data) {
  return api.put(`${baasBaseRoute}/settings`, {...data}).then(res => res.data);
}
////////////////////////////////////// TRANSFER //////////////////////////////////////////
function initiateBAASTransfer(data) {
  return api
    .post(`${baasBaseRoute}/withdrawal/initiate`, {...data})
    .then(res => res.data);
}
function completeBAASTransfer(data) {
  return api
    .post(`${baasBaseRoute}/withdrawal/complete`, {...data})
    .then(res => res.data);
}
function sendBAASOtp(data) {
  return api
    .post(`${baasBaseRoute}/withdrawal/send_otp`, {...data})
    .then(res => res.data);
}
function validateBAASOtp(data) {
  return api
    .post(`${baasBaseRoute}/withdrawal/validate_otp`, {...data})
    .then(res => res.data);
}
function accountRetrieval(params) {
  return api
    .get(`/baas/identity/resolve_nuban`, {...params})
    .then(res => res.data);
}

function addSupportEmail(data) {
  return api
    .post(`${baasBaseRoute}/email_support`, {...data})
    .then(res => res.data);
}

export {
  accountRetrieval,
  createBAASPin,
  updateBAASPin,
  fetchBAASChart,
  validateBAASPin,
  fetchBAASSettings,
  resetPinComplete,
  resetPinOtp,
  resetPinValidateOtp,
  fetchBAASCards,
  fetchBAASCustomers,
  fetchBAASCardsSummary,
  fetchBAASCardTransactions,
  fetchBAASCardDetailsSummary,
  fetchBAASCardInfo,
  fetchBAASCustomerSummary,
  fetchBAASSingleCustomer,
  fetchBAASCurrencies,
  fetchBAASBreakdown,
  updateBAASSettings,
  createBAASSettings,
  fetchBAASTransaction,
  fetchBAASTransactions,
  fetchBAASCompanyWallet,
  updateEmailNotification,
  fetchBAASCompanyWallets,
  fetchBAASWalletSettlement,
  fetchBAASTransactionPayout,
  fetchBAASTransactionPayouts,
  fetchBAASTransactionAnalysis,
  fetchBAASTransactionsSummary,
  fetchBAASTransactionPayoutsSummary,
  initiateBAASTransfer,
  completeBAASTransfer,
  sendBAASOtp,
  validateBAASOtp,
  addSupportEmail,
};
