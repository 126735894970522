import React, {useCallback, useEffect, useState} from 'react';
import {calculateFileSize} from '../helpers';
import {DocumentListItem} from './DocumentListItem';

const FileUpload = ({uploadFiles, uploadBtn, uploaded}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [draggingOver, setDraggingOver] = useState(false);

  const handleFileUpload = event => {
    const files = event.target.files;

    const fileList = Object.values(files).map(file => file);
    setUploadedFiles(prevFiles => [...prevFiles, ...fileList]);
    event.target.value = '';
  };

  const getFileDataArray = useCallback(
    async uploadedFiles => {
      const fileDataArray = [];
      for (const file of uploadedFiles) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        await new Promise(resolve => {
          reader.onload = function () {
            const data = {
              file: reader.result,
              filename: file.name,
            };
            fileDataArray.push(data);
            resolve();
          };
        });
      }
      uploadFiles(fileDataArray);
      return fileDataArray;
    },
    [uploadFiles],
  );

  useEffect(() => {
    try {
      getFileDataArray(uploadedFiles).then(r => r);
    } catch (e) {
      console.log(e);
    }
  }, [uploadedFiles, getFileDataArray]);
  useEffect(() => {
    if (uploaded) {
      setUploadedFiles([]);
    }
  }, [uploaded, setUploadedFiles]);

  const handleDrop = event => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const fileList = Object.values(files).map(file => file);
    setUploadedFiles(prevFiles => [...prevFiles, ...fileList]);
    setDraggingOver(false);
  };

  const handleDragOver = event => {
    event.preventDefault();
    setDraggingOver(true);
  };

  const handleDragLeave = () => {
    setDraggingOver(false);
  };

  const handleFileChange = (index, event) => {
    const files = event.target.files;
    const newFile = Object.values(files)[0];
    setUploadedFiles(prevFiles => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = newFile;
      return updatedFiles;
    });
  };

  const handleFileRemove = index => {
    setUploadedFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
  };

  const renderThumbnail = (file, index) => {
    // const isImage = file.type.startsWith('image/');
    // const isPdf = file.type === 'application/pdf';
    const fileExtension = file.name.split('.').pop();
    const fileSizeString = calculateFileSize(file.size);

    const fileInfo = {
      fileTitle:
        file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name,
      fileExtension: fileExtension || '.PDF',
      fileSize: fileSizeString,
    };

    return (
      <div
        key={file.name + index}
        className={`flex w-full items-start mt-2 mb-1 `}
      >
        <DocumentListItem
          doc={fileInfo}
          editDoc={
            <div className="flex justify-center items-center">
              <label
                htmlFor={`file-change-${index}`}
                className="cursor-pointer text-brandBlue mr-2 text-xs hover:underline"
              >
                Change
              </label>
              <span className="opacity-20">|</span>
              <button
                type="button "
                className="text-danger ml-2 hover:underline text-xs"
                onClick={() => handleFileRemove(index)}
              >
                Remove
              </button>
              <input
                id={`file-change-${index}`}
                type="file"
                className="hidden"
                accept=".png, .jpg, .jpeg, .pdf"
                onChange={event => handleFileChange(index, event)}
              />
            </div>
          }
        />
      </div>
    );
  };

  return (
    <>
      <div className="p-1 border mb-6 border-dashed border-brandBlue80 rounded-md ">
        <div
          className={` text-center ${
            draggingOver ? 'bg-brandBlue4 text-brandBlue' : ''
          }`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <label
            htmlFor="file-upload"
            className="cursor-pointer flex items-center justify-center  p-4  h-14 text-[#555555] rounded-md hover:bg-brandBlue4"
          >
            {draggingOver
              ? 'Drop files here'
              : 'Click here or drag and drop files to upload'}
            <p className="text-xs text-body mt-1 p-0">
              <span className="opacity-60">Supported document type: </span>
              <span className=" text-brandBlue uppercase">
                {'   '}
                .png .jpg .jpeg .pdf
              </span>{' '}
            </p>
          </label>

          <input
            id="file-upload"
            type="file"
            className="hidden "
            multiple
            accept=".png, .jpg, .jpeg, .pdf"
            onChange={handleFileUpload}
          />
        </div>
      </div>
      {uploadedFiles.length > 0 && (
        <div>
          {uploadedFiles.map((file, index) =>
            renderThumbnail(file, index, uploadedFiles),
          )}
          <div className="mt-4">{uploadBtn}</div>
        </div>
      )}
    </>
  );
};

export default FileUpload;
