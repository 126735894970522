import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import {connect, useDispatch} from 'react-redux';
import moment from 'moment';
import {toast} from 'react-toastify';
import {
  appEmpty,
  chevronRight,
  configurationIc,
  edit,
  eyeDark,
  picture,
  trash,
} from '../../assets/images/images';
import {
  DashboardLayout,
  PrimaryButton,
  SearchInput,
  TableLayout,
  Loader,
  SkeletonLoader,
  EmptyState,
  PageLimit,
  Pagination,
} from '../../components';
import CreateAppModal from './CreateAppModal';
import DeleteAppModal from './DeleteAppModal';
import EditAppModal from './EditAppModal';
import GenerateKeysModal from './GenerateKeysModal';
import RevealKeyModal from './RevealKeyModal';
import * as Actions from '../../store/actions';
import {getCookieValue} from '../../helpers/getCookie';
import {DevelopersTour} from '../../components/Tour';
import {PageContext, TourContext} from '../../helpers/context';
import {CopyIcon} from '../../components/images';
import {useQuery} from 'react-query';
import {fetchAllApps} from '../../requests/queries/dashboard';

function DevelopersConfiguration({
  getAllApps,
  getApiKeys,
  apps: {live},
  auth: {regeneratedKey, userDetails, loading},
}) {
  const [openCreateApp, setOpenCreateApp] = useState(false);
  const [openEditApp, setOpenEditApp] = useState(false);
  const [openDeleteApp, setOpenDeleteApp] = useState(false);
  const [openRevealKey, setOpenRevealKey] = useState(false);
  const [openGenerateKey, setOpenGenerateKey] = useState(false);
  const [currentApp, setCurrentApp] = useState({});
  const [search, setSearch] = useState('');
  const [view, setView] = useState(false);
  const [currentPage, setCurrentPage] = useState('1');
  const [limit, setLimit] = useState(10);
  const env = getCookieValue('env');

  const dispatch = useDispatch();

  const {data: allApps, isLoading: appLoading} = useQuery(
    ['all-apps', {name: search, page: currentPage, limit}],
    () =>
      fetchAllApps({
        params: {name: search, page: currentPage, limit},
        dispatch,
      }),
  );

  const pageClick = useCallback(selected => {
    setCurrentPage(selected);
  }, []);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('developers');
  }, [setPageName]);

  useEffect(() => {
    getAllApps({name: search, page: currentPage, limit});
  }, [search, getAllApps, currentPage, limit, getApiKeys]);

  useEffect(() => {
    getApiKeys();
  }, [getApiKeys]);

  const publicKeys =
    regeneratedKey &&
    regeneratedKey[live ? 'prod_public_key' : 'sandbox_public_key'];

  const privateKeys =
    regeneratedKey &&
    regeneratedKey[live ? 'prod_private_key' : 'sandbox_private_key'];
  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success('Code copied');
  };

  const {
    manageApps: canManageApps,
    regenerateKeys: canRegenerateKeys,
    viewLiveKeys: canViewLiveKeys,
  } = useMemo(
    () => userDetails?.permissions?.developerPermissions,
    [userDetails],
  ) || {};

  const {tourState, updateTourState} = useContext(TourContext);

  return (
    <DashboardLayout
      bg="bg-white"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={configurationIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Developers</p>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">Configuration</p>
        </div>
      }
    >
      {tourState?.developers_tour ? (
        <DevelopersTour
          updateTourState={updateTourState}
          DATA={allApps?.apps}
          loading={loading}
        />
      ) : null}

      <CreateAppModal open={openCreateApp} setOpen={setOpenCreateApp} />
      <EditAppModal
        currentApp={currentApp}
        open={openEditApp}
        setOpen={setOpenEditApp}
      />
      <DeleteAppModal
        appId={currentApp?._id}
        open={openDeleteApp}
        setOpen={setOpenDeleteApp}
      />
      <RevealKeyModal
        setRevealKey={setView}
        open={openRevealKey}
        setOpen={setOpenRevealKey}
      />
      <GenerateKeysModal open={openGenerateKey} setOpen={setOpenGenerateKey} />

      <section className="flex flex-col">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-base font-medium text-grey sm:text-lg">
            Configuration
          </h2>

          <PrimaryButton
            buttonText="Create App"
            onClick={() => setOpenCreateApp(prev => !prev)}
            disabled={!canManageApps}
          />
        </div>
        <h3 className="mt-6 text-base font-medium text-body">API Keys</h3>
        <div className="p-4 mt-4 rounded-lg bg-white80">
          <div className="p-6 bg-white rounded-lg">
            <div className="flex items-center justify-between lg:max-w-[78.39%] gap-8 sm:gap-4 flex-wrap">
              <div>
                <p className="text-xs font-medium uppercase text-body">
                  {env} public keys
                </p>
                <div className="flex flex-col gap-2 mt-2">
                  {loading ? (
                    <SkeletonLoader style={{width: 227}} />
                  ) : (
                    <p className="text-sm font-medium text-grey">
                      {publicKeys}
                    </p>
                  )}

                  <button
                    onClick={() => copyText(publicKeys)}
                    className="flex items-center gap-2 px-2 pt-[9px] pb-[7px] border rounded border-brandBlue w-fit"
                    disabled={env === 'Production' && !canViewLiveKeys}
                    title={
                      env === 'Production' && !canViewLiveKeys
                        ? 'You do not have permission to copy live public key'
                        : 'Copy public key'
                    }
                  >
                    <CopyIcon />
                    <span className="text-xs font-medium text-brandBlue">
                      Copy
                    </span>
                  </button>
                </div>
              </div>
              <div>
                <p className="text-xs font-medium uppercase text-body ">
                  {env} private keys
                </p>
                <div className="flex flex-col gap-2 mt-2">
                  {loading ? (
                    <SkeletonLoader style={{width: 227}} />
                  ) : (
                    <p className="text-sm font-medium text-grey">
                      {view
                        ? privateKeys
                        : privateKeys?.replace(/./g, '*') ||
                          '***********************'}
                    </p>
                  )}

                  <div className="flex items-center gap-2">
                    {!view ? (
                      <button
                        disabled={env === 'Production' && !canViewLiveKeys}
                        title={
                          env === 'Production' && !canViewLiveKeys
                            ? 'You do not have permission to view live private key'
                            : 'Reveal key'
                        }
                        className="flex items-center gap-2 px-2 pt-[9px] pb-[7px] border rounded border-body"
                        onClick={() => setOpenRevealKey(prev => !prev)}
                      >
                        <img src={eyeDark} alt="" width={14} height={14} />
                        <span className="text-xs font-medium text-body">
                          {view ? 'Hide' : 'Reveal'} key
                        </span>
                      </button>
                    ) : (
                      <button
                        className="flex items-center gap-2 px-2 pt-[9px] pb-[7px] border rounded border-body"
                        onClick={() => setView(!view)}
                      >
                        <img src={eyeDark} alt="" width={14} height={14} />
                        <span className="text-xs font-medium text-body">
                          {view ? 'Hide' : 'Reveal'} key
                        </span>
                      </button>
                    )}

                    <button
                      disabled={live && view === false}
                      onClick={() => copyText(privateKeys)}
                      style={{
                        cursor:
                          live && view === false ? 'not-allowed' : 'pointer',
                      }}
                      className="flex items-center gap-2 px-2 pt-[9px] pb-[7px] border rounded border-brandBlue"
                    >
                      <CopyIcon />
                      <span className="text-xs font-medium text-brandBlue">
                        Copy
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button
              title={
                canRegenerateKeys
                  ? 'Generate New Keys'
                  : 'You do not have permission to Generate New Keys'
              }
              disabled={!canRegenerateKeys}
              className="px-2 pt-[10px] pb-[6px] mt-6 text-xs font-medium border rounded text-body border-body"
              onClick={() => setOpenGenerateKey(prev => !prev)}
              style={{cursor: !canRegenerateKeys && 'not-allowed'}}
            >
              Generate New Keys
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between mt-4 mb-5">
          <h3 className="text-base font-medium text-body">Apps</h3>
          <SearchInput
            onChange={value => setSearch(value)}
            className="sm:w-[525px] outline-white80"
            defaultValue={search}
            // disabled={!allApps}
          />
        </div>
        <TableLayout negativeMargins negativeRightMargin="-mr-4 xl:-mr-[76px]">
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-4 text-xs font-medium text-left sm:pl-6 xl:pl-12 text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>app name</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>app id</span>
                </div>
              </th>
              <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                <div className="flex items-end gap-1">
                  <span>date/time</span>
                </div>
              </th>
              <th className="w-[25.9%]"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={8}>
                {appLoading ? (
                  <Loader height={35} />
                ) : search?.length > 0 && !appLoading && allApps === null ? (
                  <EmptyState
                    body="Ops no app found, try another search term"
                    height={50}
                    src={appEmpty}
                    onClick={() => window.location.reload()}
                    buttonText={`Reset search`}
                    maxWidth="max-w-[376px] p-6"
                  />
                ) : !search && !appLoading && !allApps?.apps ? (
                  <EmptyState
                    body="Creating an app is required to make use of features on the application"
                    height={50}
                    src={appEmpty}
                    buttonText="Create app"
                    onClick={() => setOpenCreateApp(prev => !prev)}
                    maxWidth="max-w-[276px] p-6"
                  />
                ) : null}
              </td>
            </tr>
          </tbody>
          <tbody className="text-sm bg-white">
            {allApps &&
              allApps?.apps?.map((app, i) => (
                <tr className="border-b border-grey60" key={i}>
                  <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                    <div className="flex items-center gap-[5px]">
                      <img
                        src={app?.logo || picture}
                        alt=""
                        width={20}
                        height={20}
                      />
                      <span>{app?.name || '-'}</span>
                    </div>
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {app?._id || '-'}
                  </td>
                  <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                    {moment(app?.createdAt).format('Do MMM, YYYY h:mm a') ||
                      '-'}
                  </td>
                  <td className="p-4 pl-0 whitespace-nowrap">
                    <div className="flex items-center gap-4">
                      <>
                        <button
                          disabled={!canManageApps}
                          title={
                            canManageApps
                              ? 'Edit app'
                              : 'You do not have permission to edit app'
                          }
                          className="flex items-end gap-1 w-max"
                          onClick={() => {
                            setCurrentApp(app);
                            setOpenEditApp(prev => !prev);
                          }}
                          style={{cursor: !canManageApps && 'not-allowed'}}
                        >
                          <img src={edit} alt="" width={16} height={16} />
                        </button>
                        <button
                          disabled={!canManageApps}
                          title={
                            canManageApps
                              ? 'Delete app'
                              : 'You do not have permission to delete app'
                          }
                          onClick={() => {
                            setCurrentApp(app);
                            setOpenDeleteApp(prev => !prev);
                          }}
                          className="h-4 aspect-square"
                          style={{cursor: !canManageApps && 'not-allowed'}}
                        >
                          <img src={trash} alt="" width={16} height={16} />
                        </button>
                      </>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>
        {!appLoading && allApps?.apps?.length > 0 && (
          <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                onLimitChange={setLimit}
                // className="mt-3 mt-sm-0"
                total={allApps && allApps?.totalRecords}
                length={allApps?.apps?.length}
                limit={limit}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Pagination
                total={allApps && Math.ceil(allApps?.totalRecords / limit)}
                current={+currentPage}
                onPageChange={activePage => {
                  pageClick(activePage);
                }}
              />
            </div>
          </div>
        )}
      </section>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(DevelopersConfiguration);
