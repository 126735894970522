import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  corruptedFile,
  transactionHistoryBreadcrumb,
  roundDownload,
} from '../../../assets/images/images';
import {
  Filter,
  FilterButton,
  Loader,
  Pagination,
  PrimaryButton,
  SecondaryButton,
  TableLayout,
  TopupModal,
} from '../../../components';
import SettingsLayout from '../SettingsLayout';
import PaymentLimitModal from './PaymentLimitModal';
import * as Actions from '../../../store/actions';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../../helpers/formatNumberToCurrency';
import EmptyState from '../../../components/EmptyState';
import PageLimit from '../../../components/PageLimit';
import BillingsLayout from './BillingsLayout';
import {
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../../helpers';
import {PageContext} from '../../../helpers/context';
import {generateBillingPDF} from './generateBillingPDF';
import AppliedFilters from 'components/AppliedFilters';

const filterOptions = [
  {
    title: 'Type',
    name: 'type',
    values: [
      {
        label: 'Paystack',
        value: 'Paystack',
        name: 'Paystack',
      },
      {
        label: 'Transfer',
        value: 'Transfer',
        name: 'Transfer',
      },
    ],
  },
];

function SettingsBilling({
  children,
  auth: {billings, loading, userDetails, updated},
  apps: {mAppLoading},
  getBillings,
  exportBillings,
}) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openFilter, setOpenFilter] = useState(false);
  const [openLimit, setOpenLimit] = useState(false);
  const [openTopup, setOpenTopup] = useState(false);
  const [filterProps, setFilterProps] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('billings');
  }, [setPageName]);

  useEffect(() => {
    getBillings({limit, page: currentPage});
  }, [getBillings, currentPage, limit, updated]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setCurrentPage(parseInt(pageParam));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = currentPage;
    }

    if (apiParams.type) {
      apiParams.type =
        apiParams.type.charAt(0).toUpperCase() + apiParams.type.slice(1);
    }

    getBillings(apiParams);
  }, [currentPage, getBillings, limit]);

  const handleFilter = selectedOptions => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);

    setCurrentPage(1);
    navigate(`?${queryParams}&page=1`);

    getBillings({
      page: currentPage,
      limit,
      ...filteredOptions,
    });
    setOpenFilter(false);
    setFiltersApplied(true);
  };

  const canTopUpBilling = userDetails?.permissions?.billingPermissions?.topup;

  const canManageThreshold =
    userDetails?.permissions?.billingPermissions?.manageThreshold;

  const canExportHistory =
    userDetails?.permissions?.billingPermissions?.exportHistory;

  const handleResetFilter = () => {
    navigate('');
    setCurrentPage(1);
    getBillings({limit, page: 1});
    setOpenFilter(true);
    setFiltersApplied(false);
  };

  const handleDownloadPDF = billing => {
    const currency = userDetails?.company?.currency || 'NGN';
    const userName = `${userDetails?.first_name} ${userDetails?.last_name}`;
    const fileName = `transaction_${billing.reference_number}`;

    generateBillingPDF([billing], currency, userName, fileName);
  };

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <SettingsLayout
      isSettingsPage={false}
      pageTitle="Billing"
      pageIcon={transactionHistoryBreadcrumb}
      action={
        <>
          <Filter
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            handleFilter={handleFilter}
            resetUrl={handleResetFilter}
            filterOptions={filterOptions}
            onFilterStateChange={handleFilterStateChange}
          />
        </>
      }
    >
      <PaymentLimitModal open={openLimit} setOpen={setOpenLimit} />
      <TopupModal open={openTopup} setOpen={setOpenTopup} />

      <div className="flex flex-wrap items-end justify-between gap-4 p-6 mt-6 bg-white rounded-lg">
        <div>
          <div className="flex items-center gap-4 flex-wrap">
            <p className="text-sm font-medium text-body">Wallet Balance</p>
            {filtersApplied && filterProps && (
              <AppliedFilters {...filterProps} isMainPage={true} />
            )}
          </div>
          <p className="mt-1 font-medium text-grey text-xxl">
            {userDetails &&
            userDetails?.company &&
            userDetails?.company?.currency === 'USD'
              ? formatUsd(userDetails?.company?.wallet_balance / 100 ?? 0)
              : formatNumberToCurrency(
                  userDetails?.company?.wallet_balance / 100 ?? 0,
                  userDetails &&
                    userDetails.company &&
                    userDetails.company.currency,
                )}
          </p>
          <p className="text-sm font-medium text-danger">
            Payment threshold:{' '}
            {userDetails &&
            userDetails?.company &&
            userDetails?.company?.currency === 'USD'
              ? formatUsd(userDetails?.company?.wallet_limit / 100)
              : formatNumberToCurrency(
                  userDetails?.company?.wallet_limit / 100,
                  userDetails &&
                    userDetails.company &&
                    userDetails.company.currency,
                )}
          </p>
        </div>

        <div className="flex items-center gap-2">
          <PrimaryButton
            buttonText="Top up wallet"
            onClick={() => setOpenTopup(!openTopup)}
            fontSize="text-xs"
            yPadding="py-2"
            xPadding="px-2"
            disabled={!canTopUpBilling}
          />

          <SecondaryButton
            buttonText="Manage threshold"
            onClick={() => setOpenLimit(!openLimit)}
            fontSize="text-xs"
            yPadding="py-2"
            xPadding="px-2"
            btnHeight="h-auto"
            disabled={!canManageThreshold}
          />
        </div>
      </div>
      <BillingsLayout>
        {children ?? (
          <section className="mt-6">
            <div className="flex flex-wrap items-center justify-between gap-4 mb-6 sm:flex-nowrap sm:gap-0">
              <p className="text-sm font-medium text-grey">
                Transaction history
              </p>
              <div className="flex items-center gap-4">
                {/* <SearchInput className="bg-white outline-white" /> */}
                <FilterButton
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                />

                <PrimaryButton
                  onClick={() => {
                    const apiParams = parseUrlParams();
                    apiParams.limit = limit;

                    // api expects first letter to be uppercase
                    if (apiParams.type) {
                      apiParams.type =
                        apiParams.type.charAt(0).toUpperCase() +
                        apiParams.type.slice(1);
                    }

                    exportBillings(apiParams);
                  }}
                  buttonText="Export"
                  loading={mAppLoading}
                  disabled={!canExportHistory}
                />
              </div>
            </div>
            <TableLayout
              negativeMargins
              negativeRightMargin="-mr-4 xl:-mr-[76px]"
            >
              <thead className="text-xs font-semibold uppercase">
                <tr className="bg-white80">
                  <th className="p-5 pl-4 text-xs font-medium text-left sm:pl-6 xl:pl-12 text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>Amount</span>
                    </div>
                  </th>
                  <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>reference number</span>
                    </div>
                  </th>
                  <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>status</span>
                    </div>
                  </th>
                  <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>payment type</span>
                    </div>
                  </th>
                  <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                    <div className="flex items-end gap-1">
                      <span>date created</span>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td colSpan={8}>
                    {loading ? (
                      <Loader height={45} />
                    ) : !loading && !billings ? (
                      <EmptyState
                        body={
                          billings === undefined
                            ? 'No data found'
                            : 'This filter did not return any data, try using different values.'
                        }
                        src={corruptedFile}
                        noBtn
                        customBtn={
                          billings === undefined ? null : (
                            <button
                              onClick={handleResetFilter}
                              className="text-brandBlue p-4 text-sm font-medium"
                            >
                              Update preferences
                            </button>
                          )
                        }
                      />
                    ) : !loading && !billings?.api_logs?.length ? (
                      <EmptyState
                        noBtn
                        body={
                          billings === undefined
                            ? 'No data found'
                            : 'This filter did not return any data, try using different values.'
                        }
                        src={corruptedFile}
                        customBtn={
                          billings === undefined ? null : (
                            <span
                              onClick={handleResetFilter}
                              className="text-brandBlue cursor-pointer"
                            >
                              Reset filter
                            </span>
                          )
                        }
                      />
                    ) : null}
                  </td>
                </tr>
              </tbody>

              <tbody className="text-sm bg-white">
                {!loading &&
                  billings &&
                  billings?.api_logs?.map((billing, i) => (
                    <tr className="border-b border-grey60" key={i}>
                      <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                        {userDetails &&
                          userDetails?.company &&
                          formatNumberToCurrency(
                            billing?.amount / 100 ?? 0,
                            userDetails &&
                              userDetails.company &&
                              userDetails.company.currency,
                          )}
                      </td>
                      <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                        {billing?.reference_number || '-'}
                      </td>
                      <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                        <div
                          className={`px-2 py-1 text-xs font-medium uppercase rounded-full  w-fit ${
                            billing?.status?.toLowerCase() === 'failed'
                              ? 'bg-red text-white'
                              : billing?.status?.toLowerCase() === 'successful'
                              ? 'bg-success20 text-success'
                              : billing?.status?.toLowerCase() === 'pending'
                              ? 'bg-warning'
                              : 'bg-disabled'
                          }`}
                        >
                          {billing?.status || '-'}
                        </div>
                      </td>
                      <td className="p-4 pl-0 whitespace-nowrap">
                        {billing?.type || '-'}
                      </td>
                      <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                        {moment(billing?.createdAt).format(
                          'Do MMM, YYYY, hh:mmA',
                        ) || '-'}
                      </td>
                      <td>
                        <button
                          onClick={() => handleDownloadPDF(billing)}
                          className="flex items-center gap-2 text-[#0D192C] rounded-lg py-2 px-3 border border-[#12376914] text-xs font-medium font-inter"
                        >
                          Download
                          <img src={roundDownload} alt="download" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </TableLayout>

            {!loading && billings?.api_logs?.length !== 0 && (
              <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
                <div className="flex items-center text-body text-tiny">
                  <PageLimit
                    onLimitChange={setLimit}
                    // className="mt-3 mt-sm-0"
                    total={billings && billings?.totalRecords}
                    length={billings?.api_logs?.length}
                    limit={limit}
                  />
                </div>

                <div className="mb-8 sm:mb-0">
                  <Pagination
                    total={
                      billings && Math.ceil(billings?.totalRecords / limit)
                    }
                    current={+currentPage}
                    onPageChange={activePage => {
                      pageClick(activePage);
                      const searchParams = new URLSearchParams(
                        document.location.search,
                      );
                      searchParams.set('page', activePage);
                      const newSearchParams = searchParams.toString();
                      navigate(`?${newSearchParams}`);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="flex flex-wrap items-center gap-4 sm:hidden">
              <SecondaryButton
                buttonText="Create payment limit"
                className="w-full"
              />
              <PrimaryButton buttonText="Top Up Wallet" className="w-full" />
            </div>
          </section>
        )}
      </BillingsLayout>
    </SettingsLayout>
  );
}

export default connect(state => state, Actions)(SettingsBilling);
