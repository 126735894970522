import React from 'react';

export const Address = () => (
  <>
    <header className="flex flex-col items-center mt-2">
      <h4 className="mt-4 font-medium text-[#1B2A4E] font-inter text-base">
        Address
      </h4>
    </header>
    <form className="w-full max-w-sm">
      <label
        htmlFor="address"
        className="mt-4 mb-1 text-[#1B2A4E] text-sm font-normal"
      >
        Address
        <input
          type="text"
          id="address"
          className="mt-2 text-sm text-[#8D8D91] bg-[#F6F6F8] border-none outline-none rounded"
        />
      </label>
    </form>
  </>
);
