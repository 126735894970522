import React, {useContext, useEffect, useRef} from 'react';
import {widgetCheck, widgetFace} from '../../../assets/images/images';
import {
  disclaimerPageDefaultInfo as defaultInformation,
  toggleStringConversion,
} from '../widgetHelpers';
import {EasyOnboardContext} from '../../../helpers/context/EasyOnboardContext';
import {useSelector} from 'react-redux';
import {Loader} from '../../../components';

const ConfigDisclaimer = () => {
  const {w_loading} = useSelector(state => state?.widgets);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  });

  const {widgetData} = useContext(EasyOnboardContext);
  const instructions =
    widgetData?.index_instructions ||
    toggleStringConversion(defaultInformation);

  const getBreakClass = text => {
    const words = text?.split(/\s+/);
    const hasLongWord = words?.some(word => word?.length > 15);
    return hasLongWord ? 'break-all' : 'break-words';
  };

  return (
    <>
      <header className="mt-2">
        <img src={widgetFace} alt="" width={80} height={80} className="mt-8" />
      </header>
      <div className="max-w-sm font-inter">
        {w_loading || initialRender?.current ? (
          <div className="p-6 mt-8 w-[380px] border rounded-lg bg-[#EBF1FC] border-brandBlue60 min-h-[150px] transition-all ease-in-out delay-150 ">
            <Loader height={initialRender?.current ? 9 : 16} />
          </div>
        ) : (
          <div className="p-6 mt-8 border rounded-lg bg-brandBlue40 border-brandBlue60 min-h-[200px] transition-all ease-in-out delay-150 ">
            <p className="font-bold text-tiny text-[#1B2A4E]">
              Please note the following
            </p>
            {instructions?.map((line, index) => (
              <div
                key={index}
                className={`flex items-start gap-3 mt-4 max-w-[98%] ${getBreakClass(
                  line,
                )}`}
              >
                <img
                  src={widgetCheck}
                  alt=""
                  width={14}
                  height={14}
                  className="flex-shrink-0"
                />
                <p className="text-small text-[#677189] -mt-[2px]">{line}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(ConfigDisclaimer);
