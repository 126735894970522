import {createHashHistory} from 'history';
import {toast} from 'react-toastify';
import reduxApi from '../../helpers/reduxApi';
import {
  GET_ALL_APPS,
  CLEAR_ALL_APPS,
  SET_LIVE,
  UPDATED,
  LOADING,
  APP_LOADING,
  M_APP_LOADING,
} from '../types';
import {getUserProfile} from './auth';
import {PAGES_URL} from '../../helpers/constants';
createHashHistory();

export const getAllApps = data =>
  reduxApi(
    `/apps?limit=${data?.limit || 10}&page=${data?.page || 1}&name=${
      data?.name || ''
    }`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        const appData =
          data.name === undefined && data.apps.length === 0 ? null : data;
        const appIds =
          (appData && appData?.apps?.map(app => app?._id).toString()) || '';
        localStorage.setItem('appIds', appIds);
        dispatch({payload: appData, type: GET_ALL_APPS});
      },
    },
    {error: 'ERROR', loading: APP_LOADING, responder: GET_ALL_APPS},
  );

export const createApp = (data, setOpen) =>
  reduxApi(
    `/apps`,
    'POST',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch(getUserProfile());
        dispatch(getAllApps());
        setOpen(false);
        dispatch({type: UPDATED});
        toast.success('App Created successfully');
      },
    },
    {error: 'ERROR', loading: M_APP_LOADING, responder: UPDATED},
  );
export const clearAllApps = ({data, appId}) =>
  reduxApi(
    `/users/widget/lookup${data}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: appId, type: GET_ALL_APPS});
      },
    },
    {error: 'ERROR', loading: LOADING, responder: CLEAR_ALL_APPS},
  );
export const setLive = data => dispatch => {
  sessionStorage.removeItem('project_id');

  const subDomainUser = localStorage.getItem('subdomain');
  const isReconcilo = subDomainUser?.includes('reconcilo');
  const isTrypila = subDomainUser?.includes('trypila');
  const projectId = sessionStorage.getItem('project_id') || 'add-project';

  dispatch({payload: data, type: SET_LIVE});

  localStorage.setItem('env', data ? 'Production' : 'Sandbox');
  toast.success(`You are now in ${data ? 'Production' : 'Sandbox'} mode`);

  const redirectUrl = isReconcilo
    ? PAGES_URL.PROJECT_ACCOUNTS?.replace(':slug', projectId)
    : isTrypila
    ? PAGES_URL.BAAS_OVERVIEW
    : subDomainUser
    ? PAGES_URL.DEVELOPERS_CONFIGURATION
    : PAGES_URL.QUICKACTIONS;

  window.location.assign(redirectUrl);
};

export const updateApp = (id, data, setOpen, type) =>
  reduxApi(
    `/apps/${id}`,
    'PUT',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({type: UPDATED});
        dispatch(getAllApps());
        !type && toast.success('App Updated Successfully');
        setOpen && setOpen(false);
        if (type) {
          getAllApps({});
        }
      },
    },
    {error: 'ERROR', loading: M_APP_LOADING, responder: UPDATED},
  );
export const deleteApp = (id, setOpen) =>
  reduxApi(
    `/apps/${id}`,
    'DELETE',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        toast.success('App Deleted Successfully');
        dispatch({type: UPDATED});
        dispatch(getAllApps());
        setOpen(false);
      },
    },
    {error: 'ERROR', loading: M_APP_LOADING, responder: UPDATED},
  );
