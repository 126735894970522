import React, {useContext} from 'react';
import {css} from '@emotion/react';
import {connect, useSelector} from 'react-redux';

import PuffLoader from 'react-spinners/PuffLoader';
import {closeModal} from '../../../../assets/images/images';
import {Modal, SecondaryButton} from '../../../../components';
import * as Actions from '../../../../store/actions';
import {toast} from 'react-toastify';
import {useMutation, useQueryClient} from 'react-query';
import {deleteTask} from '../../../../requests/queries/flows';
import {CreditCheckContext} from '../../../../helpers/context/CreditCheckContext';

function DeleteAppModal({createFlowProcess}) {
  const user = useSelector(state => state?.auth?.user);

  const {
    set: {setOpenModals, setTempFlow},
    get: {
      tempFlow,
      openModals: {openDeleteAction},
    },
  } = useContext(CreditCheckContext);
  const queryClient = useQueryClient();
  const {mutateAsync: deleteATask, isLoading} = useMutation(
    id => deleteTask(id),
    {
      onSuccess: data => {
        toast.success(`${data?.message} successfully`);
        queryClient.invalidateQueries();
      },
      onError: error => toast.error(error?.response?.data?.error),
    },
  );

  const handleDeleteActionSteps = async () => {
    const newProcess = [...tempFlow];
    const index = localStorage.getItem('ruleIndex');
    const taskId = localStorage.getItem('taskId') || '';

    newProcess[1].splice(+index, 1);
    setTempFlow(newProcess);
    createFlowProcess({process: newProcess});
    if (taskId === 'undefined') {
      setOpenModals({openDeleteAction: false});
      return toast.success('Unsaved task deleted successfully!');
    }

    await deleteATask({id: taskId, userId: user.id});
    setOpenModals({openDeleteAction: false});
  };

  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;

  return (
    <Modal
      show={openDeleteAction}
      onClose={value => setOpenModals({openDeleteAction: value})}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-grey text-base font-medium">Are you sure?</h3>
          <button onClick={() => setOpenModals({openDeleteAction: false})}>
            <img src={closeModal} alt="" />
          </button>
        </header>

        <p className="mt-4 text-sm text-body">
          By deleting this Rule, you are deleting every Configuration associated
          with it.
        </p>

        <div className="mt-4 flex items-center gap-4">
          <SecondaryButton
            buttonText="Cancel"
            onClick={() => setOpenModals({openDeleteAction: false})}
          />
          <button
            onClick={handleDeleteActionSteps}
            className={` ${
              isLoading
                ? 'flex items-center justify-center relative h-12 px-4 py-6'
                : ''
            } text-white text-sm font-medium bg-danger py-4 px-[81px] rounded`}
          >
            {isLoading ? (
              <PuffLoader css={override} size={35} color="#ffffff" />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, Actions)(DeleteAppModal);
