import React from 'react';
import {TourProvider} from './TourContext';
import {LogoProvider} from './CompanyLogoContext';
import {ColorProvider} from './CompanyColorContext';
import FilterContextProvider from './FilterContext';
import {CheckboxContextProvider} from './CheckboxContext';
import EasyOnboardContextProvider from './EasyOnboardContext';
import CreditCheckContextProvider from './CreditCheckContext';

const GlobalContextProvider = ({isAuthenticated, children}) => {
  if (isAuthenticated) {
    return (
      <CheckboxContextProvider>
        <FilterContextProvider>
          <EasyOnboardContextProvider>
            <CreditCheckContextProvider>{children}</CreditCheckContextProvider>
          </EasyOnboardContextProvider>
        </FilterContextProvider>
      </CheckboxContextProvider>
    );
  }

  return (
    <ColorProvider>
      <LogoProvider>
        <TourProvider>
          <CheckboxContextProvider>
            <FilterContextProvider>
              <EasyOnboardContextProvider>
                <CreditCheckContextProvider>
                  {children}
                </CreditCheckContextProvider>
              </EasyOnboardContextProvider>
            </FilterContextProvider>
          </CheckboxContextProvider>
        </TourProvider>
      </LogoProvider>
    </ColorProvider>
  );
};

export default GlobalContextProvider;
