/* eslint-disable react/jsx-indent */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import {Menu} from '@headlessui/react';
import {Link, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import * as Actions from '../../../store/actions';
import {
  edit,
  eye,
  resend,
  teamIc,
  trashRed,
} from '../../../assets/images/images';
import {
  Pagination,
  PrimaryButton,
  SecondaryButton,
  TableLayout,
  PageLimit,
  EmptyState,
  SearchInput,
  Filter,
  TeamesDropdown as Dropdown,
} from '../../../components';
import Loader from '../../../components/Loader';
import SettingsLayout from '../SettingsLayout';
import EditMemberModal from './EditMemberModal';
import InviteMemberModal from './InviteMemberModal';
import ViewMemberModal from './ViewMemberModal';
import FilterButton from '../../../components/FilterButton';
import {PageContext, TourContext} from '../../../helpers/context';
import {TeamTour} from '../../../components/Tour';
import TransferOwnership from './TransferOwnershipModal';
import {
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../../helpers';
import DeactivateMemberModal from './DeactivateMemberModal';
import AppliedFilters from 'components/AppliedFilters';

const filterOptions = [
  {
    title: 'Status',
    name: 'status',
    values: [
      {label: 'Active', value: 'active', name: 'active'},
      {label: 'Pending', value: 'pending', name: 'pending'},
      {label: 'Expired', value: 'expired', name: 'expired'},
    ],
  },
];

const SettingsTeam = ({
  getTeamMembers,
  getTeamRoles,
  inviteTeamMember,
  removeTeamMember,
  editTeamMemberRole,
  getAllApps,
  assignApp,
  team: {members, roles, loading},
  auth: {userDetails},
}) => {
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    filter: '',
  });
  const [transferModal, setTransferModal] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterProps, setFilterProps] = useState(null);

  const globalSearchParams = new URLSearchParams(document.location.search);
  const searchTermFromUrl = globalSearchParams.get('filter');

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('teams');
  }, [setPageName]);

  const pageClick = selected => {
    setQuery({
      ...query,
      page: selected,
    });
  };

  useEffect(() => {
    const apiParams = parseUrlParams();

    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setQuery(prevQuery => ({
        ...prevQuery,
        page: parseInt(pageParam),
      }));
      apiParams.page = parseInt(pageParam);
    } else {
      apiParams.page = query.page;
    }

    const queryParams = new URLSearchParams(apiParams).toString();

    const searchTermFromUrl = searchParams.get('filter');
    if (searchTermFromUrl) {
      setQuery(prevQuery => ({...prevQuery, filter: searchTermFromUrl}));
      apiParams.filter = searchTermFromUrl;
    } else {
      apiParams.filter = '';
    }

    getTeamMembers(queryParams);

    if (Object.keys(apiParams).length === 1 && 'page' in apiParams) {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }
  }, [getTeamMembers, query.page]);

  const handleFilter = selectedOptions => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);

    setQuery({
      ...query,
      page: 1,
    });

    const updatedQuery = {
      ...query,
      ...filteredOptions,
    };

    const queryString = new URLSearchParams(updatedQuery).toString();

    navigate(`?${queryParams.toString()}&page=1`);
    getTeamMembers(queryString);
    setFiltersApplied(true);
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    navigate('');
    setQuery({
      ...query,
      page: 1,
    });
    getTeamMembers(new URLSearchParams({}).toString());
  };

  useEffect(() => {
    if (initialRender.current) {
      getTeamRoles();
      getAllApps({limit: '500'});
      initialRender.current = false;
    }
  });

  const diffInHours = useCallback(
    (date1, date2) => Math.abs(date1 - date2) / 36e5,
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resendInvite = member => {
    if (
      member.status !== 'active' &&
      diffInHours(new Date(member.createdAt), new Date()) > 24
    ) {
      inviteTeamMember(
        {
          email: member.email,
          role: member.permissions?.role,
        },
        () => setOpen(false),
      );
    }
  };

  const takeAction = member => {
    if (
      member.status !== 'active' &&
      diffInHours(new Date(member.createdAt), new Date()) > 24
    ) {
      inviteTeamMember(
        {
          email: member.email,
          role: member.permissions?.role,
        },
        () => setOpen(false),
      );
    } else {
      setSelectedMember({
        id: member._id,
        email: member.email,
        role: member.permissions?.role,
        user: member.user,
        apps: member.apps,
      });
      setEditModal(!editModal);
    }
  };

  const {
    managePermissions: canManagePermissions,
    manageMembers: canManageMembers,
  } =
    useMemo(
      () => userDetails?.permissions?.settingsPermissions,
      [userDetails],
    ) || {};

  const {tourState, updateTourState} = useContext(TourContext);

  const isOwner = userDetails?.permissions?.role === 'Owner';

  const actionMenuItems = useMemo(
    () => [
      {
        icon: eye,
        label: 'View',
        action: () => setViewModal(!viewModal),
      },
      {
        icon: edit,
        label: 'Edit',
        action: () => setEditModal(!editModal),
      },
      {
        icon: resend,
        label: 'Resend Invite',
        action: resendInvite,
      },
      // {
      //   icon: suspend,
      //   label: 'Suspend',
      // },
      {
        icon: trashRed,
        label: 'Delete',
        action: () => setDeactivateModal(!deactivateModal),
      },
    ],
    [deactivateModal, editModal, viewModal, resendInvite],
  );

  const handleFilterStateChange = props => {
    setFilterProps(props);
  };

  return (
    <SettingsLayout
      pageTitle="Team"
      pageIcon={teamIc}
      action={
        <div className="items-center hidden gap-4 sm:flex">
          <Link to="/settings/team/owner">
            <SecondaryButton
              disabled={!canManagePermissions}
              style={{cursor: !canManagePermissions && 'not-allowed'}}
              buttonText="Manage Teams & Permissions"
            />
          </Link>

          <PrimaryButton
            buttonText="Invite a member"
            onClick={() => setOpen(!open)}
            disabled={!canManageMembers}
            style={{cursor: !canManageMembers && 'not-allowed'}}
          />
        </div>
      }
    >
      {tourState?.team_tour ? (
        <TeamTour
          updateTourState={updateTourState}
          DATA={members?.team}
          loading={loading}
        />
      ) : null}

      <InviteMemberModal
        open={open}
        setOpen={setOpen}
        roles={roles}
        inviteTeamMember={inviteTeamMember}
        loading={loading}
      />

      <DeactivateMemberModal
        loading={loading}
        selectedMember={selectedMember}
        removeTeamMember={removeTeamMember}
        deactivateModal={deactivateModal}
        setDeactivateModal={setDeactivateModal}
        setOpen={setOpen}
      />
      <EditMemberModal
        open={editModal}
        setOpen={setEditModal}
        roles={roles}
        editTeamMemberRole={editTeamMemberRole}
        loading={loading}
        removeTeamMember={removeTeamMember}
        selectedMember={selectedMember}
        deactivateModal={deactivateModal}
        setDeactivateModal={setDeactivateModal}
        assignApp={assignApp}
      />
      <ViewMemberModal
        takeAction={takeAction}
        viewModal={viewModal}
        setViewModal={setViewModal}
        roles={roles}
        editTeamMemberRole={editTeamMemberRole}
        loading={loading}
        removeTeamMember={removeTeamMember}
        selectedMember={selectedMember}
        deactivateModal={deactivateModal}
        setDeactivateModal={setDeactivateModal}
      />
      <TransferOwnership
        open={transferModal}
        setOpen={setTransferModal}
        roles={roles}
        selectedMember={selectedMember}
      />

      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilter={handleFilter}
        resetUrl={handleResetFilter}
        filterOptions={filterOptions}
        hideDateFilter
        onFilterStateChange={handleFilterStateChange}
      />

      <section className="mt-6 sm:mt-11">
        <>
          {!loading && members?.team?.length === 0 ? (
            <>
              <div className="flex flex-col items-start justify-between gap-6 mb-6 sm:mb-11 md:flex-row-reverse md:items-center md:gap-0 overflow-none">
                <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
                  <SearchInput
                    onChange={value =>
                      setQuery(prevState => ({
                        ...prevState,
                        filter: value,
                      }))
                    }
                    defaultValue={query.filter}
                  />

                  <div className="flex items-center gap-4 flex-wrap">
                    {filtersApplied && filterProps && (
                      <AppliedFilters {...filterProps} isMainPage={true} />
                    )}
                    <FilterButton
                      openFilter={openFilter}
                      setOpenFilter={setOpenFilter}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col items-start justify-between gap-6 mb-6 sm:mb-11 md:flex-row-reverse md:items-center md:gap-0 overflow-none">
                <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
                  <SearchInput
                    onChange={text => {
                      const updatedQuery = {...query, filter: text};
                      setQuery(updatedQuery);
                      if (text) {
                        const queryString = new URLSearchParams(
                          updatedQuery,
                        ).toString();
                        getTeamMembers(queryString);
                        navigate(`?filter=${text}`);
                      } else {
                        handleResetFilter();
                      }
                    }}
                    defaultValue={searchTermFromUrl || query.filter}
                  />

                  <FilterButton
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                  />
                  {isOwner ? (
                    <button
                      className="px-4 pt-[18px] pb-[14px] bg-white text-brandBlue rounded text-sm font-medium"
                      onClick={() => setTransferModal(true)}
                    >
                      Transfer ownership
                    </button>
                  ) : null}
                </div>
              </div>
              <TableLayout
                negativeMargins
                negativeRightMargin="-mr-4 xl:-mr-[76px]"
                noOverflow
              >
                <thead className="text-xs font-semibold uppercase">
                  <tr className="bg-white80">
                    <th className="p-5 pl-4 text-xs font-medium text-left sm:pl-6 xl:pl-12 text-grey whitespace-nowrap">
                      <div className="flex items-end gap-1">
                        <span>Name of team member</span>
                      </div>
                    </th>
                    <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                      <div className="flex items-end gap-1">
                        <span>Email address</span>
                      </div>
                    </th>
                    <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                      <div className="flex items-end gap-1">
                        <span>Role</span>
                      </div>
                    </th>
                    <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                      <div className="flex items-end gap-1">
                        <span>Date added</span>
                      </div>
                    </th>
                    <th className="p-5 pl-0 text-xs font-medium text-left text-grey whitespace-nowrap">
                      <div className="flex items-end gap-1">
                        <span>status</span>
                      </div>
                    </th>
                    <th className="p-3 text-xs font-medium  text-grey whitespace-nowrap">
                      Action
                    </th>
                    {/*<th></th>*/}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td colSpan={8}>
                      {loading ? (
                        <Loader height={45} />
                      ) : !loading &&
                        !filtersApplied &&
                        members?.team.length === 0 ? (
                        <EmptyState
                          body={'No data was found.'}
                          buttonText="Invite a member"
                          onClick={() => setOpen(!open)}
                          disabled={
                            !canManageMembers &&
                            userDetails?.permissions?.settingsPermissions
                              ?.manageMembers
                          }
                          style={{cursor: !canManageMembers && 'not-allowed'}}
                          height={45}
                        />
                      ) : !loading &&
                        filtersApplied &&
                        members?.team.length === 0 ? (
                        <EmptyState
                          body="No results found"
                          noBtn
                          customBtn={
                            <button
                              onClick={handleResetFilter}
                              className="text-brandBlue p-4 text-sm font-medium"
                            >
                              Update preferences
                            </button>
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                </tbody>

                <tbody className="text-sm bg-white">
                  {members &&
                    members.team.map((member, i) => {
                      const isExpired =
                        member.status !== 'active' &&
                        diffInHours(new Date(member.createdAt), new Date()) >
                          24;
                      return (
                        <tr
                          className="border-b cursor-pointer border-grey60"
                          key={i}
                        >
                          <td className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body whitespace-nowrap">
                            {member.user?.first_name
                              ? `${member.user.first_name} ${member.user?.last_name}`
                              : '-'}
                          </td>
                          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                            {member.email}
                          </td>
                          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                            {member.permissions?.role}
                          </td>
                          <td className="p-4 pl-0 text-tiny text-body whitespace-nowrap">
                            {moment(member.createdAt).format(
                              'Do, MMMM YYYY, hh:mmA',
                            )}
                          </td>
                          <td className="p-4 pl-0 whitespace-nowrap">
                            <div
                              className={`px-2 py-1 text-xs font-medium uppercase rounded-full text-success20 ${
                                member.status !== 'active'
                                  ? isExpired
                                    ? 'bg-status-500'
                                    : 'bg-brandBlue50'
                                  : 'bg-success'
                              } w-fit`}
                            >
                              {member.status !== 'active'
                                ? isExpired
                                  ? 'expired'
                                  : 'pending'
                                : member.status}
                            </div>
                          </td>
                          <td className=" text-sm text-center text-grey80 whitespace-nowrap">
                            {member.user?._id !== members.admin && (
                              <div className="flex justify-center">
                                <Dropdown>
                                  {actionMenuItems
                                    .filter(
                                      item =>
                                        !(
                                          item.label === 'Resend Invite' &&
                                          !isExpired
                                        ),
                                    )
                                    .map(item => (
                                      <Menu.Item
                                        disabled={!canManageMembers}
                                        key={item.label}
                                      >
                                        {() => (
                                          <button
                                            onClick={() => {
                                              setSelectedMember({
                                                id: member._id,
                                                email: member.email,
                                                role: member.permissions?.role,
                                                user: member.user,
                                                apps: member.apps,
                                                status: member.status,
                                                createdAt: member.createdAt,
                                              });
                                              item.action(member);
                                            }}
                                            disabled={!canManageMembers}
                                            className="z-40 flex items-center gap-4 w-full font-medium text-gray-500 text-tiny"
                                          >
                                            <img src={item.icon} alt="" />
                                            {item.label}
                                          </button>
                                        )}
                                      </Menu.Item>
                                    ))}
                                </Dropdown>
                              </div>
                            )}
                          </td>
                          {/*<td className="p-4 pl-0 whitespace-nowrap">*/}
                          {/*  {member.user?._id !== members.admin && (*/}
                          {/*    <button*/}
                          {/*      title="View role"*/}
                          {/*      className="flex items-end gap-1 w-max"*/}
                          {/*      onClick={() => {*/}
                          {/*        setSelectedMember(member);*/}
                          {/*        setViewModal(true);*/}
                          {/*      }}*/}
                          {/*    >*/}
                          {/*      <img*/}
                          {/*        src={eye}*/}
                          {/*        alt=""*/}
                          {/*        width={16}*/}
                          {/*        height={16}*/}
                          {/*      />*/}
                          {/*    </button>*/}
                          {/*  )}*/}
                          {/*</td>*/}
                        </tr>
                      );
                    })}
                </tbody>
              </TableLayout>
            </>
          )}

          {!loading && members?.team?.length > 0 && (
            <div className="flex flex-wrap items-center justify-between gap-4 mt-8 mb-8 sm:gap-0 sm:mb-0">
              <PageLimit
                onLimitChange={limit => {
                  setQuery(prevState => ({
                    ...prevState,
                    limit,
                    page: 1,
                  }));
                }}
                // className="mt-3 mt-sm-0"
                total={members?.totalRecords}
                length={members?.team?.length}
              />

              <Pagination
                total={members?.totalPages}
                current={Number(members?.currentPage)}
                onPageChange={activePage => {
                  pageClick(activePage);
                  const searchParams = new URLSearchParams(
                    document.location.search,
                  );
                  searchParams.set('page', activePage);
                  const newSearchParams = searchParams.toString();
                  navigate(`?${newSearchParams}`);
                }}
              />
            </div>
          )}
        </>
        <div className="flex flex-wrap items-center gap-4 sm:hidden">
          <Link to="/settings/team/owner" className="w-full">
            <SecondaryButton
              buttonText="Manage Teams & Permissions"
              className="w-full"
            />
          </Link>
          {userDetails?.permissions?.settingsPermissions?.manageMembers && (
            <PrimaryButton
              buttonText="Invite a member"
              onClick={() => setOpen(!open)}
              className="w-full"
            />
          )}
        </div>
      </section>
    </SettingsLayout>
  );
};

export default connect(state => state, Actions)(SettingsTeam);
