/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useMemo} from 'react';
import * as Components from '../../../../components';
import {Modal, PrimaryButton, SecondaryButton} from '../../../../components';
import Select from 'react-select';
import {IS_TRUE_OR_FALSE} from './transactions';
import {closeModal, plus, trashDarkRed} from '../../../../assets/images/images';
import {CreditCheckContext} from '../../../../helpers/context/CreditCheckContext';
import {connect} from 'react-redux';
import * as Actions from '../../../../store/actions';
import {separateCamelCaseWithCaps} from '../../../../helpers';
import {
  findParentByChild,
  getChildFields,
  getOperators,
  getParentFields,
} from './utils';

const ConditionInputs = ({
  action,
  condition,
  onClick,
  fieldsType,
  isLoading,
  handleAddCondition,
}) => {
  const {
    set: {setRuleState, setOpenModals},
    get: {
      ruleState,
      currentFlowData,
      currentSubRuleConditions = [],
      openModals: {openConditionEditorModal},
    },
    handleDeleteCondition,
    handleUpdateCondition,
  } = React.useContext(CreditCheckContext);

  const taskId = localStorage.getItem('taskId') || '';
  const ruleConditionIndex = localStorage.getItem('ruleConditionIndex') || '0';
  const ruleConditionsKey = localStorage.getItem('ruleConditionsKey') || '0';

  const availableFields = React.useMemo(() => {
    if (!isLoading && fieldsType) {
      return fieldsType.data;
    }
  }, [fieldsType?.data, isLoading]);

  const PARENT_FIELD = useMemo(
    () => getParentFields(availableFields, currentFlowData?.usecase),
    [availableFields],
  );

  const findParentFields = useCallback(
    childValue => findParentByChild(childValue, availableFields),
    [availableFields],
  );

  const CHILD_FIELD = useMemo(
    () =>
      getChildFields(ruleState.selectedParent[action]?.value, availableFields),
    [ruleState.selectedParent[action]?.value],
  );

  const OPERATOR_FIELD = useMemo(
    () =>
      getOperators(
        ruleState.selectedParent[action]?.value,
        ruleState.selectedField[action]?.value,
        availableFields,
      ),
    [
      ruleState.selectedParent[action]?.value,
      ruleState.selectedField[action]?.value,
    ],
  );

  const OPERATORS = [
    ...new Set(Object.values(availableFields?.operators || {}).flat() || []),
  ].map(op => ({
    value: op,
    label: separateCamelCaseWithCaps(op),
  }));

  const CURRENT_CONDITION = {};
  const [selectedTrueOrFalse, setSelectedTrueOrFalse] = React.useState(
    IS_TRUE_OR_FALSE[0] || [],
  );

  const currentParent = React.useMemo(
    () =>
      [...((PARENT_FIELD?.length && PARENT_FIELD) || [])]?.find(
        parent =>
          parent?.label?.toLowerCase() ===
          CURRENT_CONDITION?.parent?.toLowerCase(),
      ),
    [condition?.key],
  );

  const currentFiled = React.useMemo(
    () =>
      CHILD_FIELD[currentParent?.value]?.length &&
      CHILD_FIELD[currentParent?.value]?.find(
        field => field?.value === CURRENT_CONDITION?.field,
      ),
    [condition?.key],
  );
  const currentOperator = React.useMemo(
    () =>
      OPERATORS.find(
        operator => operator.value === CURRENT_CONDITION?.operator,
      ),
    [condition?.key],
  );

  const isPepOrSanctionList = ['is_pep', 'is_sanction_list']?.includes(
    CURRENT_CONDITION?.fact,
  );
  // const isInOrNotIn = ['contain', 'doesNotContain']?.includes(
  //   selectedOperator[action]?.value,
  // );
  const isInOrNotIn =
    ruleState.selectedOperator[action]?.operatorType === 'boolean';

  React.useEffect(() => {
    setRuleState(prev => ({
      ...prev,
      selectedParent: {...currentParent, [action]: currentParent},
      selectedField: {...currentFiled, [action]: currentFiled},
      actionValue: {
        ...ruleState.actionValue,
        [action]: CURRENT_CONDITION?.value,
      },
      actionScore: {[action]: ruleState.currentRule?.score},
    }));
  }, [
    setRuleState,
    setSelectedTrueOrFalse,
    currentParent,
    action,
    currentFiled,
  ]);

  const valueObject = React.useCallback(() => {
    if (isInOrNotIn) {
      return {
        value: selectedTrueOrFalse,
        func: setSelectedTrueOrFalse,
        option: IS_TRUE_OR_FALSE,
      };
    }
  }, [isInOrNotIn, selectedTrueOrFalse, IS_TRUE_OR_FALSE]);

  return (
    <Modal
      show={openConditionEditorModal}
      onClose={value => setOpenModals({openConditionEditorModal: value})}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <div className="flex items-center gap-2 text-sm">
            <p className="opacity-50 font-thin  uppercase tracking-wide">
              {`${
                ruleState.currentRule?.name
              } - ${ruleConditionsKey?.toUpperCase()}`}
            </p>
            <h3 className=" text-sm  font-medium text-grey">
              {` > SUB-RULE ${+ruleConditionIndex + 1}`}
            </h3>
          </div>

          <button
            onClick={() => setOpenModals({openConditionEditorModal: false})}
          >
            <img src={closeModal} alt="" width={24} height={24} />
          </button>
        </header>
        {Array.isArray(currentSubRuleConditions) &&
          currentSubRuleConditions?.map((condition, index) => (
            <Components.Accordion
              key={index}
              isOpen={false}
              title={
                condition?.key === 0
                  ? 'Condition - 1'
                  : `Condition - ${index + 1}`
              }
              className={`bg-white80 rounded mt-3 mb-3`}
            >
              <div className="mt-5 pt-1 ">
                <label htmlFor="end_process" className="mt-4 mb-2 relative">
                  Parent Field
                </label>
                <Select
                  defaultValue={
                    currentParent || findParentFields(condition?.fact)
                  }
                  onChange={data => {
                    handleUpdateCondition('parent', data?.label, index);

                    setRuleState(prev => ({
                      ...prev,
                      selectedField: {value: '', label: 'Select Fact'},
                      selectedParent: {
                        [action]: data,
                      },
                    }));
                    setSelectedTrueOrFalse({});
                  }}
                  options={PARENT_FIELD}
                  placeholder="Select an Parent Field"
                  className="basic-multi-select white-select capitalize"
                  classNamePrefix="react-select"
                  components={{DropdownIndicator: Components.DropdownIndicator}}
                  styles={Components.customStyles}
                  menuPlacement="auto"
                />
                <label htmlFor="end_process" className="mb-2 mt-4 relative">
                  Fact
                </label>

                <Select
                  defaultValue={
                    currentFiled || {
                      label: condition.fact.replaceAll('_', ' '),
                      value: condition.fact,
                    }
                  }
                  onChange={data => {
                    handleUpdateCondition('fact', data?.value, index);

                    setRuleState(prev => ({
                      ...prev,
                      selectedField: {
                        [action]: data,
                      },
                    }));
                    setSelectedTrueOrFalse({});
                  }}
                  options={
                    CHILD_FIELD.length > 0
                      ? CHILD_FIELD
                      : getChildFields(
                          findParentFields(condition?.fact)?.value,
                          availableFields,
                        )
                  }
                  formatGroupLabel={Components.formatGroupLabel}
                  placeholder="Select field"
                  className="basic-multi-select white-select capitalize"
                  classNamePrefix="react-select"
                  components={{DropdownIndicator: Components.DropdownIndicator}}
                  styles={Components.customStyles}
                  menuPlacement="auto"
                />
                <label htmlFor="end_process" className="mt-4 mb-2 relative">
                  Operator
                </label>

                <Select
                  defaultValue={
                    currentOperator || {
                      label: separateCamelCaseWithCaps(condition.operator),
                      value: condition.operator,
                    }
                  }
                  onChange={data => {
                    handleUpdateCondition('operator', data?.value, index);
                    setRuleState(prev => ({
                      ...prev,
                      selectedOperator: {
                        ...prev.selectedOperator,
                        [action]: data,
                      },
                    }));
                    setSelectedTrueOrFalse({});
                  }}
                  // options={filteredOperators}
                  options={
                    OPERATOR_FIELD.length > 0
                      ? OPERATOR_FIELD
                      : getOperators(
                          findParentFields(condition?.fact)?.value,
                          condition.fact,
                          availableFields,
                        )
                  }
                  placeholder="Select an operator"
                  className="basic-multi-select white-select capitalize"
                  classNamePrefix="react-select"
                  components={{DropdownIndicator: Components.DropdownIndicator}}
                  styles={Components.customStyles}
                  menuPlacement="auto"
                />

                {/*********** VALUE should be dynamic ************/}

                <label htmlFor="value" className="mt-4">
                  Value
                </label>
                {isInOrNotIn || isPepOrSanctionList ? (
                  <Select
                    defaultValue={valueObject()?.value}
                    onChange={data => {
                      handleUpdateCondition('value', data?.value, index);
                      valueObject()?.func(data);

                      setRuleState(prev => ({
                        ...prev,
                        actionValue: {
                          ...prev.actionValue,
                          [index]: data?.value,
                        },
                      }));
                    }}
                    options={valueObject()?.option}
                    placeholder="Select list"
                    className="basic-multi-select white-select capitalize"
                    classNamePrefix="react-select"
                    components={{
                      DropdownIndicator: Components.DropdownIndicator,
                    }}
                    styles={Components.customStyles}
                    menuPlacement="auto"
                  />
                ) : (
                  <input
                    data-bg="white"
                    type="text"
                    id="value"
                    className="mt-2 text-sm conditions-style"
                    required
                    placeholder={(condition?.value || '').toString()}
                    onChange={e => {
                      handleUpdateCondition('value', e.target.value, index);
                      setRuleState(prev => ({
                        ...prev,
                        actionValue: {
                          ...prev.actionValue,
                          [index]: e.target.value,
                        },
                      }));
                    }}
                  />
                )}
              </div>
              {index >= 1 && (
                <div className="mt-5">
                  <Components.SecondaryButton
                    src={trashDarkRed}
                    buttonText="Delete condition"
                    className="text-[#A13E3F]  bg-white outline outline-1 ml-auto outline-[#A13E3F] flex items-center justify-center gap-3 outline-none"
                    leftImg
                    width={15}
                    onClick={() => {
                      handleDeleteCondition(index);
                    }}
                    noOutline
                  />
                </div>
              )}
            </Components.Accordion>
          ))}

        <div className="mt-6 flex items-start gap-3">
          <PrimaryButton
            buttonText={taskId && taskId !== 'undefined' ? 'Update' : 'Apply'}
            className="w-full border border-brandBlue"
            onClick={onClick}
            // disabled={
            //   disabled ||
            //   !isNestedObjectTruthy({
            //     ...allData,
            //     actionValue: actionValue[action]?.toString(),
            //   })
            // }
            loading={isLoading}
          />
          {currentSubRuleConditions.length < 4 && (
            <SecondaryButton
              src={plus}
              buttonText="Add new Condition"
              className="w-full flex items-center justify-center gap-5"
              leftImg
              width={25}
              onClick={handleAddCondition}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
export default connect(state => state, Actions)(ConditionInputs);
