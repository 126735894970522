/*eslint-disable  react/jsx-indent*/
import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import moment from 'moment';
import {
  biodataIc,
  chevronRight,
  verificationsIc,
  addressIc,
  ipDeviceCheckIc,
  chevronRightSm,
  amlCheckIc,
  failed_t,
} from '../../assets/images/images';
import {
  DashboardLayout,
  SecondaryButton,
  PrimaryButton,
} from '../../components';
import {Constants, capitalizeEveryWord} from '../../helpers';
import ActivityLog from './ActivityLog';
import ReusableLink from './ReusableLink';
import ReviewUserModal from './ReviewUserModal';
import SessionsModal from './SessionsModal';
import * as Actions from '../../store/actions';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../helpers/formatNumberToCurrency';
import {getCookieValue} from '../../helpers/getCookie';
import {
  getStatusTextAndStyles,
  // getStatusWithNumbers,
} from '../../helpers/getStatusTextAndStyles';

function CostSummary({summary = [], displayPrice}) {
  const [seeMore, setSeeMore] = useState(false);
  const ARR = seeMore ? summary : summary.slice(0, 4);
  return (
    <div className="mt-2 flex flex-col gap-2">
      {ARR.map((item, index) => (
        <Fragment key={index}>
          {!['index', 'countries'].includes(item?.name) && (
            <div
              key={index}
              className="flex items-center justify-between gap-2"
            >
              <p className="text-sm text-body w-full capitalize">{item.name}</p>
              <p className="text-sm text-brandBlue">
                {displayPrice(item.cost, item.cost / 100)}
              </p>
            </div>
          )}
        </Fragment>
      ))}

      {summary?.length > 4 && (
        <SecondaryButton
          buttonText={seeMore ? 'See less' : 'See more'}
          fontSize="text-xs"
          className="w-fit"
          xPadding="px-2"
          yPadding="py-2"
          btnHeight="h-fit"
          onClick={() => setSeeMore(!seeMore)}
        />
      )}
    </div>
  );
}

function Layout({
  verifications: {session_summary, loading},
  children,
  verificationId,
  auth: {userDetails, userSettings},
}) {
  const {slug} = useParams();
  const [openReview, setOpenReview] = useState(false);
  const [openSessions, setOpenSessions] = useState(false);
  const location = window.location.pathname;
  const route = location.split('/')[2];

  const VERIFICATION = session_summary?.data?.Verification;
  const COST = session_summary?.data?.cost || [];
  const vStatus = VERIFICATION?.status?.toLowerCase();
  const reviewProcess = VERIFICATION?.review_process;
  const reviewStatus = VERIFICATION?.review_status;
  // const isReviewerStatus = ![null, undefined, 0].includes(reviewStatus);
  // const REVIEW_STATUS = isReviewerStatus && reviewStatus;
  // const rStatus = getStatusWithNumbers(REVIEW_STATUS);

  const envData = getCookieValue('env');

  const COST_SUMMARY =
    envData === 'Production'
      ? COST.filter(cost => Boolean(Number(cost.cost)))
      : COST.map(cost => ({
          ...cost,
          cost: 0,
        }));

  const showReviewButton =
    !loading &&
    vStatus === 'pending' &&
    (reviewProcess === 'Manual' ||
      (reviewProcess === 'Automatic' && [null, 0, 2].includes(reviewStatus)));

  const TOTAL_COST = COST_SUMMARY?.reduce(
    (acc, cost) => acc + Number(cost?.cost),
    0,
  );

  const reviewUserPermission =
    userDetails?.permissions?.onboardPermissions?.reviewVerifications;

  const LINKS = [
    {
      path: Constants.PAGES_URL.VERIFICATIONS_BIO_DATA.replace(
        ':slug',
        verificationId || slug,
      ),
      src: biodataIc,
      text: 'Bio data',
    },
    {
      path: Constants.PAGES_URL.VERIFICATIONS_IP_DEVICE_CHECK.replace(
        ':slug',
        verificationId || slug,
      ),
      src: ipDeviceCheckIc,
      text: 'IP/Device Check',
    },
    {
      path: Constants.PAGES_URL.VERIFICATIONS_ADDRESS.replace(
        ':slug',
        verificationId || slug,
      ),
      src: addressIc,
      text: 'Address',
    },
    {
      path: Constants.PAGES_URL.VERIFICATIONS_AML_CHECK.replace(
        ':slug',
        verificationId || slug,
      ),
      src: amlCheckIc,
      text: 'AML Check',
    },
  ];

  const displayPrice = (condition, price) =>
    condition &&
    userDetails &&
    userDetails.company &&
    userDetails.company.currency === 'USD'
      ? formatUsd(price || 0)
      : formatNumberToCurrency(
          price || 0,
          userDetails && userDetails.company && userDetails.company.currency,
          userSettings &&
            userSettings.settings &&
            userSettings.settings.dollar_exchange_rate,
        );

  return (
    <DashboardLayout
      bg="bg-white xl:bg-white80"
      topPadding="pt-0"
      xlLeftMargin="xl:ml-12"
      fullScreen
      overFlow=""
      breadCrumbs={
        <div className="flex items-center">
          <img src={verificationsIc} alt="" width={18} height={18} />
          <Link to={Constants.PAGES_URL.VERIFICATIONS} className="ml-2 -mb-1">
            Verifications
          </Link>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">
            {capitalizeEveryWord(route.split('-').join(' '))}
          </p>
        </div>
      }
    >
      <ReviewUserModal open={openReview} setOpen={setOpenReview} />
      <SessionsModal
        totalTime={VERIFICATION?.completion_time}
        open={openSessions}
        setOpen={setOpenSessions}
      />
      <div className="flex-wrap items-start min-h-full xl:flex xl:flex-nowrap basis-full">
        <div className="basis-[17.14%] flex flex-col gap-6 pt-16 sm:pt-6 xl:pt-[35px] xl:sticky xl:top-[35px]">
          <h2 className="hidden text-base font-medium sm:block text-grey sm:text-xl whitespace-nowrap">
            Verifications Details
          </h2>
          <div className="flex items-center justify-between sm:hidden">
            <h2 className="text-base font-medium sm:hidden text-grey sm:text-xl whitespace-nowrap">
              Verifications
            </h2>
            <div
              className={`uppercase pt-[5px] pb-[3px] px-2 text-xs ${
                VERIFICATION?.status?.toLowerCase() === 'failed'
                  ? 'text-white bg-danger'
                  : 'text-success20 bg-success'
              } font-medium  rounded-full w-fit flex items-start gap-[2px]`}
            >
              {VERIFICATION?.status ?? '-'}
            </div>
          </div>
          <div className="border-b border-grey60 xl:border-none">
            <div className="flex gap-2 xl:p-2 xl:flex-col">
              {LINKS.map(({path, src, text}) => (
                <ReusableLink key={path} path={path} src={src} text={text} />
              ))}
            </div>
          </div>
        </div>

        <div className="xl:ml-[37px] xl:basis-[45.67%] mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white80 xl:bg-white">
          {children}
        </div>

        <div className="xl:basis-[36.04%] xl:px-6 py-10 xl:sticky xl:top-[35px] bg-white xl:bg-white80">
          <div className="flex items-center justify-between h-1">
            {!loading && (
              <div className="flex gap-5 items-center justify-between w-full">
                <div
                  className={`flex uppercase pt-[5px] pb-[3px] px-2 text-xs  font-medium ${
                    getStatusTextAndStyles(vStatus).styles
                  } rounded-full w-fit items-start gap-[2px]`}
                >
                  {getStatusTextAndStyles(vStatus).text}
                </div>

                {/* <SecondaryButton
                  buttonText="Download"
                  className="w-fit h-11 font-inter flex items-center justify-center !py-5 !px-4"
                /> */}

                {/*{isReviewerStatus && (*/}
                {/*  <div className="flex items-center p-2 bg-brandBlue4 rounded">*/}
                {/*    <p className="font-medium text-xs">{"Reviewer's Status"}</p>*/}
                {/*    <div*/}
                {/*      className={`flex uppercase pt-[5px] pb-[3px] px-2 text-xs ml-3  font-medium ${*/}
                {/*        getStatusTextAndStyles(rStatus || 'pending').styles*/}
                {/*      } rounded-full w-fit items-start gap-[2px]`}*/}
                {/*    >*/}
                {/*      {getStatusTextAndStyles(rStatus || 'pending').text}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            )}

            {showReviewButton && (
              <PrimaryButton
                buttonText="Review user"
                fontSize="text-xs"
                xPadding="px-2"
                yPadding="pt-[9px] pb-[7px]"
                className="hidden sm:flex"
                onClick={() => setOpenReview(!openReview)}
                disabled={!reviewUserPermission}
              />
            )}

            <div
              className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80"
              style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
            >
              {!loading && vStatus === 'pending' && (
                <PrimaryButton
                  buttonText="Review user"
                  xPadding="p-[35px]"
                  className="w-full bottom-2"
                  onClick={() => setOpenReview(!openReview)}
                  disabled={!reviewUserPermission}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-1 p-4 mt-4 text-xs uppercase rounded bg-white80 xl:bg-white text-body">
            <span className="font-medium">Reference ID</span>
            <span>{VERIFICATION?.reference_id ?? '-'}</span>
          </div>
          <div className="flex flex-wrap items-stretch gap-4 mt-4 lg:flex-nowrap">
            <></>
            <div className="w-full p-4 rounded bg-white80 xl:bg-white">
              <p className="mt-2 text-xs font-medium uppercase text-grey">
                Total time spent
              </p>
              <p className="mt-2 text-xs text-body">
                The amount of time taken to process verification details
              </p>
              <p className="mt-2 text-xl font-medium text-grey">
                {VERIFICATION?.completion_time
                  ?.replace('m', ':')
                  .replace('s', '') || '00:00'}
              </p>
            </div>
            <div className="w-full p-4 rounded bg-white80 xl:bg-white">
              <p className="mt-2 text-xs font-medium uppercase text-grey">
                Sessions
              </p>
              <p className="mt-2 text-xs text-body">Number of sessions</p>
              <p className="mt-2 text-xl font-medium text-grey">
                {session_summary?.data?.sessions?.length || 1}
              </p>
              <SecondaryButton
                className="flex items-start gap-2 mt-2"
                fontSize="text-xs"
                xPadding="px-2"
                yPadding="pt-[9px] pb-[7px]"
                buttonText="View all sessions"
                btnHeight="h-7"
                src={chevronRightSm}
                width={12}
                height={12}
                onClick={() => setOpenSessions(!openSessions)}
              />
            </div>
          </div>

          <div className=" p-4 bg-white items-stretch  mt-4 w-full">
            <p className="mt-2 text-xs font-medium uppercase text-grey">
              date/time created
            </p>
            <div className="mt-2 flex rounded w-full">
              <p className="font-medium text-grey">
                {VERIFICATION && VERIFICATION.date_created
                  ? moment(VERIFICATION.date_created).format('Do MMMM YYYY')
                  : loading
                  ? '-'
                  : '-'}
              </p>

              <p className=" font-medium text-grey">
                ,{' '}
                {VERIFICATION && VERIFICATION.date_created
                  ? moment(VERIFICATION.date_created).format('hh:mm A')
                  : loading
                  ? '-'
                  : '-'}
              </p>
            </div>
          </div>

          {loading ? null : VERIFICATION?.review_process?.toLowerCase() ===
            'manual' ? (
            <>
              {(vStatus === 'failed' ||
                VERIFICATION?.r_action?.toLowerCase() === 'failed' ||
                VERIFICATION?.r_action?.toLowerCase() === 'completed') &&
                vStatus !== 'completed' &&
                vStatus !== 'successful' &&
                VERIFICATION?.r_action && (
                  <div className="w-full p-4 rounded bg-white80 xl:bg-white mt-4">
                    <div className="flex items-center mb-2">
                      {/*<img src={failed_t} alt="" />*/}
                      <p className=" text-xs font-medium uppercase text-grey">
                        recommended action
                      </p>
                    </div>
                    <p className="mt-2 text-sm text-body">
                      {VERIFICATION?.r_action || '-'}
                    </p>
                  </div>
                )}
              {(vStatus === 'pending' || vStatus === 'failed') &&
                vStatus !== 'successful' &&
                vStatus !== 'completed' &&
                VERIFICATION?.comment && (
                  <div className="w-full p-4 rounded bg-white80 xl:bg-white mt-4">
                    <div className="flex items-center mb-2">
                      <img src={failed_t} alt="" />
                      <p className="ml-2 text-xs font-medium uppercase text-grey">
                        reason for{' '}
                        {VERIFICATION?.r_action?.toLowerCase() === 'failed'
                          ? VERIFICATION?.r_action
                          : vStatus}{' '}
                        verification
                      </p>
                    </div>
                    <p className="mt-2 text-sm text-body">
                      {VERIFICATION.comment || '-'}
                    </p>
                  </div>
                )}
            </>
          ) : (
            <>
              {loading
                ? null
                : vStatus !== 'successful' &&
                  vStatus !== 'completed' &&
                  vStatus !== 'pending' &&
                  vStatus !== 'failed' &&
                  VERIFICATION?.r_action && (
                    <div className="w-full p-4 rounded bg-white80 xl:bg-white mt-4">
                      <div className="flex items-center mb-2">
                        {/*<img src={failed_t} alt="" />*/}
                        <p className=" text-xs font-medium uppercase text-grey">
                          recommended action
                        </p>
                      </div>
                      <p className="mt-2 text-sm text-body">
                        {VERIFICATION?.r_action || '-'}
                      </p>
                    </div>
                  )}
              {(vStatus === 'pending' || vStatus === 'failed') &&
                VERIFICATION?.comment && (
                  <div className="w-full p-4 rounded bg-white80 xl:bg-white mt-4">
                    <div className="flex items-center mb-2">
                      <img src={failed_t} alt="" />
                      <p className="ml-2 text-xs font-medium uppercase text-grey">
                        reason for {vStatus} verification
                      </p>
                    </div>
                    <p className="mt-2 text-sm text-body">
                      {VERIFICATION.comment || '-'}
                    </p>
                  </div>
                )}{' '}
              {Array.isArray(COST_SUMMARY) && COST_SUMMARY?.length > 0 && (
                <div className="w-full p-4 rounded bg-white80 xl:bg-white mt-4">
                  <div className="flex items-center mb-2">
                    <p className="text-xs font-medium uppercase text-grey">
                      Total cost
                    </p>
                  </div>
                  <p className="mt-2 text-xl text-grey font-medium flex items-end gap-2">
                    {displayPrice(TOTAL_COST, TOTAL_COST / 100)}
                    {/*<span className="text-base text-body mb-[2px]">NGN</span>*/}
                  </p>
                </div>
              )}
              {Array.isArray(COST_SUMMARY) && COST_SUMMARY?.length > 0 && (
                <div className="w-full p-4 rounded bg-white80 xl:bg-white mt-4">
                  <div className="flex items-center mb-2">
                    <p className="text-xs font-medium uppercase text-grey">
                      Cost Summary breakdown
                    </p>
                  </div>
                  <CostSummary
                    summary={COST_SUMMARY}
                    displayPrice={displayPrice}
                  />
                </div>
              )}
            </>
          )}

          <ActivityLog />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(Layout);
