import React, {Suspense, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {QueryClientProvider} from 'react-query';
import {ToastContainer} from 'react-toastify';

import Main from './Main';
import store from './store';
import {saveState} from './helpers';
import Loader from './components/Loader';
import {initializeServices} from './appToolsInit';
import {PageProvider} from './helpers/context/PageContext';
import GlobalContextProvider from './helpers/context/GlobalContextProvider';

import './tailwind.css';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

const {queryClient} = initializeServices();

// Save state to localStorage to persist Redux state
store.subscribe(() => {
  saveState(store.getState());
});

// Utility function to check authenticated pages
const isAuthenticatedPage = pathname => {
  const authenticatedPaths = [
    '/signin',
    '/complete/sign-up',
    '/signup/add-company',
    '/join-company',
    '/create-password',
    '/create-password/:token',
    '/forgot-password',
    '/reset-password/:token',
  ];

  return authenticatedPaths.some(path => {
    if (path.includes(':')) {
      const pathTokens = path.split('/');
      const currentPathTokens = pathname.split('/');

      if (pathTokens.length !== currentPathTokens.length) {
        return false;
      }

      return pathTokens.every(
        (token, i) => token.startsWith(':') || token === currentPathTokens[i],
      );
    }

    return path === pathname;
  });
};

const App = () => {
  const currentPath = useMemo(() => window.location.pathname, []);
  const isAuthPage = isAuthenticatedPage(currentPath);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PageProvider>
          <GlobalContextProvider isAuthenticated={isAuthPage}>
            <Suspense fallback={<Loader />}>
              <Router>
                <Main />
              </Router>
            </Suspense>
            <ToastContainer autoClose={5000} />
          </GlobalContextProvider>
        </PageProvider>
      </Provider>
    </QueryClientProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
