import {Constants} from '../../helpers';
import * as Assets from '../../assets/images/images';

const reconciloNavLinks = [
  {
    url: Constants.PAGES_URL.PROJECT_ACCOUNTS,
    imgSrc: Assets.projectsNav,
    label: 'Accounts',
  },
  {
    url: Constants.PAGES_URL.PROJECT_TRANSACTIONS,
    imgSrc: Assets.transactionsNav,
    label: 'Transactions',
  },
  {
    url: Constants.PAGES_URL.PROJECT_RECONCILIATION,
    imgSrc: Assets.discrepanciesNav,
    label: 'Reconciliation',
  },
  {
    url: Constants.PAGES_URL.PROJECT_CUSTOMERS,
    imgSrc: Assets.usersNav,
    label: 'Customers',
  },
  {
    url: Constants.PAGES_URL.PROJECT_SETTINGS,
    imgSrc: Assets.settingsNav,
    label: 'Project Settings',
  },
];

const baasNavLinks = [
  {
    url: Constants.PAGES_URL.BAAS_OVERVIEW,
    imgSrc: Assets.baasOverview,
    label: 'Overview',
  },
  {
    url: Constants.PAGES_URL.BAAS_TRANSACTIONS,
    imgSrc: Assets.baasTransactions,
    label: 'Transactions',
  },
  {
    url: Constants.PAGES_URL.BAAS_WALLETS,
    imgSrc: Assets.baasWallets,
    label: 'Wallets',
  },
  {
    url: Constants.PAGES_URL.BAAS_PAYOUTS,
    imgSrc: Assets.baasPayouts,
    label: 'Payouts',
  },
  {
    url: Constants.PAGES_URL.BAAS_SETTINGS,
    imgSrc: Assets.baasSettings,
    label: 'BAAS Settings',
  },
];

export {reconciloNavLinks, baasNavLinks};
