import React, {useMemo} from 'react';
import Chart from 'react-apexcharts';
import {shortenLargeNumbers} from '../helpers/shortenLargeNumbers';
import {SkeletonLoader} from './index';

function VerificationAnalyticsPieChart({verifications = {}, isLoading}) {
  const options = {
    labels: ['Successful', 'Failed', 'Abandoned', 'In Progress', ''],
    colors: ['#87D4DB', '#F15D5F', '#2A5392', '#73739B'],
    dataLabels: {
      enabled: false,
    },
    legend: false,
    stroke: {
      width: 0,
    },
    tooltip: {
      theme: false,
    },
    chart: {
      width: 300,
      height: 300,
    },
  };
  const {Completed, Failed, Abandoned, Pending, Ongoing} = verifications || {};

  const series = useMemo(
    () => [
      Completed || 0,
      Failed || 0,
      Abandoned || 0,
      (Pending || 0) + (Ongoing || 0),
    ],
    [Abandoned, Completed, Failed, Ongoing, Pending],
  );

  const TOTAL_VERIFICATIONS = useMemo(
    () => series.reduce((sum, value) => sum + (value || 0), 0),
    [series],
  );

  const formatNumber = num => {
    const maxNumber = 1000;
    const wholeNumber = Math.floor(num / 1000) * 1000;
    return num > maxNumber && num > wholeNumber ? '+' : '';
  };

  return (
    <div className="relative flex justify-center items-center">
      {isLoading ? (
        <SkeletonLoader
          style={{
            width: 180,
            height: 180,
            borderRadius: '50%',
            marginLeft: 60,
          }}
          className="before:h-[200px]"
        />
      ) : (
        <Chart type="donut" options={options} series={series} height={200} />
      )}
      <div
        className="absolute flex flex-col items-center w-fit"
        // style={{top: '34%'}}
      >
        {!isLoading && (
          <>
            <h1 className="font-semibold text-base xl:text-xxl text-grey">
              {shortenLargeNumbers(TOTAL_VERIFICATIONS || 0, null, {
                decimals: 0,
              })}
              <span className="font-normal text-grey-500 text-xl">
                {formatNumber(TOTAL_VERIFICATIONS)}
              </span>
            </h1>{' '}
            <span className="text-[6px] xl:text-xs font-medium uppercase text-grey">
              Verifications
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default VerificationAnalyticsPieChart;
