/* eslint-disable react-hooks/exhaustive-deps */

import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {Modal, PrimaryButton, SecondaryButton} from '../../../../components';
import {closeModal} from '../../../../assets/images/images';
import RangeSlider from './RangeSlider';
import {CreditCheckContext} from '../../../../helpers/context/CreditCheckContext';
import {toast} from 'react-toastify';
import {createFlowProcess} from '../../../../store/actions';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {fetchFlow, updateFlow} from '../../../../requests/queries/flows';
import {useParams} from 'react-router-dom';

export default function EndProcessModal() {
  const {slug} = useParams();
  const queryClient = useQueryClient();

  const {
    set: {setRangeValue, setOpenModals, setTempFlow},
    get: {
      tempFlow,
      openModals: {openEndProcessModal},
      rangeValue = [25, 75],
    },
  } = useContext(CreditCheckContext);
  const {data: flowData, isLoading} = useQuery(['e-flow', slug], () =>
    fetchFlow(slug),
  );
  const currentFlowData = useMemo(
    () => !isLoading && flowData && flowData?.entity,
    [flowData, isLoading],
  );

  const {mutate: updateCurrentFLow, isLoading: updateLoading} = useMutation(
    'update-flow',
    updateFlow,
  );

  const process = useMemo(() => {
    const newProcess = [...tempFlow];
    newProcess.forEach((flow, index) => {
      if (flow?.type === 'ACTION') {
        newProcess[index].threshold = {
          review: rangeValue[0],
          block: rangeValue[1],
        };
      }
    });
    return newProcess;
  }, [tempFlow, rangeValue]);

  const editEndingProcess = useCallback(async () => {
    setTempFlow(process);
    createFlowProcess({process: process});

    try {
      const data = {
        name: currentFlowData?.name,
        webhook: currentFlowData?.webhook || ' ',
        notification_emails: currentFlowData?.notification_emails || [' '],
        c_webhook: currentFlowData?.convoy_url || ' ',
        historical_config: {
          retention_period_months: 6,
          min_transactions_required: 3,
          max_transactions_analyzed: 1000,
          include_fields: [
            'amount',
            'currency',
            'type',
            'outcome',
            'risk_score',
          ],
        },
        outcome_ranges: {
          block: {
            min: rangeValue[1] + 1,
            max: 100,
          },
          review: {
            min: rangeValue[0] + 1,
            max: rangeValue[1],
          },
          allow: {
            min: 0,
            max: rangeValue[0],
          },
        },
      };

      await updateCurrentFLow(
        {
          id: slug,
          data: {...data},
        },
        {
          onSuccess: data => {
            queryClient?.invalidateQueries();
            toast.success(data?.message || 'FLow updated successfully');
            setOpenModals({openEndProcessModal: false});
          },
          onError: error => {
            toast.error(error?.response?.data?.error);
          },
        },
      );
    } catch (e) {
      console.log(e);
    }
  }, [
    setTempFlow,
    process,
    currentFlowData?.name,
    currentFlowData?.webhook,
    currentFlowData?.notification_emails,
    currentFlowData?.convoy_url,
    rangeValue,
    updateCurrentFLow,
    slug,
    queryClient,
    setOpenModals,
  ]);

  useEffect(() => {
    setTempFlow(process);
    createFlowProcess({process});
    setRangeValue([
      currentFlowData?.outcome_ranges?.allow?.max || 25,
      currentFlowData?.outcome_ranges?.review?.max || 75,
    ]);
  }, [
    currentFlowData?.outcome_ranges?.allow?.max,
    currentFlowData?.outcome_ranges?.review?.max,
    setRangeValue,
    setTempFlow,
  ]);

  return (
    <Modal
      show={openEndProcessModal}
      onClose={value => setOpenModals({openEndProcessModal: value})}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="py-6 sm:py-12 px-[19px] sm:px-6">
        <header className="flex items-center justify-between">
          <h3 className="text-base font-medium text-grey">End process</h3>
          <button onClick={() => setOpenModals({openEndProcessModal: false})}>
            <img src={closeModal} alt="" width={24} height={24} />
          </button>
        </header>
        <div className="mt-6 bg-white80 rounded w-full p-4">
          <label htmlFor="edit_name" className="relative">
            Edit name
            <input
              id="edit_name"
              className="mt-2 bg-white"
              placeholder="End process"
            />
          </label>
          <div className="mt-4">
            <label htmlFor="status">Status</label>
            <p className="mt-1 text-start text-small text-body">
              Set a percentage(%) value (Move sliders to change){' '}
            </p>
            <p className="text-sm text-start font-medium mt-2">
              <span className="text-success">Allow, </span>
              <span className="text-body">Pending</span>
              <span className="text-grey"> and </span>
              <span className="text-status-404">Blocked</span>
            </p>
            <div className="relative mt-5">
              <RangeSlider
                onChange={setRangeValue}
                className="w-full h-4"
                defaultValue={[25, 75]}
                value={rangeValue}
              />
              <div className="absolute -bottom-[3px] right-0 h-[19.02px] w-[19.02px] flex items-center bg-status-404 rounded-full aspect-square cursor-not-allowed">
                <span className="text-xs text-white mt-[2px]">100</span>
              </div>
              <div className="cursor-not-allowed bg-status-404 absolute bottom-[19px] -right-[17px] px-2 pt-[5px] pb-[3px] text-white text-xs rounded after:content-[''] after:absolute after:block after:w-0 after:h-0 after:border-l-transparent after:border-l-4 after:border-b-4 after:border-r-transparent after:border-r-4 after:rotate-180 after:left-1/2 after:-translate-x-1/2 after:-bottom-[3px] after:border-b-status-404">
                Blocked
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center sm:justify-center gap-2 mt-6">
          <PrimaryButton
            buttonText="Apply"
            className="w-full"
            onClick={editEndingProcess}
            loading={updateLoading}
          />
          <SecondaryButton
            buttonText="Reset"
            onClick={() => {
              setRangeValue([25, 75]);
            }}
            className="whitespace-nowrap"
          />
        </div>
      </div>
    </Modal>
  );
}
