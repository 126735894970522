import React from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import {getLighterShade} from '../colorUtils';
import {InfoIcon} from '../InfoIcon';

const BusinessID = ({widgets: {singleApp}}) => {
  const baseColor = singleApp?.color || singleApp?.color_code || '';

  const lighterShade = getLighterShade(baseColor, 90);

  const buttonStyle = {
    backgroundColor: baseColor,
  };

  const textColor = {
    color: baseColor,
  };

  const lighterBackgroundStyle = {
    backgroundColor: lighterShade,
  };

  return (
    <>
      <div className="mt-3 overflow-y-auto max-w-sm w-full">
        <div
          className="mx-auto w-fit px-2 pt-2 rounded-[10px] mb-3 text-center"
          style={lighterBackgroundStyle}
        >
          <span
            className="text-sm font-medium inline-flex items-center font-inter"
            style={textColor}
          >
            <InfoIcon backgroundColor={baseColor} />
            To continue this on your smartphone.
            <span className="font-semibold ml-[2px]">Click here</span>
          </span>
        </div>

        <p className="text-center text-base font-medium text-[#1B2A4E] mb-3">
          Upload document
        </p>

        <label className="mb-1 text-[#1B2A4E] text-sm font-normal text-start">
          Upload document
        </label>
        <div className="flex flex-col justify-center items-center">
          <div className="border border-dashed bg-[#F6F6F8] w-full p-[14px] rounded border-[#677189]">
            <p className="text-[#68686C] text-sm text-center">
              Click here to upload document
            </p>
          </div>
          <p className="my-7 text-center text-body text-sm">
            Upload your international passport
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center w-full max-w-sm gap-4">
        <button
          style={buttonStyle}
          className="text-small !text-white font-medium h-12 bg-brandBlue rounded w-full"
          disabled
        >
          Upload
        </button>
        <button
          className="text-small font-medium h-12 bg-white rounded w-full border border-[#12376914]"
          disabled
        >
          <span style={textColor}>Capture Instead</span>
        </button>
      </div>
    </>
  );
};

export default connect(state => state, Actions)(BusinessID);
