/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {toast} from 'react-toastify';
import {Link, useNavigate} from 'react-router-dom';
import {
  amlScreeningIc,
  eye,
  infoBlue,
  infoGreen,
  infoRed,
  lookupEmpty,
  backNofill,
} from '../../../assets/images/images';
import {
  DashboardLayout,
  EmptyState,
  Pagination,
  PrimaryButton,
  TableLayout,
  PageLimit,
  Loader,
} from '../../../components';
import {PAGES_URL} from '../../../helpers/constants';
import {AMLScreeningTour} from '../../../components/Tour';
import PerformLookupModal from '../PerformLookupModal';
import * as Actions from '../../../store/actions';
import {PageContext, TourContext} from '../../../helpers/context';
import ToggleOngoingMonitoring from '../ToggleOngoingMonitoring';
import NoInfoModal from '../NoInfoModal';
import {Constants} from '../../../helpers';
import {OverviewCards} from '../../../components/OverviewCards';
import {buildTableCardData} from '../../../helpers/buildTableCardData';

function AmlUserScreeningSingle({
  getIndividualAMLHistories,
  getAllApps,
  getAMLHistory,
  amlConfigApi,
  auth: {userDetails, updated},
  aml: {amlHistories, loading, configLoading},
  apps: {allApps},
}) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState('1');
  const [resultPage, setResultPage] = useState(false);
  const [limit, setLimit] = useState(10);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [dummyData, setDummyData] = useState('');
  const [noInfoDialog, setNoInfoDialog] = useState(false);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('amllookup');
  }, [setPageName]);

  const appIds =
    useMemo(
      () => allApps?.apps && allApps?.apps?.map(app => app?._id)?.toString(),
      [allApps],
    ) || localStorage.getItem('appIds');

  useEffect(() => {
    getAllApps({});
    userDetails?.company?.id &&
      getIndividualAMLHistories({
        page: currentPage,
        companyId: userDetails?.company?.id,
        limit,
        appIds,
      });
  }, [
    getIndividualAMLHistories,
    userDetails?.company?.id,
    amlConfigApi,
    limit,
    currentPage,
    getAllApps,
    updated,
    Boolean(appIds?.split(',')?.length),
  ]);

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const getStatusInfo = status => {
    switch (status) {
      case 'failed':
        return 'something went wrong';

      case 'available':
        return 'Info available';

      case 'not_availbale':
        return 'No info returned';
      default:
        return 'No info returned';
    }
  };

  const DATA = amlHistories?.records;

  const {tourState, updateTourState} = useContext(TourContext);

  const {performSingleIndividualLookup: canPerformAndViewAML} = useMemo(
    () => userDetails?.permissions?.amlScreeningPermissions || {},
    [],
  );

  // const canConfigureOngoingMonitoring =
  //   userDetails?.permissions?.amlPermissions?.ongoingMonitoring;
  const handleViewAMLDetails = useCallback(
    history => {
      if (
        history?.status === 'not_available' ||
        history?.status === 'not_availbale'
      ) {
        setDummyData(
          `${history?.first_name || history?.business_name || '-'} ${
            history?.middle_name || ''
          } ${history?.last_name || ''}`,
        );
        setNoInfoDialog(true);
        return;
      }

      if (!history?.aml_entity_lookup_id) return;
      if (appIds && history?.id) {
        getAMLHistory({
          id: history?.aml_entity_lookup_id,
          watchlist_history_id: history?.id,
          entityType: history.entity_type,
          lookup_id: history?.lookup_id,
          page: Constants.PAGES_URL.AML_USER_SCREENING_SEARCH_RESULTS,
          navigate,
        });
        localStorage.setItem('aml_app_id', history?.app_id);
        history.entity_type === 'business'
          ? localStorage.setItem('organisation', 'organisation')
          : localStorage.removeItem('organisation');
        localStorage.setItem(
          'amlData',
          JSON.stringify({
            watchlist_id: history?.aml_user_lookup,
            company_id: history?.company_id,
            ongoing_monitoring: history?.ongoing_monitoring || false,
            watchlist_history_id: history?.id,
            no_of_times_checked: history?.no_of_times_checked,
            date_created: history?.date_created,
          }),
        );
        setResultPage(true);
        // setStep(2);
        return;
      }
      toast.error(
        <div>
          Please create an app{' '}
          <span
            className="underline font-bold	"
            onClick={() => navigate('/developers/configuration')}
          >
            {' '}
            HERE{' '}
          </span>{' '}
          to view details
        </div>,
      );
    },
    [appIds],
  );

  const tableCardData = buildTableCardData({
    count: amlHistories?.totalRecords,
  });

  return (
    <DashboardLayout
      bg="bg-white80"
      xlLeftMargin="xl:ml-12"
      breadCrumbs={
        <div className="flex items-center">
          <img src={amlScreeningIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">AML Screening</p>
        </div>
      }
    >
      {tourState?.aml_screening_tour ? (
        <AMLScreeningTour
          updateTourState={updateTourState}
          DATA={DATA}
          loading={loading}
        />
      ) : null}
      {/*<OngoingMonitoringConfig setOpen={setOnGoing} open={onGoing} />*/}
      <ToggleOngoingMonitoring
        loading={configLoading}
        setOpen={setOpenConfirm}
        open={openConfirm}
      />
      <NoInfoModal open={noInfoDialog} setOpen={setNoInfoDialog} />
      <PerformLookupModal
        open={open}
        setOpen={setOpen}
        step={step}
        setStep={setStep}
        dummyData={dummyData}
        lookupType="Single"
      />

      <>
        <Link
          to={PAGES_URL.AML_USER_SCREENING}
          className="flex items-center gap-2"
        >
          <img src={backNofill} alt="" />
          <span className="text-small text-grey80 font-medium -mb-1">Back</span>
        </Link>
        <div className="flex pt-5 flex-col items-start justify-between gap-6 mt-4 mb-6 md:flex-row md:items-center md:gap-0 sm:mt-0">
          <h2 className="text-base  relative flex items-center font-medium text-grey sm:text-lg">
            AML Screening
          </h2>
          <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
            <PrimaryButton
              buttonText="Perform AML Lookup"
              xPadding="px-4"
              className="hidden sm:flex"
              onClick={() => {
                setOpen(true);
              }}
              disabled={!canPerformAndViewAML}
              title={
                !canPerformAndViewAML
                  ? 'You do not have permission to do this'
                  : ''
              }
            />
            <div className="fixed bottom-0 left-0 right-0 z-10 px-4 py-2 overflow-x-hidden sm:hidden bg-white80">
              <PrimaryButton
                buttonText="Perform AML Lookup"
                xPadding="p-4"
                className="w-full bottom-2"
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>
          </div>
        </div>

        <OverviewCards
          cardData={tableCardData}
          overviewLoading={loading}
          className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-1 gap-2 lg:gap-4 flex-wrap my-5`}
        />

        <TableLayout negativeMargins>
          <thead className="text-xs font-semibold uppercase">
            <tr className="bg-white80">
              <th className="p-5 pl-4 sm:pl-6 xl:pl-12 font-medium text-left text-xs text-grey whitespace-nowrap">
                name
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                AML Status
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                Type
              </th>
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                Date created
              </th>{' '}
              <th className="p-5 pl-0 font-medium text-left text-xs text-grey whitespace-nowrap">
                Entity type
              </th>
              <th className="w-[18.69%]" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={8}>
                {loading || resultPage ? (
                  <Loader height={45} />
                ) : !loading && DATA?.length === 0 ? (
                  <EmptyState
                    noBtn
                    className="w-full"
                    height={45}
                    src={lookupEmpty}
                    body="Perform multiple identity lookup with ease."
                    maxWidth="max-w-[500px]"
                    customBtn={
                      <div className="flex gap-6 mt-2 w-full justify-center">
                        <PrimaryButton
                          buttonText="Perform AML Lookup"
                          onClick={() => {
                            setOpen(true);
                          }}
                        />
                      </div>
                    }
                  />
                ) : null}
              </td>
            </tr>
          </tbody>

          <tbody className="text-sm bg-white">
            {!loading &&
              DATA?.map((history, i = 0) => (
                <tr
                  className={`border-b border-grey60 ${
                    history?.aml_entity_lookup_id &&
                    history?.status === 'available'
                      ? 'cursor-pointer'
                      : history?.status === 'not_availbale'
                      ? 'cursor-pointer'
                      : 'cursor-default'
                  }`}
                  key={i}
                >
                  <td
                    className="p-4 pl-4 sm:pl-6 xl:pl-12 text-tiny text-body"
                    onClick={() => handleViewAMLDetails(history)}
                  >
                    {`${history?.first_name || history?.business_name || '-'} ${
                      history?.middle_name || ''
                    } ${history?.last_name || ''}`}
                  </td>
                  <td
                    className="p-4 pl-0"
                    onClick={() => handleViewAMLDetails(history)}
                  >
                    <div
                      className={`flex items-center gap-2 uppercase py-1 px-2 text-xs ${
                        history?.status === 'failed'
                          ? 'text-danger  bg-statusBg-500'
                          : history?.status === 'available'
                          ? 'text-success  bg-statusBg-200'
                          : 'text-brandBlue50  bg-brandBlue40'
                      } font-medium  rounded-full w-fit`}
                    >
                      <img
                        src={
                          history?.status === 'failed'
                            ? infoRed
                            : history?.status === 'available'
                            ? infoGreen
                            : infoBlue
                        }
                        alt=""
                        width={12}
                        height={12}
                      />
                      <span className="mt-1">
                        {getStatusInfo(history?.status)}{' '}
                      </span>
                    </div>
                  </td>
                  <td
                    className="min-w-[100px]"
                    onClick={() => handleViewAMLDetails(history)}
                  >
                    <p
                      className={`py-1 px-2 text-xs font-medium  w-fit uppercase rounded-full ${
                        history?.call_type?.toLowerCase() === 'no-code'
                          ? 'text-success  bg-statusBg-200'
                          : history?.call_type?.toLowerCase() === 'api'
                          ? 'text-brandBlue50  bg-brandBlue40'
                          : 'text-success  bg-statusBg-200'
                      }`}
                    >
                      {history?.call_type || 'no-code'}
                    </p>
                  </td>

                  <td
                    className="p-4 pl-0 text-tiny text-body"
                    onClick={() => handleViewAMLDetails(history)}
                  >
                    {moment(history?.date_created).format(
                      'Do, MMM YYYY, hh:mm a',
                    )}
                  </td>

                  <td
                    className="min-w-[100px]"
                    onClick={() => handleViewAMLDetails(history)}
                  >
                    <p
                      className={`py-1 px-2 text-xs font-medium  w-fit uppercase rounded-full ${
                        history?.entity_type?.toLowerCase() === 'business'
                          ? 'text-brandBlue50  bg-brandBlue40'
                          : 'text-danger  bg-statusBg-500'
                      }`}
                    >
                      {history?.entity_type || 'Individual'}
                    </p>
                  </td>

                  <td
                    className="p-4 pl-0"
                    onClick={() => handleViewAMLDetails(history)}
                  >
                    <button
                      className={
                        history?.aml_entity_lookup_id &&
                        history?.status === 'not_available'
                          ? 'cursor-pointer'
                          : history?.status === 'not_availbale'
                          ? 'cursor-pointer'
                          : 'cursor-default'
                      }
                      title={
                        !canPerformAndViewAML
                          ? 'You do not have permission to view'
                          : 'View'
                      }
                      onClick={() => {
                        if (!canPerformAndViewAML) return;
                        if (
                          history?.status === 'not_available' ||
                          history?.status === 'not_availbale'
                        ) {
                          setDummyData(
                            `${history?.first_name || '-'} ${
                              history?.last_name || '-'
                            }`,
                          );
                          setNoInfoDialog(true);
                          return;
                        }
                        if (!history?.aml_entity_lookup_id) return;

                        handleViewAMLDetails(history);
                        // getAMLHistory({id: history?.aml_user_lookup});
                        // localStorage.setItem('aml_app_id', history?.app_id);
                        setResultPage(true);
                      }}
                      disabled={!canPerformAndViewAML}
                    >
                      <img src={eye} alt="" width={16} height={16} />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </TableLayout>

        {!loading && amlHistories?.totalRecords > 0 && (
          <div className="flex flex-wrap items-center justify-between gap-4 mt-8 sm:gap-0">
            <div className="flex items-center text-body text-tiny">
              <PageLimit
                limit={limit}
                onLimitChange={setLimit}
                total={amlHistories && amlHistories?.totalRecords}
                length={amlHistories && amlHistories?.records?.length}
              />
            </div>

            <div className="mb-8 sm:mb-0">
              <Pagination
                total={
                  amlHistories && Math.ceil(amlHistories?.totalRecords / limit)
                }
                current={+currentPage}
                onPageChange={activePage => {
                  localStorage.setItem('page', String(activePage));
                  pageClick(activePage);
                }}
              />
            </div>
          </div>
        )}
      </>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(AmlUserScreeningSingle);
